import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import { AlertTriangle, ArrowLeft, ArrowRight, Check, ExternalLink, RefreshCw, X } from "react-feather";
import Swal from "sweetalert2";
import {
  INSERT_PHOTO_TYPE,
  LOADED,
  LOADING,
  PHOTO_TYPE,
} from "../../../../constant";
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../common/datatable";
import { useSelector } from "react-redux";
// customer hooks
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../customHooks/useValidation";
import ConfigDB from "../../../../data/customizer/config";
import default_avatar from "../../../../assets/images/user/default_avatar.png";
import { LoadStaticURL } from "../../../../data/util/LoadStaticURL";
import DocumentUploader from "../../../common/documentUploader";
import FileUploader from "../../../common/fileUploader";
import ImageUploader from "../../../common/imageUploader";

const Photo = ({ b_partner_id = 0, client_id = 0, org_id = 0, set_basic_tab = null, set_back_tab = null }) => {
  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  const MAXFILELIMIT = 1;

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  // helper containers
  const [attachmentList, setAttachmentList] = useState([]);

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);
  const [previewFileList, setPreviewFileList] = useState([]);
  const [attachmentExtension, setAttachmentExtension] = useState();

  // form controls
  // const [profilePicture, setProfilePicture] = useState({
  //   base64Value: "",
  //   extension: "",
  //   changed: false,
  //   url: "",
  // });
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshDpPhotoType, setRefreshDpPhotoType] = useState(false);

  // form values
  const [photoTypeList, setPhotoTypeList] = useState([
    { photoTypeId: "", name: "-Select-" },
  ]);
  const [dpPhotoTypeValue, setDpPhotoTypeValue] = useState(0);
  const [uploadedPhotoTypeList, setUploadedPhotoTypeList] = useState([]);
  const [photoId, setPhotoId] = useState(0);

  const [isSuccess, setIsSuccess] = useState(false);

  // table data
  const [photoRowList, setPhotoRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Photo Type",
    // "Remarks",
    // {
    //   name: "Active",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <BooleanValue value={value} />;
    //     },
    //   },
    // },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editPhoto] = useState(() => (photo_id, response_data) => {
    const editingPhoto = response_data.find(
      (item) => item.photoId === photo_id
    );
    console.log("edit photo");
    setPhotoId(editingPhoto.photoId);
    setDpPhotoTypeValue(editingPhoto.photoTypeId);
    // setProfilePicture({
    //   changed: false,
    //   base64Value: "",
    //   extension: "",
    //   url: editingPhoto.documentPath,
    // });
    setAttachmentList([{
      documentId: editingPhoto.photoId,
      isModified: false,
      attachmentExtension: "",
      attachment: "",
    }]);
    // setPreviewFileList([{
    //   id: editingPhoto.photoId,
    //   type: editingPhoto.filePath.split(".")[1],
    //   source: editingPhoto.filePath,
    // }]);

    console.log(editingPhoto.photoId);
    console.log(editingPhoto.documentPath.split(".")[1]);
    console.log(editingPhoto.documentPath);
    setPreviewFileList([{
      id: editingPhoto.photoId,
      type: editingPhoto.documentPath.split(".")[1],
      source: editingPhoto.documentPath,
    }]);

    document.querySelector("textarea[name='fm_11_remark']").value =
      editingPhoto.remark;
    // if (document.querySelector("input[name='fm_11_is_active']")) {
    //   document.querySelector("input[name='fm_11_is_active']").checked =
    //     editingPhoto.isActive;
    // }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    // const reqBody = {
    //   photoId: editingPhoto.photoId,
    // };
    // new Promise((resolve) => {
    //   const result = sendRequest({
    //     url: "/customer/getPhotoAttachmentListByPhotoId",
    //     data: reqBody,
    //   });
    //   resolve(result);
    // }).then((response_data) => {
    //   if (response_data) {
    //     let tempList = [];
    //     let tempList2 = [];
    //     response_data.forEach((listItem) => {
    //       tempList.push({
    //         documentId: listItem.id,
    //         isModified: false,
    //         attachmentExtension: "",
    //         attachment: "",
    //       });
    //       tempList2.push({
    //         id: listItem.id,
    //         type: listItem.filePath.split(".")[1],
    //         source: listItem.filePath,
    //       });
    //     });
    //     setPreviewFileList(tempList2);
    //     setAttachmentList([...tempList]);
    //     setIsLoaded(LOADED);
    //     setRefreshTable(false);
    //   }
    // });

  });
  const [deletePhoto] = useState(() => (photo_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this photo!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          photoId: photo_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "customer/deletePhoto",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Photo has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });

  // form control functions
  const submitForm = (evt) => {
    console.log(evt);
    setIsValidated(true);
    evt.preventDefault();
    console.log(uploadedPhotoTypeList);
    const existResult = uploadedPhotoTypeList.find(
      (photo_type) => photo_type.photoTypeId === parseInt(dpPhotoTypeValue)
    );
    // if (profilePicture.base64Value === "" && profilePicture.url === "") {
    //   evt.target.setCustomValidity("Invalid");
    // } else if (photoId === 0 && existResult) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Already Exists",
    //     text: `A photo related to ${
    //       photoTypeList.find(
    //         (photo_type) =>
    //           photo_type.photoTypeId === parseInt(dpPhotoTypeValue)
    //       ).name
    //     } has already added.`,
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,
    //     showDenyButton: false,
    //     confirmButtonText: "Ok",
    //   });
    // } else {

    if (!attachmentList || attachmentList.length === 0) {
      // Display an error message if attachmentList is empty
      console.log("Attachment list is empty. cannot save photo data.");
      Swal.fire({
        icon: "error",
        title: "Attachment Error",
        text: "Attachment list is empty. Cannot save photo data.",
        showConfirmButton: false,
        timer: 5000,
      });
      return; // Exit the function early if attachmentList is empty
    }

    // if(attachmentList=== ""){

    //   console.log("not apply file");
    // }


    if (evt.target.checkValidity()) {

      const formData = new FormData(evt.target);


      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/checkCustomerPhotoTypeExist",
          data: {
            clientId: parseInt(loggedUser.clientId),
            bpartnerId: parseInt(b_partner_id),
            photoId: photoId,
            photoTypeId: parseInt(dpPhotoTypeValue),
          },
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          switch (response_data.existType) {
            case 0:

              let photo = {
                clientId: parseInt(client_id),
                orgId: parseInt(org_id),
                userId: parseInt(loggedUser.userId),
                photoId: photoId,
                bpartnerId: parseInt(b_partner_id),
                photoTypeId: parseInt(dpPhotoTypeValue),
                isActive: true,
                // isActive: formData.get("fm_11_is_active") ? true : false,
                // isAttachmentModified: profilePicture.changed,
                // attachmentExtension: profilePicture.extension,
                // attachment: profilePicture.base64Value, //base64
                isAttachmentModified: attachmentList[0].isModified,
                attachmentExtension: attachmentList[0].attachmentExtension,
                remark: formData.get("fm_11_remark"),
                attachment: attachmentList[0].attachment,
              };

              console.log(attachmentList[0].attachment);



              let nestedResponseData = new Promise((resolve) => {
                const result = sendRequest({
                  url: "/customer/savePhotoTab",
                  data: photo,
                });
                resolve(result);
              });
              nestedResponseData.then((nested_reponse_data) => {
                if (nested_reponse_data) {
                  Swal.fire({
                    icon: "success",
                    title: "Request Successful",
                    text: photoId
                      ? "Photo has successfully updated."
                      : "Photo has successfully added.",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  setIsLoaded(LOADING);
                  setRefreshTable(true);
                  setIsSuccess(true);
                }
              });
              break;
            case 1:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Photo Type Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            default:
              break;
          }
        }
      });






      // let family = {
      //   clientId: parseInt(client_id),
      //   orgId: parseInt(org_id),
      //   userId: parseInt(loggedUser.userId),
      //   photoId: photoId,
      //   bpartnerId: parseInt(b_partner_id),
      //   photoTypeId: parseInt(dpPhotoTypeValue),
      //   isActive: formData.get("fm_11_is_active") ? true : false,
      //   // isAttachmentModified: profilePicture.changed,
      //   // attachmentExtension: profilePicture.extension,
      //   // attachment: profilePicture.base64Value, //base64
      //   isAttachmentModified: attachmentList[0].isModified,
      //   attachmentExtension: attachmentList[0].attachmentExtension,
      //   remark: formData.get("fm_11_remark"),
      //   attachment: attachmentList[0].attachment,
      // };
      // console.log(parseInt(client_id));
      // let responseData = new Promise((resolve) => {
      //   const result = sendRequest({
      //     url: "/customer/savePhotoTab",
      //     data: family,
      //     template: "CONTROL_DATA",
      //   });
      //   resolve(result);
      // });
      // responseData.then((reponse_data) => {
      //   if (reponse_data) {
      //     Swal.fire({
      //       icon: "success",
      //       title: "Request Successful",
      //       text: photoId
      //         ? "Photo has successfully updated."
      //         : "Photo has successfully added.",
      //       showConfirmButton: false,
      //       timer: 5000,
      //     });
      //     setIsLoaded(LOADING);
      //     setRefreshTable(true);
      //   }
      // });
    }
    // }
  };

  const resetForm = (evt) => {
    evt.preventDefault();
    // setIsValidated(false);
    // setDpPhotoTypeValue(0);
    // setPhotoId(0);
    // setAttachmentList([]);
    // setPreviewFileList([]);
    // setProfilePicture({
    //   base64Value: "",
    //   extension: "",
    //   url: "",
    //   changed: true,
    // });
    // let inputFields = document.querySelectorAll(".form-control");
    // let customValidationMessages =
    //   document.querySelectorAll(".input-validation");
    // inputFields.forEach((field) => {
    //   if (field.classList.contains("is-valid")) {
    //     field.classList.remove("is-valid");
    //   } else if (field.classList.contains("is-invalid")) {
    //     field.classList.remove("is-invalid");
    //   }
    // });
    // customValidationMessages.forEach((element) => {
    //   if (element.classList.contains("input-validation")) {
    //     if (!element.classList.contains("d-none")) {
    //       element.classList.add("d-none");
    //     }
    //   }
    // });

    Swal.fire({
      title: 'Are you sure you want to reset?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {

        setDpPhotoTypeValue(0);
        setPhotoId(0);
        setAttachmentList([]);
        setPreviewFileList([]);

        document.getElementById('id_input_fm_11_remark').value = '';

        setIsValidated(false);
      }
    });

    setIsSuccess(false)
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/configuration/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewPhotoTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            if (response_data.activePhotoTypeList) {
              let tempPhotoTypeList = [];
              response_data.activePhotoTypeList.forEach((photo_type_item) => {
                let searchResult = response_data.allCustomerPhotoTableList.find(
                  (photo) => photo.photoTypeId === photo_type_item.photoTypeId
                );
                if (searchResult) {
                  tempPhotoTypeList.push({
                    photoId: searchResult.photoId,
                    photoTypeId: searchResult.photoTypeId,
                  });
                }
              });
              setUploadedPhotoTypeList(tempPhotoTypeList);
              setPhotoTypeList([
                { photoTypeId: "", name: "-Select-" },
                ...response_data.activePhotoTypeList,
              ]);
            }
            if (response_data.allCustomerPhotoTableList) {
              let tempList = [];
              response_data.allCustomerPhotoTableList.forEach((listItem) => {
                tempList.push([
                  listItem.photoId,
                  listItem.photoTypeName,
                  // listItem.remark,
                  // listItem.isActive ? "True" : "False",
                  <ControlButtons
                    editMethod={() =>
                      editPhoto(
                        listItem.photoId,
                        response_data.allCustomerPhotoTableList
                      )
                    }
                    disableEdit={!checkIsAccessible(EDITFUNCTION)}
                    deleteMethod={() =>
                      deletePhoto(listItem.photoId, loggedUser)
                    }
                    disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                  />,
                ]);
              });
              setPhotoRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
  }, [refreshTable]);

  // const readUrl = (event) => {
  //   if (event.target.files.length === 0) return;
  //   //Image upload validation
  //   var mimeType = event.target.files[0].type;
  //   // Image upload
  //   var reader = new FileReader();
  //   reader.readAsDataURL(event.target.files[0]);
  //   reader.onload = (_event) => {
  //     console.log(event.target);
  //     console.log(mimeType.replace(/image\//, "."));
  //     setProfilePicture({
  //       changed: true,
  //       base64Value: reader.result,
  //       extension: mimeType.replace(/image\//, "."),
  //       url: "",
  //     });
  //   };
  // };

  useEffect(() => {
    if (refreshDpPhotoType) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActivePhotoType",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setPhotoTypeList([
            { photoTypeId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpPhotoType(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpPhotoType]);

  const closeItem = () => {
    setIsSuccess(false);
  };

  const handleBackClick = () => {
    set_back_tab()
  };

  const handleNextClick = () => {
    set_basic_tab()
  };

  return (
    <>
      <div className="card-modified-body bg-white pt-0 mb-4">
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={b_partner_id === 0}
        >
          <AlertTriangle />
          <p className="ml-2">
            You must create a Customer, before add Customer Photos.
          </p>
        </Alert>
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-11 ${isValidated ? "was-validated" : ""
            }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}
          {/* <div className="form-row mb-4"> */}
          {/* <div className="form-group mb-4 mb-sm-0 col-12 col-sm-3 d-flex justify-content-center align-items-center">
              <div className="user-image">
                <div className="avatar">
                  <img
                    className="pro"
                    alt=""
                    src={
                      profilePicture.url
                        ? `${ConfigDB.data.fileServerUrl}${profilePicture.url}`
                        : profilePicture.base64Value
                        ? profilePicture.base64Value
                        : LoadStaticURL(default_avatar)
                    }
                    data-intro="This is Profile image"
                  />
                  <div className="icon-wrapper">
                    <i
                      className="icofont icofont-pencil-alt-5"
                      data-intro="Change Profile image here"
                    >
                      <input
                        accept="image/*"
                        className="pencil"
                        type="file"
                        onChange={(e) => readUrl(e)}
                      />
                    </i>
                  </div>
                </div>
              </div>
            </div> */}
          {/* <div className="col-12 col-sm-9"> */}
          <div className="form-group mb-4">
            <div className="form-group col-12 col-sm-3 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_11_sa_photo_type_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Photo Type
                </label>
                {/* <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${refreshDpPhotoType ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => setRefreshDpPhotoType(true)}
              /> */}
                {/* <ExternalLink
                className="mt-1 dp-refresh-icon"
                height={"14px"}
                onClick={() =>
                  openMasterFileWindow(PHOTO_TYPE, INSERT_PHOTO_TYPE)
                }
              /> */}
              </div>
              <select
                id="id_input_fm_11_sa_photo_type_id"
                name="fm_11_sa_photo_type_id"
                className="form-select"
                onChange={(evt) => setDpPhotoTypeValue(evt.target.value)}
                value={dpPhotoTypeValue}
                required
              >
                {photoTypeList.length
                  ? photoTypeList.map((listItem, index) => (
                    <option value={listItem?.photoTypeId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                  : null}
              </select>
            </div>
          </div>

          <ImageUploader
            attachment_list={attachmentList}
            preview_list={previewFileList}
            set_attachment_list={setAttachmentList}
            set_preview_list={setPreviewFileList}
            max_file_limit={MAXFILELIMIT}
          />

          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_11_remark">Remark</label>
            <textarea
              id="id_input_fm_11_remark"
              name="fm_11_remark"
              rows="3"
              maxLength={250}
              className="form-control"
              placeholder="Enter Remarks"
              onChange={(evt) =>
                formValidation({ event: evt, checkExpression: "" })
              }
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          {/* </div> */}
          {/* </div> */}
          {/* {(checkIsAccessible(INSERTFUNCTION) && photoId === 0) ||
            (checkIsAccessible(DEACTIVATEFUNCTION) && photoId !== 0) ? (
            <div className="form-group mb-4">
              <div className="checkbox checkbox-solid-dark col-12 col-sm-6 mx-1 mx-sm-0">
                <input
                  id="id_input_fm_11_is_active"
                  name="fm_11_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_11_is_active">Active</label>
              </div>
            </div>
          ) : null} */}
          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
              (checkIsAccessible(EDITFUNCTION) && photoId !== 0) ? (
              <div className="d-sm-flex justify-content-end">

                <button
                  className="btn btn-outline-primary col-md-1 col-3 px-1 mb-2 mb-sm-0"
                  type="reset"
                >
                  Reset
                </button>
                <button
                  className="btn btn-primary col-md-2 col-4 mx-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {photoId ? "Update" : "Submit"}
                </button>

                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleBackClick()}><ArrowLeft height={"16px"} width={"16px"} />Back</button>

                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleNextClick()}>Next<ArrowRight height={"16px"} width={"16px"} /></button>

              </div>
            ) : null
          ) : null}

          <br />

          {isSuccess && (
            <div className="col-sm-12 mb-2">
              <div className="bg-primary px-3 py-3 rounded d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div
                    style={{
                      backgroundColor: "#FFEBB2",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Check
                      height={"16px"}
                      style={{ strokeWidth: 5 }}
                      className="txt-primary"
                    />
                  </div>
                  <div>
                    <div className="mb-1 d-flex align-items-center">
                      <h6
                        style={{ color: "#FFEBB2" }}
                        className="uppercase px-1 m-0"
                      >

                      </h6>
                      <div
                        style={{ backgroundColor: "#FFEBB2", height: "5px" }}
                        className="w-100 rounded ml-2"
                      ></div>
                    </div>
                    <div style={{ color: "#FFEBB2", marginLeft: 15 }} className="fw-bold">
                      Do you want to add a another Photo.
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className=" d-flex justify-content-end"                >
                    <div>
                      <button
                        type="reset"
                        className="btn 
                            rounded btn-sm "
                        style={{ backgroundColor: "#FFEBB2" }}
                      >

                        <span className="fw-bold">Add</span>
                      </button>
                    </div>
                  </div>
                  <div className="mx-2 d-flex"
                  >
                    <div style={{ marginTop: -15 }} >
                      <span
                        onClick={closeItem}
                        style={{
                          cursor: "pointer",
                        }}
                      > <X style={{ color: "#FFEBB2" }} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )}


        </form>
      </div>
      <div className="mb-4">
        <Datatable
          titleData="Photo Collection"
          columnData={tableColumnHeaderList}
          rowData={photoRowList}
          csvFilename="Photo.csv"
        />
      </div>
    </>
  );
};

export default Photo;
