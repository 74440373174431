import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";
import { Alert, Col, Input, Label, Row } from "reactstrap";
import {
  AlertTriangle,
  ArrowLeft,
  ArrowRight,
  Check,
  PlusCircle,
  RefreshCw,
  X,
} from "react-feather";

// custom components
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../common/datatable";

// customer hooks
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../customHooks/useValidation";
// import { ConvertMillisToDate } from "../../../../data/util/DateUtil";
import ConfigDB from "../../../../data/customizer/config";
import { INSERT_COUNTRY, COUNTRY } from "../../../../constant";
const Consent = ({
  b_partner_id = 0,
  client_id = 0,
  org_id = 0,
  set_basic_tab = null,
  set_back_tab = null,
}) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const sendRequest = useAxiosTemplates();

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);

  // form values
  const [consentToBeAgreed, setConsentToBeAgreed] = useState()
  const [providedServiceList, setProvidedServiceList] = useState()
  const [countryList, setCountryList] = useState([
    { countryId: "", name: "-Select-" },
  ]);
  const [dpCountryValue, setDpCountryValue] = useState(0);

  const [expectedCountryId, setExpectedCountryId] = useState(0);

  const [isSuccess, setIsSuccess] = useState(false);

  const resetForm = (evt) => {
    evt.preventDefault();
    // setIsValidated(false);
    // setExpectedCountryId(0);
    // setDpCountryValue(0);

    // let inputFields = document.querySelectorAll(".form-control");
    // let customValidationMessages =
    //   document.querySelectorAll(".input-validation");
    // inputFields.forEach((field) => {
    //   if (field.classList.contains("is-valid")) {
    //     field.classList.remove("is-valid");
    //   } else if (field.classList.contains("is-invalid")) {
    //     field.classList.remove("is-invalid");
    //   }
    // });
    // customValidationMessages.forEach((element) => {
    //   if (element.classList.contains("input-validation")) {
    //     if (!element.classList.contains("d-none")) {
    //       element.classList.add("d-none");
    //     }
    //   }
    // });

    Swal.fire({
      title: "Are you sure you want to reset?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setExpectedCountryId(0);
        setDpCountryValue(0);

        document.getElementById("id_input_fm_4_remark").value = "";

        setIsValidated(false);
      }
    });

    setIsSuccess(false);
  };
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    console.log(evt);

    const form = new FormData(evt.target);
    let subscribedServiceList = []
    providedServiceList.forEach(service_item => {
      let element = document.querySelector(`#cf_hotelier_service_id_id_${service_item.hotelierServiceId}`)
      if (element.checked) {
        subscribedServiceList.push(
          {
            hotelierServiceLineId: 0,
            hotelierServiceId: service_item.hotelierServiceId
          }
        )
      }
    });
    console.log(form.getAll("cf_hotelier_service"));

    if (evt.target.checkValidity()) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        bpartnerId: b_partner_id,
        isAgreed: form.get("fm_1_is_agreed_to_consent") ? true : false,
        serviceList: subscribedServiceList
      }
      let nestedResponseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/saveConsentTab",
          data: reqBody,
        });
        resolve(result);
      });
      nestedResponseData.then((nested_reponse_data) => {
        if (nested_reponse_data.isSuccess) {
          setIsSuccess(true);
        }
      });
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        bpartnerId: b_partner_id,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "customer/viewConsentTab",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setConsentToBeAgreed(response_data.hotelierConsent)
          setProvidedServiceList(response_data.activeHotelierServiceList)
          response_data.subscribedServiceList.forEach(subscribed_item => {
            document.querySelector(`#cf_hotelier_service_id_id_${subscribed_item.hotelierServiceId}`).checked = true
          });
          document.querySelector("#cf_is_agreed_to_consent_id").checked = response_data.agreedToConsent
          //   if (response_data.activeCountryList) {
          //     setCountryList([
          //       { countryId: "", name: "-Select-" },
          //       ...response_data.activeCountryList,
          //     ]);
          //   }

          //   if (response_data.allTableList) {
          //     let tempList = [];
          //     response_data.allTableList.forEach((listItem) => {
          //       tempList.push([
          //         listItem.expectedCountryId,
          //         listItem.country,
          //         // listItem.remark,
          //         // listItem.active ? "True" : "False",
          //         <ControlButtons
          //           editMethod={() =>
          //             editExpectedCountry(
          //               listItem.expectedCountryId,
          //               response_data.allTableList
          //             )
          //           }
          //           disableEdit={!checkIsAccessible(EDITFUNCTION)}
          //           deleteMethod={() =>
          //             deleteExpectedCountry(
          //               listItem.expectedCountryId,
          //               loggedUser
          //             )
          //           }
          //           disableDelete={!checkIsAccessible(DELETEFUNCTION)}
          //         />,
          //       ]);
          //     });
          //     setExpectedCountryRowList([...tempList]);
        }
        setIsLoaded(LOADED);
        //   setRefreshTable(false);
        // }
      });
    } else {
      setIsLoaded(LOADED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeItem = () => {
    setIsSuccess(false);
  };

  const handleBackClick = () => {
    set_back_tab();
  };

  const handleNextClick = () => {
    set_basic_tab();
  };

  return (
    <>
      <div className="card-modified-body bg-white pt-0 mb-4">
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={b_partner_id === 0}
        >
          <AlertTriangle />
          <p className="ml-2">
            You must create a customer, before add language details.
          </p>
        </Alert>
        <form
          onSubmit={(evt) => submitForm(evt)}
          // onReset={(evt) => resetForm(evt)}
          className={`position-relative consent-form ${isValidated ? "was-validated" : ""
            }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-row mb-4">
            <div className="form-group col-12 col-sm-3 position-relative">
              {/* <div className="form-group col-12 col-sm-8 mb-3 mb-sm-0 position-relative"> */}
              <Row>
                <Col xs="9" md="12">
                  <Label
                    className="d-block"
                    htmlFor="input_fm_1_active"
                  // key={index2}
                  >
                    <Input
                      className="checkbox_animated"
                      id={"cf_is_agreed_to_consent_id"}
                      // value={??}
                      name="fm_1_is_agreed_to_consent"
                      type="checkbox"
                    />
                    {consentToBeAgreed}
                  </Label>
                </Col>
              </Row>
              <Row>
                {providedServiceList?.map(service_item => <Col xs="9" md="12">
                  <Label
                    className="d-block"
                    htmlFor="input_fm_1_active"
                  >
                    <Input
                      className="checkbox_animated"
                      id={`cf_hotelier_service_id_id_${service_item.hotelierServiceId}`}
                      name={"cf_hotelier_service"}
                      type="checkbox"
                    />
                    {service_item.name}
                  </Label>
                </Col>)}
              </Row>
            </div>
          </div>
          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
              (checkIsAccessible(EDITFUNCTION) && expectedCountryId !== 0) ? (
              <div className="d-sm-flex justify-content-end">
                <button
                  className="btn btn-outline-primary col-md-1 col-3 px-1 mb-2 mb-sm-0"
                  type="reset"
                >
                  Reset
                </button>
                <button
                  className="btn btn-primary col-md-2 col-4 mx-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {expectedCountryId ? "Update" : "Submit"}
                </button>

                <button
                  type="button"
                  class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center"
                  onClick={() => handleBackClick()}
                >
                  <ArrowLeft height={"16px"} width={"16px"} />
                  Back
                </button>
              </div>
            ) : null
          ) : null}

          <br />

          {isSuccess && (
            <div className="col-sm-12 mb-2">
              <div className="bg-primary px-3 py-3 rounded d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div
                    style={{
                      backgroundColor: "#FFEBB2",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Check
                      height={"16px"}
                      style={{ strokeWidth: 5 }}
                      className="txt-primary"
                    />
                  </div>
                  <div>
                    <div className="mb-1 d-flex align-items-center">
                      <h6
                        style={{ color: "#FFEBB2" }}
                        className="uppercase px-1 m-0"
                      >
                        {/* {searchKey} */}
                      </h6>
                      <div
                        style={{ backgroundColor: "#FFEBB2", height: "5px" }}
                        className="w-100 rounded ml-2"
                      ></div>
                    </div>
                    <div
                      style={{ color: "#FFEBB2", marginLeft: 15 }}
                      className="fw-bold"
                    >
                      {/* <UserCheck height={"14px"} style={{ strokeWidth: 3 }} /> */}
                      {/* {` ${firstName}’s`}  */}
                      Do you want to add a another expected country detail.
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className=" d-flex justify-content-end">
                    <div>
                      <button
                        type="reset"
                        className="btn 
                            rounded btn-sm "
                        style={{ backgroundColor: "#FFEBB2" }}
                      >
                        <span className="fw-bold">Add</span>
                      </button>
                    </div>
                  </div>
                  <div className="mx-2 d-flex">
                    <div style={{ marginTop: -15 }}>
                      <span
                        onClick={closeItem}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        <X style={{ color: "#FFEBB2" }} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </>
  );
};
export default Consent;
