import React, { useEffect, useRef, useState } from "react";

// components
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// custom components
import Breadcrumb from "../../common/breadcrumb";

// tab panes
import Personal from "./save/Personal";
import Job from "./save/Job";
import Passport from "./save/Passport";
import Experience from "./save/Experience";
import Education from "./save/Education";
// import Family from "./save/Family";
// import DetailInSinhala from "./save/DetailInSinhala";
// import CustomerStatus from "./save/CustomerStatus";
import Contact from "./save/Contact/Contact";
import Photo from "./save/Photo";
import { useParams } from "react-router-dom";
import {
  ABOUT_TO_CHECK,
  CHECKED,
  CHECKING,
  CUSTOMER_EXIST,
  CUSTOMER_NOT_EXIST,
} from "../../../constant";
import formValidation from "../../../customHooks/useValidation";
import Swal from "sweetalert2";
import useAxiosTemplates from "../../../customHooks/useAxiosTemplates";
import { useSelector } from "react-redux";

import searchImage from "../../../assets/images/search_customer.png";
import { useDispatch } from "react-redux";
import { LoadStaticURL } from "../../../data/util/LoadStaticURL";
import ConnectedSource from "./save/ConnectedSource";
import VisitDetail from "./save/VisitDetail";
import ConfigDB from "../../../data/customizer/config";
import Language from "./save/Language";
import Skill from "./save/Skill";
// import Remark from "./save/Remark";
import { Check, UserCheck } from "react-feather";
import ExpectedCountry from "./save/ExpectedCountry";
import Consent from "./save/Consent";

const Index = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const { customer_id, tab_id } = useParams();
  // custom hooks
  const sendRequest = useAxiosTemplates();
  const dispatch = useDispatch();

  const loggedUser = useSelector((content) => content.UserReducer);
  let childLinks = [
    {
      value: `${customer_id !== undefined ? customer_id : "Customer"}`,
      active: false,
    },
    { value: b_partner_id ? "Update" : "New", active: true },
  ];
  const [basicTab, setBasicTab] = useState(tab_id);
  const [triggerShourtcut, setTriggerShourtcut] = useState(false);

  const [bPartnerId, setbPartnerId] = useState(b_partner_id);
  const [clientId, setClientId] = useState(client_id);
  const [orgId, setOrgId] = useState(org_id);
  const [searchKey, setSearchKey] = useState("");
  const [firstName, setFirstName] = useState("");
  const [checkExistence, setCheckExistence] = useState(
    customer_id !== undefined ? CHECKED : ABOUT_TO_CHECK
  );
  const [customerExist, setCustomerExist] = useState(CUSTOMER_NOT_EXIST);
  const [customerData, setCustomerData] = useState(undefined);
  const nicIF = useRef("");
  const nicNumber = useRef("");
  const passportNumber = useRef("");

  const [inputVisibility, setInputVisibility] = useState("none");
  const [buttonVisibility, setButtonVisibility] = useState("none");
  const [inputLabel, setInputLabel] = useState(""); // Initialize label text
  const [inputPlaceholder, setInputPlaceholder] = useState(""); // Initialize placeholder text

  const [passportChecked, setPassportChecked] = useState(false);
  const [nicChecked, setNicChecked] = useState(false);

  const [error, setError] = useState("");

  const setbPartnerFromChild = (id) => {
    setbPartnerId(id);
  };
  const setClientFromChild = (id) => {
    setClientId(id);
  };
  const setOrgFromChild = (id) => {
    setOrgId(id);
  };

  function handleCheckboxChange(checkboxType) {
    if (checkboxType === "passport") {
      setPassportChecked(true);
      setNicChecked(false);
      setInputVisibility("block");
      setButtonVisibility("block");
      setInputLabel("Passport"); // Set label text for Passport
      setInputPlaceholder("Enter Passport Number"); // Set placeholder text for Passport
    } else if (checkboxType === "nic") {
      setPassportChecked(false);
      setNicChecked(true);
      setInputVisibility("block");
      setButtonVisibility("block");
      setInputLabel("NIC"); // Set label text for NIC
      setInputPlaceholder("Enter NIC Number"); // Set placeholder text for NIC
    }
  }

  // console.log(nicChecked);

  //   const checkbox1 = document.getElementById("id_input_fm_1_is_passport_available");
  // const checkbox2 = document.getElementById("id_input_fm_1_is_nic");

  // checkbox1.addEventListener("change", function() {
  //     if (checkbox1.checked) {
  //         checkbox2.checked = false;
  //     }
  // });

  // checkbox2.addEventListener("change", function() {
  //     if (checkbox2.checked) {
  //         checkbox1.checked = false;
  //     }
  // });

  // const checkbox1 = document.getElementById("id_input_fm_1_is_passport_available");
  // const checkbox2 = document.getElementById("id_input_fm_1_is_nic");

  // checkbox1.addEventListener("change", function() {
  //     if (checkbox1.checked) {
  //         checkbox2.checked = false;
  //     }
  // });

  // checkbox2.addEventListener("change", function() {
  //     if (checkbox2.checked) {
  //         checkbox1.checked = false;
  //     }
  // });

  const checkCustomer = (evt) => {
    evt.preventDefault();

    // console.log(evt.target.value);

    // id="id_input_fm_min_nic_no"
    // if (evt.target.value === "") {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: 'Please enter a value',
    //   });
    // }

    if (evt.target.checkValidity()) {
      if (passportChecked) {
        passportNumber.current = nicIF.current.value;
        setCheckExistence(CHECKING);
        let reqData = {
          clientId: loggedUser.clientId,
          passportNo: nicIF.current.value,
          bpartnerId: loggedUser.bpartnerId,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/checkLogPassportExist",
            data: reqData,
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          console.log(passportNumber.current);
          if (response_data) {
            switch (response_data.existType) {
              case 0:
                // nicNumber.current = nicIF.current.value;
                setCheckExistence(CHECKED);

                break;
              case 1:
                setCheckExistence(ABOUT_TO_CHECK);
                setCustomerExist(CUSTOMER_EXIST);
                new Promise((resolve) => {
                  const result = sendRequest({
                    url: "/customer/searchProfile",
                    data: {
                      clientId: loggedUser.clientId,
                      orgIdList: loggedUser.activeOrgRoleList.map(
                        (org_item) => org_item.orgId
                      ),
                      orgId: loggedUser.orgId,
                      userId: loggedUser.userId,
                      customerId: "",
                      referenceId: "",
                      customerName: "",
                      nic: "",
                      districtId: -1,
                      cc1DispositionId: -1,
                      cc2DispositionId: -1,
                      male: false,
                      female: false,
                      minAge: 0,
                      maxAge: 0,
                    },
                  });
                  resolve(result);
                }).then((response_data) => {
                  if (response_data.customerList.length > 0) {
                    setCustomerData(response_data.customerList[0]);
                  }
                });
                Swal.fire({
                  icon: "warning",
                  title: "Exists",
                  text: "Customer Already Exists",
                  showConfirmButton: false,
                  timer: 5000,
                });
                break;
              default:
                break;
            }
          }
        });
      } else {
        setCheckExistence(CHECKING);
        let reqData = {
          clientId: loggedUser.clientId,
          nicNo: nicIF.current.value,
          bpartnerId: loggedUser.bpartnerId,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/checkNicExist",
            data: reqData,
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          console.log(passportNumber.current);
          if (response_data) {
            switch (response_data.existType) {
              case 0:
                nicNumber.current = nicIF.current.value;
                setCheckExistence(CHECKED);
                break;
              case 1:
                setCheckExistence(ABOUT_TO_CHECK);
                setCustomerExist(CUSTOMER_EXIST);
                new Promise((resolve) => {
                  const result = sendRequest({
                    url: "/customer/searchProfile",
                    data: {
                      clientId: loggedUser.clientId,
                      orgIdList: loggedUser.activeOrgRoleList.map(
                        (org_item) => org_item.orgId
                      ),
                      orgId: loggedUser.orgId,
                      userId: loggedUser.userId,
                      customerId: "",
                      referenceId: "",
                      customerName: "",
                      nic: nicIF.current.value,
                      districtId: -1,
                      cc1DispositionId: -1,
                      cc2DispositionId: -1,
                      male: false,
                      female: false,
                      minAge: 0,
                      maxAge: 0,
                    },
                  });
                  resolve(result);
                }).then((response_data) => {
                  if (response_data.customerList.length > 0) {
                    setCustomerData(response_data.customerList[0]);
                  }
                });
                Swal.fire({
                  icon: "warning",
                  title: "Exists",
                  text: "Customer Already Exists",
                  showConfirmButton: false,
                  timer: 5000,
                });
                break;
              default:
                break;
            }
          }
        });
      }

      // setCheckExistence(CHECKING);
      // let reqData = {
      //   clientId: loggedUser.clientId,
      //   nicNo: nicIF.current.value,
      //   bpartnerId: loggedUser.bpartnerId,
      // };
      // let responseData = new Promise((resolve) => {
      //   const result = sendRequest({
      //     url: "/customer/checkNicExist",
      //     data: reqData,
      //   });
      //   resolve(result);
      // });
      // responseData.then((response_data) => {
      //   if (response_data) {
      //     switch (response_data.existType) {
      //       case 0:
      //         nicNumber.current = nicIF.current.value;
      //         setCheckExistence(CHECKED);
      //         break;
      //       case 1:
      //         setCheckExistence(ABOUT_TO_CHECK);
      //         setCustomerExist(CUSTOMER_EXIST);
      //         new Promise((resolve) => {
      //           const result = sendRequest({
      //             url: "/customer/searchProfile",
      //             data: {
      //               clientId: loggedUser.clientId,
      //               orgIdList: loggedUser.activeOrgRoleList.map(
      //                 (org_item) => org_item.orgId
      //               ),
      //               orgId: loggedUser.orgId,
      //               userId: loggedUser.userId,
      //               customerId: "",
      //               referenceId: "",
      //               customerName: "",
      //               nic: nicIF.current.value,
      //               districtId: -1,
      //               cc1DispositionId: -1,
      //               cc2DispositionId: -1,
      //               male: false,
      //               female: false,
      //               minAge: 0,
      //               maxAge: 0,
      //             },
      //           });
      //           resolve(result);
      //         }).then((response_data) => {
      //           if (response_data.customerList.length > 0) {
      //             setCustomerData(response_data.customerList[0]);
      //           }
      //         });
      //         Swal.fire({
      //           icon: "warning",
      //           title: "Exists",
      //           text: "Customer Already Exists",
      //           showConfirmButton: false,
      //           timer: 5000,
      //         });
      //         break;
      //       default:
      //         break;
      //     }
      //   }
      // });
    }
  };

  const directToCustomerProfile = () => {
    // console.log(customerData);
    dispatch({
      type: "STORE_CUSTOMER",
      payload: {
        bpartnerId: customerData.bpartnerId,
        clientId: customerData.clientId,
        orgId: customerData.orgId,
        searchKey: customerData.customerId,
      },
    });
    window.open(
      `${ConfigDB.data.hosted_url}/customer/view/${customerData.customerId}/`,
      "_blank"
    );
  };

  const directToCustomerApplication = () => {
    dispatch({
      type: "STORE_CUSTOMER",
      payload: {
        bpartnerId: bPartnerId,
        clientId: clientId,
        orgId: orgId,
        searchKey: searchKey,
      },
    });
    window.open(
      `${ConfigDB.data.hosted_url}/customer/view/${searchKey}/cv/`,
      "_blank"
    );
  };

  useEffect(() => {
    setPassportChecked(true);
    setNicChecked(false);
    setInputVisibility("block");
    setButtonVisibility("block");
    setInputLabel("Passport"); // Set label text for Passport
    setInputPlaceholder("Enter Passport Number"); // Set placeholder text for Passport

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (customer_id !== undefined) {
      setBasicTab(tab_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Breadcrumb
        parent="Dashboard"
        //  title="Customer"
        title={loggedUser.roleName === "NormalUser" ? "My Details" : "Customer"}
        children={childLinks}
      />
      <div className="container-fluid">
        <div className="row">
          {triggerShourtcut ? (
            <div className="col-sm-12 mb-2">
              <div className="bg-primary px-3 py-3 rounded d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div
                    style={{
                      backgroundColor: "#FFEBB2",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Check
                      height={"16px"}
                      style={{ strokeWidth: 5 }}
                      className="txt-primary"
                    />
                  </div>
                  <div className="mx-2">
                    <div className="mb-1 d-flex align-items-center">
                      <h6
                        style={{ color: "#FFEBB2" }}
                        className="uppercase px-1 m-0"
                      >
                        {searchKey}
                      </h6>
                      <div
                        style={{ backgroundColor: "#FFEBB2", height: "5px" }}
                        className="w-100 rounded ml-2"
                      ></div>
                    </div>
                    <div style={{ color: "#FFEBB2" }} className="fw-bold">
                      <UserCheck height={"14px"} style={{ strokeWidth: 3 }} />
                      {` ${firstName}’s`} Profile has successfully created.
                    </div>
                  </div>
                </div>

                {/* <div
                  className="px-3 btn fw-bold"
                  style={{ backgroundColor: "#FFEBB2", color: "#777777" }}
                  onClick={directToCustomerApplication}
                >
                  Create Application
                </div> */}
              </div>
            </div>
          ) : null}
          <div className="col-sm-12">
            {checkExistence === CHECKED ? (
              <div className="card-modified">
                <div className="card-modified-header d-none d-sm-block">
                  {/* <div>{customer_id}</div>
                <div>{bPartnerId}</div>
                <div>{bPartnerId !== 0 && customer_id}</div> */}
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_1"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_1")}
                      >
                        Personal
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_2"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_2")}
                      >
                        <div>Apply Job</div>
                        {basicTab === "save_customer_tab_2" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_3"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_3")}
                      >
                        <div>Language</div>
                        {basicTab === "save_customer_tab_3" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_4"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_4")}
                      >
                        <div>Skill</div>
                        {basicTab === "save_customer_tab_4" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_5"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_5")}
                      >
                        <div>Contact</div>
                        {basicTab === "save_customer_tab_5" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_6"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_6")}
                      >
                        <div>Passport</div>
                        {basicTab === "save_customer_tab_6" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_7"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_7")}
                      >
                        <div>Experience</div>
                        {basicTab === "save_customer_tab_7" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_8"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_8")}
                      >
                        <div>Education</div>
                        {basicTab === "save_customer_tab_8" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${basicTab === "save_customer_tab_9"
                          ? "active"
                          : "txt-primary"
                          }`}
                        onClick={() => setBasicTab("save_customer_tab_9")}
                      >
                        <div>Family</div>
                        {basicTab === "save_customer_tab_9" &&
                          bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem> */}
                    {/* <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${basicTab === "save_customer_tab_10"
                          ? "active"
                          : "txt-primary"
                          }`}
                        onClick={() => setBasicTab("save_customer_tab_10")}
                      >
                        <div>Remark</div>
                        {basicTab === "save_customer_tab_10" &&
                          bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem> */}
                    {/* <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${basicTab === "save_customer_tab_11"
                          ? "active"
                          : "txt-primary"
                          }`}
                        onClick={() => setBasicTab("save_customer_tab_11")}
                      >
                        <div>Detail In Sinhala</div>
                        {basicTab === "save_customer_tab_11" &&
                          bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem> */}
                    {/* <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${basicTab === "save_customer_tab_12"
                          ? "active"
                          : "txt-primary"
                          }`}
                        onClick={() => setBasicTab("save_customer_tab_12")}
                      >
                        <div>Customer Status</div>
                        {basicTab === "save_customer_tab_12" &&
                          bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_13"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_13")}
                      >
                        <div>
                          {loggedUser.roleName === "EdgeAdmin"
                            ? "Customer Photos"
                            : "Your Photos"}
                        </div>

                        {basicTab === "save_customer_tab_13" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_14"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_14")}
                      >
                        {/* <div>Connected Source</div> */}
                        <div>How you got to know about us</div>
                        {basicTab === "save_customer_tab_14" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    {loggedUser.roleName === "EdgeAdmin" && (
                      <NavItem>
                        <NavLink
                          href="#"
                          className={`d-flex fw-bold ${
                            basicTab === "save_customer_tab_15"
                              ? "active"
                              : "txt-primary"
                          }`}
                          onClick={() => setBasicTab("save_customer_tab_15")}
                        >
                          <div>Visit Details</div>
                          {basicTab === "save_customer_tab_15" &&
                          bPartnerId === 0 ? (
                            <div className="animated-dot">
                              <span className="animate-circle"></span>
                              <span className="main-circle"></span>
                            </div>
                          ) : null}
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_16"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_16")}
                      >
                        <div>Expected Country</div>
                        {basicTab === "save_customer_tab_16" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                      {/* mekata compnent ekk hadala TabContent eka athule call karanna. */}
                    </NavItem>{" "}
                    <NavItem>
                      <NavLink
                        href="#"
                        className={`d-flex fw-bold ${
                          basicTab === "save_customer_tab_17"
                            ? "active"
                            : "txt-primary"
                        }`}
                        onClick={() => setBasicTab("save_customer_tab_17")}
                      >
                        <div>Legal and Compliance Considerations</div>
                        {basicTab === "save_customer_tab_17" &&
                        bPartnerId === 0 ? (
                          <div className="animated-dot">
                            <span className="animate-circle"></span>
                            <span className="main-circle"></span>
                          </div>
                        ) : null}
                      </NavLink>
                      {/* mekata compnent ekk hadala TabContent eka athule call karanna. */}
                    </NavItem>
                  </Nav>
                </div>
                <TabContent activeTab={basicTab}>
                  <TabPane className="fade show" tabId="save_customer_tab_1">
                    {basicTab === "save_customer_tab_1" ? (
                      // customer_id === undefined ? (
                      <Personal
                        b_partner_id={bPartnerId}
                        set_b_partner_id={setbPartnerFromChild}
                        set_search_key={setSearchKey}
                        set_client_id={setClientFromChild}
                        set_org_id={setOrgFromChild}
                        set_first_name={setFirstName}
                        nic_no={nicNumber?.current}
                        trigger_shortcut={setTriggerShourtcut}
                        passport_number={passportNumber.current}
                        set_basic_tab={() => setBasicTab("save_customer_tab_3")} // Update this line
                      />
                    ) : // ) : (
                    //   <Personal
                    //     b_partner_id={bPartnerId}
                    //     set_b_partner_id={setbPartnerFromChild}
                    //     set_client_id={setClientFromChild}
                    //     set_org_id={setOrgFromChild}
                    //   />
                    // )
                    null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_2">
                    {basicTab === "save_customer_tab_2" ? (
                      <Job
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                        set_basic_tab={() => setBasicTab("save_customer_tab_3")}
                        set_back_tab={() => setBasicTab("save_customer_tab_1")}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_3">
                    {basicTab === "save_customer_tab_3" ? (
                      <Language
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                        set_basic_tab={() => setBasicTab("save_customer_tab_4")}
                        set_back_tab={() => setBasicTab("save_customer_tab_1")}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_4">
                    {basicTab === "save_customer_tab_4" ? (
                      <Skill
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                        set_basic_tab={() => setBasicTab("save_customer_tab_5")}
                        set_back_tab={() => setBasicTab("save_customer_tab_3")}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_5">
                    {basicTab === "save_customer_tab_5" ? (
                      <Contact
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                        set_back_tab={setBasicTab}
                        set_basic_tab={setBasicTab}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_6">
                    {basicTab === "save_customer_tab_6" ? (
                      <Passport
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                        passport_number={passportNumber.current}
                        set_back_tab={() => setBasicTab("save_customer_tab_5")}
                        set_basic_tab={() => setBasicTab("save_customer_tab_7")}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_7">
                    {basicTab === "save_customer_tab_7" ? (
                      <Experience
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                        set_back_tab={() => setBasicTab("save_customer_tab_6")}
                        set_basic_tab={() => setBasicTab("save_customer_tab_8")}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_8">
                    {basicTab === "save_customer_tab_8" ? (
                      <Education
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                        set_back_tab={() => setBasicTab("save_customer_tab_7")}
                        set_basic_tab={() =>
                          setBasicTab("save_customer_tab_13")
                        }
                      />
                    ) : null}
                  </TabPane>
                  {/* <TabPane tabId="save_customer_tab_9">
                    {basicTab === "save_customer_tab_9" ? (
                      <Family
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane> */}
                  {/* <TabPane tabId="save_customer_tab_10">
                    {basicTab === "save_customer_tab_10" ? (
                      <Remark
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane> */}
                  {/* <TabPane tabId="save_customer_tab_11">
                    {basicTab === "save_customer_tab_11" ? (
                      <DetailInSinhala
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane> */}
                  {/* <TabPane tabId="save_customer_tab_12">
                    {basicTab === "save_customer_tab_12" ? (
                      <CustomerStatus
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                      />
                    ) : null}
                  </TabPane> */}
                  <TabPane tabId="save_customer_tab_13">
                    {basicTab === "save_customer_tab_13" ? (
                      <Photo
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                        set_back_tab={() => setBasicTab("save_customer_tab_8")}
                        set_basic_tab={() =>
                          setBasicTab("save_customer_tab_14")
                        }
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_14">
                    {basicTab === "save_customer_tab_14" ? (
                      <ConnectedSource
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                        set_back_tab={() => setBasicTab("save_customer_tab_13")}
                        // set_basic_tab={() => setBasicTab("save_customer_tab_15")}

                        set_basic_tab={
                          loggedUser.roleName === "NormalUser"
                            ? () => setBasicTab("save_customer_tab_16")
                            : () => setBasicTab("save_customer_tab_15")
                        }
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_15">
                    {basicTab === "save_customer_tab_15" ? (
                      <VisitDetail
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                        set_back_tab={() => setBasicTab("save_customer_tab_14")}
                        set_basic_tab={() =>
                          setBasicTab("save_customer_tab_16")
                        }
                        // set_basic_tab={
                        //   loggedUser.roleName === "NormalUser"
                        //     ? () => setBasicTab("save_customer_tab_16")
                        //     : () => setBasicTab("save_customer_tab_15")
                        // }
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="save_customer_tab_16">
                    {basicTab === "save_customer_tab_16" ? (
                      <ExpectedCountry
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                        // set_back_tab={() => setBasicTab("save_customer_tab_14")}

                        set_back_tab={
                          loggedUser.roleName === "NormalUser"
                            ? () => setBasicTab("save_customer_tab_14")
                            : () => setBasicTab("save_customer_tab_15")
                        }
                        set_basic_tab={() =>
                          setBasicTab("save_customer_tab_17")
                        }
                      />
                    ) : null}
                  </TabPane>{" "}
                  <TabPane tabId="save_customer_tab_17">
                    {basicTab === "save_customer_tab_17" ? (
                      <Consent
                        b_partner_id={bPartnerId}
                        client_id={clientId}
                        org_id={orgId}
                        // set_back_tab={() => setBasicTab("save_customer_tab_14")}

                        set_back_tab={
                          loggedUser.roleName === "NormalUser"
                            ? () => setBasicTab("save_customer_tab_15")
                            : () => setBasicTab("save_customer_tab_16")
                        }
                        set_basic_tab={() => setBasicTab("save_customer_tab_1")}
                      />
                    ) : null}
                  </TabPane>
                </TabContent>
              </div>
            ) : (
              <div className="card">
                <div className="d-flex">
                  <form
                    onSubmit={(evt) => checkCustomer(evt)}
                    className="card-body col-md-3 col-12"
                    noValidate
                  >
                    <h5 className="fw-bold mb-4">
                      Check Customer Availability
                    </h5>

                    <div className="form-row mb-4">
                      <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 position-relative d-flex justify-content-between flex-column">
                        <div className="checkbox checkbox-solid-dark">
                          <input
                            id="id_input_fm_1_is_passport_available"
                            name="fm_1_is_passport_available"
                            type="checkbox"
                            onChange={() => handleCheckboxChange("passport")}
                            checked={passportChecked}
                          />
                          <label
                            htmlFor="id_input_fm_1_is_passport_available"
                            className="mt-0 mb-0"
                          >
                            Passport
                          </label>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 d-flex align-items-end">
                        <div className="checkbox checkbox-solid-dark">
                          <input
                            id="id_input_fm_1_is_nic"
                            name="fm_1_is_nic"
                            type="checkbox"
                            onChange={() => handleCheckboxChange("nic")}
                            checked={nicChecked}
                          />
                          <label
                            htmlFor="id_input_fm_1_is_nic"
                            className="mt-0 mb-0"
                          >
                            NIC
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      className="form-group w-100 position-relative mb-4"
                      style={{ display: inputVisibility }}
                    >
                      <label htmlFor="id_input_fm_min_nic_no">
                        {/* NIC Number */}
                        {inputLabel} Number
                      </label>
                      <input
                        id="id_input_fm_min_nic_no"
                        type="text"
                        ref={nicIF}
                        className="form-control"
                        // placeholder="Enter NIC Number"
                        placeholder={inputPlaceholder}
                        autoComplete="off"
                        maxLength={20}
                        onChange={(evt) =>
                          // formValidation({
                          //   event: evt,
                          //   validateNIC: true,
                          // })
                          {
                            if (inputLabel === "Passport") {
                              formValidation({
                                event: evt,
                                validateSearchKey: true,
                                validateNIC: false, // Set validateNIC to false
                              });
                            } else if (inputLabel === "NIC") {
                              formValidation({
                                event: evt,
                                validateSearchKey: false, // Set validateSearchKey to false
                                validateNIC: true,
                              });
                            }
                          }
                        }
                        required
                      />
                      {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
                      <small className="invalid-feedback position-absolute">
                        {/* Please enter NIC Number */}
                        Please enter {inputLabel} Number
                      </small>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div
                      className="form-group w-100 form-row mt-3 mb-0 px-1 mb-4"
                      style={{ display: inputVisibility }}
                    >
                      <button className="btn btn-primary w-100" type="submit">
                        {checkExistence === ABOUT_TO_CHECK
                          ? "Check"
                          : "Checking..."}
                      </button>
                    </div>
                    {customerExist === CUSTOMER_EXIST ? (
                      <div
                        className="px-1 btn btn-outline-warning w-50"
                        onClick={directToCustomerProfile}
                      >
                        Visit Customer Profile
                      </div>
                    ) : null}
                  </form>
                  <img
                    width="300px"
                    className="d-none d-md-block"
                    src={LoadStaticURL(searchImage)}
                    alt=""
                  />
                </div>
                <img
                  width="100%"
                  className="d-block d-md-none"
                  src={LoadStaticURL(searchImage)}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
