import React, { useState, useEffect } from "react";
import { HOME_PAGE_RT, LOGIN } from "../constant";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useAxiosTemplates from "../customHooks/useAxiosTemplates";

const LoginWithOrg = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const sendRequest = useAxiosTemplates();

  // form controls
  const [isValidated, setIsValidated] = useState(false);

  // form values
  const [organizationList, setOrganizationList] = useState([
    { orgId: "", name: "-Select-" },
  ]);
  const [dpOrganizationValue, setDpOrganizationValue] = useState(0);

  useEffect(() => {
    setOrganizationList((prevList) => [
      prevList[0],
      ...loggedUser.activeOrgRoleList,
    ]);
  }, [loggedUser, dispatch]);

  const loginAuth = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      dispatch({
        type: "AUTHENTICATE_USER",
        payload: {
          ...loggedUser,
          clientId: loggedUser.activeClientList[0].clientId,
          orgId: parseInt(dpOrganizationValue),
        },
      });
      const reqBody = {
        orgId: parseInt(dpOrganizationValue),
        roleId: parseInt(loggedUser.userRoleId),
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/getUserRoleLine",
          data: reqBody,
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        dispatch({
          type: "STORE_ROLE_LIST",
          payload: {
            roleList: response_data,
          },
        });
        // **HOST
        // navigate("/");
        navigate(HOME_PAGE_RT);
      });
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <span className="fs-1 fw-bold text-danger">
                        Sapro Solutions
                      </span>
                      {/* <p class="fs-4" style={{color:"black"}}> Maldives</p> */}
                      {/* <br />
                      <span className="fs-4 text-warning">E R P</span> */}
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>{LOGIN}</h4>
                          <h6>{"Select An Organization"} </h6>
                        </div>
                        <form
                          onSubmit={(evt) => loginAuth(evt)}
                          className={`form-1 ${
                            isValidated ? "was-validated" : ""
                          }`}
                          noValidate
                        >
                          <div className="form-group">
                            <label className="col-form-label pt-0">
                              {"Organization"}
                            </label>
                            <select
                              id="id_input_fm_1_sa_org_id"
                              name="fm_1_sa_org_id"
                              className="form-select"
                              onChange={(evt) =>
                                setDpOrganizationValue(evt.target.value)
                              }
                              value={dpOrganizationValue}
                              required
                            >
                              {organizationList.length
                                ? organizationList.map((listItem, index) => (
                                    <option value={listItem?.orgId} key={index}>
                                      {listItem?.name}
                                    </option>
                                  ))
                                : null}
                            </select>
                          </div>
                          <div className="form-group form-row mt-3 mb-0 d-grid">
                            <button className="btn btn-primary" type="submit">
                              Proceed
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default LoginWithOrg;
