import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

// components
import Breadcrumb from "../common/breadcrumb";
import Datatable, { ControlButtons, BooleanValue } from "../common/datatable";

//network requests
import formValidation from "../../customHooks/useValidation";
import useAccess from "../../customHooks/useAccess";
import useAxiosTemplates from "../../customHooks/useAxiosTemplates";
import {
  DEACTIVATE_GRAMA_SEVA_DIVISION,
  DELETE_GRAMA_SEVA_DIVISION,
  EDIT_GRAMA_SEVA_DIVISION,
  GRAMA_SEVA_DIVISION,
  INSERT_GRAMA_SEVA_DIVISION,
} from "../../constant";

const GramaSevaDivision = () => {
  // breadcrumb values
  let childLinks = [
    { value: "Master File", active: false },
    { value: "Grama Seva Division", active: true },
  ];

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, GRAMA_SEVA_DIVISION);
  const sendRequest = useAxiosTemplates();

  // helper containers
  const [resData, setResData] = useState();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form controls
  const [isValidated, setIsValidated] = useState(false);

  // form values
  const [districtList, setDistrictList] = useState([
    { districtId: "", name: "-Select-" },
  ]);
  const [dpDistrictValue, setDpDistrictValue] = useState(0);
  const [cityList, setCityList] = useState([{ cityId: "", name: "-Select-" }]);
  const [dpCityValue, setDpCityValue] = useState(0);
  const [policeStationList, setPoliceStationList] = useState([
    { policeStationId: "", name: "-Select-" },
  ]);
  const [dpPoliceStationValue, setDpPoliceStationValue] = useState(0);
  const [gramaSevaDivisionId, setGramaSevaDivisionId] = useState(0);

  // table data
  const [gramaSevaDivisionRowList, setGramaSevaDivisionRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    // {
    //   name: "Client",
    //   options: {
    //     display: false,
    //     download: true,
    //     filter: false,
    //     viewColumns: false,
    //   },
    // },
    // {
    //   name: "Organization",
    //   options: {
    //     display: false,
    //     download: true,
    //     filter: false,
    //     viewColumns: false,
    //   },
    // },
    "Search Key",
    "Name",
    "District",
    "City",
    "Police Station",
    "Remarks",
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDIT_GRAMA_SEVA_DIVISION) ||
          checkIsAccessible(DELETE_GRAMA_SEVA_DIVISION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editGramaSevaDivision] = useState(
    () => (grama_seva_division_id, response_data) => {
      document.querySelector(".form-1").focus();
      const editingGramaSevaDivision = response_data.find(
        (item) => item.gramaSevaDivisionId === grama_seva_division_id
      );
      setGramaSevaDivisionId(grama_seva_division_id);
      document.querySelector("input[name='fm_1_search_key']").value =
        editingGramaSevaDivision.searchKey;
      document.querySelector("input[name='fm_1_name']").value =
        editingGramaSevaDivision.name;
      if (editingGramaSevaDivision.district.isActive) {
        setDpDistrictValue(editingGramaSevaDivision.district.districtId);
      } else {
        Swal.fire({
          icon: "warning",
          title: "Warning",
          text: `${editingGramaSevaDivision.district.name} District has Deactivated`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
      if (editingGramaSevaDivision.city.isActive) {
        setDpCityValue(editingGramaSevaDivision.city.cityId);
      } else {
        Swal.fire({
          icon: "warning",
          title: "Warning",
          text: `${editingGramaSevaDivision.city.name} City has Deactivated`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
      if (editingGramaSevaDivision.policeStation.isActive) {
        setDpPoliceStationValue(
          editingGramaSevaDivision.policeStation.policeStationId
        );
      } else {
        Swal.fire({
          icon: "warning",
          title: "Warning",
          text: `${editingGramaSevaDivision.policeStation.name} has Deactivated`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
      document.querySelector("textarea[name='fm_1_remark']").value =
        editingGramaSevaDivision.remark;
      document.querySelector("input[name='fm_1_is_active']").checked =
        editingGramaSevaDivision.isActive;
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  );
  const [deleteGramaSevaDivision] = useState(
    () => (grama_seva_division_id, logged_user) => {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "This will permenantly deletes this Grama Seva Division!!!",
        showDenyButton: true,
        confirmButtonText: "Delete",
        denyButtonText: "Cancel",
        denyButtonColor: "#2a3142",
      }).then((result) => {
        if (result.isConfirmed) {
          const reqBody = {
            clientId: logged_user.clientId,
            orgId: logged_user.orgId,
            userId: logged_user.userId,
            gramaSevaDivisionId: grama_seva_division_id,
          };
          let responseData = new Promise((resolve) => {
            const result = sendRequest({
              url: "/deleteGramaSevaDivision",
              data: reqBody,
              template: "CONTROL_DATA",
            });
            resolve(result);
          });
          responseData.then((response_data) => {
            if (response_data) {
              Swal.fire({
                icon: "success",
                title: "Request Successful",
                text: "Grama Seva Division has successfully deleted.",
                showConfirmButton: false,
                timer: 5000,
              });
              setRefreshTable(true);
            }
          });
        }
      });
    }
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // fetch master data
  useEffect(() => {
    if (refreshTable) {
      document
        .querySelectorAll("input:required,select:required")
        .forEach((elem) =>
          elem.previousSibling.classList.add("required-input-mark")
        );
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/viewGramaSevaDivision",
          data: reqBody,
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          if (response_data.activeDistrictList) {
            setDistrictList([
              { districtId: "", name: "-Select-" },
              ...response_data.activeDistrictList,
            ]);
          }
          if (response_data.activeCityList) {
            setCityList([
              { cityId: "", name: "-Select-" },
              ...response_data.activeCityList,
            ]);
          }
          if (response_data.activePoliceStationList) {
            setPoliceStationList([
              { policeStationId: "", name: "-Select-" },
              ...response_data.activePoliceStationList,
            ]);
          }
          if (response_data.allGramaSevaDivisionList) {
            setResData(response_data.allGramaSevaDivisionList);
          }
        }
      });
      setRefreshTable(false);
    }
  }, [refreshTable, loggedUser, isValidated, sendRequest]);

  useEffect(() => {
    if (resData) {
      let tempList = [];
      resData.forEach((listItem) => {
        // if (
        //   loggedUser.activeOrgRoleList?.find(
        //     (item) => item.orgId === listItem.orgId
        //   )
        // ) {
        tempList.push([
          listItem.gramaSevaDivisionId,
          // loggedUser.activeClientList?.find(
          //   (item) => item.clientId === listItem.clientId
          // ).name,
          // loggedUser.activeOrgRoleList?.find(
          //   (item) => item.orgId === listItem.orgId
          // ).name,
          listItem.searchKey,
          listItem.name,
          listItem.district.name,
          listItem.city.name,
          listItem.policeStation.name,
          listItem.remark,
          listItem.isActive ? "True" : "False",
          <ControlButtons
            editMethod={() =>
              editGramaSevaDivision(listItem.gramaSevaDivisionId, resData)
            }
            disableEdit={!checkIsAccessible(EDIT_GRAMA_SEVA_DIVISION)}
            deleteMethod={() =>
              deleteGramaSevaDivision(listItem.gramaSevaDivisionId, loggedUser)
            }
            disableDelete={!checkIsAccessible(DELETE_GRAMA_SEVA_DIVISION)}
          />,
        ]);
        // }
      });
      setGramaSevaDivisionRowList([...tempList]);
    }
  }, [
    resData,
    editGramaSevaDivision,
    deleteGramaSevaDivision,
    loggedUser,
    checkIsAccessible,
  ]);

  useEffect(() => {
    if (dpDistrictValue) {
      const reqBody = {
        districtId: dpDistrictValue,
      };
      let responseArrayData = new Promise((resolve) => {
        const result = sendRequest({
          url: [
            "/getAllActiveCityByDistrict",
            "/getAllActivePoliceStationByDistrict",
          ],
          data: reqBody,
          template: "REQUEST_ARRAY",
        });
        resolve(result);
      });
      responseArrayData.then((response_data_array) => {
        if (response_data_array) {
          setCityList((prevList) => [prevList[0], ...response_data_array[0]]);
          setPoliceStationList((prevList) => [
            prevList[0],
            ...response_data_array[1],
          ]);
        }
      });
    }
  }, [dpDistrictValue, sendRequest]);

  // form control functions
  const resetForm = (evt) => {
    setIsValidated(false);
    setGramaSevaDivisionId(0);
    setDpDistrictValue(0);
    setDpCityValue(0);
    setDpPoliceStationValue(0);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/checkGramaSevaDivisionExist",
          data: {
            clientId: loggedUser.clientId,
            gramaSevaDivisionId: gramaSevaDivisionId,
            searchKey: formData.get("fm_1_search_key"),
            name: formData.get("fm_1_name"),
          },
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          switch (response_data.existType) {
            case 0:
              let gramaSevaDivision = {
                clientId: parseInt(loggedUser.clientId),
                orgId: parseInt(loggedUser.orgId),
                userId: parseInt(loggedUser.userId),
                gramaSevaDivisionId: gramaSevaDivisionId,
                isActive: document.querySelector("input[name='fm_1_is_active']")
                  ?.checked,
                searchKey: formData.get("fm_1_search_key"),
                name: formData.get("fm_1_name"),
                districtId: parseInt(dpDistrictValue),
                cityId: parseInt(dpCityValue),
                policeStationId: parseInt(dpPoliceStationValue),
                remark: formData.get("fm_1_remark"),
              };
              let nestedResponseData = new Promise((resolve) => {
                const result = sendRequest({
                  url: "/saveGramaSevaDivision",
                  data: gramaSevaDivision,
                  template: "CONTROL_DATA",
                });
                resolve(result);
              });
              nestedResponseData.then((nested_reponse_data) => {
                if (nested_reponse_data) {
                  Swal.fire({
                    icon: "success",
                    title: "Request Successful",
                    text: gramaSevaDivisionId
                      ? "Grama Seva Division has successfully updated."
                      : "New Grama Seva Division has successfully added.",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  setRefreshTable(true);
                }
              });
              break;
            case 1:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Search Key Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            case 2:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Name Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            case 3:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Search Key & Name Exist",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            default:
              break;
          }
        }
      });
    }
  };

  return (
    <>
      <Breadcrumb
        parent="Dashboard"
        title="Master File"
        children={childLinks}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Grama Seva Division</h5>
                <span>Master File for creating a Grama Seva Division </span>
              </div>
              <div className="card-body">
                <form
                  tabIndex={1}
                  onSubmit={(evt) => submitForm(evt)}
                  onReset={(evt) => resetForm(evt)}
                  className={`form-1 ${isValidated ? "was-validated" : ""}`}
                  noValidate
                >
                  <div className="form-row mb-4">
                    <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 position-relative">
                      <label htmlFor="id_input_fm_1_search_key">
                        Search Key
                      </label>
                      <input
                        id="id_input_fm_1_search_key"
                        name="fm_1_search_key"
                        type="text"
                        maxLength={45}
                        className="form-control"
                        placeholder="Enter Search Key"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            validateSearchKey: true,
                          })
                        }
                        required
                      />
                      <small className="invalid-feedback position-absolute">
                        Please enter a Search Key
                      </small>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div className="form-group col-12 col-sm-6 position-relative">
                      <label htmlFor="id_input_fm_1_name">Name</label>
                      <input
                        id="id_input_fm_1_name"
                        name="fm_1_name"
                        type="text"
                        maxLength={150}
                        className="form-control"
                        placeholder="Enter Grama Seva Division Name"
                        onChange={(evt) => formValidation({ event: evt })}
                        required
                      />
                      <small className="invalid-feedback position-absolute">
                        Please enter a Grama Seva Division Name
                      </small>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                  </div>
                  <div className="form-row mb-4">
                    <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_district_id"
                        className="col-form-label pt-0"
                      >
                        District
                      </label>
                      <select
                        id="id_input_fm_1_sa_district_id"
                        name="fm_1_sa_district_id"
                        className="form-select"
                        onChange={(evt) => setDpDistrictValue(evt.target.value)}
                        value={dpDistrictValue}
                        required
                      >
                        {districtList.length
                          ? districtList.map((listItem, index) => (
                              <option value={listItem?.districtId} key={index}>
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a District
                      </small>
                    </div>
                    <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_city_id"
                        className="col-form-label pt-0"
                      >
                        City
                      </label>
                      <select
                        id="id_input_fm_1_sa_city_id"
                        name="fm_1_sa_city_id"
                        className="form-select"
                        onChange={(evt) => setDpCityValue(evt.target.value)}
                        value={dpCityValue}
                        disabled={dpDistrictValue ? false : true}
                        required
                      >
                        {cityList.length
                          ? cityList.map((listItem, index) => (
                              <option value={listItem?.cityId} key={index}>
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a City
                      </small>
                    </div>
                    <div className="form-group col-12 col-sm-4 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_police_station_id"
                        className="col-form-label pt-0"
                      >
                        Police Station
                      </label>
                      <select
                        id="id_input_fm_1_sa_police_station_id"
                        name="fm_1_sa_police_station_id"
                        className="form-select"
                        onChange={(evt) =>
                          setDpPoliceStationValue(evt.target.value)
                        }
                        value={dpPoliceStationValue}
                        disabled={dpDistrictValue ? false : true}
                        required
                      >
                        {policeStationList.length
                          ? policeStationList.map((listItem, index) => (
                              <option
                                value={listItem?.policeStationId}
                                key={index}
                              >
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a Police Station
                      </small>
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="id_input_fm_1_remark">Remarks</label>
                    <textarea
                      id="id_input_fm_1_remark"
                      name="fm_1_remark"
                      rows="3"
                      maxLength={250}
                      className="form-control"
                      placeholder="Enter Remarks"
                      onChange={(evt) =>
                        formValidation({ event: evt, checkExpression: "" })
                      }
                    ></textarea>
                    <small className="txt-danger position-absolute d-none input-validation"></small>
                  </div>
                  <div className="form-group mb-4">
                    <div className="checkbox checkbox-solid-dark col-12 col-sm-6 mx-1">
                      <input
                        id="id_input_fm_1_is_active"
                        name="fm_1_is_active"
                        type="checkbox"
                        disabled={
                          !checkIsAccessible(DEACTIVATE_GRAMA_SEVA_DIVISION)
                        }
                        defaultChecked
                      />
                      <label htmlFor="id_input_fm_1_is_active">Active</label>
                    </div>
                  </div>
                  {checkIsAccessible(INSERT_GRAMA_SEVA_DIVISION) ||
                  (checkIsAccessible(EDIT_GRAMA_SEVA_DIVISION) &&
                    gramaSevaDivisionId !== 0) ? (
                    <div className="d-sm-flex justify-content-end">
                      <button
                        className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                        type="submit"
                      >
                        {gramaSevaDivisionId ? "Update" : "Submit"}{" "}
                      </button>
                      <button
                        className="btn btn-warning col-12 col-sm-2"
                        type="reset"
                      >
                        Reset
                      </button>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
            <div className="mb-4">
              <Datatable
                titleData="Grama Seva Division List"
                columnData={tableColumnHeaderList}
                rowData={gramaSevaDivisionRowList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GramaSevaDivision;
