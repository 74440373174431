import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";
import { Alert, Col, Input, Label, Row } from "reactstrap";
import { AlertTriangle, ArrowLeft, ArrowRight, Check, PlusCircle, RefreshCw, UserCheck, X } from "react-feather";

// custom components
// import Datatable, {
//   BooleanValue,
//   ControlButtons,
// } from "../../../common/datatable";

// customer hooks
import useAxiosTemplates from "../../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../../customHooks/useValidation";
// import {
//   INSERT_JOB_CATEGORY,
//   INSERT_JOB_CATEGORY_LINE,
//   JOB_CATEGORY,
//   JOB_CATEGORY_LINE,
//   MarginTop,
//   Textarea,
// } from "../../../../constant";
import ConfigDB from "../../../../../data/customizer/config";
import { Carousel } from 'react-bootstrap';

const EmergencyContact = ({ b_partner_id = 0, client_id = 0, org_id = 0, set_basic_tab = null, set_back_tab = null }) => {
    const LOADING = 0;
    const LOADED = 1;

    const EDITFUNCTION = "EditCustomer";
    const INSERTFUNCTION = "InsertCustomer";
    const DEACTIVATEFUNCTION = "DeactivateCustomer";
    const DELETEFUNCTION = "DeleteCustomer";

    // stored data
    const loggedUser = useSelector((content) => content.UserReducer);

    // custom hooks
    const [checkIsAccessible] = useAccess(loggedUser, "Customer");
    const isInsertAllowed = useAccess(loggedUser)[0];
    const sendRequest = useAxiosTemplates();

    // interface status data
    const [refreshTable, setRefreshTable] = useState(true);

    // form controls
    const [isValidated, setIsValidated] = useState(false);
    const [isLoaded, setIsLoaded] = useState(LOADING);
    const [refreshDpJobCategory, setRefreshDpJobCategory] = useState(false);
    const [refreshDpHowSoonJoin, setRefreshDpHowSoonJoin] = useState(false);

    const [refreshDpJobCategoryWithVacancy, setRefreshDpJobCategoryWithVacancy] = useState(false);

    const [refreshDpJobCategoryLine, setRefreshDpJobCategoryLine] =
        useState(false);

    // form values
    const [jobList, setJobList] = useState([
        { jobCategoryId: "", name: "-Select-" },
    ]);

    const [howSoonJoinList, setHowSoonJoinList] = useState([
        { joinId: "", name: "-Select-" },
    ]);

    const [jobWithVacancyList, setJobWithVacancyList] = useState([]);
    const [dpJobValue, setDpJobValue] = useState(0);
    const [dpHowSoonJoinValue, setDpHowSoonJoinValue] = useState(0);
    const [vacancyList, setVacancyList] = useState([
        { jobCategoryLineId: "", name: "-Select-" },
    ]);
    const [dpVacancyValue, setDpVacancyValue] = useState(0);
    const [emergencyId, setEmergencyId] = useState(0);

    const [isSuccess, setIsSuccess] = useState(false);
    const [jobCategoryLineIdsList, setJobCategoryLineIdsList] = useState([])

    const [viewEmergencyList, setViewEmergencyList] = useState([])

    const [filterVacancyList, setFilterVacancyList] = useState([])

    const [locationTables, setLocationTables] = useState([]);

    // table data
    const [jobRowList, setJobRowList] = useState([]);

    const [mostInterestedId, setMostInterestedId] = useState(null);

    const [filterValue, setFilterValue] = useState('');
    const [showFilterDropdown, setShowFilterDropdown] = useState(false)

    const dropdownItems = document.querySelectorAll(".dropdown-menu li");
    const filterButton = document.querySelector(".btn.dropdown-toggle");

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [emergencyResponseData, setEmergencyResponseData] = useState()

    const [emergencyTypeList, setEmergencyTypeList] = useState([]);

    const handleInputChange = (event) => {

        const value = event.target.value.toLowerCase();


        setFilterValue(value);

        const dropdownItems = document.querySelectorAll(".dropdown-menu li");
        dropdownItems.forEach(item => {
            const itemText = item.textContent.toLowerCase();
            item.style.display = itemText.indexOf(value) > -1 ? 'block' : 'none';
        });

    };

    const [filteredVacancyList, setFilteredVacancyList] = useState(jobWithVacancyList);

    useEffect(() => {
        if (selectedItemId) {
            setFilteredVacancyList(jobWithVacancyList.filter(item => item.categoryId === selectedItemId))
        } else {
            setFilteredVacancyList(jobWithVacancyList)
        }
    }, [selectedItemId, jobWithVacancyList])

    const handleItemClick = (itemId, itemName) => {

        setTimeout(() => {
            setSelectedItemId(itemId);

            const filterButton = document.querySelector(".btn.dropdown-toggle");
            if (filterButton) {
                filterButton.textContent = itemName;
            }

            setShowFilterDropdown(false);
        }, 100)

        const reqBody = {
            clientId: loggedUser.clientId,
            orgId: loggedUser.orgId,
            name: itemName
        };
        new Promise((resolve) => {
            const result = sendRequest({
                url: "/getJobCategoryLine",
                data: reqBody,
            });
            resolve(result);
        }).then((response_data) => {
            if (response_data) {

                console.log(response_data);
                setFilterVacancyList(response_data);

                setIsLoaded(LOADED);
                // setRefreshTable(false);

                console.log(filteredVacancyList);
            }
        });

    };

    const handleCheckboxChange = (evt, relationTypeId) => {
        const linkInput = document.getElementById(`id_input_fm_2__${relationTypeId}__relation`);

        if (linkInput) {
            linkInput.required = evt.target.checked;
        }
    };

    const submitForm = (evt) => {

        setIsValidated(true);
        evt.preventDefault();
        console.log(evt);

        const form = new FormData(document.querySelector(".form-2"));


        const selectedEmergencies = form.getAll("fm_1_interface_relation");
        console.log(selectedEmergencies);

        console.log(selectedEmergencies.length);

        if (selectedEmergencies.length === 0) {
            Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Sorry, please apply a emergency contact number before enter next",
                showConfirmButton: false,
                timer: 5000,
            });
        }


        let emergencywithnumberidlist = []
        emergencyTypeList.forEach(view_emergency_list_item => {

            const relationTypeIds = view_emergency_list_item.relationTypeId;
            // Push socialTypeId into the new array
            emergencywithnumberidlist.push(relationTypeIds);

        })


        const numberList = [];

        selectedEmergencies.forEach((emergency_item) => {
            const NumberValue = form.getAll(`fm_2__${emergency_item}__relation`);
            numberList.push({ relationTypeId: emergency_item, emegencyNumber: NumberValue });
        });

        console.log(numberList.length);
        console.log(emergencywithnumberidlist);

        let numberEnteredForUnselectedEmergencyType = false;

        emergencywithnumberidlist.forEach(view_emergency_list_item => {
            let filteredNumber = form.getAll(`fm_2__${view_emergency_list_item}__relation`)[0]
            if (filteredNumber !== "" && filteredNumber != undefined) {
                if (!selectedEmergencies.includes(view_emergency_list_item + '')) {
                    numberEnteredForUnselectedEmergencyType = true
                }
            }
        })


        let deselectedEmergencies = []
        let viewEmergencyIdList = viewEmergencyList.map(emergency_item => emergency_item.relationId)
        console.log(viewEmergencyIdList);
        selectedEmergencies.forEach((selected_emergency_item) => {
            let emergencyItem = viewEmergencyList.find(view_emergency_item => view_emergency_item.relationTypeId === parseInt(selected_emergency_item))
            console.log(emergencyItem);
            if (emergencyItem !== undefined) {
                let itemToRemove = viewEmergencyIdList.indexOf(emergencyItem.relationId)
                delete viewEmergencyIdList[itemToRemove]
                console.log(emergencyItem);
            }
        })
        deselectedEmergencies = viewEmergencyIdList.filter(filtered_emergency_id_item => filtered_emergency_id_item > 0)
        console.log(deselectedEmergencies);
        deselectedEmergencies.forEach((deselected_emergency_item) => {
            const reqBody = {
                clientId: loggedUser.clientId,
                orgId: loggedUser.orgId,
                userId: loggedUser.userId,
                relationId: deselected_emergency_item,
            };
            new Promise((resolve) => {
                const result = sendRequest({
                    url: "/deleteRelationTab",
                    data: reqBody,
                });
                resolve(result);
            }).then((nested_reponse_data) => {
                if (nested_reponse_data.isSuccess) {

                    setIsLoaded(LOADING);
                    setIsSuccess(true);

                }
            });
        })

        if (evt.target.checkValidity()) {
            const formData = new FormData(evt.target);
            selectedEmergencies.forEach((emergency_item) => {

                console.log(evt.target);

                console.log(viewEmergencyList);

                const emergencyIds = [...new Set(viewEmergencyList.map(item => item.relationTypeId))];

                console.log(emergencyIds);
                console.log(typeof emergencyIds[0]);

                console.log(parseInt(emergency_item));

                const filteredEmergencies = viewEmergencyList
                    .filter((item) => item.relationTypeId === parseInt(emergency_item));

                let emergencyIdNew = 0;

                if (filteredEmergencies.length > 0) {
                    const emergencyIdsArray = filteredEmergencies.map((item) => item.relationId);
                    // setEmergencyId(emergencyIdsArray[0]);
                    console.log(emergencyIdsArray[0]);
                    console.log(emergencyId);
                    emergencyIdNew = emergencyIdsArray[0];
                    // console.log(emergencyIdNew);
                } else {
                    emergencyIdNew = 0;
                    console.log(emergencyIdNew);
                }

                if (isNaN(parseInt(emergency_item))) {
                    console.log(isNaN(emergency_item));
                    // Show an alert if jobCategoryLineId is not a valid number
                    Swal.fire({
                        icon: "error",
                        title: "Request Failed",
                        text: "Please select a relation type",
                        showConfirmButton: false,
                        timer: 5000,
                    });
                }

                if ((numberEnteredForUnselectedEmergencyType)) {
                    // alert("Selected most interested or remark values should be included in selected vacancies, but no vacancies are selected.");

                    Swal.fire({
                        icon: "error",
                        title: "Request Failed",
                        text: "If you need to store data, please select the relation type related to emergency number.",
                        showConfirmButton: false,
                        timer: 5000,
                    });
                } else {

                    let emergency = {
                        bpartnerId: parseInt(b_partner_id),
                        relationId: parseInt(emergencyIdNew),
                        clientId: parseInt(client_id),
                        orgId: parseInt(org_id),
                        userId: parseInt(loggedUser.userId),
                        relationTypeId: parseInt(emergency_item),
                        number: formData.get(`fm_2__${emergency_item}__relation`),
                        remark: "",
                        // isActive: formData.get("fm_2_is_active") ? true : false,
                        isActive: true,
                    };
                    console.log(formData);
                    let nestedResponseData = new Promise((resolve) => {
                        const result = sendRequest({
                            url: "/saveRelationTab",
                            data: emergency,
                        });
                        resolve(result);
                    });
                    nestedResponseData.then((nested_reponse_data) => {
                        if (nested_reponse_data.isSuccess) {

                            set_basic_tab("save_customer_tab_6");
                            // set_basic_tab();
                            setIsLoaded(LOADING);
                            setIsSuccess(true);
                        }


                    });

                }
            })

        }



    };

    useEffect(() => {
        console.log('Updated jobId:', emergencyId);
    }, [emergencyId]);

    const resetForm = (evt) => {
        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to reset?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {

                setIsValidated(false);

                let lineIdList = []
                lineIdList = emergencyResponseData.map((listItem) => listItem.relationTypeId)
                lineIdList.forEach(relation_item => {
                    let checkbox = document.querySelector(`#input_fm_1_active_relation_id_${relation_item}`)

                    console.log(emergencyResponseData);

                    if (checkbox != null) {
                        checkbox.checked = false;
                    }
                })


                let numberList = []
                numberList = emergencyResponseData.map((listItem) =>
                //  listItem.jobCategoryLineId)
                ({
                    relationTypeId: listItem.relationTypeId,
                    number: listItem.number,
                }));
                numberList.forEach(number_item => {

                    let number_list = document.querySelectorAll(`#id_input_fm_2__${number_item.relationTypeId}__relation`);
                    if (number_list.length > 0) {
                        number_list[0].value = "";
                    }

                })

            }
        });
        // setIsSuccess(false)
    };

    useEffect(() => {
        // if (b_partner_id > 0) {
        if (refreshTable) {
            const reqBody = {
                clientId: loggedUser.clientId,
                orgId: loggedUser.orgId,
                userId: loggedUser.userId,
                bpartnerId: b_partner_id,
            };
            new Promise((resolve) => {
                const result = sendRequest({
                    url: "/viewRelationTab",
                    data: reqBody,
                });
                resolve(result);
            }).then((response_data) => {
                if (response_data) {
                    if (response_data.relationTypeList) {
                        setEmergencyTypeList([...response_data.relationTypeList]);
                    }

                    if (response_data.allTableList) {
                        setViewEmergencyList([...response_data.allTableList]);
                        // response_data.allTableList.forEach((listItem) => {
                        setEmergencyResponseData(response_data.allTableList)
                        // });
                    }
                    setIsLoaded(LOADED);
                }
            });
        }
        // } else {
        //     setIsLoaded(LOADED);
        // }
    }, [isLoaded]);

    useEffect(() => {
        if (viewEmergencyList?.length > 0) {
            viewEmergencyList.forEach((listItem) => {

                let relationypeList = []
                relationypeList = viewEmergencyList.map((listItem) => listItem.relationTypeId)
                relationypeList.forEach(relation_item => {
                    document.querySelector(`#input_fm_1_active_relation_id_${relation_item}`).checked = true
                })

                viewEmergencyList.forEach(link_item => {
                    console.log(link_item);

                    let linkList = document.querySelector(`#id_input_fm_2__${link_item.relationTypeId}__relation`);
                    console.log(linkList);
                    linkList.value = link_item.number;
                })
            });
        }
    }, [viewEmergencyList])

    const closeItem = () => {
        setIsSuccess(false);
    };

    const handleBackClick = () => {
        set_back_tab()
    };

    // const handleNextClick = () => {
    //     set_basic_tab()
    // };

    return (
        <>
            <div className="card-modified-body bg-white pt-0 mb-4">
                <Alert
                    className="alert-dismissible d-flex align-items-center"
                    color="warning"
                    isOpen={b_partner_id === 0}
                >
                    <AlertTriangle />
                    <p className="ml-2">
                        You must create a customer, before add emergency details.
                    </p>
                </Alert>
                <form
                    onSubmit={(evt) => submitForm(evt)}
                    onReset={(evt) => resetForm(evt)}
                    className={`position-relative form-2 ${isValidated ? "was-validated" : ""
                        }`}
                    noValidate
                >
                    {isLoaded === LOADING ? (
                        <div className="form-loader d-flex justify-content-center align">
                            <div className="loader-box">
                                <div className="loader">
                                    <div className="line bg-secondary"></div>
                                    <div className="line bg-secondary"></div>
                                    <div className="line bg-secondary"></div>
                                    <div className="line bg-secondary"></div>
                                </div>
                            </div>
                        </div>
                    ) : null}


                    <Row className="m-b-10">
                        <Col lg="15 m-t-25">

                            <Row className="m-b-20 border-bottom d-none d-md-flex">
                                <Col lg="2" md="6">
                                    <div className="fw-bold m-b-10 required-input-mark">
                                        Relation Type
                                    </div>
                                </Col>
                                <Col lg="2" md="6">
                                    <div className="fw-bold m-b-10 required-input-mark">
                                        Emergency Number
                                    </div>
                                </Col>
                            </Row>


                            <Row className="m-b-20">

                                {emergencyTypeList?.length > 0
                                    ? emergencyTypeList
                                        .map((emergency_item, index) => (
                                            <Col lg="12"
                                                key={index}
                                            >
                                                <Row className="m-b-10 border-bottom">
                                                    <Col lg="2" md="6">
                                                        <Label
                                                            className="d-block fw-bold"
                                                            htmlFor="input_fm_1_active"
                                                            key={index}
                                                        >
                                                            <Input
                                                                className="checkbox_animated"
                                                                id={`input_fm_1_active_relation_id_${emergency_item.relationTypeId}`}
                                                                value={`${emergency_item.relationTypeId}`}
                                                                name="fm_1_interface_relation"
                                                                type="checkbox"
                                                                onChange={(evt) => handleCheckboxChange(evt, emergency_item.relationTypeId)}
                                                            />

                                                            {emergency_item.name}
                                                        </Label>
                                                    </Col>

                                                    <Col lg="2" md="6" className="mt-3 mt-md-0">
                                                        {/* {

                                                            job_item.jobCategoryLineMinList
                                                                .map(
                                                                    (vacancy_item, index2) => ( */}
                                                        <Row className="px-4 px-md-0 mb-2 mb-md-0">


                                                            <Col xs="12" className=" mt-1 mt-md-0">
                                                                <Label
                                                                    className="d-block"
                                                                    htmlFor="input_fm_1_active"
                                                                    key={index}
                                                                >
                                                                    <Input
                                                                        className="form-control px-0"
                                                                        id={`id_input_fm_2__${emergency_item.relationTypeId}__relation`}
                                                                        name={`fm_2__${emergency_item.relationTypeId}__relation`}
                                                                        type="text"
                                                                        maxLength={15}
                                                                        minLength={9}
                                                                        placeholder="Enter Contact Number"
                                                                        autoComplete="off"
                                                                        onChange={(evt) => {
                                                                            formValidation({
                                                                                event: evt,
                                                                                validateNumber: true,
                                                                            });
                                                                            document.querySelectorAll(`#id_input_fm_2__${emergency_item.relationTypeId}__relation`).value = evt.target.value;
                                                                        }}
                                                                    // required
                                                                    ></Input>
                                                                    <div className="invalid-feedback position-absolute">
                                                                        Please enter Number
                                                                    </div>
                                                                    <small className="txt-danger position-absolute d-none input-validation"></small>

                                                                </Label>
                                                            </Col>
                                                        </Row>
                                                        {/* )
                                                                )} */}
                                                    </Col>

                                                </Row>

                                            </Col>

                                        ))
                                    : null}

                            </Row>


                        </Col>
                    </Row>



                    {b_partner_id ? (
                        checkIsAccessible(INSERTFUNCTION) ||
                            (checkIsAccessible(EDITFUNCTION) && emergencyId !== 0) ? (
                            <div className="d-sm-flex justify-content-end">

                                <button className="btn btn-outline-primary col-md-1 col-3 px-1 mb-2 mb-sm-0" type="reset">
                                    Reset
                                </button>
                                <button className="btn btn-primary col-md-2 col-4 mx-3 mb-2 mb-sm-0" type="submit">
                                    {emergencyId ? "Next" : "Next"}
                                </button>

                                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleBackClick()}><ArrowLeft height={"16px"} width={"16px"} />Back</button>

                                {/* <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => set_basic_tab("save_customer_tab_6")}>Next<ArrowRight height={"16px"} width={"16px"} /></button> */}

                            </div>
                        ) : null
                    ) : null}
                    <br />


                </form >
            </div >

        </>
    );
};

export default EmergencyContact;
