import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";
import { Alert, Col, Input, Label, Row } from "reactstrap";
import {
  AlertTriangle,
  ArrowLeft,
  Check,
  PlusCircle,
  RefreshCw,
  UserCheck,
  X,
} from "react-feather";

// custom components
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../common/datatable";

// customer hooks
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../customHooks/useValidation";
import {
  INSERT_JOB_CATEGORY,
  INSERT_JOB_CATEGORY_LINE,
  JOB_CATEGORY,
  JOB_CATEGORY_LINE,
  MarginTop,
  Textarea,
} from "../../../../constant";
import ConfigDB from "../../../../data/customizer/config";
import { Carousel } from "react-bootstrap";
import Breadcrumb from "../../../common/breadcrumb";

const Job = ({
  b_partner_id = 0,
  client_id = 0,
  org_id = 0,
  set_basic_tab = null,
  set_back_tab = null,
}) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshDpJobCategory, setRefreshDpJobCategory] = useState(false);
  const [refreshDpHowSoonJoin, setRefreshDpHowSoonJoin] = useState(false);

  const [refreshDpJobCategoryWithVacancy, setRefreshDpJobCategoryWithVacancy] =
    useState(false);

  const [refreshDpJobCategoryLine, setRefreshDpJobCategoryLine] =
    useState(false);

  // form values
  const [jobList, setJobList] = useState([
    { jobCategoryId: "", name: "-Select-" },
  ]);

  const [howSoonJoinList, setHowSoonJoinList] = useState([
    { joinId: "", name: "-Select-" },
  ]);

  const [jobWithVacancyList, setJobWithVacancyList] = useState([]);
  const [dpJobValue, setDpJobValue] = useState(0);
  const [dpHowSoonJoinValue, setDpHowSoonJoinValue] = useState(0);
  const [vacancyList, setVacancyList] = useState([
    { jobCategoryLineId: "", name: "-Select-" },
  ]);
  const [dpVacancyValue, setDpVacancyValue] = useState(0);
  const [jobId, setJobId] = useState(0);

  const [isSuccess, setIsSuccess] = useState(false);
  const [jobCategoryLineIdsList, setJobCategoryLineIdsList] = useState([]);

  const [viewJobList, setViewJobList] = useState([]);

  const [filterVacancyList, setFilterVacancyList] = useState([]);

  const [locationTables, setLocationTables] = useState([]);

  // table data
  const [jobRowList, setJobRowList] = useState([]);

  const [mostInterestedId, setMostInterestedId] = useState(null);

  const [filterValue, setFilterValue] = useState("");
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);

  const dropdownItems = document.querySelectorAll(".dropdown-menu li");
  const filterButton = document.querySelector(".btn.dropdown-toggle");

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [jobResponseData, setJobResponseData] = useState();

  const handleInputChange = (event) => {
    const value = event.target.value.toLowerCase();

    setFilterValue(value);

    const dropdownItems = document.querySelectorAll(".dropdown-menu li");
    dropdownItems.forEach((item) => {
      const itemText = item.textContent.toLowerCase();
      item.style.display = itemText.indexOf(value) > -1 ? "block" : "none";
    });
  };

  const [filteredVacancyList, setFilteredVacancyList] =
    useState(jobWithVacancyList);

  useEffect(() => {
    if (selectedItemId) {
      setFilteredVacancyList(
        jobWithVacancyList.filter((item) => item.categoryId === selectedItemId)
      );
    } else {
      setFilteredVacancyList(jobWithVacancyList);
    }
  }, [selectedItemId, jobWithVacancyList]);

  const handleItemClick = (itemId, itemName) => {
    // Update state with the selected item's ID
    setTimeout(() => {
      setSelectedItemId(itemId);

      // Update the button text with the selected item's name
      const filterButton = document.querySelector(".btn.dropdown-toggle");
      if (filterButton) {
        filterButton.textContent = itemName;
      }

      // You can also close the dropdown if needed

      setShowFilterDropdown(false);
    }, 100);

    const reqBody = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      name: itemName,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/getJobCategoryLine",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      if (response_data) {
        console.log(response_data);
        setFilterVacancyList(response_data);

        setIsLoaded(LOADED);
        // setRefreshTable(false);

        console.log(filteredVacancyList);
      }
    });
  };

  const handleCheckboxChange = (jobCategoryLineId, evt) => {
    // document.querySelectorAll(`#input_fm_1_most_interested_id_${jobCategoryLineId}`)[0].checked = evt.target.checked;
    // document.querySelectorAll(`#input_fm_1_most_interested_id_${jobCategoryLineId}`)[1].checked = evt.target.checked;
    setMostInterestedId((prevId) =>
      prevId === jobCategoryLineId ? null : jobCategoryLineId
    );
  };

  // const handleCheckboxChange = (jobCategoryLineId) => {
  //   setMostInterestedId((prevId) => (prevId === jobCategoryLineId ? true : jobCategoryLineId));
  // };

  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    console.log(evt);

    const form = new FormData(document.querySelector(".form-2"));
    let assignedJobList = [];
    const selectedJobs = form.getAll("fm_1_interface_job");
    console.log(selectedJobs);

    const selectedVacancies = form.getAll("fm_1_interface_vacancy");
    console.log(selectedVacancies);

    const selectedMostInterestedList = form.getAll("fm_1_most_interested");
    console.log(selectedMostInterestedList);

    console.log(selectedVacancies.length);

    if (selectedVacancies.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Request Failed",
        text: "Sorry, please apply a job vacancy before you go to the next step.",
        showConfirmButton: false,
        timer: 5000,
      });
    }

    // const selectedRemarkList = form.getAll("fm_2_remark");
    // console.log(selectedRemarkList);

    const remarkList = [];

    selectedVacancies.forEach((vacancy_item) => {
      const remarkValue = form.getAll(`fm_2__${vacancy_item}__remark`)[0];
      remarkList.push({ jobCategoryLineId: vacancy_item, remark: remarkValue });
    });

    console.log(remarkList.length);

    let remarkEnteredForUnselectedVacancy = false;
    let jobwithvacancyidlist = [];
    jobWithVacancyList.forEach((view_job_list_item) => {
      jobwithvacancyidlist.push(
        view_job_list_item.jobCategoryLineMinList.map(
          (item) => item.jobCategoryLineId
        )
      );
    });

    console.log(jobwithvacancyidlist);

    jobwithvacancyidlist.forEach((view_job_list_item) => {
      view_job_list_item.forEach((view_job_list_item_vacancy_item) => {
        let filteredRemark =
          form.getAll(`fm_2__${view_job_list_item_vacancy_item}__remark`)[0] ||
          form.getAll(`fm_2__${view_job_list_item_vacancy_item}__remark`)[1];

        console.log(filteredRemark);

        if (filteredRemark !== "" && filteredRemark != undefined) {
          if (
            !selectedVacancies.includes(view_job_list_item_vacancy_item + "")
          ) {
            remarkEnteredForUnselectedVacancy = true;
            console.log(remarkEnteredForUnselectedVacancy);
          }
        }
      });
    });

    // Check if selectedMostInterestedList has values not included in selectedVacancies
    const mostInterestedNotInVacancies = selectedMostInterestedList.some(
      (value) => !selectedVacancies.includes(value)
    );

    console.log(viewJobList.length);

    let deselectedJobs = [];
    let viewJobIdList = viewJobList.map((job_item) => job_item.jobId);
    console.log(viewJobIdList);
    selectedVacancies.forEach((selected_vacancy_item) => {
      let jobItem = viewJobList.find(
        (view_job_item) =>
          view_job_item.jobCategoryLineId === parseInt(selected_vacancy_item)
      );
      console.log(jobItem);
      if (jobItem !== undefined) {
        let itemToRemove = viewJobIdList.indexOf(jobItem.jobId);
        delete viewJobIdList[itemToRemove];
        console.log(jobItem);
      }
    });
    deselectedJobs = viewJobIdList.filter(
      (filtered_job_id_item) => filtered_job_id_item > 0
    );
    console.log(deselectedJobs);
    deselectedJobs.forEach((deselected_job_item) => {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        jobId: deselected_job_item,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/deleteJob",
          data: reqBody,
        });
        resolve(result);
      }).then((nested_reponse_data) => {
        if (nested_reponse_data.isSuccess) {
          // setTimeout(() => {
          setIsLoaded(LOADING);
          // }, 50)
          // setRefreshTable(true);
          setIsSuccess(true);
        }
      });
    });

    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      selectedVacancies.forEach((vacancy_item) => {
        let isMostInterested =
          selectedMostInterestedList.includes(vacancy_item);

        // let remark = selectedRemarkList.includes(vacancy_item)

        // let matchingValue = null;
        // if (remark) {
        //   matchingValue = selectedRemarkList.find(item => item === vacancy_item);
        // }

        console.log(evt.target);

        console.log(viewJobList);

        const jobCategoryLineIds = [
          ...new Set(viewJobList.map((item) => item.jobCategoryLineId)),
        ];

        console.log(jobCategoryLineIds);
        console.log(typeof jobCategoryLineIds[0]);

        console.log(parseInt(vacancy_item));

        // jobCategoryLineIds.filter(item => item === vacancy_item)

        const filteredJobs = viewJobList.filter(
          (item) => item.jobCategoryLineId === parseInt(vacancy_item)
        );
        // .map((item) => item.jobId);

        let jobIdNew = 0;

        if (filteredJobs.length > 0) {
          // If there are matching jobs, set jobId to an array of jobIds
          const jobIdsArray = filteredJobs.map((item) => item.jobId); // Assuming jobId is a string
          setJobId(jobIdsArray[0]);
          console.log(jobIdsArray[0]);
          console.log(jobId);
          jobIdNew = jobIdsArray[0];
          console.log(jobIdNew);
        } else {
          // If no matching jobs, set jobId to an empty string or another default value
          setJobId(0);
          jobIdNew = 0;
          console.log(jobIdNew);
        }

        // Check if jobCategoryLineId has a valid value
        if (isNaN(parseInt(vacancy_item))) {
          console.log(isNaN(vacancy_item));
          // Show an alert if jobCategoryLineId is not a valid number
          Swal.fire({
            icon: "error",
            title: "Request Failed",
            text: "Please select a vacancy",
            showConfirmButton: false,
            timer: 5000,
          });
        }

        if (mostInterestedNotInVacancies || remarkEnteredForUnselectedVacancy) {
          // alert("Selected most interested or remark values should be included in selected vacancies, but no vacancies are selected.");

          Swal.fire({
            icon: "error",
            title: "Request Failed",
            text: "If you need to store data, please select the vacancy related to most interested and remark.",
            showConfirmButton: false,
            timer: 5000,
          });

          // Handle the alert as needed
        } else {
          let job = {
            bpartnerId: parseInt(b_partner_id),
            jobId: parseInt(jobIdNew),
            clientId: parseInt(client_id),
            orgId: parseInt(org_id),
            userId: parseInt(loggedUser.userId),
            jobCategoryLineId: parseInt(vacancy_item),
            remark:
              formData.getAll(`fm_2__${vacancy_item}__remark`)[0] ||
              formData.getAll(`fm_2__${vacancy_item}__remark`)[1],
            joinId: parseInt(dpHowSoonJoinValue),
            // isActive: formData.get("fm_2_is_active") ? true : false,
            isActive: true,
            isMostInterestedJob: isMostInterested,
          };
          console.log(formData);
          let nestedResponseData = new Promise((resolve) => {
            const result = sendRequest({
              url: "/customer/saveJobTab",
              data: job,
            });
            resolve(result);
          });
          nestedResponseData.then((nested_reponse_data) => {
            if (nested_reponse_data.isSuccess) {
              // set_basic_tab();

              setIsSuccess(true);
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    console.log("Updated jobId:", jobId);
  }, [jobId]);

  const resetForm = (evt) => {
    evt.preventDefault();
    Swal.fire({
      title: "Are you sure you want to reset?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setDpHowSoonJoinValue("");

        let lineIdList = [];
        lineIdList = jobResponseData.map(
          (listItem) => listItem.jobCategoryLineId
        );
        lineIdList.forEach((vacancy_item) => {
          let checkbox = document.querySelector(
            `#input_fm_1_active_location_id_${vacancy_item}`
          );
          if (checkbox != null) {
            checkbox.checked = false;
          }
        });

        let mostInterestedList = [];
        mostInterestedList = jobResponseData.map(
          (listItem) => listItem.jobCategoryLineId
        );
        jobResponseData.forEach((most_interested_item) => {
          console.log(most_interested_item.isMostInterestedJob);
          if (most_interested_item.isMostInterestedJob) {
            let checkBox = document.querySelectorAll(
              `#input_fm_1_most_interested_id_${most_interested_item.jobCategoryLineId}`
            );
            console.log(checkBox);
            if (checkBox.length > 0) {
              checkBox[0].checked = false;
              checkBox[1].checked = false;
              document
                .querySelector(
                  `#most_interested_icon_for_${most_interested_item.jobCategoryLineId}`
                )
                .classList.add("fa-heart-o");
              document
                .querySelector(
                  `#most_interested_icon_for_${most_interested_item.jobCategoryLineId}`
                )
                .classList.remove("fa-heart");
            }
          }
        });

        let remarkList = [];
        remarkList = jobResponseData.map((listItem) =>
        //  listItem.jobCategoryLineId)
        ({
          jobCategoryLineId: listItem.jobCategoryLineId,
          remark: listItem.remark,
        })
        );
        remarkList.forEach((remark_item) => {
          let remark_list = document.querySelectorAll(
            `#fm_2__${remark_item.jobCategoryLineId}__remark`
          );
          if (remark_list.length > 0) {
            remark_list[0].value = "";
            remark_list[1].value = "";
          }
        });
      }
    });
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    // if (b_partner_id > 0) {
    if (refreshTable) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        bpartnerId: b_partner_id,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/viewJobTab",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          // if (response_data.activeJobCategoryList) {
          //   setJobList([
          //     { jobCategoryId: "", name: "-Select-" },
          //     ...response_data.activeJobCategoryList,
          //   ]);
          // }

          // console.log(response_data.allCustomerJobTableList);

          setViewJobList([...response_data.allCustomerJobTableList]);

          if (response_data.allCustomerJobTableList) {
            if (
              response_data.allCustomerJobTableList &&
              response_data.allCustomerJobTableList.length > 0
            ) {
              setDpHowSoonJoinValue(
                response_data.allCustomerJobTableList[0].joinId
              );
            }

            response_data.allCustomerJobTableList.forEach((listItem) => {
              setJobResponseData(response_data.allCustomerJobTableList);
            });
            // setJobRowList([...tempList]);
          }
          setIsLoaded(LOADED);
          // setRefreshTable(false);
        }
      });
    }
  }, [isLoaded]);

  useEffect(() => {
    if (jobResponseData && jobWithVacancyList && filteredVacancyList) {
      console.log(filteredVacancyList);
      setTimeout(() => {
        let lineIdList = [];
        lineIdList = jobResponseData.map(
          (listItem) => listItem.jobCategoryLineId
        );
        lineIdList.forEach((vacancy_item) => {
          let checkbox = document.querySelector(
            `#input_fm_1_active_location_id_${vacancy_item}`
          );
          if (checkbox != null) {
            checkbox.checked = true;
          }
        });

        let mostInterestedList = [];
        mostInterestedList = jobResponseData.map(
          (listItem) => listItem.jobCategoryLineId
        );
        jobResponseData.forEach((most_interested_item) => {
          console.log(most_interested_item.isMostInterestedJob);
          if (most_interested_item.isMostInterestedJob) {
            let checkBox = document.querySelectorAll(
              `#input_fm_1_most_interested_id_${most_interested_item.jobCategoryLineId}`
            );
            console.log(checkBox);
            if (checkBox.length > 0) {
              checkBox[0].checked = true;
              checkBox[1].checked = true;
              document
                .querySelector(
                  `#most_interested_icon_for_${most_interested_item.jobCategoryLineId}`
                )
                .classList.remove("fa-heart-o");
              document
                .querySelector(
                  `#most_interested_icon_for_${most_interested_item.jobCategoryLineId}`
                )
                .classList.add("fa-heart");
              setMostInterestedId(most_interested_item.jobCategoryLineId);
            }
          }
        });

        let remarkList = [];
        remarkList = jobResponseData.map((listItem) =>
        //  listItem.jobCategoryLineId)
        ({
          jobCategoryLineId: listItem.jobCategoryLineId,
          remark: listItem.remark,
        })
        );
        remarkList.forEach((remark_item) => {
          let remark_list = document.querySelectorAll(
            `#fm_2__${remark_item.jobCategoryLineId}__remark`
          );
          if (remark_list.length > 0) {
            remark_list[0].value = remark_item.remark;
            remark_list[1].value = remark_item.remark;
          }

          // document.querySelector(`input[name='fm_2__${remark_item}__remark']`).value=remark_item.remark;

          // document.querySelector(`#fm_2__${remark_item.jobCategoryLineId}__remark`);

          // {`fm_2__${vacancy_item.jobCategoryLineId}__remark`}
        });
      }, 100);
    }
  }, [jobResponseData, filteredVacancyList]);

  useEffect(() => {
    if (dpJobValue || refreshDpJobCategoryLine) {
      const reqBody = {
        jobCategoryId: dpJobValue,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveJobCategoryLineByJobCategoryId",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setVacancyList((prevList) => [prevList[0], ...response_data]);
          setRefreshDpJobCategoryLine(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpJobValue, refreshDpJobCategoryLine]);

  useEffect(() => {
    if (refreshDpJobCategory) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveJobCategory",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setJobList([
            { jobCategoryId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpJobCategory(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpJobCategory]);

  useEffect(() => {
    // if (refreshDpHowSoonJoin) {
    const reqBody = {
      clientId: loggedUser.clientId,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/getAllActiveJoinInformation",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      if (response_data) {
        setHowSoonJoinList([
          { joinId: "", name: "-Select-" },
          ...response_data,
        ]);
        setRefreshDpHowSoonJoin(false);
      }
    });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpHowSoonJoin]);

  useEffect(() => {
    // if (refreshDpJobCategoryWithVacancy) {
    const reqBody = {
      clientId: loggedUser.clientId,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/getAllActiveJobCategoryLineWithCategoryId",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      if (response_data) {
        console.log(response_data);
        setJobWithVacancyList(response_data);
        setRefreshDpJobCategoryWithVacancy(false);
      }
    });
    // }
    console.log(jobWithVacancyList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpJobCategoryWithVacancy]);

  const closeItem = () => {
    setIsSuccess(false);
  };

  const mobileViewMostInteresetedIcon = (most_interested_item) => {
    let mostInterestedIcon = document.querySelector(
      `#most_interested_icon_for_${most_interested_item}`
    );
    let mostInterestedInput = document.querySelector(
      `#input_fm_1_most_interested_id_${most_interested_item}`
    );

    if (mostInterestedIcon.classList.contains("fa-heart")) {
      mostInterestedIcon.classList.remove("fa-heart");
      mostInterestedIcon.classList.add("fa-heart-o");
      mostInterestedInput.checked = true;
    } else {
      mostInterestedIcon.classList.add("fa-heart");
      mostInterestedIcon.classList.remove("fa-heart-o");
      mostInterestedInput.checked = false;
    }
  };

  const handleBackClick = () => {
    set_back_tab();
  };

  // const addItem = () => {
  //   setIsSuccess(false);
  // };

  return (
    <>
      <Breadcrumb
        parent="Dashboard"
        title="Apply Job"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="card-modified-body bg-white pt-0 mb-4">
            <Alert
              className="alert-dismissible d-flex align-items-center"
              color="warning"
              isOpen={b_partner_id === 0}
            >
              <AlertTriangle />
              <p className="ml-2">
                You must create a customer, before add job details.
              </p>
            </Alert>
            <form
              onSubmit={(evt) => submitForm(evt)}
              // onSubmit={(evt) => submitForm(evt)}
              onReset={(evt) => resetForm(evt)}
              className={`position-relative form-2 ${isValidated ? "was-validated" : ""
                }`}
              noValidate
            >
              {isLoaded === LOADING ? (
                <div className="form-loader d-flex justify-content-center align">
                  <div className="loader-box">
                    <div className="loader">
                      <div className="line bg-secondary"></div>
                      <div className="line bg-secondary"></div>
                      <div className="line bg-secondary"></div>
                      <div className="line bg-secondary"></div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="form-row mb-4">
                <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
                  <div clasasName="d-flex">
                    <label
                      htmlFor="id_input_fm_2_sa_job_category_id"
                      className="col-form-label pt-0"
                    >
                      Filter Job
                    </label>{" "}
                  </div>

                  <div class="dropdown-wrapper" className="form-group col-12">
                    <div className="form-group col-6">
                      <button
                        class="btn dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        style={{
                          border: "1px solid #ccc",
                          paddingTop: "8px",
                          paddingBottom: "7px",
                          width: "100%",
                          color: "black",
                        }}
                        onClick={() => setShowFilterDropdown((prev) => !prev)}
                      >
                        {selectedItemId
                          ? jobWithVacancyList.find(
                            (item) => item.categoryId === selectedItemId
                          )?.name
                          : "Filter"}
                        {/* filter */}
                        <span class="caret"></span>
                      </button>
                    </div>
                    <ul
                      class={`dropdown-menu ${showFilterDropdown ? "d-block" : "d-none"
                        }`}
                    >
                      <div class="input-group mb-3 col-6">
                        <input
                          class="form-control"
                          id="myInput"
                          type="text"
                          placeholder="Search..."
                          value={filterValue}
                          style={{
                            width: "100%",
                            // width: "99%",
                            // padding: 20,
                            // MarginTop: -6,
                            // border: 0,
                            // borderRadius: 0,
                            // backgroundColor: "#f1f1f1"
                          }}
                          onChange={handleInputChange}
                        />
                      </div>
                      {jobWithVacancyList?.length > 0
                        ? jobWithVacancyList.map((job_item, index) => (
                          <li key={index}>
                            <a
                              // href="#"
                              id={`input_fm_1_job_id_${job_item.categoryId}`}
                              value={`${job_item.categoryId}`}
                              onClick={() =>
                                handleItemClick(
                                  job_item.categoryId,
                                  job_item.name
                                )
                              }
                            >
                              {job_item.name}
                            </a>
                          </li>

                          // <option value={job_item?.categoryId} key={index}>
                          //   {job_item?.name}
                          // </option>
                        ))
                        : null}
                    </ul>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-5 mb-2 mb-sm-0 position-relative">
                  <div clasasName="d-flex">
                    <label
                      htmlFor="id_input_fm_2_sa_job_category_id"
                      className="col-form-label pt-0 required-input-mark"
                    >
                      {/* How Soon Can Join */}
                      When would you be available to start working?
                    </label>{" "}
                  </div>
                  <select
                    id="id_input_fm_2_sa_job_category_id"
                    name="fm_2_sa_job_category_id"
                    className="form-select"
                    onChange={(evt) => setDpHowSoonJoinValue(evt.target.value)}
                    value={dpHowSoonJoinValue}
                    required
                  >
                    {howSoonJoinList.length
                      ? howSoonJoinList.map((listItem, index) => (
                        <option value={listItem?.joinId} key={index}>
                          {listItem?.name}
                        </option>
                      ))
                      : null}
                  </select>
                </div>
              </div>

              <Row className="m-b-10">
                <Col lg="15">
                  <Row className="m-b-20 border-bottom d-none d-md-flex">
                    <Col lg="2">
                      <div className="fw-bold m-b-10">Job</div>
                    </Col>
                    <Col lg="2">
                      <div className="fw-bold m-b-10 required-input-mark">
                        Vacancy
                      </div>
                    </Col>
                    <Col lg="2">
                      <div className="fw-bold m-b-10">Most Interested</div>
                    </Col>
                    <Col lg="3">
                      <div className="fw-bold m-b-10">Remark</div>
                    </Col>
                  </Row>

                  <Row className="m-b-20">
                    {filteredVacancyList?.length > 0
                      ? filteredVacancyList.map((job_item, index) => (
                        <Col lg="12" key={index}>
                          <Row className="m-b-10 border-bottom">
                            <Col md="2">
                              <Label
                                className="d-block fw-bold"
                                htmlFor="input_fm_1_active"
                                key={index}
                              >
                                {/* <Input
                              className="checkbox_animated"
                              id={`input_fm_1_active_job_id_${job_item.categoryId}`}
                              value={`${job_item.categoryId}__${job_item.categoryId}`}
                              name="fm_1_interface_job"
                              type="checkbox"
                            /> */}

                                {/* {selectedItemId ? jobWithVacancyList.find(item => item.categoryId === selectedItemId)?.name : job_item.name} */}

                                {job_item.name}
                              </Label>
                            </Col>

                            <Col sm="12" md="2" className="mt-3 mt-md-0">
                              {
                                // (selectedItemId ? filterVacancyList : job_item.jobCategoryLineMinList)
                                job_item.jobCategoryLineMinList.map(
                                  (vacancy_item, index2) => (
                                    <Row className="px-4 px-md-0 mb-2 mb-md-0">
                                      <Col xs="9" md="12">
                                        <Label
                                          className="d-block"
                                          htmlFor="input_fm_1_active"
                                          key={index2}
                                        >
                                          <Input
                                            className="checkbox_animated"
                                            id={`input_fm_1_active_location_id_${vacancy_item.jobCategoryLineId}`}
                                            value={`${vacancy_item.jobCategoryLineId}`}
                                            name="fm_1_interface_vacancy"
                                            type="checkbox"
                                          />
                                          {vacancy_item.name}
                                        </Label>
                                      </Col>
                                      <Col className="d-md-none d-flex justify-content-end">
                                        <Label
                                          className="d-block"
                                          htmlFor="input_fm_1_active"
                                          key={index2}
                                        >
                                          <Input
                                            className="checkbox_animated d-none"
                                            id={`input_fm_1_most_interested_id_${vacancy_item.jobCategoryLineId}`}
                                            name="fm_1_most_interested"
                                            type="checkbox"
                                            value={`${vacancy_item.jobCategoryLineId}`}
                                            checked={
                                              mostInterestedId ===
                                              vacancy_item.jobCategoryLineId
                                            }
                                            onChange={(evt) =>
                                              handleCheckboxChange(
                                                vacancy_item.jobCategoryLineId,
                                                evt
                                              )
                                            }
                                          />
                                          <i
                                            id={`most_interested_icon_for_${vacancy_item.jobCategoryLineId}`}
                                            className="fa fa-heart-o txt-danger"
                                            onClick={() =>
                                              mobileViewMostInteresetedIcon(
                                                vacancy_item.jobCategoryLineId
                                              )
                                            }
                                          ></i>
                                        </Label>
                                      </Col>
                                      <Col
                                        xs="12"
                                        className="d-md-none mt-1 mt-md-0"
                                      >
                                        <Label
                                          className="d-block"
                                          htmlFor="input_fm_1_active"
                                          key={index2}
                                        >
                                          <Input
                                            className="form-control px-0"
                                            id={`fm_2__${vacancy_item.jobCategoryLineId}__remark`}
                                            name={`fm_2__${vacancy_item.jobCategoryLineId}__remark`}
                                            maxLength={250}
                                            onChange={(evt) => {
                                              formValidation({
                                                event: evt,
                                                checkExpression: "",
                                              });
                                              document.querySelectorAll(
                                                `#fm_2__${vacancy_item.jobCategoryLineId}__remark`
                                              )[1].value = evt.target.value;
                                            }}
                                          ></Input>
                                        </Label>
                                      </Col>
                                    </Row>
                                  )
                                )
                              }
                            </Col>

                            <Col md="2" className="d-none d-md-block">
                              {
                                // (selectedItemId ? filterVacancyList : job_item.jobCategoryLineMinList)
                                job_item.jobCategoryLineMinList.map(
                                  (vacancy_item, index2) => (
                                    <Label
                                      className="d-block"
                                      htmlFor="input_fm_1_active"
                                      key={index2}
                                    >
                                      <Input
                                        className="checkbox_animated"
                                        id={`input_fm_1_most_interested_id_${vacancy_item.jobCategoryLineId}`}
                                        name="fm_1_most_interested"
                                        type="checkbox"
                                        value={`${vacancy_item.jobCategoryLineId}`}
                                        checked={
                                          mostInterestedId ===
                                          vacancy_item.jobCategoryLineId
                                        }
                                        onChange={(evt) =>
                                          handleCheckboxChange(
                                            vacancy_item.jobCategoryLineId,
                                            evt
                                          )
                                        }
                                      />
                                    </Label>
                                  )
                                )
                              }
                            </Col>

                            <Col md="3" className="d-none d-md-block">
                              {
                                // (selectedItemId ? filterVacancyList : job_item.jobCategoryLineMinList)
                                job_item.jobCategoryLineMinList.map(
                                  (vacancy_item, index2) => (
                                    <Label
                                      className="d-block"
                                      htmlFor="input_fm_1_active"
                                      key={index2}
                                    >
                                      <Input
                                        className="form-control p-0"
                                        id={`fm_2__${vacancy_item.jobCategoryLineId}__remark`}
                                        name={`fm_2__${vacancy_item.jobCategoryLineId}__remark`}
                                        maxLength={250}
                                        onChange={(evt) => {
                                          formValidation({
                                            event: evt,
                                            checkExpression: "",
                                          });
                                          document.querySelectorAll(
                                            `#fm_2__${vacancy_item.jobCategoryLineId}__remark`
                                          )[0].value = evt.target.value;
                                        }}
                                      ></Input>
                                    </Label>
                                  )
                                )
                              }
                            </Col>
                          </Row>
                        </Col>
                      ))
                      : null}
                  </Row>
                </Col>
              </Row>

              {b_partner_id ? (
                checkIsAccessible(INSERTFUNCTION) ||
                  (checkIsAccessible(EDITFUNCTION) && jobId !== 0) ? (
                  <div className="d-sm-flex justify-content-end">
                    <button
                      className="btn btn-outline-primary col-md-1 col-3 px-1 mb-2 mb-sm-0"
                      type="reset"
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-primary col-md-2 col-4 mx-3 mb-2 mb-sm-0"
                      type="submit"
                    >
                      {jobId ? "Save" : "Save"}
                    </button>

                    {/* <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleBackClick()}><ArrowLeft height={"16px"} width={"16px"} />Back</button> */}
                  </div>
                ) : null
              ) : null}
              {/* </div> */}
              <br />
            </form>
          </div></div></div>
    </>
  );
};

export default Job;
