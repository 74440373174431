import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";
import { Alert } from "reactstrap";
import { AlertTriangle, ArrowLeft, ArrowRight, Check, PlusCircle, RefreshCw, X } from "react-feather";

// custom components
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../common/datatable";
import FileUploader from "../../../common/fileUploader";

// customer hooks
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../customHooks/useValidation";
import ConfigDB from "../../../../data/customizer/config";
import {
  EDUCATION_ACHIEVEMENT,
  EDUCATION_FIELD,
  EDUCATION_GRADE,
  EDUCATION_LEVEL,
  EDUCATION_SUBJECT,
  INSERT_EDUCATION_ACHIEVEMENT,
  INSERT_EDUCATION_FIELD,
  INSERT_EDUCATION_GRADE,
  INSERT_EDUCATION_LEVEL,
  INSERT_EDUCATION_SUBJECT,
} from "../../../../constant";

const Education = ({ b_partner_id = 0, client_id = 0, org_id = 0, set_basic_tab = null, set_back_tab = null }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  const MAXFILELIMIT = 5;

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const sendRequest = useAxiosTemplates();

  // helper containers
  const [attachmentList, setAttachmentList] = useState([]);

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);
  const [previewFileList, setPreviewFileList] = useState([]);
  const isInsertAllowed = useAccess(loggedUser)[0];

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshDpEducationGrade, setRefreshDpEducationGrade] = useState(false);
  const [refreshDpEducationLevel, setRefreshDpEducationLevel] = useState(false);
  const [refreshDpEducationField, setRefreshDpEducationField] = useState(false);
  const [refreshDpEducationAchievement, setRefreshDpEducationAchievement] =
    useState(false);
  const [refreshDpEducationSubject, setRefreshDpEducationSubject] =
    useState(false);

  // form values
  const [levelList, setLevelList] = useState([
    { educationLevelId: "", name: "-Select-" },
  ]);
  const [dpLevelValue, setDpLevelValue] = useState(0);
  const [achievementList, setAchievementList] = useState([
    { educationAchievementId: "", name: "-Select-" },
  ]);
  const [dpAchievementValue, setDpAchievementValue] = useState(0);
  const [fieldList, setFieldList] = useState([
    { educationFieldId: "", name: "-Select-" },
  ]);
  const [dpFieldValue, setDpFieldValue] = useState(0);
  const [gradeList, setGradeList] = useState([
    { educationGradeId: "", name: "-Select-" },
  ]);
  const [dpGradeValue, setDpGradeValue] = useState(0);
  const [subjectList, setSubjectList] = useState([
    { educationSubjectId: "", name: "-Select-" },
  ]);
  const [dpSubjectValue, setDpSubjectValue] = useState(0);
  const [educationQualificationId, setEducationQualificationId] = useState(0);

  const [isSuccess, setIsSuccess] = useState(false);

  // table data
  const [educationRowList, setEducationRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Level",
    "Achievement",
    // "Field",
    // "Stream",
    // "Subject",
    // "Grade",
    // "Qualified Year",
    // "Remarks",
    // {
    //   name: "Active",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <BooleanValue value={value} />;
    //     },
    //   },
    // },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editEducation] = useState(
    () => (education_qualification_id, response_data) => {
      const editingEducation = response_data.find(
        (item) => item.educationQualificationId === education_qualification_id
      );
      setEducationQualificationId(editingEducation.educationQualificationId);
      setDpLevelValue(editingEducation.levelId);
      setDpAchievementValue(editingEducation.achievementId);
      setDpFieldValue(editingEducation.fieldId);
      setDpSubjectValue(editingEducation.subjectId);
      // setDpGradeValue(editingEducation.gradeId);
      document.querySelector(
        "input[name='id_input_fm_7_qualified_year']"
      ).value = editingEducation.qualifiedYear;
      document.querySelector("textarea[name='fm_7_remark']").value =
        editingEducation.remark;
      // document.querySelector("input[name='fm_7_is_active']").checked =
      //   editingEducation.isActive;
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      const reqBody = {
        educationQualificationId: editingEducation.educationQualificationId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/getEducationAttachmentListByEducationQualificationId",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          let tempList = [];
          let tempList2 = [];
          response_data.forEach((listItem) => {
            tempList.push({
              documentId: listItem.id,
              isModified: false,
              attachmentExtension: "",
              attachment: "",
            });
            tempList2.push({
              id: listItem.id,
              type: listItem.filePath.split(".")[1],
              source: listItem.filePath,
            });
          });
          setPreviewFileList(tempList2);
          setAttachmentList([...tempList]);
          setIsLoaded(LOADED);
          setRefreshTable(false);
        }
      });
    }
  );
  const [deleteEducation] = useState(
    () => (education_qualification_id, logged_user) => {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "This will permenantly deletes this education record!!!",
        showDenyButton: true,
        confirmButtonText: "Delete",
        denyButtonText: "Cancel",
        denyButtonColor: "#2a3142",
      }).then((result) => {
        if (result.isConfirmed) {
          const reqBody = {
            clientId: logged_user.clientId,
            orgId: logged_user.orgId,
            userId: logged_user.userId,
            educationQualificationId: education_qualification_id,
          };
          let responseData = new Promise((resolve) => {
            const result = sendRequest({
              url: "customer/deleteEducation",
              data: reqBody,
              template: "CONTROL_DATA",
            });
            resolve(result);
          });
          responseData.then((response_data) => {
            if (response_data) {
              Swal.fire({
                icon: "success",
                title: "Request Successful",
                text: "Education qualification record has successfully deleted.",
                showConfirmButton: false,
                timer: 5000,
              });
              setRefreshTable(true);

            }
          });
        }
      });
    }
  );

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);


      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/checkCustomerEducationExist",
          data: {
            clientId: parseInt(loggedUser.clientId),
            educationQualificationId: parseInt(educationQualificationId),
            educationLevelId: parseInt(dpLevelValue),
            educationAchievementId: parseInt(dpAchievementValue),
            educationFieldId: parseInt(dpFieldValue),
            qualifiedYear: formData.get("id_input_fm_7_qualified_year"),
            // educationSubjectId: parseInt(dpSubjectValue),
          },
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          switch (response_data.existType) {
            case 0:
              let education = {
                bpartnerId: parseInt(b_partner_id),
                educationQualificationId: parseInt(educationQualificationId),
                clientId: parseInt(client_id),
                orgId: parseInt(org_id),
                userId: parseInt(loggedUser.userId),
                qualifiedYear: formData.get("id_input_fm_7_qualified_year"),
                educationLevelId: parseInt(dpLevelValue),
                educationAchievementId: parseInt(dpAchievementValue),
                // educationFieldId: formData.get("id_input_fm_1_stream"),
                educationFieldId: parseInt(dpFieldValue),
                educationSubjectId: parseInt(dpSubjectValue),
                // educationGradeId: parseInt(dpGradeValue),
                isActive: true,
                // isActive: formData.get("fm_7_is_active") ? true : false,
                remark: formData.get("fm_7_remark"),
                attachmentList: attachmentList,
              };
              let nestedResponseData = new Promise((resolve) => {
                const result = sendRequest({
                  url: "/customer/saveEducationTab",
                  data: education,
                });
                resolve(result);
              });
              nestedResponseData.then((nested_reponse_data) => {

                if (nested_reponse_data.isSuccess) {
                  Swal.fire({
                    icon: "success",
                    title: "Request Successful",
                    text: educationQualificationId
                      ? "Education qualification record has successfully updated."
                      : "New education qualification record has successfully added.",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  setIsLoaded(LOADING);
                  setRefreshTable(true);
                  setIsSuccess(true);
                }


              });
              break;
            case 1:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "This Education Qualification Already Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            default:
              break;
          }
        }
      });





      // let education = {
      //   bpartnerId: parseInt(b_partner_id),
      //   educationQualificationId: parseInt(educationQualificationId),
      //   clientId: parseInt(client_id),
      //   orgId: parseInt(org_id),
      //   userId: parseInt(loggedUser.userId),
      //   qualifiedYear: formData.get("id_input_fm_7_qualified_year"),
      //   educationLevelId: parseInt(dpLevelValue),
      //   educationAchievementId: parseInt(dpAchievementValue),
      //   educationFieldId: parseInt(dpFieldValue),
      //   educationSubjectId: parseInt(dpSubjectValue),
      //   educationGradeId: parseInt(dpGradeValue),
      //   isActive: formData.get("fm_7_is_active") ? true : false,
      //   remark: formData.get("fm_7_remark"),
      //   attachmentList: attachmentList,
      // };
      // console.log(education);
      // let responseData = new Promise((resolve) => {
      //   const result = sendRequest({
      //     url: "/customer/saveEducationTab",
      //     data: education,
      //     template: "CONTROL_DATA",
      //   });
      //   resolve(result);
      // });
      // responseData.then((reponse_data) => {
      //   if (reponse_data) {
      //     Swal.fire({
      //       icon: "success",
      //       title: "Request Successful",
      //       text: educationQualificationId
      //         ? "Education Qualification Record has successfully updated."
      //         : "New Education Qualification Record has successfully added.",
      //       showConfirmButton: false,
      //       timer: 5000,
      //     });
      //     setIsLoaded(LOADING);
      //     setRefreshTable(true);
      //   }
      // });
    }
  };

  const resetForm = (evt) => {

    evt.preventDefault();

    // setIsValidated(false);
    // setDpLevelValue(0);
    // setDpAchievementValue(0);
    // setDpFieldValue(0);
    // // setDpGradeValue(0);
    // setDpSubjectValue(0);
    // setEducationQualificationId(0);
    // setAttachmentList([]);
    // setPreviewFileList([]);
    // let inputFields = document.querySelectorAll(".form-control");
    // let customValidationMessages =
    //   document.querySelectorAll(".input-validation");
    // inputFields.forEach((field) => {
    //   if (field.classList.contains("is-valid")) {
    //     field.classList.remove("is-valid");
    //   } else if (field.classList.contains("is-invalid")) {
    //     field.classList.remove("is-invalid");
    //   }
    // });
    // customValidationMessages.forEach((element) => {
    //   if (element.classList.contains("input-validation")) {
    //     if (!element.classList.contains("d-none")) {
    //       element.classList.add("d-none");
    //     }
    //   }
    // });

    Swal.fire({
      title: 'Are you sure you want to reset?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {

        setDpLevelValue(0);
        setDpAchievementValue(0);
        setDpFieldValue(0);
        setDpSubjectValue(0);
        setEducationQualificationId(0);
        setAttachmentList([]);
        setPreviewFileList([]);

        // document.getElementById('id_input_fm_1_stream').value = '';
        document.getElementById('id_input_fm_7_remark').value = '';
        document.getElementById('id_input_fm_7_qualified_year').value = '';

        setIsValidated(false);
      }
    });

    setIsSuccess(false)
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewEducationTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            if (response_data.activeLevelList) {
              setLevelList([
                { educationLevelId: "", name: "-Select-" },
                ...response_data.activeLevelList,
              ]);
            }
            if (response_data.activeAchievementList) {
              setAchievementList([
                { educationAchievementId: "", name: "-Select-" },
                ...response_data.activeAchievementList,
              ]);
            }
            if (response_data.activeFieldList) {
              setFieldList([
                { educationFieldId: "", name: "-Select-" },
                ...response_data.activeFieldList,
              ]);
            }
            if (response_data.activeSubjectList) {
              setSubjectList([
                { educationSubjectId: "", name: "-Select-" },
                ...response_data.activeSubjectList,
              ]);
            }
            // if (response_data.activeGradeList) {
            //   setGradeList([
            //     { educationGradeId: "", name: "-Select-" },
            //     ...response_data.activeGradeList,
            //   ]);
            // }
            if (response_data.allCustomerEducationTableList) {
              let tempList = [];
              response_data.allCustomerEducationTableList.forEach(
                (listItem) => {
                  tempList.push([
                    listItem.educationQualificationId,
                    listItem.levelName,
                    listItem.achievementName,
                    // listItem.fieldName,
                    // listItem.subjectName,
                    // listItem.gradeName,
                    // listItem.qualifiedYear,
                    // listItem.remark,
                    // listItem.isActive ? "True" : "False",
                    <ControlButtons
                      editMethod={() =>
                        editEducation(
                          listItem.educationQualificationId,
                          response_data.allCustomerEducationTableList
                        )
                      }
                      disableEdit={!checkIsAccessible(EDITFUNCTION)}
                      deleteMethod={() =>
                        deleteEducation(
                          listItem.educationQualificationId,
                          loggedUser
                        )
                      }
                      disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                    />,
                  ]);
                }
              );
              setEducationRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
  }, [refreshTable]);

  useEffect(() => {
    if (refreshDpEducationAchievement) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveEducationAchievement",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setAchievementList([
            { educationAchievementId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpEducationAchievement(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpEducationAchievement]);

  useEffect(() => {
    if (refreshDpEducationLevel) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveEducationLevel",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setLevelList([
            { educationLevelId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpEducationLevel(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpEducationLevel]);

  useEffect(() => {
    if (dpLevelValue || refreshDpEducationGrade) {
      const reqBody = {
        // clientId: loggedUser.clientId,
        educationLevelId: dpLevelValue,
      };
      // new Promise((resolve) => {
      //   const result = sendRequest({
      //     url: "/getAllActiveEducationGrade",
      //     data: reqBody,
      //   });
      //   resolve(result);
      // }).then((response_data) => {
      //   if (response_data) {
      //     setGradeList([
      //       { educationGradeId: "", name: "-Select-" },
      //       ...response_data,
      //     ]);
      //     setRefreshDpEducationGrade(false);
      //   }
      // });

      let responseArrayData = new Promise((resolve) => {
        const result = sendRequest({
          url:
            "customer/getAllActiveEducationGradeByLevel",

          data: reqBody,
          // template: "REQUEST_ARRAY",
        });
        resolve(result);
      });
      responseArrayData.then((response_data_array) => {
        // if (response_data_array) {
        //   setGradeList((prevList) => [prevList[0], ...response_data_array[0]]);
        //   setRefreshDpEducationGrade(false);
        // }
        if (response_data_array) {
          //response_data_array.forEach((item) => {
          setGradeList((prevList) => [prevList[0], ...response_data_array]);
          //});
          setRefreshDpEducationGrade(false);

        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpLevelValue, refreshDpEducationGrade]);

  useEffect(() => {
    if (setRefreshDpEducationField) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveEducationField",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setFieldList([
            { educationFieldId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpEducationField(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpEducationField]);

  useEffect(() => {
    if (dpLevelValue || refreshDpEducationSubject) {
      const reqBody = {
        educationLevelId: dpLevelValue,
      };
      // new Promise((resolve) => {
      //   const result = sendRequest({
      //     url: "/getAllActiveEducationSubject",
      //     data: reqBody,
      //   });
      //   resolve(result);
      // }).then((response_data) => {
      //   if (response_data) {
      //     setSubjectList([
      //       { educationSubjectId: "", name: "-Select-" },
      //       ...response_data,
      //     ]);
      //     setRefreshDpEducationSubject(false);
      //   }
      // });

      let responseArrayData = new Promise((resolve) => {
        const result = sendRequest({
          url:
            "customer/getAllActiveEducationSubjectByLevel",
          data: reqBody,
        });
        resolve(result);
      });
      responseArrayData.then((response_data_array) => {
        console.log(response_data_array);
        // if (response_data_array) {
        //   setSubjectList((prevList) => [prevList[0], ...response_data_array[0]]);
        //   setRefreshDpEducationSubject(false);
        // }
        if (response_data_array) {
          //response_data_array.forEach((item) => {
          setSubjectList((prevList) => [prevList[0], ...response_data_array]);
          //});
          setRefreshDpEducationSubject(false);

        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpLevelValue, refreshDpEducationSubject]);

  const closeItem = () => {
    setIsSuccess(false);
  };

  const handleBackClick = () => {
    set_back_tab()
  };

  const handleNextClick = () => {
    set_basic_tab()
  };

  return (
    <>
      <div className="card-modified-body bg-white pt-0 mb-4">
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={b_partner_id === 0}
        >
          <AlertTriangle />
          <p className="ml-2">
            You must create a Customer, before add Education Qualification
            Details.
          </p>
        </Alert>
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-7 ${isValidated ? "was-validated" : ""
            }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-row mb-4">
            <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_7_sa_education_level_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Level
                </label>
                {/* <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpEducationLevel ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpEducationLevel(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(
                      EDUCATION_LEVEL,
                      INSERT_EDUCATION_LEVEL
                    )
                  }
                /> */}
              </div>
              <select
                id="id_input_fm_7_sa_education_level_id"
                name="fm_7_sa_education_level_id"
                className="form-select"
                onChange={(evt) => setDpLevelValue(evt.target.value)}
                value={dpLevelValue}
                required
              >
                {levelList.length
                  ? levelList.map((listItem, index) => (
                    <option value={listItem?.educationLevelId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                  : null}
              </select>
              <div className="invalid-feedback position-absolute">
                Please select Level
              </div>
            </div>
            <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_7_sa_education_achievement_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Achievement
                </label>
                {/* <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpEducationAchievement ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpEducationAchievement(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(
                      EDUCATION_ACHIEVEMENT,
                      INSERT_EDUCATION_ACHIEVEMENT
                    )
                  }
                /> */}
              </div>
              <select
                id="id_input_fm_7_sa_education_achievement_id"
                name="fm_7_sa_education_achievement_id"
                className="form-select"
                onChange={(evt) => setDpAchievementValue(evt.target.value)}
                value={dpAchievementValue}
                required
              >
                {achievementList.length
                  ? achievementList.map((listItem, index) => (
                    <option
                      value={listItem?.educationAchievementId}
                      key={index}
                    >
                      {listItem?.name}
                    </option>
                  ))
                  : null}
              </select>
              <div className="invalid-feedback position-absolute">
                Please select Achievement
              </div>
            </div>
            <div className="form-group col-12 col-sm-3 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_7_sa_education_field_id"
                  className="col-form-label pt-0"
                >
                  Stream
                </label>
                {" "}

              </div>
              <select
                id="id_input_fm_7_sa_education_field_id"
                name="fm_7_sa_education_field_id"
                className="form-select"
                onChange={(evt) => setDpFieldValue(evt.target.value)}
                value={dpFieldValue}

              >
                {fieldList.length
                  ? fieldList.map((listItem, index) => (
                    <option value={listItem?.educationFieldId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                  : null}
              </select>
              {/* <input
                id="id_input_fm_1_stream"
                name="fm_1_stream"
                // type="text"
                maxLength={200}
                className="form-control"
                placeholder="Enter Stream"
                autoComplete="off"
                onChange={(evt) => formValidation({ event: evt })}
              // required
              />
              <div className="invalid-feedback position-absolute">
                Please select Stream
              </div>
              <small className="txt-danger position-absolute d-none input-validation"></small> */}
            </div>

            <div className="form-group col-12 col-sm-3 position-relative">
              <label
                htmlFor="id_input_fm_7_qualified_year"
                className="col-form-label pt-0 required-input-mark"
              >
                Qualified Year
              </label>
              <input
                id="id_input_fm_7_qualified_year"
                name="id_input_fm_7_qualified_year"
                type="text"
                maxLength={5}
                className="form-control"
                placeholder="Enter Qualified Year"
                onChange={(evt) =>
                  formValidation({
                    event: evt,
                    checkExpression: /[^\d-]+/,
                  })
                }
                required
              />
              <div className="invalid-feedback position-absolute">
                Please enter an year
              </div>
              <small className="txt-danger position-absolute d-none input-validation"></small>
            </div>

          </div>
          {/* <div className="form-row mb-4"> */}
          {/* <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_7_sa_education_subject_id"
                  className="col-form-label pt-0"
                >
                  Subject
                </label>
               
              </div>
              <select
                id="id_input_fm_7_sa_education_subject_id"
                name="fm_7_sa_education_subject_id"
                className="form-select"
                onChange={(evt) => setDpSubjectValue(evt.target.value)}
                value={dpSubjectValue}
                disabled={dpLevelValue ? false : true}
              >
                {subjectList.length
                  ? subjectList.map((listItem, index) => (
                    <option value={listItem?.educationSubjectId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                  : null}
              </select>
            </div> */}

          {/* <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_7_sa_education_grade_id"
                  className="col-form-label pt-0"
                >
                  Grade
                </label> */}
          {/* <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpEducationGrade ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpEducationGrade(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(
                      EDUCATION_GRADE,
                      INSERT_EDUCATION_GRADE
                    )
                  }
                /> */}
          {/* </div>
              <select
                id="id_input_fm_7_sa_education_grade_id"
                name="fm_7_sa_education_grade_id"
                className="form-select"
                onChange={(evt) => setDpGradeValue(evt.target.value)}
                value={dpGradeValue}
                disabled={dpLevelValue ? false : true}
              >
                {gradeList.length
                  ? gradeList.map((listItem, index) => (
                      <option value={listItem?.educationGradeId} key={index}>
                        {listItem?.name}
                      </option>
                    ))
                  : null}
              </select>
            </div> */}

          {/* <div className="form-group col-12 col-sm-6 position-relative">
              <label
                htmlFor="id_input_fm_7_qualified_year"
                className="col-form-label pt-0 required-input-mark"
              >
                Qualified Year
              </label>
              <input
                id="id_input_fm_7_qualified_year"
                name="id_input_fm_7_qualified_year"
                type="text"
                maxLength={5}
                className="form-control"
                placeholder="Enter Qualified Year"
                onChange={(evt) =>
                  formValidation({
                    event: evt,
                    checkExpression: /[^\d-]+/,
                  })
                }
                required
              />
              <div className="invalid-feedback position-absolute">
                Please enter an year
              </div>
              <small className="txt-danger position-absolute d-none input-validation"></small>
            </div> */}
          {/* </div> */}
          <FileUploader
            label={"Upload educational cerificate and transcript"}
            attachment_list={attachmentList}
            preview_list={previewFileList}
            set_attachment_list={setAttachmentList}
            set_preview_list={setPreviewFileList}
            max_file_limit={MAXFILELIMIT}
          />
          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_7_remark">Remark</label>
            <textarea
              id="id_input_fm_7_remark"
              name="fm_7_remark"
              rows="3"
              maxLength={250}
              className="form-control"
              placeholder="Enter Remark"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  checkExpression: "",
                })
              }
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          {/* {(checkIsAccessible(INSERTFUNCTION) &&
            educationQualificationId === 0) ||
          (checkIsAccessible(DEACTIVATEFUNCTION) &&
            educationQualificationId !== 0) ? (
            <div className="form-group mb-4">
              <div className="checkbox checkbox-solid-dark col-12 col-sm-6">
                <input
                  id="id_input_fm_7_is_active"
                  name="fm_7_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_7_is_active">Active</label>
              </div>
            </div>
          ) : null} */}
          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
              (checkIsAccessible(EDITFUNCTION) &&
                educationQualificationId !== 0) ? (
              <div className="d-sm-flex justify-content-end">

                <button
                  className="btn btn-outline-primary col-md-1 col-3 px-1 mb-2 mb-sm-0"
                  type="reset"
                >
                  Reset
                </button>
                <button
                  className="btn btn-primary col-md-2 col-4 mx-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {educationQualificationId ? "Update" : "Submit"}
                </button>

                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleBackClick()}><ArrowLeft height={"16px"} width={"16px"} />Back</button>

                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleNextClick()}>Next<ArrowRight height={"16px"} width={"16px"} /></button>

              </div>
            ) : null
          ) : null}

          <br />

          {isSuccess && (
            <div className="col-sm-12 mb-2">
              <div className="bg-primary px-3 py-3 rounded d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div
                    style={{
                      backgroundColor: "#FFEBB2",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Check
                      height={"16px"}
                      style={{ strokeWidth: 5 }}
                      className="txt-primary"
                    />
                  </div>
                  <div>
                    <div className="mb-1 d-flex align-items-center">
                      <h6
                        style={{ color: "#FFEBB2" }}
                        className="uppercase px-1 m-0"
                      >

                      </h6>
                      <div
                        style={{ backgroundColor: "#FFEBB2", height: "5px" }}
                        className="w-100 rounded ml-2"
                      ></div>
                    </div>
                    <div style={{ color: "#FFEBB2", marginLeft: 15 }} className="fw-bold">
                      Do you want to add a another Education detail.
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className=" d-flex justify-content-end"                >
                    <div>
                      <button
                        type="reset"
                        className="btn 
                            rounded btn-sm "
                        style={{ backgroundColor: "#FFEBB2" }}
                      >

                        <span className="fw-bold">Add</span>
                      </button>
                    </div>
                  </div>
                  <div className="mx-2 d-flex"
                  >
                    <div style={{ marginTop: -15 }} >
                      <span
                        onClick={closeItem}
                        style={{
                          cursor: "pointer",
                        }}
                      > <X style={{ color: "#FFEBB2" }} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )}


        </form>
      </div>
      <div className="mb-4">
        <Datatable
          titleData="Education Qualification List"
          columnData={tableColumnHeaderList}
          rowData={educationRowList}
          csvFilename="Education.csv"
        />
      </div>
    </>
  );
};

export default Education;
