import { useEffect, useRef, useState } from "react";
import { AlertTriangle, ArrowLeft, ArrowRight, Check, PlusCircle, RefreshCw, X } from "react-feather";
import { Alert, Progress } from "reactstrap";
import Swal from "sweetalert2";
import {
  INSERT_LANGUAGE,
  LANGUAGE,
  PROFICIENCY_LIST,
} from "../../../../constant";
import useAccess from "../../../../customHooks/useAccess";
import ConfigDB from "../../../../data/customizer/config";
import { useSelector } from "react-redux";
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import Datatable, { BooleanValue, ControlButtons } from "../../../common/datatable";
import formValidation from "../../../../customHooks/useValidation";
import { Range, getTrackBackground } from "react-range";
import { Container, Row, Col, Card, CardHeader, CardBody, Label, Form } from 'reactstrap';

import * as React from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import { Arrow } from "reactour";


const labels = {
  // 0: 'Bad',
  // 0.5: 'Useless',
  // 1: 'Useless+',
  // 1.5: 'Poor',
  // 2: 'Poor+',
  // 2.5: 'Ok',
  // 3: 'Ok+',
  // 3.5: 'Good',
  // 4: 'Good+',
  // 4.5: 'Excellent',
  // 5: 'Excellent+',
  1: 'Bad',
  2: 'Beginner',
  3: 'Average',
  4: 'Good',
  5: 'Fluent'
};


function getWriteLabelText(writeValue) {
  return `${writeValue} Star${writeValue !== 1 ? 's' : ''}, ${labels[writeValue]}`;
}

function getReadLabelText(readValue) {
  return `${readValue} Star${readValue !== 1 ? 's' : ''}, ${labels[readValue]}`;
}

function getSpeakLabelText(speakValue) {
  return `${speakValue} Star${speakValue !== 1 ? 's' : ''}, ${labels[speakValue]}`;
}

function getUnderstandLabelText(understandValue) {
  return `${understandValue} Star${understandValue !== 1 ? 's' : ''}, ${labels[understandValue]}`;
}




const Language = ({ b_partner_id = 0, client_id = 0, org_id = 0, set_basic_tab = null, set_back_tab = null }) => {

  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshDpLanguage, setRefreshDpLanguage] = useState(true);

  // form values
  const [LanguageList, setLanguageList] = useState([
    { languageId: "", name: "-Select-" },
  ]);
  const [dpLanguageValue, setDpLanguageValue] = useState(0);
  const [dpProficiencyValue, setDpProficiencyValue] = useState(0);
  //   const [languageList, setlanguageList] = useState([]);
  // const [proficiencyList] = useState(PROFICIENCY_LIST);
  const [proficiencyList, setProficiencyList] = useState(PROFICIENCY_LIST);
  const selectedLanguages = useRef([]);
  const [languageId, setLanguageId] = useState(0);

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  const [isSuccess, setIsSuccess] = useState(false);

  const MaxValue = "Max value"
  const [values, setValues] = useState([5, 10]);

  const STEP = 1;
  const MIN = 0;
  const MAX = 10;

  const [values1, setValues1] = useState([10]);
  const [values2, setValues2] = useState([10]);
  const [values3, setValues3] = useState([10]);
  const [values4, setValues4] = useState([10]);


  const Default = "Default"
  const BasicSlider = "Basic Slider"
  const NegativeValues = "Negative Values"
  const FormatedLabel = "Formated Label"
  const Disabled = "Disabled"

  const [writeValue, setWriteValue] = React.useState(0);
  const [readValue, setReadValue] = React.useState(0);
  const [speakValue, setSpeakValue] = React.useState(0);
  const [understandValue, setUnderstandValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);
  const [hoverRead, setHoverRead] = React.useState(-1);
  const [hoverSpeak, setHoverSpeak] = React.useState(-1);
  const [hoverUnderstand, setHoverUnderstand] = React.useState(-1);

  const [showRating, setShowRating] = useState(false);


  useEffect(() => {
    console.log(writeValue);
    console.log(readValue);
    console.log(speakValue);
    console.log(understandValue);
  }, [writeValue, readValue, speakValue, understandValue])


  // table data
  const [languageRowList, setLanguageRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Language",
    "Language Proficiency",
    // "Remarks",
    // {
    //   name: "Active",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <BooleanValue value={value} />;
    //     },
    //   },
    // },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];

  // table functions
  const [editLanguage] = useState(() => (customerLanguage_id, response_data) => {
    const editingLanguage = response_data.find(
      (item) => item.customerLanguageId === customerLanguage_id
    );
    console.log(editingLanguage);

    setShowRating(true);

    setLanguageId(editingLanguage.customerLanguageId);
    setDpLanguageValue(editingLanguage.languageId);
    // setDpProficiencyValue(editingLanguage.proficiency);

    setWriteValue(editingLanguage.write);
    setReadValue(editingLanguage.read);
    setSpeakValue(editingLanguage.speak);
    setUnderstandValue(editingLanguage.understanding);



    document.querySelector("textarea[name='fm_5_remark']").value =
      editingLanguage.remark;
    // document.querySelector("input[name='fm_5_is_active']").checked = editingLanguage.active;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });


  const [deleteLanguage] = useState(() => (language_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this language record!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          customerLanguageId: language_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "/deleteEdgeLanguageTab",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Language record has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });


  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      // selectedLanguages.current = [];
      // languageList.forEach((langauge_item) => {
      //   selectedLanguages.current.push({
      //     languageId: langauge_item.languageId,
      //     isCan: !!formData.get(
      //       `fm_13_lan_${langauge_item.languageId}_canspeak`
      //     ),
      //     isShowApplication: !!formData.get(
      //       `fm_13_lan_${langauge_item.languageId}_isshowinapplication`
      //     ),
      //     remark: formData.get(
      //       `fm_13_lan_${langauge_item.languageId}_proficiency`
      //     ),
      //   });
      // });

      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/checkCustomerEdgeLanguageExist",
          data: {
            clientId: parseInt(loggedUser.clientId),
            bpartnerId: parseInt(b_partner_id),
            customerLanguageId: parseInt(languageId),
            languageId: parseInt(dpLanguageValue),
            // proficiency: formData.get("fm_13_lan_proficiency"),
            // level:values1,
          },
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          switch (response_data.existType) {
            case 0:
              let language = {
                bpartnerId: parseInt(b_partner_id),
                clientId: parseInt(client_id),
                orgId: parseInt(org_id),
                userId: parseInt(loggedUser.userId),
                customerLanguageId: parseInt(languageId),
                languageId: parseInt(dpLanguageValue),
                // proficiency: formData.get("fm_13_lan_proficiency"),
                // level: values1[0],
                remark: formData.get("fm_5_remark"),
                isActive: true,
                write: parseInt(writeValue),
                read: parseInt(readValue),
                speak: parseInt(speakValue),
                understanding: parseInt(understandValue),
                // isActive: formData.get("fm_5_is_active") ? true : false,
                // languageList: selectedLanguages.current,
              };
              setIsLoaded(LOADING);
              let nestedResponseData = new Promise((resolve) => {
                const result = sendRequest({
                  url: "/saveEdgeLanguageTab",
                  data: language,
                });
                resolve(result);
              });
              nestedResponseData.then((nested_reponse_data) => {
                if (nested_reponse_data) {
                  Swal.fire({
                    icon: "success",
                    title: "Request Successful",
                    // text: "Customer Languages has successfully updated.",
                    text: languageId
                      ? "Language has successfully updated."
                      : "New Language has successfully added.",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  // setRefreshDpLanguage(true);

                  setIsLoaded(LOADING);
                  setRefreshTable(true);
                  setIsSuccess(true);
                }
              });
              break;
            case 1:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Language Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            default:
              break;
          }
        }
      });

      // let language = {
      //   bpartnerId: parseInt(b_partner_id),
      //   clientId: parseInt(client_id),
      //   orgId: parseInt(org_id),
      //   userId: parseInt(loggedUser.userId),
      //   customerLanguageId: parseInt(languageId),
      //   languageId: parseInt(dpLanguageValue),
      //   proficiency: formData.get("fm_13_lan_proficiency"),
      //   remark: formData.get("fm_5_remark"),
      //   isActive: formData.get("fm_5_is_active") ? true : false,
      //   // languageList: selectedLanguages.current,
      // };
      // setIsLoaded(LOADING);
      // // new Promise((resolve) => {
      // let responseData = new Promise((resolve) => {
      //   const result = sendRequest({
      //     url: "/saveEdgeLanguageTab",
      //     data: language,
      //     template: "CONTROL_DATA",
      //   });
      //   resolve(result);
      // });
      // // })
      // // .then((reponse_data) => {
      // responseData.then((reponse_data) => {
      //   if (reponse_data) {
      //     Swal.fire({
      //       icon: "success",
      //       title: "Request Successful",
      //       // text: "Customer Languages has successfully updated.",
      //       text: languageId
      //         ? "Languages has successfully updated."
      //         : "New Language has successfully added.",
      //       showConfirmButton: false,
      //       timer: 5000,
      //     });
      //     // setRefreshDpLanguage(true);
      //     setIsLoaded(LOADING);
      //     setRefreshTable(true);
      //   }
      // });
    }
  };


  const resetForm = (evt) => {
    // setIsValidated(false);
    // setLanguageId(0);
    // setDpLanguageValue(0);
    // setDpProficiencyValue(0);
    // let inputFields = document.querySelectorAll(".form-control");
    // let customValidationMessages =
    //   document.querySelectorAll(".input-validation");
    // inputFields.forEach((field) => {
    //   if (field.classList.contains("is-valid")) {
    //     field.classList.remove("is-valid");
    //   } else if (field.classList.contains("is-invalid")) {
    //     field.classList.remove("is-invalid");
    //   }
    // });
    // customValidationMessages.forEach((element) => {
    //   if (element.classList.contains("input-validation")) {
    //     if (!element.classList.contains("d-none")) {
    //       element.classList.add("d-none");
    //     }
    //   }
    // });
    evt.preventDefault();

    Swal.fire({
      title: 'Are you sure you want to reset?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked 'OK', reset the form or perform other actions
        setDpLanguageValue('');
        setWriteValue('');
        setReadValue('');
        setSpeakValue('');
        setUnderstandValue('');
        setHover('');
        setHoverRead('');
        setHoverSpeak('');
        setHoverUnderstand('');
        document.getElementById('id_input_fm_5_remark').value = '';

        setIsValidated(false);
      }
    });

  };


  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };


  // useEffect(() => {
  //   if (refreshDpLanguage) {
  //     const reqBody = {
  //       clientId: loggedUser.clientId,
  //       orgId: loggedUser.orgId,
  //       userId: loggedUser.userId,
  //       bpartnerId: b_partner_id,
  //     };
  //     new Promise((resolve) => {
  //       const result = sendRequest({
  //         url: "customer/viewLanguageTab",
  //         data: reqBody,
  //       });
  //       resolve(result);
  //     }).then((response_data) => {
  //       if (response_data) {
  //         setlanguageList([...response_data.languageList]);
  //       }
  //       setRefreshDpLanguage(false);
  //       setIsLoaded(LOADED);
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [refreshDpLanguage]);





  // const resetForm = (evt) => {
  //   setRefreshDpLanguage(true);
  //   setIsLoaded(LOADING);
  // };


  const getLabel = (value) => {
    return labels[value] || 'Unknown';
  };

  useEffect(() => {
    console.log(values1[0]);
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/viewEdgeLanguageTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            if (response_data.activeLanguageList) {
              setLanguageList([
                { countryId: "", name: "-Select-" },
                ...response_data.activeLanguageList,
              ]);
              // setProficiencyList([
              //   ...response_data.proficiency,
              // ]);
            }

            // if (response_data.proficiency) {
            //   setProficiencyList([
            //     ...response_data.proficiency,
            //   ]);
            // }

            if (response_data.allTableList) {
              let tempList = [];
              response_data.allTableList.forEach((listItem) => {
                tempList.push([
                  listItem.customerLanguageId,

                  listItem.language,

                  // listItem.proficiency,
                  <tr>
                    <div>{`Write: ${getLabel(listItem.write)}`}</div>
                    <div>{`Read: ${getLabel(listItem.read)}`}</div>
                    <div>{`Speak: ${getLabel(listItem.speak)}`}</div>
                    <div>{`Understand: ${getLabel(listItem.understanding)}`}</div>
                  </tr>,


                  // listItem.remark,
                  // listItem.active ? "True" : "False",
                  <ControlButtons
                    editMethod={() =>
                      editLanguage(
                        listItem.customerLanguageId,
                        response_data.allTableList
                      )
                    }
                    disableEdit={!checkIsAccessible(EDITFUNCTION)}
                    deleteMethod={() =>
                      deleteLanguage(listItem.customerLanguageId, loggedUser)
                    }
                    disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                  />,
                ]);



              });
              setLanguageRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  const closeItem = () => {
    setIsSuccess(false);
  };

  const handleChangeEditLanguage = (value) => {

    setShowRating(true);

    console.log('Handle Change EditLanguage method called with value:', value);

    if (b_partner_id > 0) {
      const reqBody = {
        bpartnerId: parseInt(b_partner_id),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        languageId: parseInt(value),
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getLanguageDetails",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          if (response_data.length > 0) {

            console.log("have languages");
            console.log(response_data);
            setLanguageId(response_data[0].id);
            setDpLanguageValue(value);
            //  setDpProficiencyValue(response_data.proficiency);
            setWriteValue(response_data[0].write);
            setReadValue(response_data[0].read);
            setSpeakValue(response_data[0].speak);
            setUnderstandValue(response_data[0].understanding);

            document.querySelector("textarea[name='fm_5_remark']").value =
              response_data[0].remark;
          } else {
            setLanguageId(0);
            setDpLanguageValue(value);
            //  setDpProficiencyValue(response_data.proficiency);
            setWriteValue(0);
            setReadValue(0);
            setSpeakValue(0);
            setUnderstandValue(0);

            document.querySelector("textarea[name='fm_5_remark']").value = "";
          }
          // setIsLoaded(LOADED);
          // setRefreshTable(false);
        }
      });

    } else {
      setIsLoaded(LOADED);
    }

  };

  const handleBackClick = () => {
    set_back_tab()
  };

  const handleNextClick = () => {
    set_basic_tab()
  };


  return (
    <>
      <div className="card-modified-body bg-white pt-0 mb-4">
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={b_partner_id === 0}
        >
          <AlertTriangle />
          <p className="ml-2">
            You must create a customer, before add language details.
          </p>
        </Alert>
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-13 ${isValidated ? "was-validated" : ""
            }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}



          <div className="form-row mb-4">

            <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_2_sa_job_category_id"
                  className="required-input-mark col-form-label pt-0"
                >
                  Language
                </label>

              </div>

              <select
                id="id_input_fm_5_sa_language_id"
                name="fm_5_sa_language_id"
                className="form-select"
                onChange={(evt) => {
                  setDpLanguageValue(evt.target.value);
                  handleChangeEditLanguage(evt.target.value);
                }}
                value={dpLanguageValue}
                required
              >
                {LanguageList.length
                  ? LanguageList.map((listItem, index) => (
                    <option value={listItem?.languageId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select a language
              </small>

            </div>

            {/* <div className="form-group col-12 col-sm-9 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_2_is_most_interested_job"
                  className="required-input-mark col-form-label pt-0"
                >
                  Language Proficiency
                </label>
              </div>
              <select
                id="id_input_fm_2_sa_level_proficiency"
                
                name="fm_13_lan_proficiency"
                className="form-select"
                
                onChange={(evt) => setDpProficiencyValue(evt.target.value)}
                value={dpProficiencyValue}
                required
              >
                {proficiencyList.length
                  ? proficiencyList.map((list_item, index) => (
                    <option value={list_item} key={index}>
                      {list_item}
                    </option>
                  ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select a Proficiency Lavel
              </small>
            </div> */}
          </div>

          {showRating && (
            <div className="form-row mb-4">

              <div className="form-group col-12 col-md-3 mb-2 mb-sm-0 position-relative">

                <div className="d-flex">
                  <Label>Write</Label>
                </div>

                <Box
                  sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name="write-hover-feedback"
                    value={writeValue}
                    precision={1}
                    getLabelText={getWriteLabelText}
                    onChange={(event, newValue) => {
                      // setWriteValue(newValue);
                      if (newValue !== null) {
                        setWriteValue(newValue);
                      } else {
                        setWriteValue(0)
                      }
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                  />
                  {writeValue !== null && (
                    <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : writeValue]}</Box>
                  )}
                </Box>


              </div>

              <div className="form-group col-12 col-md-3 mb-2 mb-sm-0 position-relative">

                <div className="d-flex">
                  <Label>Read</Label>
                </div>

                <Box
                  sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name="read-hover-feedback"
                    value={readValue}
                    precision={1}
                    getLabelText={getReadLabelText}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        setReadValue(newValue);
                      } else {
                        setReadValue(0)
                      }
                    }}
                    onChangeActive={(event, newHover) => {
                      setHoverRead(newHover);
                    }}
                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                  />
                  {readValue !== null && (
                    <Box sx={{ ml: 1 }}>{labels[hoverRead !== -1 ? hoverRead : readValue]}</Box>
                  )}
                </Box>
              </div>

              <div className="form-group col-12 col-md-3 mb-2 mb-sm-0 position-relative">

                <div className="d-flex">
                  <Label>Speak</Label>
                </div>

                <Box
                  sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name="hover-feedback"
                    value={speakValue}
                    precision={1}
                    getLabelText={getSpeakLabelText}
                    onChange={(event, newValue) => {
                      // setSpeakValue(newValue);
                      if (newValue !== null) {
                        setSpeakValue(newValue);
                      } else {
                        setSpeakValue(0)
                      }
                    }}
                    onChangeActive={(event, newHover) => {
                      setHoverSpeak(newHover);
                    }}
                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                  />
                  {speakValue !== null && (
                    <Box sx={{ ml: 1 }}>{labels[hoverSpeak !== -1 ? hoverSpeak : speakValue]}</Box>
                  )}
                </Box>


              </div>

              <div className="form-group col-12 col-md-3  mb-2 mb-sm-0 position-relative">

                <div className="d-flex">
                  <Label>Understand</Label>
                </div>

                <Box
                  sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {understandValue == null ? 0 : (
                    <Rating
                      name="hover-feedback"
                      value={understandValue}
                      precision={1}
                      getLabelText={getUnderstandLabelText}
                      onChange={(event, newValue) => {
                        // setUnderstandValue(newValue);
                        // setUnderstandValue(event.target.value == null ? 0 : newValue);
                        if (newValue !== null) {
                          setUnderstandValue(newValue);
                        } else {
                          setUnderstandValue(0)
                        }
                      }}
                      onChangeActive={(event, newHover) => {
                        setHoverUnderstand(newHover);
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                  )}

                  {understandValue !== null && (
                    <Box sx={{ ml: 1 }}>{labels[hoverUnderstand !== -1 ? hoverUnderstand : understandValue]}</Box>
                  )}
                  {/* :{(
                  <>
                  {understandValue = 0}
                  <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : understandValue]}</Box>
                </>
                )} */}
                </Box>

              </div>

            </div>
          )}

          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_5_remark">Remark</label>
            <textarea
              id="id_input_fm_5_remark"
              name="fm_5_remark"
              rows="3"
              maxLength={250}
              className="form-control"
              placeholder="Enter Remark"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  checkExpression: "",
                })
              }
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>


          {/* <div className="form-row mb-4"> */}
          {/* <div className="checkbox checkbox-solid-dark mx-1 mx-sm-1 col-12 col-sm-3">
            <input
              id="id_input_fm_5_is_collected"
              name="fm_5_is_collected"
              type="checkbox"
            />
            <label htmlFor="id_input_fm_5_is_collected">Collected</label>
          </div> */}

          {/* {(checkIsAccessible(INSERTFUNCTION) && languageId === 0) ||
              (checkIsAccessible(DEACTIVATEFUNCTION) && languageId !== 0) ? (
              <div className="checkbox checkbox-solid-dark mx-1 mx-sm-1 col-12 col-sm-3">
                <input
                  id="id_input_fm_5_is_active"
                  name="fm_5_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_5_is_active">Active</label>
              </div>
            ) : null} */}
          {/* </div> */}

          {/* {b_partner_id ? (
          <div className="d-sm-flex justify-content-end">
            <button
              className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
              type="submit"
            >
              Update
            </button>
            <button className="btn btn-warning col-12 col-sm-2" type="reset">
              Reset
            </button>
          </div>
        ) : null} */}

        {/* <ul className="justify-content-space-between flex-wrap"> */}
          {/* <li>
          <div className="d-flex justify-content-start">
            <button class="btn btn-outline-dark col-md-1 col-3 px-1 mb-2 mb-sm-0" onClick={() => handleBackClick()}>Back</button>
          </div>
          </li> */}

          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
              (checkIsAccessible(EDITFUNCTION) && languageId !== 0) ? (

              // <li>
              <div className="d-sm-flex justify-content-end">

                <button
                  className="btn btn-outline-primary col-md-1 col-3 px-1 mb-2 mb-sm-0"
                  type="reset"
                >
                  Reset
                </button>
                <button
                  className="btn btn-primary col-md-2 col-4 mx-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {languageId ? "Update" : "Submit"}
                </button>

                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleBackClick()}><ArrowLeft height={"16px"} width={"16px"}/>Back</button>

                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleNextClick()}>Next<ArrowRight height={"16px"} width={"16px"}/></button>

                
           
          
              </div>
              // </li>
            ) : null
          ) : null}
          {/* </ul> */}

{/* <ul style={{ display: 'flex', justifyContent: 'space-between' }}>
  <li>
    <div className="d-sm-flex justify-content-start">
      <button className="btn btn-outline-dark col-md-1 col-3 px-1 mb-2 mb-sm-0" onClick={() => handleBackClick()}>Back</button>
    </div>
  </li>
  
  {b_partner_id ? (
    checkIsAccessible(INSERTFUNCTION) ||
      (checkIsAccessible(EDITFUNCTION) && languageId !== 0) ? (
        <li>
          <div className="d-sm-flex justify-content-end">
            <button
              className="btn btn-outline-primary col-md-5 col-3 px-1 mb-2 mb-sm-0"
              type="reset"
            >
              Reset
            </button>
            <button
              className="btn btn-primary col-md-8 col-4 mx-3 mb-2 mb-sm-0"
              type="submit"
            >
              {languageId ? "Update" : "Submit"}
            </button>
          </div>
        </li>
      ) : null
  ) : null}
</ul> */}

          <br />

          {isSuccess && (
            <div className="col-sm-12 mb-2">
              <div className="bg-primary px-3 py-3 rounded d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div
                    style={{
                      backgroundColor: "#FFEBB2",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Check
                      height={"16px"}
                      style={{ strokeWidth: 5 }}
                      className="txt-primary"
                    />
                  </div>
                  <div>
                    <div className="mb-1 d-flex align-items-center">
                      <h6
                        style={{ color: "#FFEBB2" }}
                        className="uppercase px-1 m-0"
                      >
                        {/* {searchKey} */}
                      </h6>
                      <div
                        style={{ backgroundColor: "#FFEBB2", height: "5px" }}
                        className="w-100 rounded ml-2"
                      ></div>
                    </div>
                    <div style={{ color: "#FFEBB2", marginLeft: 15 }} className="fw-bold">
                      {/* <UserCheck height={"14px"} style={{ strokeWidth: 3 }} /> */}
                      {/* {` ${firstName}’s`}  */}
                      Do you want to add a another language detail.
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className=" d-flex justify-content-end"                >
                    <div>
                      <button
                        type="reset"
                        className="btn 
                            rounded btn-sm "
                        style={{ backgroundColor: "#FFEBB2" }}
                      >

                        <span className="fw-bold">Add</span>
                      </button>
                    </div>
                  </div>
                  <div className="mx-2 d-flex"
                  >
                    <div style={{ marginTop: -15 }} >
                      <span
                        onClick={closeItem}
                        style={{
                          cursor: "pointer",
                        }}
                      > <X style={{ color: "#FFEBB2" }} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )}

        </form>
      </div>

      <div className="mb-4">
        <Datatable
          titleData="Language List"
          columnData={tableColumnHeaderList}
          rowData={languageRowList}
          csvFilename="Langauage.csv"
        />
      </div>
    </>
  );
};

export default Language;
