import React, { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// components
import Breadcrumb from "../common/breadcrumb";
import Datatable, { ControlButtons, BooleanValue } from "../common/datatable";
import formValidation from "../../customHooks/useValidation";

import axios from "axios";
import ConfigDB from "../../data/customizer/config";

// constants
import { LOADED, LOADING } from "../../constant";

// network requests
import useAccess from "../../customHooks/useAccess";
import useAxiosTemplates from "../../customHooks/useAxiosTemplates";
import { AlertTriangle, Check, CheckCircle, Eye, EyeOff, ArrowRight } from "react-feather";
import { Alert } from "reactstrap";
// import {
//   DEACTIVATE_USER,
//   DELETE_USER,
//   EDIT_USER,
//   INSERT_USER,
//   USER,
// } from "../../constant";

const UserAccount = (
    b_partner_id = 0,
    set_b_partner_id = null,
    set_client_id = null,
    set_org_id = null,
    set_search_key = null,
    trigger_shortcut = true,
    set_first_name = null
) => {


    // stored data
    const loggedUser = useSelector((content) => content.UserReducer);
    const customerData = useSelector((content) => content.CustomerReducer);
    const { customer_id } = useParams();

    // custom hooks
    // const [checkIsAccessible] = useAccess(loggedUser, "Customer");
    const sendRequest = useAxiosTemplates();
    const interfaceFunctionAccessibility = useAccess(loggedUser);
    const isInsertAllowed = interfaceFunctionAccessibility[0];

    // breadcrumb values
    let childLinks = [
        { value: customer_id, active: false },
        { value: "User Account", active: true },
    ];

    // helper containers
    const [resData, setResData] = useState();
    const enteredPassword = useRef("");

    // interface status data
    const [refreshTable, setRefreshTable] = useState(true);

    // form controls
    const [isValidated, setIsValidated] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isF2Validated, setIsF2Validated] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [isLoaded, setIsLoaded] = useState(LOADED);

    // form values
    const [roleList, setRoleList] = useState([{ roleId: "", name: "-Select-" }]);
    const [dpRoleValue, setDpRoleValue] = useState(0);

    const [showAlert, setShowAlert] = useState(false);
    const [showPasswordAlert, setShowPasswordAlert] = useState(false)
    const [loginCount, setLoginCount] = useState(0);

    let navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    // fetch master data

    // useEffect(() => {
    //     if (refreshTable) {
    //         document
    //             .querySelectorAll("input:required,select:required")
    //             .forEach((elem) =>
    //                 elem.previousSibling.classList.add("required-input-mark")
    //             );
    //         const reqBody = {
    //             clientId: loggedUser.clientId,
    //             orgId: loggedUser.orgId,
    //             loggedUserId: loggedUser.userId,
    //         };
    //         let responseData = new Promise((resolve) => {
    //             const result = sendRequest({ url: "/viewUser", data: reqBody });
    //             resolve(result);
    //         });

    //         const dpListRequestBody = { clientId: loggedUser.clientId };
    //         responseData = new Promise((resolve) => {
    //             const result = sendRequest({
    //                 url: "/getAllActiveRole",
    //                 data: dpListRequestBody,
    //             });
    //             resolve(result);
    //         });
    //         responseData.then((response_data) => {
    //             if (response_data) {
    //                 setRoleList((prevList) => [prevList[0], ...response_data]);

    //             }
    //         });

    //     }
    // }, [loggedUser, isValidated, sendRequest]);

    // useEffect(() => {
    //     if (resData) {
    //         let tempList = [];
    //         resData.forEach((listItem) => {
    //             if (loggedUser.orgId === listItem.orgId) {
    //                 tempList.push([
    //                     listItem.userId,
    //                     // loggedUser.activeClientList?.find(
    //                     //   (item) => item.clientId === listItem.clientId
    //                     // ).name,
    //                     // loggedUser.activeOrgRoleList?.find(
    //                     //   (item) => item.orgId === listItem.orgId
    //                     // ).name,
    //                     listItem.employeeName,
    //                     listItem.username,
    //                     listItem.roleName,
    //                     listItem.isActive ? "True" : "False",
    //                     <ControlButtons
    //                         editMethod={() => editUser(listItem.userId, resData)}
    //                         disableEdit={!checkIsAccessible(EDIT_USER)}
    //                         deleteMethod={() => deleteUser(listItem.userId, loggedUser)}
    //                         disableDelete={!checkIsAccessible(DELETE_USER)}
    //                     />,
    //                 ]);
    //             }
    //         });
    //         setUserRowList([...tempList]);
    //         editUser(loggedUser.userId, resData);
    //     }
    // }, [resData, editUser, deleteUser, loggedUser, checkIsAccessible]);

    // form control functions
    const resetForm = () => {
        setIsValidated(false);
        setDpRoleValue(0);
        let inputFields = document.querySelectorAll(".form-control");
        let customValidationMessages =
            document.querySelectorAll(".input-validation");
        inputFields.forEach((field) => {
            if (field.classList.contains("is-valid")) {
                field.classList.remove("is-valid");
            } else if (field.classList.contains("is-invalid")) {
                field.classList.remove("is-invalid");
            }
        });
        customValidationMessages.forEach((element) => {
            if (element.classList.contains("input-validation")) {
                if (!element.classList.contains("d-none")) {
                    element.classList.add("d-none");
                }
            }
        });
    };
    const submitForm = (evt) => {
        setIsValidated(true);
        evt.preventDefault();
        if (evt.target.checkValidity()) {
            console.log("user form validated");
            const formData = new FormData(evt.target);
            let responseData = new Promise((resolve) => {
                const result = sendRequest({
                    url: "/checkUsernameExist",
                    data: {
                        clientId: loggedUser.clientId,
                        userId: loggedUser.userId,
                        username: formData.get("fm_1_username"),
                    },
                });
                resolve(result);
            });
            responseData.then((response_data) => {
                if (response_data) {
                    switch (response_data.existType) {
                        case -1:
                            let user = {
                                clientId: parseInt(loggedUser.clientId),
                                orgId: parseInt(loggedUser.orgId),
                                loggedUserId: parseInt(loggedUser.userId),
                                userId: loggedUser.userId,
                            };
                            if (loggedUser.userId) {
                                user = {
                                    ...user,

                                    roleId: parseInt(formData.get("fm_1_sa_role_id")),
                                    // username: formData.get("fm_1_username"),
                                    isActive: document.querySelector(
                                        "input[name='fm_1_is_active']"
                                    )?.checked,
                                    isPasswordChanged: false,
                                };
                            } else {
                                user = {
                                    ...user,
                                    bpartnerId: loggedUser.bpartnerId,
                                    roleId: parseInt(dpRoleValue),
                                    isActive: document.querySelector(
                                        "input[name='fm_1_is_active']"
                                    )?.checked,
                                    username: formData.get("fm_1_username"),
                                    password: formData.get("fm_1_confirm_password"),
                                };
                            }
                            let nestedResponseData = new Promise((resolve) => {
                                const result = sendRequest({
                                    url: "/saveUser",
                                    data: user,
                                    template: "CONTROL_DATA",
                                });
                                resolve(result);
                            });
                            nestedResponseData.then((nested_reponse_data) => {
                                if (nested_reponse_data) {
                                    Swal.fire({
                                        icon: "success",
                                        title: "Request Successful",
                                        text: loggedUser.userId
                                            ? "User has successfully updated."
                                            : "New User has successfully added.",
                                        showConfirmButton: false,
                                        timer: 5000,
                                    });
                                    // setRefreshTable(true);
                                }
                            });
                            break;
                        case 0:
                            let user0 = {
                                clientId: parseInt(loggedUser.clientId),
                                orgId: parseInt(loggedUser.orgId),
                                loggedUserId: parseInt(loggedUser.userId),
                                userId: loggedUser.userId,
                            };
                            if (loggedUser.userId) {
                                user0 = {
                                    ...user0,
                                    roleId: parseInt(formData.get("fm_1_sa_role_id")),
                                    // username: formData.get("fm_1_username"),
                                    // password: formData.get("fm_1_confirm_password"),
                                    isActive: document.querySelector(
                                        "input[name='fm_1_is_active']"
                                    )?.checked,
                                    isPasswordChanged: false,
                                };
                            } else {
                                user0 = {
                                    ...user0,
                                    bpartnerId: loggedUser.bpartnerId,
                                    roleId: parseInt(dpRoleValue),
                                    isActive: document.querySelector(
                                        "input[name='fm_1_is_active']"
                                    )?.checked,
                                    username: formData.get("fm_1_username"),
                                    password: formData.get("fm_1_confirm_password"),
                                };
                            }
                            let nestedResponseData0 = new Promise((resolve) => {
                                const result = sendRequest({
                                    url: "/saveUser",
                                    data: user0,
                                    template: "CONTROL_DATA",
                                });
                                resolve(result);
                            });
                            nestedResponseData0.then((nested_reponse_data) => {
                                if (nested_reponse_data) {
                                    Swal.fire({
                                        icon: "success",
                                        title: "Request Successful",
                                        text: loggedUser.userId
                                            ? "User has successfully updated."
                                            : "New User has successfully added.",
                                        showConfirmButton: false,
                                        timer: 5000,
                                    });
                                    setRefreshTable(true);
                                }
                            });
                            break;
                        case 1:
                            Swal.fire({
                                icon: "error",
                                title: "Request Failed",
                                text: "Username Exists",
                                showConfirmButton: false,
                                timer: 5000,
                            });
                            break;
                        default:
                            break;
                    }
                }
            });
        }
    };

    const submitNewPassword = (evt) => {
        setIsF2Validated(true);
        evt.preventDefault();
        if (evt.target.checkValidity()) {
            const formData = new FormData(evt.target);
            let user = {
                clientId: parseInt(loggedUser.clientId),
                orgId: parseInt(loggedUser.orgId),
                loggedUserId: parseInt(loggedUser.userId),
                userId: parseInt(loggedUser.userId),
                isPasswordChanged: true,
                password: formData.get("fm_2_confirm_password"),
            };
            let responseData = new Promise((resolve) => {
                const result = sendRequest({
                    url: "/saveUser",
                    data: user,
                    template: "CONTROL_DATA",
                });
                resolve(result);
            });
            responseData.then((reponse_data) => {
                if (reponse_data) {
                    Swal.fire({
                        icon: "success",
                        title: "Request Successful",
                        text: "User passsword has successfully updated.",
                        showConfirmButton: false,
                        timer: 5000,
                    });

                    console.log(loginCount);

                    if (loggedUser.roleName === "NormalUser" && loginCount < 3) {
                        setShowPasswordAlert(true);
                        // setTimeout(5000);
                    }
                    setTimeout(() => {
                        if (loggedUser.roleName === "NormalUser") {
                            navigate(`/customer/view/${customer_id}/`);
                        }
                    }, 6000)

                    setRefreshTable(true);
                }
            });
        }
    };

    const handleNextClick = () => {
        if (loggedUser.roleName === "NormalUser") {
            navigate(`/customer/view/${customer_id}/`);
        }
      };

    useEffect(() => {
        if (loggedUser.bpartnerId > 0) {

            document.querySelector("input[name='fm_1_username']").value = loggedUser.username;

            setDpRoleValue(parseInt(loggedUser.userRoleId));

            const dpListRequestBody = { clientId: loggedUser.clientId };
            let responseData = new Promise((resolve) => {
                const result = sendRequest({
                    url: "/getAllActiveRole",
                    data: dpListRequestBody,
                });
                resolve(result);
            });
            responseData.then((response_data) => {
                if (response_data) {
                    setRoleList((prevList) => [prevList[0], ...response_data]);
                }
            });

        }
        setIsLoaded(LOADED);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        console.log("check")
        if (loggedUser.bpartnerId > 0) {

            document.querySelector("input[name='fm_1_username']").value = loggedUser.username;

            setDpRoleValue(parseInt(loggedUser.userRoleId));

            const dpListRequestBody = { userId: parseInt(loggedUser.userId), };
            let responseData = new Promise((resolve) => {
                const result = sendRequest({
                    url: "/loginCount",
                    data: dpListRequestBody,
                });
                resolve(result);
            });
            responseData.then((response_data) => {

                console.log(response_data)
                if (loggedUser.roleName === "NormalUser" && response_data.count < 3) {
                    setShowAlert(true);
                    setLoginCount(response_data.count)
                }
            });

        }
        setIsLoaded(LOADED);
    }, []);


    return (
        <>
            <Breadcrumb
                // parent="Dashboard"
                // title="User Account"
                title={loggedUser.roleName === "NormalUser" ? "My Account" : "User Account"}
            // children={childLinks}
            />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            {/* <div className="card-header">
                                <h5>User</h5>
                                <span>Master File for editing your user account</span>
                            </div> */}

                            <div className="card-body border-bottom">
                                {isLoaded === LOADING ? (
                                    <div className="form-loader d-flex flex-column justify-content-center align-items-center">
                                        <div className="loader-box">
                                            <div className="loader">
                                                <div className="line bg-secondary"></div>
                                                <div className="line bg-secondary"></div>
                                                <div className="line bg-secondary"></div>
                                                <div className="line bg-secondary"></div>
                                            </div>
                                        </div>
                                        {/* <div>Saving... {uploadPercentage}%</div> */}
                                    </div>
                                ) : null}
                                <form
                                    //   tabIndex={1}
                                    onSubmit={(evt) => submitForm(evt)}
                                    onReset={(evt) => resetForm(evt)}
                                    className={`form-1 ${isValidated ? "was-validated" : ""}`}
                                    noValidate
                                >
                                    {/* <div className="form-row mb-4"> */}

                                    {/* <div className="form-group col-6 position-relative"> */}
                                    {/* <label
                                            htmlFor="id_input_fm_1_sa_connected_source_id"
                                            className="col-form-label pt-0"
                                        >
                                            Role
                                        </label>
                                        <select
                                            id="id_input_fm_1_sa_role_id"
                                            name="fm_1_sa_role_id"
                                            className="form-select"
                                            onChange={(evt) => setDpRoleValue(evt.target.value)}
                                            value={dpRoleValue}
                                            required
                                        >
                                            {roleList.length
                                                ? roleList.map((listItem, index) => (
                                                    <option value={listItem?.roleId} key={index}>
                                                        {listItem?.name}
                                                    </option>
                                                ))
                                                : null}
                                        </select>
                                        <small className="invalid-feedback position-absolute">
                                            Please select a Role
                                        </small> */}
                                    {/* </div> */}
                                    {/* </div> */}

                                    {showAlert && (
                                        <Alert
                                            className="alert-dismissible d-flex align-items-center"
                                            color="success"
                                            isOpen={true}
                                            toggle={() => setShowAlert(false)}
                                        >
                                            <CheckCircle style={{ width: 16, height: 16 }} />
                                            <div className="ml-2 fw-bold" style={{ marginLeft: 20 }}>
                                                Welcome to The Hotelier Pulse System!
                                                {/* <div>We have sent a temporary password to your email, so it will expire after 24 hours, so it is advisable to change the password here first.</div> */}
                                                <div>If you need to change the password, you can change it below.</div>
                                            </div>
                                        </Alert>
                                    )}





                                    <div className="form-row mb-4">
                                        {/* <div className="form-group col-6 position-relative"> */}
                                        <label htmlFor="id_input_fm_1_username">User Name</label>
                                        <input
                                            id="id_input_fm_1_username"
                                            name="fm_1_username"
                                            type="text"
                                            maxLength={45}
                                            className="form-control"
                                            placeholder="Enter Username"
                                            disabled
                                            onChange={(evt) =>
                                                formValidation({
                                                    event: evt,
                                                    validateName: true,
                                                })
                                            }
                                            required
                                        />
                                        <small className="invalid-feedback position-absolute">
                                            Please enter a User Name
                                        </small>
                                        <small className="txt-danger position-absolute d-none input-validation"></small>
                                        {/* </div> */}
                                    </div>
                                    {!loggedUser.userId ? (
                                        <>
                                            <div className="form-row mb-4">
                                                <div className="form-group col-6 position-relative">
                                                    <label htmlFor="id_input_fm_1_password">
                                                        Password
                                                    </label>
                                                    <input
                                                        ref={enteredPassword}
                                                        id="id_input_fm_1_password"
                                                        name="fm_1_password"
                                                        type="password"
                                                        maxLength={45}
                                                        minLength={8}
                                                        className="form-control"
                                                        placeholder="Enter Password"
                                                        onChange={(evt) =>
                                                            formValidation({
                                                                event: evt,
                                                                validatePassword: true,
                                                            })
                                                        }
                                                        required
                                                    />
                                                    <small className="invalid-feedback position-absolute" >

                                                        Please enter a Password
                                                    </small>
                                                    <small className="txt-danger position-absolute d-none input-validation"></small>

                                                </div>
                                                <div className="form-group col-6 position-relative">
                                                    <label htmlFor="id_input_fm_1_confirm_password">
                                                        Confirm Password
                                                    </label>
                                                    <input
                                                        id="id_input_fm_1_confirm_password"
                                                        name="fm_1_confirm_password"
                                                        type="password"
                                                        maxLength={45}
                                                        className="form-control"
                                                        placeholder="Re-Enter Password"
                                                        onChange={(evt) =>
                                                            formValidation({
                                                                event: evt,
                                                                confirmPassword: true,
                                                                enteredPassword: enteredPassword.current.value,
                                                            })
                                                        }
                                                        required
                                                    />
                                                    <small className="invalid-feedback position-absolute">
                                                        Please re-enter Password
                                                    </small>
                                                    <small className="txt-danger position-absolute d-none input-validation"></small>
                                                </div>
                                            </div>
                                            <div className="text-warning ">
                                                Password Requirements
                                                <ol className=" d-flex flex-column">
                                                    <li>Minimum character length of password is 8.</li>
                                                    <li>
                                                        Passowrd must consist of at least a single character
                                                        from uppercase letters, lowercase letters, digits
                                                        and special characters (!@#$%^&*-_+).
                                                    </li>
                                                    <li>
                                                        New password should not be a password from last 5
                                                        passwords used.
                                                    </li>
                                                    {/* <li>
                                                        Password will be expired in 60 days. You must
                                                        changed the password before expiration.
                                                    </li> */}
                                                </ol>
                                            </div>
                                        </>
                                    ) : null}
                                    {/* <div className="form-group mb-4">
                                        <div className="checkbox checkbox-solid-dark col-6">
                                            <input
                                                id="id_input_fm_1_is_active"
                                                name="fm_1_is_active"
                                                type="checkbox"
                                                // disabled={!checkIsAccessible(DEACTIVATE_USER)}
                                                defaultChecked
                                            />
                                            <label htmlFor="id_input_fm_1_is_active">Active</label>
                                        </div>
                                    </div> */}
                                    {/* {checkIsAccessible(INSERT_USER) ||
                                        (checkIsAccessible(EDIT_USER) && userId !== 0) ? ( */}


                                    {/* <div className="d-flex justify-content-end">
                                        <button
                                            className="btn btn-primary col-2 mx-3"
                                            type="submit"
                                        >
                                            {loggedUser.userId ? "Update" : "Submit"}
                                        </button>

                                    </div> */}
                                    {/* // ) : null} */}
                                </form>
                            </div>

                            {loggedUser.userId ? (
                                <div className="card-body">

                                    <div className="fw-bold mb-4">Change Your Password</div>

                                    <form
                                        onSubmit={(evt) => submitNewPassword(evt)}
                                        className={`form-2 ${isF2Validated ? "was-validated" : ""}`}
                                        noValidate
                                    >


                                        <div className={`form-row mb-4 ${loggedUser.userId ? "" : "d-none"}`}>

                                            <div className="form-group col-6 position-relative">
                                                <label htmlFor="id_input_fm_2_password">New Password</label>
                                                <input
                                                    ref={enteredPassword}
                                                    id="id_input_fm_2_password"
                                                    name="fm_2_password"
                                                    type="password"
                                                    maxLength={45}
                                                    minLength={8}
                                                    className="form-control"
                                                    placeholder="Enter New Password"
                                                    onChange={(evt) =>
                                                        formValidation({
                                                            event: evt,
                                                            validatePassword: true,
                                                        })
                                                    }
                                                    required
                                                />
                                                <small className="invalid-feedback position-absolute">
                                                    Please enter new password
                                                </small>
                                                <small className="txt-danger position-absolute d-none input-validation"></small>
                                            </div>
                                            <div className="form-group col-6 position-relative">
                                                <label htmlFor="id_input_fm_2_confirm_password">
                                                    Confirm New Password
                                                </label>
                                                <input
                                                    id="id_input_fm_2_confirm_password"
                                                    name="fm_2_confirm_password"
                                                    type="password"
                                                    maxLength={45}
                                                    className="form-control"
                                                    placeholder="ReEnter New Password"
                                                    onChange={(evt) =>
                                                        formValidation({
                                                            event: evt,
                                                            confirmPassword: true,
                                                            enteredPassword: enteredPassword.current.value,
                                                        })
                                                    }
                                                    required
                                                />
                                                <small className="invalid-feedback position-absolute">
                                                    Please ReEnter New Password
                                                </small>
                                                <small className="txt-danger position-absolute d-none input-validation"></small>
                                            </div>
                                        </div>
                                        <div className="" style={{ color: "#EA2027" }}>
                                            Password Requirements
                                            <ol className=" d-flex flex-column">
                                                <li>Minimum character length of password is 8.</li>
                                                <li>
                                                    Passowrd must consist of at least a single character
                                                    from uppercase letters, lowercase letters, digits and
                                                    special characters (!@#$%^&*-_+).
                                                </li>
                                                {/* <li>
                                                    New password should not be a password from last 5
                                                    passwords used.
                                                </li> */}
                                                {/* <li>
                                                    Password will expired in 60 days. We will send an email to you.Send an email prior 3 weeks.
                                                    We strongly suggest change your password regularly. We will notify.
                                                </li> */}
                                                <li>
                                                    New password should not be a password from last 5
                                                    passwords used.
                                                </li>
                                            </ol>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <button
                                                className="btn btn-outline-primary col-3 mx-3"
                                                type="submit"
                                            >
                                                Change password and continue
                                            </button>

                                            <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleNextClick()}>Next<ArrowRight height={"16px"} width={"16px"} /></button>
                                        </div>

                                        {showPasswordAlert && (
                                            <Alert
                                                className="alert-dismissible d-flex align-items-center"
                                                color="success"
                                                isOpen={true}
                                                toggle={() => setShowPasswordAlert(false)}
                                            >
                                                <CheckCircle style={{ width: 16, height: 16 }} />
                                                <div className="ml-2 fw-bold" style={{ marginLeft: 20 }}>
                                                    Your password has been successfully changed, now you can enter your relevant details and continue.
                                                </div>
                                            </Alert>
                                        )}

                                    </form>
                                </div>
                            ) : null}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default UserAccount;
