import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// customer hooks
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";

// utilities
import formValidation from "../../../../customHooks/useValidation";
import { ConvertMillisToDate } from "../../../../data/util/DateUtil";
import {
  // BUREAU_TRAINING_CENTER,
  CITY,
  CIVIL_STATUS,
  // COMPLEXION,
  COUNTRY,
  // CUSTOMER_TYPE,
  DISTRICT,
  GENDER,
  // GRAMA_SEVA_DIVISION,
  // INSERT_BUREAU_TRAINING_CENTER,
  INSERT_CITY,
  INSERT_CIVIL_STATUS,
  // INSERT_COMPLEXION,
  INSERT_COUNTRY,
  // INSERT_CUSTOMER_TYPE,
  INSERT_DISTRICT,
  INSERT_GENDER,
  // INSERT_GRAMA_SEVA_DIVISION,
  // INSERT_MOST_INTERESTED,
  INSERT_NATIONALITY,
  // INSERT_POLICE_STATION,
  // INSERT_RELIGION,
  INSERT_TITLE,
  LOADED,
  LOADING,
  // MOST_INTERESTED,
  NATIONALITY,
  // POLICE_STATION,
  // RELIGION,
  TITLE,
} from "../../../../constant";
import { Alert } from "reactstrap";
import { AlertTriangle, PlusCircle, RefreshCw } from "react-feather";
import ConfigDB from "../../../../data/customizer/config";
import useAccess from "../../../../customHooks/useAccess";
import { DataObject } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Personal = ({
  b_partner_id = 0,
  set_b_partner_id = null,
  set_client_id = null,
  set_org_id = null,
  nic_no = null,
  set_search_key = null,
  trigger_shortcut = true,
  set_first_name = null,
  passport_number = null,
  set_basic_tab = null
}) => {
  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [basicTab, setBasicTab] = useState("save_customer_tab_2");


  // custom hooks
  const sendRequest = useAxiosTemplates();
  const interfaceFunctionAccessibility = useAccess(loggedUser);
  const isInsertAllowed = interfaceFunctionAccessibility[0];

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshDpCity, setRefreshDpCity] = useState(true);
  // const [refreshDpPoliceStation, setRefreshDpPoliceStation] = useState(true);
  // const [refreshDpGramaSewaDivision, setRefreshDpGramaSewaDivision] =
  //   useState(true);
  const [refreshDpDistrict, setRefreshDpDistrict] = useState(false);
  const [refreshDpGender, setRefreshDpGender] = useState(false);
  const [refreshDpTitle, setRefreshDpTitle] = useState(false);
  const [refreshDpNationality, setRefreshDpNationality] = useState(false);
  // const [refreshDpReligion, setRefreshDpReligion] = useState(false);
  // const [refreshDpCustomerType, setRefreshDpCustomerType] = useState(false);
  const [refreshDpCivilStatus, setRefreshDpCivilStatus] = useState(false);
  // const [refreshDpComplexion, setRefreshDpComplexion] = useState(false);
  // const [refreshDpMostInterested, setRefreshDpMostInterested] = useState(false);
  const [refreshDpCountry, setRefreshDpCountry] = useState(false);

  // const [refreshDpBureauTrainingCenter, setRefreshDpBureauTrainingCenter] =
  //   useState(true);

  // form values
  const [genderList, setGenderList] = useState([
    { genderId: "", name: "-Select-" },
  ]);
  const [dpGenderValue, setDpGenderValue] = useState(0);

  const [civilStatusList, setCivilStatusList] = useState([
    { civilStatusId: "", name: "-Select-" },
  ]);
  const [dpCivilStatusValue, setDpCivilStatusValue] = useState(0);

  const [districtList, setDistrictList] = useState([
    { districtId: "", name: "-Select-" },
  ]);
  const [dpDistrictValue, setDpDistrictValue] = useState(0);

  const [cityList, setCityList] = useState([{ cityId: "", name: "-Select-" }]);
  const [dpCityValue, setDpCityValue] = useState(0);

  // const [gramaSewaDivisionList, setGramaSewaDivisionList] = useState([
  //   { gramaSewaDivisionId: "", name: "-Select-" },
  // ]);
  // const [dpGramaSewaDivisionValue, setDpGramaSewaDivisionValue] = useState(0);

  // const [policeStationList, setPoliceStationList] = useState([
  //   { policeStationId: "", name: "-Select-" },
  // ]);
  // const [dpPoliceStationValue, setDpPoliceStationValue] = useState(0);

  const [nationalityList, setNationalityList] = useState([
    { nationalityId: "", name: "-Select-" },
  ]);
  const [dpNationalityValue, setDpNationalityValue] = useState(0);

  const [titleList, setTitleList] = useState([
    { titleId: "", name: "-Select-" },
  ]);
  const [dpTitleValue, setDpTitleValue] = useState(0);

  // const [religionList, setReligionList] = useState([
  //   { religionId: "", name: "-Select-" },
  // ]);
  // const [dpReligionValue, setDpReligionValue] = useState(0);

  // const [customerTypeList, setCustomerTypeList] = useState([
  //   { customerTypeId: "", name: "-Select-" },
  // ]);
  // const [dpCustomerTypeValue, setDpCustomerTypeValue] = useState(0);

  // const [complexionList, setComplexionList] = useState([
  //   { complexionId: "", name: "-Select-" },
  // ]);
  // const [dpComplexionValue, setDpComplexionValue] = useState(0);

  // const [mostInterestedList, setMostInterestedList] = useState([
  //   { mostInterestedId: "", name: "-Select-" },
  // ]);
  // const [dpMostInterestedValue, setDpMostInterestedValue] = useState(0);

  const [expectedCountryList, setExpectedCountryList] = useState([
    { countryId: "", name: "-Select-" },
  ]);
  const [dpExpectedCountryValue, setDpExpectedCountryValue] = useState(0);

  const [showAlert, setShowAlert] = useState(false);

  const nicExpression = /\b[0-9]{12}\b|\b[0-9]{9}[vV]\b|\b[0-9]{9}[xX]\b/; // \b for exact match. Checks for 12 digits or 9digits with a v or V

  // const [bureauTrainingCenterList, setBureauTrainingCenterList] = useState([
  //   { bbureauTrainingCenterId: "", name: "-Select-" },
  // ]);
  // const [dpBureauTrainingCenterValue, setDpBureauTrainingCenterValue] =
  //   useState(0);

  // custom validation messages
  const customeValidationMessages = [
    "Please enter letters and . only",
    "Please enter Numbers Only",
    "Please enter digits and . only",
  ];

  const validateDateOfBirth = (year, nic) => {
    console.log("nic:" + nic);
    console.log("Year: " + year);
    const currentDate = new Date();

    // Calculate the range for the year (100 years ago to 16 years ago)
    const startYear = currentDate.getFullYear() - 100;

    const endYear = currentDate.getFullYear() - 16;

    if (year < startYear || year > endYear) {
      console.log(year);
      console.log(year > startYear);
      Swal.fire({
        icon: "error",
        title: "Request Failed",
        text: "Invalid date of birth. You must fulfill the requirement to be 18 years old or above.",
        showConfirmButton: false,
        timer: 5000,
      });
      document.getElementById("id_input_fm_1_date_of_birth").classList.remove("is-valid");
      document.getElementById("id_input_fm_1_date_of_birth").classList.add("is-invalid");
      return false;
    }


    document.getElementById("id_input_fm_1_date_of_birth").classList.remove("is-invalid");
    document.getElementById("id_input_fm_1_date_of_birth").classList.add("is-valid");
    return true;


  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Convert the date to "mm/dd/yy" format
      const month = date.getMonth() + 1; // JavaScript months are 0-indexed
      const day = date.getDate();
      const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year

      return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    }
    return inputDate; // Return the original date if it couldn't be parsed
  }

  const validateHieght = (height) => {

    console.log(height);
    const numericHeight = parseFloat(height);
    console.log(numericHeight);

    if (isNaN(numericHeight)) {
      document.getElementById("id_input_fm_1_height").classList.remove("is-invalid");
      document.getElementById("id_input_fm_1_height").classList.add("is-valid");
      return true;
    } else {
      if (numericHeight < 120 || numericHeight > 240) {
        if (numericHeight === 0) {
          console.log("correct");
          document.getElementById("id_input_fm_1_height").classList.remove("is-invalid");
          document.getElementById("id_input_fm_1_height").classList.add("is-valid");
          return true;
        } else {
          document.getElementById("id_input_fm_1_height").classList.remove("is-valid");
          document.getElementById("id_input_fm_1_height").classList.add("is-invalid");
          return false;
        }
      } else {
        console.log("float correct");
        document.getElementById("id_input_fm_1_height").classList.remove("is-invalid");
        document.getElementById("id_input_fm_1_height").classList.add("is-valid");
        return true;
      }
    }


  };

  const validateWeight = (weight) => {

    const numericWeight = parseFloat(weight);

    if (isNaN(numericWeight)) {
      document.getElementById("id_input_fm_1_weight").classList.remove("is-invalid");
      document.getElementById("id_input_fm_1_weight").classList.add("is-valid");
      return true;
    } else {
      if (numericWeight < 20 || numericWeight > 200) {
        if (numericWeight === 0) {
          document.getElementById("id_input_fm_1_weight").classList.remove("is-invalid");
          document.getElementById("id_input_fm_1_weight").classList.add("is-valid");
          return true;
        } else {
          document.getElementById("id_input_fm_1_weight").classList.remove("is-valid");
          document.getElementById("id_input_fm_1_weight").classList.add("is-invalid");
          return false;
        }
      } else {
        document.getElementById("id_input_fm_1_weight").classList.remove("is-invalid");
        document.getElementById("id_input_fm_1_weight").classList.add("is-valid");
        return true;
      }
    }

  };

  const validateNic = (nic) => {
    if (nic) {
      let year;
      let years;
      let dayText;
      let day;
      let month;
      // Year
      if (nic.length === 10) {
        year = "19" + nic.substr(0, 2);
        dayText = parseInt(nic.substr(2, 3));
      } else {
        year = nic.substr(0, 4);
        dayText = parseInt(nic.substr(4, 3));
      }
      // setting gender
      if (dayText > 500) {
        // gender = "Female";
        setDpGenderValue(8);
        dayText = dayText - 500;
      } else {
        // gender = "Male";
        setDpGenderValue(7);
      }
      // setting nic
      document.querySelector("input[name='fm_1_nic_no']").value = nic;
      // setting dob
      // eslint-disable-next-line no-unused-vars
      if ((years = year % 100 === 0 ? year % 400 === 0 : year % 4 === 0)) {
        // Loop for leap year
        if (dayText > 335) {
          day = dayText - 335;
          month = "12"; //"December";
        } else if (dayText > 305) {
          day = dayText - 305;
          month = "11"; //November";
        } else if (dayText > 274) {
          day = dayText - 274;
          month = "10"; //"October";
        } else if (dayText > 244) {
          day = dayText - 244;
          month = "09"; //"September";
        } else if (dayText > 213) {
          day = dayText - 213;
          month = "08"; //"Auguest";
        } else if (dayText > 182) {
          day = dayText - 182;
          month = "07"; //"July";
        } else if (dayText > 152) {
          day = dayText - 152;
          month = "06"; //"June";
        } else if (dayText > 121) {
          day = dayText - 121;
          month = "05"; //"May";
        } else if (dayText > 91) {
          day = dayText - 91;
          month = "04"; //"April";
        } else if (dayText > 60) {
          day = dayText - 60;
          month = "03"; //"March";
        } else if (dayText < 32) {
          month = "01"; //"January";
          day = dayText;
        } else if (dayText > 31) {
          day = dayText - 31;
          month = "02"; //"Febuary";
        }
      }
      else {
        if (dayText >= 335) {
          day = dayText - 335;
          month = "12"; //"December";
        } else if (dayText > 305) {
          day = dayText - 305;
          month = "11"; //"November";
        } else if (dayText > 274) {
          day = dayText - 274;
          month = "10"; //"October";
        } else if (dayText > 244) {
          day = dayText - 244;
          month = "09"; //"September";
        } else if (dayText >= 213) {
          day = dayText - 212;
          month = "08"; //"Auguest";
        } else if (dayText > 182) {
          day = dayText - 182;
          month = "07"; //"July";
        } else if (dayText > 152) {
          day = dayText - 152;
          month = "06"; //"June";
        } else if (dayText > 121) {
          day = dayText - 121;
          month = "05"; //"May";
        } else if (dayText > 91) {
          day = dayText - 91;
          month = "04"; //"April";
        } else if (dayText >= 60) {
          day = dayText - 59;
          month = "03"; //"March";
        } else if (dayText <= 31) {
          month = "01"; //"January";
          day = dayText;
        } else if (dayText >= 28) {
          day = dayText - 31;
          month = "02"; //"Febuary";
        }
      }
      document.querySelector(
        "input[name='fm_1_date_of_birth']"
      ).value = `${year}-${month}-${day}`;

      // console.log(document.querySelector(
      //   "input[name='fm_1_date_of_birth']"
      // ).value = `${year}`);
    }

  };

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();

    if (evt.target.checkValidity()) {

      const height = document.getElementById("id_input_fm_1_height").value;

      if (!validateHieght(height)) {
        return;
      }


      console.log(height);

      const weight = document.getElementById("id_input_fm_1_weight").value;

      if (!validateWeight(weight)) {
        return;
      }

      console.log(weight);

      const startDateInput = document.getElementById("id_input_fm_1_date_of_birth");
      // const nic = document.getElementById("id_input_fm_1_nic_no").value;

      // if (validateNic(nic)) {
      //   return;
      // }
      // validateNic(nic);

      // const selectedDate = formatDate(startDateInput.value);
      // console.log("selectedDate: "+selectedDate)
      const dateObject = new Date(startDateInput.value);
      console.log("dataObject: " + dateObject);
      const year = dateObject.getFullYear();

      if (validateDateOfBirth(year)) {
        const formData = new FormData(evt.target);


        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/checkNicExist",
            data: {
              clientId: parseInt(loggedUser.clientId),
              bpartnerId: b_partner_id,
              nicNo: formData.get("fm_1_nic_no"),
            },
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            switch (response_data.existType) {
              case 0:
                let bpartner = {
                  bpartnerId: parseInt(b_partner_id),
                  clientId: parseInt(loggedUser.clientId),
                  orgId: parseInt(loggedUser.orgId),
                  userId: parseInt(loggedUser.userId),
                  isActive: true,
                  titleId: parseInt(dpTitleValue),
                  firstName: formData.get("fm_1_first_name"),
                  lastName: formData.get("fm_1_last_name"),
                  nameWithInitial: formData.get("fm_1_name_with_initial"),
                  preferredName: formData.get("fm_1_preferred_name"),
                  dateOfBirth: formData.get("fm_1_date_of_birth"),
                  placeOfBirth: formData.get("fm_1_place_of_birth"),
                  genderId: parseInt(dpGenderValue),
                  civilStatusId: parseInt(dpCivilStatusValue),
                  districtId: parseInt(dpDistrictValue),
                  cityId: parseInt(dpCityValue),
                  // gramaSevaDivisionId: parseInt(dpGramaSewaDivisionValue),
                  // policeStationId: parseInt(dpPoliceStationValue),
                  nationalityId: parseInt(dpNationalityValue),
                  // religionId: parseInt(dpReligionValue),
                  // customerTypeId: parseInt(dpCustomerTypeValue),
                  nicNo: formData.get("fm_1_nic_no"),
                  // complexionId: parseInt(dpComplexionValue),
                  height: parseInt(formData.get("fm_1_height")),

                  weight: parseFloat(formData.get("fm_1_weight")),
                  // children: parseInt(formData.get("fm_1_children"))
                  //   ? parseInt(formData.get("fm_1_children"))
                  //   : 0,
                  // youngestChildAgeYear: parseInt(
                  //   formData.get("fm_1_youngest_child_age_month_years")
                  // )
                  //   ? parseInt(
                  //       formData.get("fm_1_youngest_child_age_month_years")
                  //     )
                  //   : 0,
                  // youngestChildAgeMonth: parseInt(
                  //   formData.get("fm_1_youngest_child_age_month_months")
                  // )
                  //   ? parseInt(
                  //       formData.get("fm_1_youngest_child_age_month_months")
                  //     )
                  //   : 0,

                  // isFingermark: formData.get("fm_1_is_fingermark") ? true : false,
                  disease: formData.get("fm_1_disease"),
                  // mostInterestedId: parseInt(dpMostInterestedValue),
                  // mostInterestedRemark: formData.get(
                  //   "fm_1_most_interested_remark"
                  // ),
                  isWorkedAbroad: formData.get("fm_1_is_worked_abroad")
                    ? true
                    : false,
                  // yearOfExperience: parseInt(
                  //   formData.get("fm_1_month_of_experience_years")
                  // )
                  //   ? parseInt(formData.get("fm_1_month_of_experience_years"))
                  //   : 0,
                  // monthOfExperience: parseInt(
                  //   formData.get("fm_1_month_of_experience_months")
                  // )
                  //   ? parseInt(formData.get("fm_1_month_of_experience_months"))
                  //   : 0,
                  // lastVisitToSriLanka: formData.get(
                  //   "fm_1_last_visit_to_sriLanka"
                  // ),
                  expectedCountryId: parseInt(dpExpectedCountryValue),
                  // bureauTrainingCenterId: parseInt(dpBureauTrainingCenterValue),
                  natureOfWork: formData.get("fm_1_nature_of_work"),
                  isPassportAvailable: formData.get("fm_1_is_passport_available")
                    ? true
                    : false,
                  isPassportToRenew: formData.get("fm_1_is_passport_to_renew")
                    ? true
                    : false,
                  // confirmedVisitDate: formData.get("fm_1_confirmed_visit_date"),
                  // isVisitToOffice: formData.get("fm_1_is_visit_to_office")
                  //   ? true
                  //   : false,
                };
                let nestedResponseData = new Promise((resolve) => {
                  const result = sendRequest({
                    url: "/customer/savePersonalTab",
                    data: bpartner,
                  });
                  resolve(result);
                });
                nestedResponseData.then((nested_reponse_data) => {
                  if (nested_reponse_data.isSuccess) {
                    // Swal.fire({
                    //   icon: "success",
                    //   title: "Request Successful",
                    //   text: b_partner_id
                    //     ? "Customer has successfully updated."
                    //     : "New Customer has successfully added.",
                    //   showConfirmButton: false,
                    //   timer: 5000,
                    // })

                    set_basic_tab();

                    // set_basic_tab="save_customer_tab_2"

                    // navigate("/customer/save/save/job");
                    // then((res) => {
                    if (passport_number === "" && loggedUser.roleName !== "NormalUser") {
                      setShowAlert(true);
                    }

                    // });
                    if (b_partner_id === 0) {
                      trigger_shortcut(true)
                    }
                    set_b_partner_id(nested_reponse_data.bpartnerId);
                    set_client_id(parseInt(loggedUser.clientId));
                    set_org_id(parseInt(loggedUser.orgId));
                    set_search_key(nested_reponse_data.searchKey);
                    set_first_name(nested_reponse_data.firstName)


                  }
                });
                break;
              case 1:
                Swal.fire({
                  icon: "error",
                  title: "Request Failed",
                  text: "NIC Exists",
                  showConfirmButton: false,
                  timer: 5000,
                });
                break;
              default:
                break;
            }
          }
        });
      }
    }
  };

  const resetForm = (evt) => {
    evt.preventDefault();
    Swal.fire({
      title: 'Are you sure you want to reset?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked 'OK', reset the form or perform other actions
        setDpTitleValue('');
        setDpGenderValue('');
        setDpCivilStatusValue('');
        setDpNationalityValue('');
        setDpExpectedCountryValue('');
        setDpDistrictValue('');
        setDpCityValue('');
        document.getElementById('id_input_fm_1_first_name').value = '';
        document.getElementById('id_input_fm_1_last_name').value = '';
        document.getElementById('id_input_fm_1_name_with_initial').value = '';
        document.getElementById('id_input_fm_1_preferred_name').value = '';
        document.getElementById('id_input_fm_1_date_of_birth').value = '';
        document.getElementById('id_input_fm_1_place_of_birth').value = '';
        document.getElementById('id_input_fm_1_nic_no').value = '';
        document.getElementById('id_input_fm_1_sa_nationality_id').value = '';
        document.getElementById('id_input_fm_1_height').value = '';
        document.getElementById('id_input_fm_1_weight').value = '';
        document.getElementById('id_input_fm_1_disease').value = '';
        document.getElementById('id_input_fm_1_nature_of_work').value = '';
        document.getElementById('id_input_fm_1_is_passport_to_renew').value = '';

        const checkWorkAbroad = document.getElementById('id_input_fm_1_is_worked_abroad');
        if (checkWorkAbroad) {
          checkWorkAbroad.checked = false;
        }

        const checkPassportAvailable = document.getElementById('id_input_fm_1_is_passport_available');
        if (checkPassportAvailable) {
          checkPassportAvailable.checked = false;
        }

        const checkPassportToRenew = document.getElementById('id_input_fm_1_is_passport_to_renew');
        if (checkPassportToRenew) {
          checkPassportToRenew.checked = false;
        }

        // setFirstName('');
        setIsValidated(false);
      }
    });
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    // console.log(
    //   `insert accessible -> ${isInsertAllowed(function_name, interface_name)}`
    // );
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });

      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    const reqBody = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      userId: loggedUser.userId,
      bpartnerId: b_partner_id,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/viewPersonalTab",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      if (response_data) {
        if (response_data.activeTitleList) {
          setTitleList([
            { titleId: "", name: "-Select-" },
            ...response_data.activeTitleList,
          ]);
        }
        if (response_data.activeGenderList) {
          setGenderList([
            { genderId: "", name: "-Select-" },
            ...response_data.activeGenderList,
          ]);
        }
        if (response_data.activeCivilStatusList) {
          setCivilStatusList([
            { civilStatusId: "", name: "-Select-" },
            ...response_data.activeCivilStatusList,
          ]);
        }
        if (response_data.activeDistrictList) {
          setDistrictList([
            { districtId: "", name: "-Select-" },
            ...response_data.activeDistrictList,
          ]);
        }
        if (response_data.activeNationalityList) {
          setNationalityList([
            { nationalityId: "", name: "-Select-" },
            ...response_data.activeNationalityList,
          ]);
        }
        // if (response_data.activeReligionList) {
        //   setReligionList([
        //     { religionId: "", name: "-Select-" },
        //     ...response_data.activeReligionList,
        //   ]);
        // }
        // if (response_data.activeCustomerTypeList) {
        //   setCustomerTypeList([
        //     { customerTypeId: "", name: "-Select-" },
        //     ...response_data.activeCustomerTypeList,
        //   ]);
        // }
        // if (response_data.activeComplexionList) {
        //   setComplexionList([
        //     { complexionId: "", name: "-Select-" },
        //     ...response_data.activeComplexionList,
        //   ]);
        // }
        // if (response_data.activeMostInterestedList) {
        //   setMostInterestedList([
        //     { mostInterestedId: "", name: "-Select-" },
        //     ...response_data.activeMostInterestedList,
        //   ]);
        // }
        if (response_data.activeExpectedCountryList) {
          setExpectedCountryList([
            { countryId: "", name: "-Select-" },
            ...response_data.activeExpectedCountryList,
          ]);
        }
        // if (response_data.activeBureauTrainingCenterList) {
        //   setBureauTrainingCenterList([
        //     { bureauTrainingCenterId: "", name: "-Select-" },
        //     ...response_data.activeBureauTrainingCenterList,
        //   ]);
        // }
        if (b_partner_id > 0) {
          if (response_data.activeCityList) {
            setCityList([
              { cityId: "", name: "-Select-" },
              ...response_data.activeBureauTrainingCenterList,
            ]);
          }
          // if (response_data.activeGramaSevaDivisionList) {
          //   setGramaSewaDivisionList([
          //     { gramaSewaDivisionId: "", name: "-Select-" },
          //     ...response_data.activeBureauTrainingCenterList,
          //   ]);
          // }
          // if (response_data.activePoliceStationList) {
          //   setPoliceStationList([
          //     { policeStationId: "", name: "-Select-" },
          //     ...response_data.activeBureauTrainingCenterList,
          //   ]);
          // }
          setDpTitleValue(parseInt(response_data.titleId));

          document.querySelector("input[name='fm_1_first_name']").value =
            response_data.firstName;
          document.querySelector("input[name='fm_1_last_name']").value =
            response_data.lastName;
          document.querySelector("input[name='fm_1_name_with_initial']").value =
            response_data.nameWithInitial;
          document.querySelector("input[name='fm_1_preferred_name']").value =
            response_data.preferredName;
          document.querySelector("input[name='fm_1_date_of_birth']").value =
            ConvertMillisToDate(response_data.dateOfBirth);
          document.querySelector("input[name='fm_1_place_of_birth']").value =
            response_data.placeOfBirth;
          setDpGenderValue(parseInt(response_data.genderId));
          setDpCivilStatusValue(parseInt(response_data.civilStatusId));
          setDpDistrictValue(parseInt(response_data.districtId));
          setDpCityValue(parseInt(response_data.cityId));
          // setDpGramaSewaDivisionValue(
          //   parseInt(response_data.gramaSevaDivisionId)
          // );
          // setDpPoliceStationValue(parseInt(response_data.policeStationId));
          setDpNationalityValue(parseInt(response_data.nationalityId));
          // setDpReligionValue(parseInt(response_data.religionId));
          // setDpCustomerTypeValue(parseInt(response_data.customerTypeId));
          document.querySelector("input[name='fm_1_nic_no']").value =
            response_data.nicNo;

          console.log(document.getElementById("id_input_fm_1_nic_no").value);
          // setDpComplexionValue(parseInt(response_data.complexionId));

          // if (response_data.weight === 0.0 || (response_data.weight >= 20 && response_data.weight <= 200)) {
          //   document.querySelector("input[name='fm_1_weight']").value =
          //     response_data.weight;
          // }
          document.querySelector("input[name='fm_1_weight']").value =
            response_data.weight;

          console.log(document.getElementById("id_input_fm_1_height").value);

          document.querySelector("input[name='fm_1_height']").value =
            response_data.height;

          console.log(document.getElementById("id_input_fm_1_weight").value);

          // document.querySelector("input[name='fm_1_weight']").value =
          //   response_data.weight;
          // document.querySelector("input[name='fm_1_children']").value =
          //   response_data.children;
          // document.querySelector(
          //   "input[name='fm_1_youngest_child_age_month_years']"
          // ).value = response_data.youngestChildAgeYear;
          // document.querySelector(
          //   "input[name='fm_1_youngest_child_age_month_months']"
          // ).value = response_data.youngestChildAgeMonth;

          // document.querySelector("input[name='fm_1_is_fingermark']").checked =
          //   response_data.isFingermark;
          document.querySelector("textarea[name='fm_1_disease']").value =
            response_data.disease;
          // setDpMostInterestedValue(parseInt(response_data.mostInterestedId));
          // document.querySelector(
          //   "textarea[name='fm_1_most_interested_remark']"
          // ).value = response_data.mostInterestedRemark;
          document.querySelector(
            "input[name='fm_1_is_worked_abroad']"
          ).checked = response_data.isWorkedAbroad;
          // document.querySelector(
          //   "input[name='fm_1_month_of_experience_years']"
          // ).value = response_data.yearOfExperience;
          // document.querySelector(
          //   "input[name='fm_1_month_of_experience_months']"
          // ).value = response_data.monthOfExperience;
          // document.querySelector(
          //   "input[name='fm_1_last_visit_to_sriLanka']"
          // ).value = ConvertMillisToDate(response_data.lastVisitToSriLanka);
          setDpExpectedCountryValue(parseInt(response_data.expectedCountryId));
          // setDpBureauTrainingCenterValue(
          //   parseInt(response_data.bureauTrainingCenterId)
          // );
          document.querySelector("textarea[name='fm_1_nature_of_work']").value =
            response_data.natureOfWork;
          document.querySelector(
            "input[name='fm_1_is_passport_available']"
          ).checked = response_data.isPassportAvailable;
          document.querySelector(
            "input[name='fm_1_is_passport_to_renew']"
          ).checked = response_data.isPassportToRenew;
          // document.querySelector(
          //   "input[name='fm_1_confirmed_visit_date']"
          // ).value = ConvertMillisToDate(response_data.confirmedVisitDate);
          // document.querySelector(
          //   "input[name='fm_1_is_visit_to_office']"
          // ).checked = response_data.isVisitToOffice;
        } else if (nic_no) {
          let year;
          let years;
          let dayText;
          let day;
          let month;
          // Year
          if (nic_no.length === 10) {
            year = "19" + nic_no.substr(0, 2);
            dayText = parseInt(nic_no.substr(2, 3));
          } else {
            year = nic_no.substr(0, 4);
            dayText = parseInt(nic_no.substr(4, 3));
          }
          // setting gender
          if (dayText > 500) {
            // gender = "Female";
            setDpGenderValue(2);
            dayText = dayText - 500;
          } else {
            // gender = "Male";
            setDpGenderValue(1);
          }
          // setting nic
          document.querySelector("input[name='fm_1_nic_no']").value = nic_no;
          // setting dob
          // eslint-disable-next-line no-unused-vars
          if ((years = year % 100 === 0 ? year % 400 === 0 : year % 4 === 0)) {
            // Loop for leap year
            if (dayText > 335) {
              day = dayText - 335;
              month = "12"; //"December";
            } else if (dayText > 305) {
              day = dayText - 305;
              month = "11"; //November";
            } else if (dayText > 274) {
              day = dayText - 274;
              month = "10"; //"October";
            } else if (dayText > 244) {
              day = dayText - 244;
              month = "09"; //"September";
            } else if (dayText > 213) {
              day = dayText - 213;
              month = "08"; //"Auguest";
            } else if (dayText > 182) {
              day = dayText - 182;
              month = "07"; //"July";
            } else if (dayText > 152) {
              day = dayText - 152;
              month = "06"; //"June";
            } else if (dayText > 121) {
              day = dayText - 121;
              month = "05"; //"May";
            } else if (dayText > 91) {
              day = dayText - 91;
              month = "04"; //"April";
            } else if (dayText > 60) {
              day = dayText - 60;
              month = "03"; //"March";
            } else if (dayText < 32) {
              month = "01"; //"January";
              day = dayText;
            } else if (dayText > 31) {
              day = dayText - 31;
              month = "02"; //"Febuary";
            }
          }
          // Loop for non-leap year
          else {
            if (dayText >= 335) {
              day = dayText - 335;
              month = "12"; //"December";
            } else if (dayText > 305) {
              day = dayText - 305;
              month = "11"; //"November";
            } else if (dayText > 274) {
              day = dayText - 274;
              month = "10"; //"October";
            } else if (dayText > 244) {
              day = dayText - 244;
              month = "09"; //"September";
            } else if (dayText >= 213) {
              day = dayText - 212;
              month = "08"; //"Auguest";
            } else if (dayText > 182) {
              day = dayText - 182;
              month = "07"; //"July";
            } else if (dayText > 152) {
              day = dayText - 152;
              month = "06"; //"June";
            } else if (dayText > 121) {
              day = dayText - 121;
              month = "05"; //"May";
            } else if (dayText > 91) {
              day = dayText - 91;
              month = "04"; //"April";
            } else if (dayText >= 60) {
              day = dayText - 59;
              month = "03"; //"March";
            } else if (dayText <= 31) {
              month = "01"; //"January";
              day = dayText;
            } else if (dayText >= 28) {
              day = dayText - 31;
              month = "02"; //"Febuary";
            }
          }
          document.querySelector(
            "input[name='fm_1_date_of_birth']"
          ).value = `${year}-${month}-${day}`;

          console.log(document.querySelector(
            "input[name='fm_1_date_of_birth']"
          ).value = `${year}`);
        }
        setIsLoaded(LOADED);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dpDistrictValue || refreshDpCity
      //  || refreshDpPoliceStation
    ) {
      const reqBody = {
        districtId: dpDistrictValue,
      };
      let responseArrayData = new Promise((resolve) => {
        const result = sendRequest({
          url: [
            "/getAllActiveCityByDistrict",
            // "/getAllActivePoliceStationByDistrict",
          ],
          data: reqBody,
          template: "REQUEST_ARRAY",
        });
        resolve(result);
      });
      responseArrayData.then((response_data_array) => {
        if (response_data_array) {
          setCityList((prevList) => [prevList[0], ...response_data_array[0]]);
          // setPoliceStationList((prevList) => [
          //   prevList[0],
          //   ...response_data_array[1],
          // ]);
          setRefreshDpCity(false);
          // setRefreshDpPoliceStation(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpDistrictValue, refreshDpCity
    // , refreshDpPoliceStation
  ]);

  // useEffect(() => {
  //   if (dpCityValue || refreshDpGramaSewaDivision) {
  //     const reqBody = {
  //       cityId: dpCityValue,
  //     };
  //     new Promise((resolve) => {
  //       const result = sendRequest({
  //         url: "/getAllActiveGramaSevaDivisionByCity",
  //         data: reqBody,
  //       });
  //       resolve(result);
  //     }).then((response_data) => {
  //       if (response_data) {
  //         setGramaSewaDivisionList((prevList) => [
  //           prevList[0],
  //           ...response_data,
  //         ]);
  //         setRefreshDpGramaSewaDivision(false);
  //       }
  //     });
  //   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dpCityValue, refreshDpGramaSewaDivision]);

  useEffect(() => {
    if (refreshDpGender) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveGender",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setGenderList([{ genderId: "", name: "-Select-" }, ...response_data]);
          setRefreshDpGender(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpGender]);
  useEffect(() => {
    if (refreshDpTitle) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveTitle",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setTitleList([{ titleId: "", name: "-Select-" }, ...response_data]);
          setRefreshDpTitle(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpTitle]);
  useEffect(() => {
    if (refreshDpNationality) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveNationality",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setNationalityList([
            { nationalityId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpNationality(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpNationality]);
  // useEffect(() => {
  //   if (refreshDpReligion) {
  //     const reqBody = {
  //       clientId: loggedUser.clientId,
  //     };
  //     new Promise((resolve) => {
  //       const result = sendRequest({
  //         url: "/getAllActiveReligion",
  //         data: reqBody,
  //       });
  //       resolve(result);
  //     }).then((response_data) => {
  //       if (response_data) {
  //         setReligionList([
  //           { religionId: "", name: "-Select-" },
  //           ...response_data,
  //         ]);
  //         setRefreshDpReligion(false);
  //       }
  //     });
  //   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [refreshDpReligion]);
  // useEffect(() => {
  //   if (refreshDpCustomerType) {
  //     const reqBody = {
  //       clientId: loggedUser.clientId,
  //     };
  //     new Promise((resolve) => {
  //       const result = sendRequest({
  //         url: "/getAllActiveCustomerType",
  //         data: reqBody,
  //       });
  //       resolve(result);
  //     }).then((response_data) => {
  //       if (response_data) {
  //         setCustomerTypeList([
  //           { customerTypeId: "", name: "-Select-" },
  //           ...response_data,
  //         ]);
  //         setRefreshDpCustomerType(false);
  //       }
  //     });
  //   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [refreshDpCustomerType]);
  useEffect(() => {
    if (refreshDpDistrict) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveDistrict",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setDistrictList([
            { districtId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpDistrict(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpDistrict]);
  useEffect(() => {
    if (refreshDpCivilStatus) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveCivilStatus",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setCivilStatusList([
            { civilStatusId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpCivilStatus(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpCivilStatus]);
  // useEffect(() => {
  //   if (refreshDpComplexion) {
  //     const reqBody = {
  //       clientId: loggedUser.clientId,
  //     };
  //     new Promise((resolve) => {
  //       const result = sendRequest({
  //         url: "/getAllActiveComplexion",
  //         data: reqBody,
  //       });
  //       resolve(result);
  //     }).then((response_data) => {
  //       if (response_data) {
  //         setComplexionList([
  //           { complexionId: "", name: "-Select-" },
  //           ...response_data,
  //         ]);
  //         setRefreshDpComplexion(false);
  //       }
  //     });
  //   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [refreshDpComplexion]);
  // useEffect(() => {
  //   if (refreshDpCivilStatus) {
  //     const reqBody = {
  //       clientId: loggedUser.clientId,
  //     };
  //     new Promise((resolve) => {
  //       const result = sendRequest({
  //         url: "/getAllActiveCivilStatus",
  //         data: reqBody,
  //       });
  //       resolve(result);
  //     }).then((response_data) => {
  //       if (response_data) {
  //         setCivilStatusList([
  //           { civilStatusId: "", name: "-Select-" },
  //           ...response_data,
  //         ]);
  //         setRefreshDpCivilStatus(false);
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [refreshDpCivilStatus]);
  // useEffect(() => {
  //   if (refreshDpMostInterested) {
  //     const reqBody = {
  //       clientId: loggedUser.clientId,
  //     };
  //     new Promise((resolve) => {
  //       const result = sendRequest({
  //         url: "/getAllActiveMostInterested",
  //         data: reqBody,
  //       });
  //       resolve(result);
  //     }).then((response_data) => {
  //       if (response_data) {
  //         setMostInterestedList([
  //           { mostInterestedId: "", name: "-Select-" },
  //           ...response_data,
  //         ]);
  //         setRefreshDpMostInterested(false);
  //       }
  //     });
  //   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [refreshDpMostInterested]);
  useEffect(() => {
    if (refreshDpCountry) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveCountry",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setExpectedCountryList([
            { countryId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpCountry(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpCountry]);
  // useEffect(() => {
  //   if (refreshDpBureauTrainingCenter) {
  //     const reqBody = {
  //       clientId: loggedUser.clientId,
  //     };
  //     new Promise((resolve) => {
  //       const result = sendRequest({
  //         url: "/getAllActiveBureauTrainingCenter",
  //         data: reqBody,
  //       });
  //       resolve(result);
  //     }).then((response_data) => {
  //       if (response_data) {
  //         setBureauTrainingCenterList([
  //           { bureauTrainingCenterId: "", name: "-Select-" },
  //           ...response_data,
  //         ]);
  //         setRefreshDpBureauTrainingCenter(false);
  //       }
  //     });
  //   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [refreshDpBureauTrainingCenter]);

  return (
    <div className="card-modified-body">

      {/* {showAlert && (
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={true}
          toggle={() => setShowAlert(false)}
        
        >
          <AlertTriangle />
          <div className="ml-2" style={{ marginLeft: 20 }}>
            We save your profile, but you need to save your passport to enable your vacancies.
          </div>
        </Alert>
      )} */}

      <form
        tabIndex={1}
        onSubmit={(evt) => submitForm(evt)}
        className={`position-relative form-1 ${isValidated ? "was-validated" : ""
          }`}
        noValidate
      >
        {isLoaded === LOADING ? (
          <div className="form-loader d-flex justify-content-center">
            <div className="loader-box">
              <div className="loader">
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="form-row mb-4">

          <div className="form-group col-12 col-sm-1 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_nationality_id"
                className="col-form-label pt-0"
              >
                Title
              </label>
              {/* <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${refreshDpTitle ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => setRefreshDpTitle(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${refreshDpCity ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => openMasterFileWindow(TITLE, INSERT_TITLE)}
              /> */}
            </div>
            <select
              id="id_input_fm_1_sa_title_id"
              name="fm_1_sa_title_id"
              className="form-select"
              onChange={(evt) => setDpTitleValue(evt.target.value)}
              value={dpTitleValue}
            >
              {titleList.length
                ? titleList.map((listItem, index) => (
                  <option value={listItem?.titleId} key={index}>
                    {listItem?.name}
                  </option>
                ))
                : null}
            </select>
          </div>

          <div className="form-group col-12 col-sm-2 mb-2 mb-sm-0 position-relative">
            <label
              htmlFor="id_input_fm_1_first_name"
              className="required-input-mark"
            >
              First Name
            </label>
            <input
              id="id_input_fm_1_first_name"
              name="fm_1_first_name"
              type="text"
              maxLength={250}
              className="form-control"
              placeholder="Enter First Name"
              autoComplete="off"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  checkExpression: /[0-9!@#$%^<=>{}]+/,
                  customMessage: customeValidationMessages[0],
                })
              }
              required
            />
            <small className="invalid-feedback position-absolute">
              Please enter first name
            </small>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          <div className="form-group col-12 col-sm-2 position-relative">
            <label htmlFor="id_input_fm_1_last_name">Last Name</label>
            <input
              id="id_input_fm_1_last_name"
              name="fm_1_last_name"
              type="text"
              maxLength={250}
              className="form-control"
              placeholder="Enter Last Name"
              autoComplete="off"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  validateName: true,
                })
              }
            />
            <small className="invalid-feedback position-absolute">
              Please enter a last name
            </small>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>

          <div className="form-group col-12 col-sm-5 mb-2 mb-sm-0 position-relative">
            <label
              htmlFor="id_input_fm_1_name_with_initial"
              className="required-input-mark"
            >
              Name With Initials
            </label>
            <input
              id="id_input_fm_1_name_with_initial"
              name="fm_1_name_with_initial"
              type="text"
              maxLength={200}
              className="form-control"
              placeholder="Enter Name with Initials"
              autoComplete="off"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  validateName: true,
                })
              }
              required
            />
            <small className="invalid-feedback position-absolute">
              Please enter name with initials
            </small>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          <div className="form-group col-12 col-sm-2 position-relative">
            <label htmlFor="id_input_fm_1_preferred_name">Preferred Name</label>
            <input
              id="id_input_fm_1_preferred_name"
              name="fm_1_preferred_name"
              type="text"
              maxLength={250}
              className="form-control"
              placeholder="Enter Preferred Name"
              autoComplete="off"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  validateName: true,
                })
              }
            />
            <small className="invalid-feedback position-absolute">
              Please enter a preferred name
            </small>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>


        </div>
        {/* <div className="form-row mb-4">
          <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 position-relative">
            <label
              htmlFor="id_input_fm_1_name_with_initial"
              className="required-input-mark"
            >
              Name with Initials
            </label>
            <input
              id="id_input_fm_1_name_with_initial"
              name="fm_1_name_with_initial"
              type="text"
              maxLength={200}
              className="form-control"
              placeholder="Enter Name with Initials"
              autoComplete="off"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  validateName: true,
                })
              }
              required
            />
            <small className="invalid-feedback position-absolute">
              Please enter Name with Initials
            </small>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          <div className="form-group col-12 col-sm-6 position-relative">
            <label htmlFor="id_input_fm_1_preferred_name">Preferred Name</label>
            <input
              id="id_input_fm_1_preferred_name"
              name="fm_1_preferred_name"
              type="text"
              maxLength={250}
              className="form-control"
              placeholder="Enter Preferred Name"
              autoComplete="off"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  validateName: true,
                })
              }
            />
            <small className="invalid-feedback position-absolute">
              Please enter a Preferred Name
            </small>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
        </div> */}
        <div className="form-row mb-4">
          <div className="form-group col-12 col-sm-3 position-relative">
            <label
              htmlFor="id_input_fm_1_nic_no"
              className="required-input-mark"
            >
              NIC
            </label>
            <input
              id="id_input_fm_1_nic_no"
              name="fm_1_nic_no"
              type="text"
              className="form-control"
              placeholder="Enter NIC Number"
              autoComplete="off"
              maxLength={20}
              required
              // onChange={(evt) =>
              //   formValidation({
              //     event: evt,
              //     validateNIC: true,
              //   })

              // }
              onChange={(evt) => {
                formValidation({
                  event: evt,
                  validateNIC: true,
                });
                const nic = document.getElementById("id_input_fm_1_nic_no").value;
                if (nic.match(nicExpression)) {
                  console.log("Populating DOB and Gender...");
                  validateNic(nic);
                }

              }}
            />
            <small className="invalid-feedback position-absolute">
              Please enter nic number
            </small>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          <div className="form-group col-12 col-sm-2 mb-2 mb-sm-0 position-relative">
            <label
              htmlFor="id_input_fm_1_date_of_birth"
              className="required-input-mark"
            >
              Date Of Birth
            </label>
            <input
              type="date"
              className="form-control"
              id="id_input_fm_1_date_of_birth"
              name="fm_1_date_of_birth"
              required
            />
            <div className="invalid-feedback position-absolute">
              Please select date of birth
            </div>
          </div>
          <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
            <label
              htmlFor="id_input_fm_1_place_of_birth"
            // className="required-input-mark"
            >
              Place Of Birth
            </label>
            <input
              id="id_input_fm_1_place_of_birth"
              name="fm_1_place_of_birth"
              type="text"
              maxLength={200}
              className="form-control"
              placeholder="Enter Place of Birth"
              autoComplete="off"
              onChange={(evt) => formValidation({ event: evt })}
            // required
            />
            <small className="invalid-feedback position-absolute">
              Please enter place of birth
            </small>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          <div className="form-group col-12 col-sm-2 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_gender_id"
                className="col-form-label pt-0 required-input-mark"
              >
                Gender
              </label>
              {/* <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${refreshDpGender ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => setRefreshDpGender(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${refreshDpCity ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => openMasterFileWindow(GENDER, INSERT_GENDER)}
              /> */}
            </div>
            <select
              id="id_input_fm_1_sa_gender_id"
              name="fm_1_sa_gender_id"
              className="form-select"
              onChange={(evt) => setDpGenderValue(evt.target.value)}
              value={dpGenderValue}
              required
            >
              {genderList.length
                ? genderList.map((listItem, index) => (
                  <option value={listItem?.genderId} key={index}>
                    {listItem?.name}
                  </option>
                ))
                : null}
            </select>
            <small className="invalid-feedback position-absolute">
              Please select a gender
            </small>
          </div>
          <div className="form-group col-12 col-sm-2 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_civil_status_id"
                className="col-form-label pt-0"
              >
                Civil Status
              </label>
              {/* <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${refreshDpCivilStatus ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => setRefreshDpCivilStatus(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${refreshDpCity ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() =>
                  openMasterFileWindow(CIVIL_STATUS, INSERT_CIVIL_STATUS)
                }
              /> */}
            </div>
            <select
              id="id_input_fm_1_sa_civil_status_id"
              name="fm_1_sa_civil_status_id"
              className="form-select"
              onChange={(evt) => setDpCivilStatusValue(evt.target.value)}
              value={dpCivilStatusValue}
            // required
            >
              {civilStatusList.length
                ? civilStatusList.map((listItem, index) => (
                  <option value={listItem?.civilStatusId} key={index}>
                    {listItem?.name}
                  </option>
                ))
                : null}
            </select>
            <small className="invalid-feedback position-absolute">
              Please select a civil status
            </small>
          </div>



        </div>
        {/* <div className="form-row mb-4"> */}
        {/* <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_nationality_id"
                className="col-form-label pt-0"
              >
                Title
              </label> */}
        {/* <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${refreshDpTitle ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => setRefreshDpTitle(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${refreshDpCity ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => openMasterFileWindow(TITLE, INSERT_TITLE)}
              /> */}
        {/* </div>
            <select
              id="id_input_fm_1_sa_title_id"
              name="fm_1_sa_title_id"
              className="form-select"
              onChange={(evt) => setDpTitleValue(evt.target.value)}
              value={dpTitleValue}
            >
              {titleList.length
                ? titleList.map((listItem, index) => (
                  <option value={listItem?.titleId} key={index}>
                    {listItem?.name}
                  </option>
                ))
                : null}
            </select>
          </div> */}
        {/* <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_nationality_id"
                className="col-form-label pt-0"
              >
                Nationality
              </label> */}
        {/* <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${refreshDpNationality ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => setRefreshDpNationality(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${refreshDpCity ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() =>
                  openMasterFileWindow(NATIONALITY, INSERT_NATIONALITY)
                }
              /> */}
        {/* </div>
            <select
              id="id_input_fm_1_sa_nationality_id"
              name="fm_1_sa_nationality_id"
              className="form-select"
              onChange={(evt) => setDpNationalityValue(evt.target.value)}
              value={dpNationalityValue}
            >
              {nationalityList.length
                ? nationalityList.map((listItem, index) => (
                  <option value={listItem?.nationalityId} key={index}>
                    {listItem?.name}
                  </option>
                ))
                : null}
            </select>
            <small className="invalid-feedback position-absolute">
              Please select a Nationality
            </small>
          </div> */}
        {/* <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_religion_id"
                className="col-form-label pt-0"
              >
                Religion
              </label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpReligion ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpReligion(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${
                  refreshDpCity ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => openMasterFileWindow(RELIGION, INSERT_RELIGION)}
              />
            </div>
            <select
              id="id_input_fm_1_sa_religion_id"
              name="fm_1_sa_religion_id"
              className="form-select"
              onChange={(evt) => setDpReligionValue(evt.target.value)}
              value={dpReligionValue}
            >
              {religionList.length
                ? religionList.map((listItem, index) => (
                    <option value={listItem?.religionId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
          </div> */}
        {/* <div className="form-group col-12 col-sm-3 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_customer_type_id"
                className="col-form-label pt-0"
              >
                Customer Type
              </label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpCustomerType ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpCustomerType(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${
                  refreshDpCity ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() =>
                  openMasterFileWindow(CUSTOMER_TYPE, INSERT_CUSTOMER_TYPE)
                }
              />
            </div>
            <select
              id="id_input_fm_1_sa_customer_type_id"
              name="fm_1_sa_customer_type_id"
              className="form-select"
              onChange={(evt) => setDpCustomerTypeValue(evt.target.value)}
              value={dpCustomerTypeValue}
              required
            >
              {customerTypeList.length
                ? customerTypeList.map((listItem, index) => (
                    <option value={listItem?.customerTypeId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
            <small className="invalid-feedback position-absolute">
              Please select a Customer Type
            </small>
          </div> */}
        {/* </div> */}
        <div className="form-row mb-4">

          <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_nationality_id"
                className="col-form-label pt-0"
              >
                Nationality
              </label>

            </div>
            <select
              id="id_input_fm_1_sa_nationality_id"
              name="fm_1_sa_nationality_id"
              className="form-select"
              onChange={(evt) => setDpNationalityValue(evt.target.value)}
              value={dpNationalityValue}
            >
              {nationalityList.length
                ? nationalityList.map((listItem, index) => (
                  <option value={listItem?.nationalityId} key={index}>
                    {listItem?.name}
                  </option>
                ))
                : null}
            </select>
            <small className="invalid-feedback position-absolute">
              Please select a nationality
            </small>
          </div>

          <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_country_id"
                className="col-form-label pt-0 required-input-mark"
              >
                Country
              </label>
              {/* <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${refreshDpCountry ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => setRefreshDpCountry(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${refreshDpCity ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => openMasterFileWindow(COUNTRY, INSERT_COUNTRY)}
              /> */}
            </div>
            <select
              id="id_input_fm_1_sa_country_id"
              name="fm_1_sa_country_id"
              className="form-select"
              onChange={(evt) => setDpExpectedCountryValue(evt.target.value)}
              value={dpExpectedCountryValue}
              required
            >
              {expectedCountryList.length
                ? expectedCountryList.map((listItem, index) => (
                  <option value={listItem?.countryId} key={index}>
                    {listItem?.name}
                  </option>
                ))
                : null}
            </select>
            <small className="invalid-feedback position-absolute">
              Please select a country
            </small>
          </div>

          <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_district_id"
                className="col-form-label pt-0"
              >
                District
              </label>
              {/* <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${refreshDpDistrict ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => setRefreshDpDistrict(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${refreshDpCity ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => openMasterFileWindow(DISTRICT, INSERT_DISTRICT)}
              /> */}
            </div>
            <select
              id="id_input_fm_1_sa_district_id"
              name="fm_1_sa_district_id"
              className="form-select"
              onChange={(evt) => setDpDistrictValue(evt.target.value)}
              value={dpDistrictValue}
            // required
            >
              {districtList.length
                ? districtList.map((listItem, index) => (
                  <option value={listItem?.districtId} key={index}>
                    {listItem?.name}
                  </option>
                ))
                : null}
            </select>
            <small className="invalid-feedback position-absolute">
              Please select a district
            </small>
          </div>
          <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_city_id"
                className="col-form-label pt-0"
              >
                City
              </label>
              {/* <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${refreshDpCity ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => setRefreshDpCity(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${refreshDpCity ? "rotate-icon" : ""
                  }`}
                height={"14px"}
                onClick={() => openMasterFileWindow(CITY, INSERT_CITY)}
              /> */}
            </div>
            <select
              id="id_input_fm_1_sa_city_id"
              name="fm_1_sa_city_id"
              className="form-select"
              onChange={(evt) => setDpCityValue(evt.target.value)}
              value={dpCityValue}
              disabled={dpDistrictValue ? false : true}
            // required
            >
              {cityList.length
                ? cityList.map((listItem, index) => (
                  <option value={listItem?.cityId} key={index}>
                    {listItem?.name}
                  </option>
                ))
                : null}
            </select>
            <small className="invalid-feedback position-absolute">
              Please select a city
            </small>
          </div>
          {/* <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_police_id"
                className="col-form-label pt-0"
              >
                Police Station
              </label>{" "}
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpPoliceStation ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpPoliceStation(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${
                  refreshDpCity ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() =>
                  openMasterFileWindow(POLICE_STATION, INSERT_POLICE_STATION)
                }
              />
            </div>
            <select
              id="id_input_fm_1_sa_police_id"
              name="fm_1_sa_police_id"
              className="form-select"
              onChange={(evt) => setDpPoliceStationValue(evt.target.value)}
              value={dpPoliceStationValue}
              disabled={dpDistrictValue ? false : true}
            >
              {policeStationList.length
                ? policeStationList.map((listItem, index) => (
                    <option value={listItem?.policeStationId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
          </div> */}
          {/* <div className="form-group col-12 col-sm-3 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_grama_seva_division_id"
                className="col-form-label pt-0"
              >
                Grama Sewa Division
              </label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpGramaSewaDivision ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpGramaSewaDivision(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${
                  refreshDpCity ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() =>
                  openMasterFileWindow(
                    GRAMA_SEVA_DIVISION,
                    INSERT_GRAMA_SEVA_DIVISION
                  )
                }
              />
            </div>
            <select
              id="id_input_fm_1_sa_grama_seva_division_id"
              name="fm_1_sa_grama_seva_division_id"
              className="form-select"
              onChange={(evt) => setDpGramaSewaDivisionValue(evt.target.value)}
              value={dpGramaSewaDivisionValue}
              disabled={dpCityValue ? false : true}
            >
              {gramaSewaDivisionList.length
                ? gramaSewaDivisionList.map((listItem, index) => (
                    <option value={listItem?.gramaSevaDivisionId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
          </div> */}
        </div>
        {/* <div className="form-row mb-4"> */}

        {/* <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 pr-1 position-relative">
            <label htmlFor="id_input_fm_1_children">Children</label>
            <input
              id="id_input_fm_1_children"
              name="fm_1_children"
              type="text"
              className="form-control"
              placeholder="No. of Children"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  validateNumber: true,
                })
              }
            />
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div> */}
        {/* <div className="form-group d-flex col-12 col-sm-3 mb-2 mb-sm-0">
            <div className="form-group position-relative">
              <label htmlFor="id_input_fm_1_youngest_child_age_month">
                Youngest Child Age
              </label>
              <div className="d-flex">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>No. of Years</Tooltip>}
                >
                  <div className="input-group pr-1">
                    <div className="input-group-prepend">
                      <div className="input-group-text">YY</div>
                    </div>
                    <input
                      id="id_input_fm_1_youngest_child_age_month"
                      name="fm_1_youngest_child_age_month_years"
                      type="text"
                      className="form-control"
                      placeholder="00"
                      onChange={(evt) =>
                        formValidation({
                          event: evt,
                          validateNumber: true,
                        })
                      }
                    />
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>No. of Months</Tooltip>}
                >
                  <div className="input-group pl-1">
                    <div className="input-group-prepend">
                      <div className="input-group-text">MM</div>
                    </div>
                    <input
                      name="fm_1_youngest_child_age_month_months"
                      type="text"
                      placeholder="00"
                      className="form-control"
                      onChange={(evt) =>
                        formValidation({
                          event: evt,
                          validateNumber: true,
                        })
                      }
                    />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </div> */}
        {/* <div className="form-group col-12 col-sm-3 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_complexion_id"
                className="col-form-label pt-0"
              >
                Complexion
              </label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpComplexion ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpComplexion(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${
                  refreshDpCity ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() =>
                  openMasterFileWindow(COMPLEXION, INSERT_COMPLEXION)
                }
              />
            </div>
            <select
              id="id_input_fm_1_sa_complexion_id"
              name="fm_1_sa_complexion_id"
              className="form-select"
              onChange={(evt) => setDpComplexionValue(evt.target.value)}
              value={dpComplexionValue}
            >
              {complexionList.length
                ? complexionList.map((listItem, index) => (
                    <option value={listItem?.complexionId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
          </div> */}
        {/* </div> */}
        <div className="form-row mb-4">
          <div className="form-group d-flex col-12 col-sm-4 mb-2 mb-sm-0">
            <div className="form-group col-6 pr-1">
              <label htmlFor="id_input_fm_1_height">Height</label>
              <div className="input-group pr-1 position-relative">
                <input
                  id="id_input_fm_1_height"
                  name="fm_1_height"
                  type="text"
                  placeholder="00.0"
                  // className="form-control is-valid"
                  className="form-control"
                  aria-describedby="basic-addon2"
                  // max={240}
                  // min={120}
                  // step={.01}
                  onChange={(evt) =>
                    formValidation({
                      event: evt,
                      validateDouble: true,
                    })
                  }
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2">
                    CM
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group col-6 pl-1">
              <label htmlFor="id_input_fm_1_weight">Weight</label>
              <div className="input-group pr-1">
                <input
                  id="id_input_fm_1_weight"
                  name="fm_1_weight"
                  type="text"
                  placeholder="00.0"
                  className="form-control"
                  aria-describedby="basic-addon2"
                  // max={200}
                  // min={20}
                  // step={.01}
                  onChange={(evt) =>
                    formValidation({
                      event: evt,
                      validateDouble: true,
                    })
                  }
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2">
                    KG
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group col-12 col-sm-8">
            <label htmlFor="id_input_fm_1_disease">Disease</label>
            <textarea
              id="id_input_fm_1_disease"
              name="fm_1_disease"
              maxLength={250}
              className="form-control"
              rows="1"
              placeholder="Enter Disease"
            ></textarea>
          </div>
        </div>
        {/* <div className="form-row mb-4">
          <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_most_interested_id"
                className="col-form-label pt-0"
              >
                Most Interested
              </label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpMostInterested ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpMostInterested(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${
                  refreshDpCity ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() =>
                  openMasterFileWindow(MOST_INTERESTED, INSERT_MOST_INTERESTED)
                }
              />
            </div>
            <select
              id="id_input_fm_1_sa_most_interested_id"
              name="fm_1_sa_most_interested_id"
              className="form-select"
              onChange={(evt) => setDpMostInterestedValue(evt.target.value)}
              value={dpMostInterestedValue}
            >
              {mostInterestedList.length
                ? mostInterestedList.map((listItem, index) => (
                    <option value={listItem?.mostInterestedId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="form-group col-12 col-sm-9">
            <label htmlFor="id_input_fm_1_most_interested_remark">
              Most Interested Remark
            </label>
            <textarea
              id="id_input_fm_1_most_interested_remark"
              name="fm_1_most_interested_remark"
              maxLength={250}
              className="form-control"
              rows="1"
              placeholder="Enter Most Interested Remark"
            ></textarea>
          </div>
        </div> */}
        <div className="form-row mb-4">
          <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 d-flex align-items-end">
            <div className="checkbox checkbox-solid-dark">
              <input
                id="id_input_fm_1_is_worked_abroad"
                name="fm_1_is_worked_abroad"
                type="checkbox"
              />
              <label
                htmlFor="id_input_fm_1_is_worked_abroad"
                className="mt-0 mb-0"
              >
                Have You Worked Abroad
              </label>
            </div>
          </div>
          {/* <div className="form-group d-flex col-12 col-sm-4 mb-2 mb-sm-0">
            <div className="form-group position-relative">
              <label htmlFor="id_input_fm_1_month_of_experience">
                Work Experience
              </label>
              <div className="d-flex">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>No. of Years</Tooltip>}
                >
                  <div className="input-group pr-1">
                    <div className="input-group-prepend">
                      <div className="input-group-text">YY</div>
                    </div>
                    <input
                      id="id_input_fm_1_month_of_experience"
                      name="fm_1_month_of_experience_years"
                      type="text"
                      maxLength="2"
                      placeholder="00"
                      className="form-control"
                      onChange={(evt) =>
                        formValidation({
                          event: evt,
                          validateNumber: true,
                        })
                      }
                    />
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>No. of Months</Tooltip>}
                >
                  <div className="input-group pl-1">
                    <div className="input-group-prepend">
                      <div className="input-group-text">MM</div>
                    </div>
                    <input
                      name="fm_1_month_of_experience_months"
                      type="text"
                      maxLength="2"
                      placeholder="00"
                      className="form-control"
                      onChange={(evt) =>
                        formValidation({
                          event: evt,
                          validateNumber: true,
                        })
                      }
                    />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </div> */}
          {/* <div className="form-group col-12 col-sm-4 position-relative">
            <label htmlFor="id_input_fm_1_last_visit_to_sriLanka">
              Last Visit to Sri Lanka
            </label>
            <input
              id="id_input_fm_1_last_visit_to_sriLanka"
              name="fm_1_last_visit_to_sriLanka"
              type="date"
              className="form-control"
            />
          </div> */}
        </div>

        {/* <div className="form-row mb-4"> */}
        {/* <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_country_id"
                className="col-form-label pt-0 required-input-mark"
              >
                Country
              </label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpCountry ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpCountry(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${
                  refreshDpCity ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => openMasterFileWindow(COUNTRY, INSERT_COUNTRY)}
              />
            </div>
            <select
              id="id_input_fm_1_sa_country_id"
              name="fm_1_sa_country_id"
              className="form-select"
              onChange={(evt) => setDpExpectedCountryValue(evt.target.value)}
              value={dpExpectedCountryValue}
              required
            >
              {expectedCountryList.length
                ? expectedCountryList.map((listItem, index) => (
                    <option value={listItem?.countryId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
          </div> */}
        {/* <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_bureau_training_center_id"
                className="col-form-label pt-0"
              >
                Bureau Training Center
              </label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpBureauTrainingCenter ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpBureauTrainingCenter(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${
                  refreshDpCity ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() =>
                  openMasterFileWindow(
                    BUREAU_TRAINING_CENTER,
                    INSERT_BUREAU_TRAINING_CENTER
                  )
                }
              />
            </div>
            <select
              id="id_input_fm_1_sa_bureau_training_center_id"
              name="fm_1_sa_bureau_training_center_id"
              className="form-select"
              onChange={(evt) =>
                setDpBureauTrainingCenterValue(evt.target.value)
              }
              value={dpBureauTrainingCenterValue}
            >
              {bureauTrainingCenterList.length
                ? bureauTrainingCenterList.map((listItem, index) => (
                    <option
                      value={listItem?.bureauTrainingCenterId}
                      key={index}
                    >
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
          </div> */}
        {/* <div className="form-group col-12 col-sm-4 position-relative">
            <label htmlFor="id_input_fm_1_confirmed_visit_date">
              Confirmed Visit Date
            </label>
            <input
              id="id_input_fm_1_confirmed_visit_date"
              name="fm_1_confirmed_visit_date"
              type="date"
              className="form-control"
            />
          </div> */}
        {/* </div> */}


        <div className="form-group mb-4">
          <label htmlFor="id_input_fm_1_nature_of_work">Nature Of Work</label>
          <textarea
            id="id_input_fm_1_nature_of_work"
            name="fm_1_nature_of_work"
            maxLength={250}
            className="form-control"
            rows="3"
            placeholder="Enter Remarks"
          ></textarea>
        </div>
        <div className="form-row mb-4">
          <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative d-flex justify-content-between flex-column">
            <label>Passport</label>
            <div className="checkbox checkbox-solid-dark">
              <input
                id="id_input_fm_1_is_passport_available"
                name="fm_1_is_passport_available"
                type="checkbox"
              />
              <label
                htmlFor="id_input_fm_1_is_passport_available"
                className="mt-0 mb-0"
              >
                Available
              </label>
            </div>
          </div>
          <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 d-flex align-items-end">
            <div className="checkbox checkbox-solid-dark">
              <input
                id="id_input_fm_1_is_passport_to_renew"
                name="fm_1_is_passport_to_renew"
                type="checkbox"
              />
              <label
                htmlFor="id_input_fm_1_is_passport_to_renew"
                className="mt-0 mb-0"
              >
                Has To Renew
              </label>
            </div>
          </div>
          {/* <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 d-flex align-items-end">
            <div className="checkbox checkbox-solid-dark">
              <input
                id="id_input_fm_1_is_fingermark"
                name="fm_1_is_fingermark"
                type="checkbox"
              />
              <label
                htmlFor="id_input_fm_1_is_fingermark"
                className="mt-0 mb-0"
              >
                Fingermark
              </label>
            </div>
          </div> */}
          {/* <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 d-flex align-items-end">
            <div className="checkbox checkbox-solid-dark">
              <input
                id="id_input_fm_1_is_visit_to_office"
                name="fm_1_is_visit_to_office"
                type="checkbox"
              />
              <label
                htmlFor="id_input_fm_1_is_visit_to_office"
                className="mt-0 mb-0"
              >
                Visit to Office
              </label>
            </div>
          </div> */}
        </div>

        {/* <div className="d-sm-flex justify-content-start">

          <button
            className="btn btn-primary col-3 col-sm-1 mb-2"
            type="submit"
          >
          Back
          </button>
        </div> */}


        <div className="d-flex justify-content-end ">

          <button
            className="btn btn-outline-primary col-md-1 col-3 px-1 mb-2 mb-sm-0"
            type="reset"
            onClick={resetForm}
          >
            Reset
          </button>
          

          <button
            className="btn btn-primary col-md-2 col-4 mx-3 mb-2 mb-sm-0"
            type="submit"
          >
            {b_partner_id ? "Next" : "Submit"}
          </button>
        </div>

        <br />

        {showAlert && (
          <Alert
            className="alert-dismissible d-flex align-items-center"
            color="warning"
            isOpen={true}
            toggle={() => setShowAlert(false)}

          >
            <AlertTriangle />
            <div className="ml-2 fw-bold" style={{ marginLeft: 20 }}>
              We save your profile, but you need to save your passport to enable your vacancies.
            </div>
          </Alert>
        )}

      </form>
    </div>
  );
};

export default Personal;
