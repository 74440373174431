// constants
import { BASE_NAME_RT } from "../../constant";

// configuration data
import ConfigDB from "../customizer/config";

export const LoadStaticURL = (static_asset_url) => {
  let url = "";
  if (ConfigDB.data.developmentMode) {
    url = static_asset_url;
  } else {
    let domain = new URL(window.location);
    let splittedUrl = static_asset_url.split(".");
    url = `${domain.origin}/${BASE_NAME_RT}${splittedUrl[1]}.${splittedUrl[2]}.${splittedUrl[3]}`;
  }
  return url;
};
