function formValidation({
  event,
  checkExpression = /[!@#$%^<=>{}]+/,
  customMessage = "",
  validateSearchKey = false,
  validatePhoneNumber = false,
  validateNumber = false,
  validateDouble = false,
  validateEmail = false,
  validateNIC = false,
  validateName = false,
  validatePassword = false,
  confirmPassword = false,
  enteredPassword = "",
  validateWeight=false,
  validateHieght=false,
}) {
  let elem = event.target;
  let siblingNodes = elem.parentElement.childNodes;
  let message = "";

  console.log("Validating User Inputs...");

  // common validation messages
  const commonValidationMessageList = [
    "Maximum Character Length Limit reached",
    "Enter Letters, Digits and _ Only",
    "Minimum Character Limit is",
    "Enter Digits Only",
    "Invalid Email Address",
    "Invalid NIC Number",
    "Enter Letters, Digits, Spaces, Dots and _ Only",
    "Invalid Password. Read the below instructions carefully and Try again.",
    "Wrong Password",
  ];

  // validation expressions
  const alphanumericExpression = /[^\w]/;
  const nameExpression = /[^\w\s.]/;
  const emailExpression = /^[a-zA-z][0-9a-zA-Z-._]+@[a-zA-Z]+[.][a-zA-z]{1,3}$/;
  const numericalExpression = /[^\d]/;
  const decimalExpression = /[^\d.]/;
  const nicExpression = /\b[0-9]{12}\b|\b[0-9]{9}[vV]\b|\b[0-9]{9}[xX]\b/; // \b for exact match. Checks for 12 digits or 9digits with a v or V
  const passwordExpressions = [/[0-9]+/, /[a-z]+/, /[A-Z]+/];
  const maxLength = elem.maxLength < elem.value.length;
  const minLength = elem.minLength > elem.value.length && elem.value.length > 0;
  const weightExpression = /^(20(\.0{1,2})?|1\d{2}(\.\d{1,2})?|200(\.0{1,2})?)$/;
  const heightExpression = /^(12[0-9]|1[3-9]\d|2[0-3]\d|240)$/;


  // change elements styles and content according to validation status
  const controlValidationMessages = (element, display, message = "") => {
    if (display) {
      if (element.classList.contains("input-validation")) {
        if (element.classList.contains("d-none")) {
          element.classList.remove("d-none");
          element.textContent = message;
        }
      }
      if (element.classList.contains("invalid-feedback")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    } else {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
          element.textContent = message;
        }
      }
      if (element.classList.contains("invalid-feedback")) {
        if (element.classList.contains("d-none")) {
          element.classList.remove("d-none");
        }
      }
    }
  };

  // set form validity as valid or invalid
  const setValid = (valid) => {
    if (!valid) {
      elem.classList.add("is-invalid");
      elem.classList.remove("is-valid");
      elem.setCustomValidity("Invalid");
    } else {
      elem.classList.add("is-valid");
      elem.classList.remove("is-invalid");
      elem.setCustomValidity("");
    }
  };

  /** Required Validation Methods */
  if (validateSearchKey) {
    if (elem.value.match(alphanumericExpression)) {
      message =
        customMessage === "" ? commonValidationMessageList[1] : customMessage;
    }
  }
  if (validatePhoneNumber || validateNumber) {
    if (elem.value.match(numericalExpression)) {
      message =
        customMessage === "" ? commonValidationMessageList[3] : customMessage;
    }
  }
  if (validateWeight) {
    console.log("Validating weight");
    if (elem.value.match(weightExpression)) {
      message =
        customMessage === "" ? commonValidationMessageList[3] : customMessage;
    }
  }
  if (validateHieght) {
    console.log("Validating weight");
    if (elem.value.match(heightExpression)) {
      message =
        customMessage === "" ? commonValidationMessageList[3] : customMessage;
    }
  }
  if (validateDouble) {
    if (elem.value.match(decimalExpression)) {
      message =
        customMessage === "" ? commonValidationMessageList[3] : customMessage;
    }
  }
  if (validateEmail) {
    if (!elem.value.match(emailExpression)) {
      message =
        customMessage === "" ? commonValidationMessageList[4] : customMessage;
    }
  }
  if (validateNIC) {
    if (!elem.value.match(nicExpression)) {
      message =
        customMessage === "" ? commonValidationMessageList[5] : customMessage;
    }
  }
  if (validateName) {
    if (elem.value.match(nameExpression)) {
      message =
        customMessage === "" ? commonValidationMessageList[6] : customMessage;
    }
  }
  if (validatePassword) {
    let results = [];
    passwordExpressions.forEach((expr) => results.push(elem.value.match(expr)));
    console.log(results);
    if (results.includes(null)) {
      message =
        customMessage === "" ? commonValidationMessageList[7] : customMessage;
    }
  }
  if (confirmPassword) {
    if (elem.value !== enteredPassword) {
      message =
        customMessage === "" ? commonValidationMessageList[8] : customMessage;
    }
  }

  /** Common Validation Methods */
  if (message === "") {
    if (minLength && elem.hasAttribute("minLength")) {
      message =
        customMessage === ""
          ? `${commonValidationMessageList[2]} ${elem.minLength}`
          : customMessage;
    } else if (maxLength && elem.hasAttribute("maxLength")) {
      message =
        customMessage === "" ? commonValidationMessageList[0] : customMessage;
    }
  }

  /** Common Validation Methods */
  if (message === "") {
    if (elem.hasAttribute("max")) {
      if (parseInt(elem.value) > elem.max)
        message =
          customMessage === ""
            ? `Enter a value less than ${elem.value}`
            : customMessage;
    }
  }

  /** Applying Set Validation */
  if (message !== "") {
    setValid(false);
    siblingNodes.forEach((node) => {
      controlValidationMessages(node, true, message);
    });
  } else {
    setValid(true);
    siblingNodes.forEach((node) => {
      controlValidationMessages(node, false);
    });
  }

  return;
}

export default formValidation;
