import React, { useEffect, useState } from "react";
import {
  EDIT_JOB_SKILL,
  INSERT_JOB_SKILL,
  JOB_SKILL,
  LOADED,
  LOADING,
} from "../../constant";
import useAccess from "../../customHooks/useAccess";
import useAxiosTemplates from "../../customHooks/useAxiosTemplates";
import Breadcrumb from "../common/breadcrumb";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const JobSkill = () => {
  // breadcrumb values
  let childLinks = [
    { value: "Master File", active: false },
    { value: "Job Skill", active: true },
  ];

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, JOB_SKILL);
  const sendRequest = useAxiosTemplates();

  // helper containers
  const [jobSkillData, setJobSkillData] = useState();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);
  const [isLoaded, setIsLoaded] = useState(LOADING);

  // form controls
  const [isValidated, setIsValidated] = useState(false);

  // form values
  const [jobList, setJobList] = useState([
    { jobCategoryId: "", name: "-Select-" },
  ]);
  const [dpJobValue, setDpJobValue] = useState(0);
  const [jobSkillId, setJobSkillId] = useState(0);

  // fetch master data
  useEffect(() => {
    if (refreshTable) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/viewJobSkill",
          data: reqBody,
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          if (response_data.activeJobCategoryList && !isValidated) {
            setJobList((prevJobList) => [
              ...prevJobList,
              ...response_data.activeJobCategoryList,
            ]);
          }
        }
      });
      setRefreshTable(false);
    }
  }, [refreshTable, loggedUser, isValidated, sendRequest]);

  useEffect(() => {
    setIsLoaded(LOADING);
    let responseData = new Promise((resolve) => {
      const result = sendRequest({
        url: "/getJobSkill",
        data: {
          clientId: parseInt(loggedUser.clientId),
          jobCategoryId: parseInt(dpJobValue),
        },
      });
      resolve(result);
    });
    responseData.then((response_data) => {
      if (response_data) {
        setJobSkillData([]);
        setJobSkillData(response_data.allSkillList);
      }
      setIsLoaded(LOADED);
    });
  }, [dpJobValue, loggedUser, sendRequest]);

  // form control functions
  const resetForm = () => {
    setIsValidated(false);
    setJobSkillId(0);
    setDpJobValue(0);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };

  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    const formData = new FormData(evt.target);
    const selectedSkills = formData
      .getAll("fm_1_skill_id")
      .map((item) => item.split("__"));
    if (selectedSkills.length) {
      let element = document.querySelector(".permission-validation");
      if (!element.classList.contains("d-none")) {
        element.classList.add("d-none");
      }
      if (evt.target.checkValidity()) {
        const skillList = [];
        console.log(selectedSkills);
        selectedSkills.forEach((item) => skillList.push(item[1]));
        let skillSet = {
          clientId: parseInt(loggedUser.clientId),
          orgId: parseInt(loggedUser.orgId),
          userId: parseInt(loggedUser.userId),
          jobCategoryId: parseInt(dpJobValue),
          allocatedSkillList: skillList,
        };
        setIsLoaded(LOADING);
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "/saveJobSkill",
            data: skillSet,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Job Skills have successfully updated.",
              showConfirmButton: false,
              timer: 5000,
            });
            // setRefreshTable(true);
            let prevJobCategory = dpJobValue;
            setDpJobValue(0);
            setDpJobValue((prevValue) => prevJobCategory);
          }
        });
      }
    } else {
      validatePermission();
    }
  };

  const validatePermission = (evt) => {
    const formData = new FormData(document.querySelector(".form-1"));
    const selectedFunctions = formData
      .getAll("fm_1_function_id")
      .map((item) => item.split("__"));
    if (selectedFunctions.length) {
      let element = document.querySelector(".permission-validation");
      if (!element.classList.contains("d-none")) {
        element.classList.add("d-none");
      }
    } else {
      let element = document.querySelector(".permission-validation");
      if (element.classList.contains("d-none")) {
        element.classList.remove("d-none");
      }
    }
  };

  const CarouselListItem = ({ list_item, class_name }) => {
    return (
      <div className={`d--sm-flex align-items-center col-12 col-md-4 ${class_name}`}>
        <div className="mx-4">
          <div className="form-group">
            <div className="checkbox checkbox-solid-dark">
              <input
                id={`id_input_fm_1_skill_id_${list_item.skillId}`}
                name="fm_1_skill_id"
                type="checkbox"
                value={`sk__${list_item.skillId}`}
                defaultChecked={list_item.isAllocated}
              />
              <label htmlFor={`id_input_fm_1_skill_id_${list_item.skillId}`}>
                {list_item.skillName}
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Breadcrumb
        parent="Dashboard"
        title="Master File"
        children={childLinks}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card position-relative">
              {isLoaded === LOADING ? (
                <div className="form-loader d-flex flex-column justify-content-center align-items-center">
                  <div className="loader-box">
                    <div className="loader">
                      <div className="line bg-primary"></div>
                      <div className="line bg-primary"></div>
                      <div className="line bg-primary"></div>
                      <div className="line bg-primary"></div>
                    </div>
                  </div>
                  <div>Loading...</div>
                </div>
              ) : null}
              <div className="card-header">
                <h5>Job Skill</h5>
                <span>Master File to map Skills with Job Categories</span>
              </div>
              <div className="card-body">
                <form
                  tabIndex={1}
                  onSubmit={(evt) => submitForm(evt)}
                  onReset={(evt) => resetForm(evt)}
                  //   onChange={(evt) => validatePermission(evt)}
                  className={`form-1 ${isValidated ? "was-validated" : ""}`}
                  noValidate
                >
                  <div className="form-row mb-4">
                  <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_job_id"
                        className="col-form-label pt-0"
                      >
                        Job Category
                      </label>
                      <select
                        id="id_input_fm_1_sa_job_id"
                        name="id_input_fm_1_sa_job_id"
                        className="form-select"
                        onChange={(evt) => setDpJobValue(evt.target.value)}
                        value={dpJobValue}
                        required
                      >
                        {jobList.length
                          ? jobList.map((listItem, index) => (
                              <option
                                value={listItem?.jobCategoryId}
                                key={index}
                              >
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a Job Category
                      </small>
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="id_input_fm_1_permission">Skills</label>
                    <div className="carousel-container p-2 form-row mx-0 mt-1 justify-content-between border rounded">
                      {jobSkillData?.length
                        ? [
                            ...Array(Math.ceil(jobSkillData.length / 3)).keys(),
                          ].map((listItem, index) => (
                            <>
                              <CarouselListItem
                                list_item={jobSkillData[index * 3]}
                                class_name={"border-end"}
                              />
                              {index * 3 + 1 !== jobSkillData.length ? (
                                <>
                                  <CarouselListItem
                                    list_item={jobSkillData[index * 3 + 1]}
                                    class_name={"border-end"}
                                  />
                                  {index * 3 + 2 !== jobSkillData.length ? (
                                    <CarouselListItem
                                      list_item={jobSkillData[index * 3 + 2]}
                                    />
                                  ) : null}
                                </>
                              ) : null}
                            </>
                          ))
                        : null}
                    </div>
                    <small className="permission-validation txt-danger position-absolute d-none input-validation">
                      Select at least 1 Skill
                    </small>
                  </div>
                  {checkIsAccessible(INSERT_JOB_SKILL) ||
                  (checkIsAccessible(EDIT_JOB_SKILL) && jobSkillId !== 0) ? (
                    <div className="d-sm-flex justify-content-end">
                      <button
                        className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                        type="submit"
                      >
                        {jobSkillId ? "Update" : "Submit"}
                      </button>
                      <button
                        className="btn btn-warning col-12 col-sm-2"
                        type="reset"
                      >
                        Reset
                      </button>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobSkill;
