import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";

// custom components
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../../common/datatable";

// customer hooks
import useAxiosTemplates from "../../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../../customHooks/useValidation";
import ConfigDB from "../../../../../data/customizer/config";
import { ArrowLeft, ArrowRight, Check, PlusCircle, RefreshCw, X } from "react-feather";
import {
  CITY,
  CUSTOMER_ADDRESS_TYPE,
  // DISTRICT,
  STATE,
  INSERT_STATE,
  INSERT_CITY,
  INSERT_CUSTOMER_ADDRESS_TYPE,
  COUNTRY,
  INSERT_COUNTRY,


  // INSERT_DISTRICT,
} from "../../../../../constant";

const Address = ({ b_partner_id = 0, client_id = 0, org_id = 0, set_basic_tab = null, set_back_tab = null }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  // const [refreshDpDistrict, setRefreshDpDistrict] = useState(false);
  const [refreshDpCity, setRefreshDpCity] = useState(true);
  const [refreshDpAddressType, setRefreshDpAddressType] = useState(false);
  const [refreshDpCountry, setRefreshDpCountry] = useState(false);
  const [refreshDpState, setRefreshDpState] = useState(false);

  // form values
  // const [districtList, setDistrictList] = useState([
  //   { districtId: "", name: "-Select-" },
  // ]);
  const [dpDistrictValue, setDpDistrictValue] = useState(0);
  const [cityList, setCityList] = useState([{ cityId: "", name: "-Select-" }]);
  const [dpCityValue, setDpCityValue] = useState(0);

  const [stateList, setstateList] = useState([
    { countryId: "", name: "-Select-" },
  ]);
  const [dpstateValue, setDpstateValue] = useState(0);

  const [countryList, setCountryList] = useState([
    { countryId: "", name: "-Select-" },
  ]);
  const [dpCountryValue, setDpCountryValue] = useState(0);

  const [addressTypeList, setAddressTypeList] = useState([
    { customerAddressTypeId: "", name: "-Select-" },
  ]);
  const [dpAddressTypeValue, setDpAddressTypeValue] = useState(0);
  const [addressId, setAddressId] = useState(0);

  const [isSuccess, setIsSuccess] = useState(false);

  // table data
  const [addressRowList, setAddressRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    // "District",
    "Address Type",
    // "City",
    // "State",
    // "Country",
    "Address",
    // {
    //   name: "Default",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <BooleanValue value={value} />;
    //     },
    //   },
    // },
    // "Remarks",
    // {
    //   name: "Active",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <BooleanValue value={value} />;
    //     },
    //   },
    // },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editAddress] = useState(() => (address_id, response_data) => {
    const editingAddress = response_data.find(
      (item) => item.addressId === address_id
    );
    setAddressId(editingAddress.addressId);
    // setDpDistrictValue(editingAddress.districtId);

    setDpCityValue(editingAddress.cityId);
    setDpstateValue(editingAddress.stateId);
    setDpCountryValue(editingAddress.countryId);
    setDpAddressTypeValue(editingAddress.customerAddressTypeId);
    document.querySelector("input[name='fm_4_address']").value =
      editingAddress.address;
    // document.querySelector("input[name='fm_4_is_default']").checked =
    //   editingAddress.isDefault;
    document.querySelector("textarea[name='fm_4_remark']").value =
      editingAddress.remark;
    // document.querySelector("input[name='fm_4_is_active']").checked =
    //   editingAddress.isActive;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    
  });
  const [deleteAddress] = useState(() => (address_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this address record!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          addressId: address_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "customer/deleteAddress",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Address record has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let address = {
        bpartnerId: parseInt(b_partner_id),
        addressId: parseInt(addressId),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        address: formData.get("fm_4_address"),
        cityId: parseInt(dpCityValue),
        stateId: parseInt(dpstateValue),
        countryId: parseInt(dpCountryValue),
        customerAddressTypeId: parseInt(dpAddressTypeValue),
        remark: formData.get("fm_4_remark"),
        isActive: true,
        // isActive: formData.get("fm_4_is_active") ? true : false,
        isDefault: formData.get("fm_4_is_default") ? true : false,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/saveAddressTab",
          data: address,
          template: "CONTROL_DATA",
        });
        resolve(result);
      });
      responseData.then((reponse_data) => {
        if (reponse_data) {
          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text: addressId
              ? "Address has successfully updated."
              : "New address has successfully added.",
            showConfirmButton: false,
            timer: 5000,
          });
          setIsLoaded(LOADING);
          setRefreshTable(true);
          setIsSuccess(true);
        }
      });
    }
  };

  const resetForm = (evt) => {
    evt.preventDefault();
    // setIsValidated(false);
    // setAddressId(0);
    // setDpDistrictValue(0);
    // setDpCityValue(0);
    // setDpstateValue(0);
    // setDpCountryValue(0);
    // setDpAddressTypeValue(0);
    // let inputFields = document.querySelectorAll(".form-control");
    // let customValidationMessages =
    //   document.querySelectorAll(".input-validation");
    // inputFields.forEach((field) => {
    //   if (field.classList.contains("is-valid")) {
    //     field.classList.remove("is-valid");
    //   } else if (field.classList.contains("is-invalid")) {
    //     field.classList.remove("is-invalid");
    //   }
    // });
    // customValidationMessages.forEach((element) => {
    //   if (element.classList.contains("input-validation")) {
    //     if (!element.classList.contains("d-none")) {
    //       element.classList.add("d-none");
    //     }
    //   }
    // });
   
    Swal.fire({
      title: 'Are you sure you want to reset?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
       
        setAddressId(0);
        setDpCityValue(0);
        setDpstateValue(0);
        setDpCountryValue(0);
        setDpAddressTypeValue(0);

       
        document.getElementById('id_input_fm_4_address').value = '';
        document.getElementById('id_input_fm_4_remark').value = '';

        setIsValidated(false);
      }
    });
   
   
    setIsSuccess(false)
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewAddressTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            // if (response_data.activeDistrictList) {
            //   setDistrictList([
            //     { districtId: "", name: "-Select-" },
            //     ...response_data.activeDistrictList,
            //   ]);
            // }
            if (response_data.activeCountryList) {
              setCountryList([
                { countryId: "", name: "-Select-" },
                ...response_data.activeCountryList,
              ]);
            }
            if (response_data.activeStateList) {
              setstateList([
                { stateId: "", name: "-Select-" },
                ...response_data.activeStateList,
              ]);
            }
            if (response_data.activeCityList) {
              setCityList([
                { cityId: "", name: "-Select-" },
                ...response_data.activeCityList,
              ]);
            }
            if (response_data.activeCustomerAddressTypeList) {
              setAddressTypeList([
                { customerAddressTypeId: "", name: "-Select-" },
                ...response_data.activeCustomerAddressTypeList,
              ]);
            }
            if (response_data.allCustomerAddressTableList) {
              let tempList = [];
              response_data.allCustomerAddressTableList.forEach((listItem) => {
                tempList.push([
                  listItem.addressId,
                  // listItem.districtName,
                  listItem.customerAddressTypeName,
                  // listItem.cityName,
                  // listItem.state,
                  // listItem.countryName,
                  listItem.address,
                  // listItem.isDefault ? "True" : "False",
                  // listItem.remark,
                  // listItem.isActive ? "True" : "False",
                  <ControlButtons
                    editMethod={() =>
                      editAddress(
                        listItem.addressId,
                        response_data.allCustomerAddressTableList
                      )
                    }
                    disableEdit={!checkIsAccessible(EDITFUNCTION)}
                    deleteMethod={() =>
                      deleteAddress(listItem.addressId, loggedUser)
                    }
                    disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                  />,
                ]);
              });
              setAddressRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    if (refreshDpCity) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllCity",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setCityList([
            { cityId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpCity(false);
        }
      });
    }

  }, [refreshDpCity]);

  // useEffect(() => {
  //   if (refreshDpDistrict) {
  //     const reqBody = {
  //       clientId: loggedUser.clientId,
  //     };
  //     new Promise((resolve) => {
  //       const result = sendRequest({
  //         url: "/getAllActiveDistrict",
  //         data: reqBody,
  //       });
  //       resolve(result);
  //     }).then((response_data) => {
  //       if (response_data) {
  //         setDistrictList([
  //           { districtId: "", name: "-Select-" },
  //           ...response_data,
  //         ]);
  //         setRefreshDpDistrict(false);
  //       }
  //     });
  //   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [refreshDpDistrict]);

  useEffect(() => {
    if (dpCountryValue || refreshDpState) {
      const reqBody = {
        countryId: dpCountryValue,
      };
      console.log(dpCountryValue);

      let responseArrayData = new Promise((resolve) => {
        const result = sendRequest({
          url:
            "customer/getAllActiveStateByCountry",

          data: reqBody,
          // template: "REQUEST_ARRAY",
        });
        resolve(result);
      });
      responseArrayData.then((response_data_array) => {
        if (response_data_array) {
          setstateList((prevList) => [prevList[0], ...response_data_array]);
          setRefreshDpState(false);

        }
      });
    }
  }, [dpCountryValue, refreshDpState]);


  useEffect(() => {
    if (refreshDpCountry) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveCountry",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setCountryList([
            { countryId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpCountry(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpCountry]);


  useEffect(() => {
    if (refreshDpAddressType) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveCustomerAddressType",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setAddressTypeList([
            { customerAddressTypeId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpAddressType(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpAddressType]);

  const closeItem = () => {
    setIsSuccess(false);
  };

  const handleChangeEditAddress = (value) => {

    if (b_partner_id > 0) {
      const reqBody = {
        bpartnerId: parseInt(b_partner_id),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        customerAddressTypeId: parseInt(value),
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/getAddressDetails",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          if (response_data.length > 0) {

            console.log(response_data);
            setAddressId(response_data[0].addressId);
            setDpAddressTypeValue(value);

            setDpCityValue(response_data[0].cityId);
            setDpstateValue(response_data[0].stateId);
            setDpCountryValue(response_data[0].countryId);
            document.querySelector("input[name='fm_4_address']").value =
            response_data[0].address;
            document.querySelector("textarea[name='fm_4_remark']").value =
            response_data[0].remark;



          } else {
            setAddressId(0);
            setDpAddressTypeValue(value);
            setDpCityValue(0);
            setDpstateValue(0);
            setDpCountryValue(0);

            document.querySelector("input[name='fm_4_address']").value = "";
            document.querySelector("textarea[name='fm_4_remark']").value = "";
          }
        }
      });

    } else {
      setIsLoaded(LOADED);
    }

  };

  const handleBackClick = () => {
    set_back_tab()
  };

  const handleNextClick = () => {
    set_basic_tab()
  };

  return (
    <>
      <div className="card-modified-body mb-4">
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-4 ${isValidated ? "was-validated" : ""
            }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-row mb-4">
            {/* <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_4_sa_district_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  District
                </label>
                <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpDistrict ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpDistrict(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(DISTRICT, INSERT_DISTRICT)
                  }
                />
              </div>
              <select
                id="id_input_fm_4_sa_district_id"
                name="fm_4_sa_district_id"
                className="form-select"
                onChange={(evt) => setDpDistrictValue(evt.target.value)}
                value={dpDistrictValue}
                required
              >
                {districtList.length
                  ? districtList.map((listItem, index) => (
                      <option value={listItem?.districtId} key={index}>
                        {listItem?.name}
                      </option>
                    ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select a Ditrict
              </small>
            </div> */}


            <div className="form-group col-12 col-sm-3 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_4_sa_customer_address_type_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Address Type
                </label>
                {/* <RefreshCw
                  className="mx-3 mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() => setRefreshDpAddressType(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(
                      CUSTOMER_ADDRESS_TYPE,
                      INSERT_CUSTOMER_ADDRESS_TYPE
                    )
                  }
                /> */}
              </div>
              <select
                id="id_input_fm_4_sa_customer_address_type_id"
                name="fm_4_sa_customer_address_type_id"
                className="form-select"
                onChange={(evt) => {
                  setDpAddressTypeValue(evt.target.value)
                  handleChangeEditAddress(evt.target.value);
                }
                }
                value={dpAddressTypeValue}
                required
              >
                {addressTypeList.length
                  ? addressTypeList.map((listItem, index) => (
                    <option
                      value={listItem?.customerAddressTypeId}
                      key={index}
                    >
                      {listItem?.name}
                    </option>
                  ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select an address type
              </small>
            </div>


            <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_1_sa_country_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Country
                </label>
                {/* <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${refreshDpCountry ? "rotate-icon" : ""
                    }`}
                  height={"14px"}
                  onClick={() => setRefreshDpCountry(true)}
                />
                <PlusCircle
                  className={`mt-1 dp-refresh-icon ${refreshDpCity ? "rotate-icon" : ""
                    }`}
                  height={"14px"}
                  onClick={() => openMasterFileWindow(COUNTRY, INSERT_COUNTRY)}
                /> */}
              </div>
              <select
                id="id_input_fm_1_sa_country_id"
                name="fm_1_sa_country_id"
                className="form-select"
                onChange={(evt) => setDpCountryValue(evt.target.value)}
                value={dpCountryValue}
                required
              >
                {countryList.length
                  ? countryList.map((listItem, index) => (
                    <option value={listItem?.countryId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select a country
              </small>
            </div>


            <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_4_sa_state_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  State
                </label>
                {/* <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpState ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpState(true)}
                />
                <PlusCircle
                   className={`mt-1 dp-refresh-icon ${
                    refreshDpCity ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(STATE, INSERT_STATE)
                  }
                /> */}
              </div>
              <select
                id="id_input_fm_4_sa_state_id"
                name="fm_4_sa_state_id"
                className="form-select"
                onChange={(evt) => setDpstateValue(evt.target.value)}
                value={dpstateValue}
                required
              >
                {stateList.length
                  ? stateList.map((listItem, index) => (
                    <option value={listItem?.stateId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select a state
              </small>
            </div>


            <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_4_sa_city_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  City
                </label>
                {/* <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${refreshDpCity ? "rotate-icon" : ""
                    }`}
                  height={"14px"}
                  onClick={() => setRefreshDpCity(true)}
                />
                <PlusCircle
                    className={`mt-1 dp-refresh-icon ${
                      refreshDpCity ? "rotate-icon" : ""
                    }`}
                  height={"14px"}
                  onClick={() => openMasterFileWindow(CITY, INSERT_CITY)}
                /> */}
              </div>
              <select
                id="id_input_fm_4_sa_city_id"
                name="fm_4_sa_city_id"
                className="form-select"
                onChange={(evt) => setDpCityValue(evt.target.value)}
                value={dpCityValue}
                // disabled={dpstateValue ? false : true}
                required
              >
                {cityList.length
                  ? cityList.map((listItem, index) => (
                    <option value={listItem?.cityId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select a city
              </small>
            </div>


            {/* <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_4_sa_state_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  State
                </label>
                <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpState ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpState(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(STATE, INSERT_STATE)
                  }
                />
              </div>
              <select
                id="id_input_fm_4_sa_state_id"
                name="fm_4_sa_state_id"
                className="form-select"
                onChange={(evt) => setDpDistrictValue(evt.target.value)}
                value={dpDistrictValue}
                required
              >
                {stateList.length
                  ? stateList.map((listItem, index) => (
                      <option value={listItem?.stateId} key={index}>
                        {listItem?.name}
                      </option>
                    ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select a State
              </small>
            </div> */}


            {/* <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_1_sa_country_id"
                className="col-form-label pt-0 required-input-mark"
              >
                Country
              </label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpCountry ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpCountry(true)}
              />
              <PlusCircle
                className={`mt-1 dp-refresh-icon ${
                  refreshDpCity ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => openMasterFileWindow(COUNTRY, INSERT_COUNTRY)}
              />
            </div>
            <select
              id="id_input_fm_1_sa_country_id"
              name="fm_1_sa_country_id"
              className="form-select"
              onChange={(evt) => setDpCountryValue(evt.target.value)}
              value={dpCountryValue}
              required
            >
              {countryList.length
                ? countryList.map((listItem, index) => (
                    <option value={listItem?.countryId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
          </div> */}



          </div>

          <div className="form-row mb-4">
            <div className="form-group col-12 col-sm-8 mb-3 mb-sm-0 position-relative">
              <label
                htmlFor="id_input_fm_4_address"
                className="required-input-mark"
              >
                Address
              </label>
              <input
                id="id_input_fm_4_address"
                name="fm_4_address"
                type="text"
                maxLength={250}
                className="form-control"
                placeholder="Enter Address Line"
                autoComplete="off"
                onChange={(evt) =>
                  formValidation({
                    event: evt,
                    checkExpression: /[^\w/,'".-]+/,
                  })
                }
                required
              />
              <div className="invalid-feedback position-absolute">
                Please enter address
              </div>
              <small className="txt-danger position-absolute d-none input-validation"></small>
            </div>
            {/* <div className="form-group col-12 col-sm-4 mx-1 mx-sm-0 d-flex align-items-end">
              <div className="checkbox checkbox-solid-dark">
                <input
                  id="id_input_fm_4_is_default"
                  name="fm_4_is_default"
                  type="checkbox"
                />
                <label htmlFor="id_input_fm_4_is_default" className="mt-0 mb-0">
                  Default
                </label>
              </div>
            </div> */}
          </div>
          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_4_remark">Remark</label>
            <textarea
              id="id_input_fm_4_remark"
              name="fm_4_remark"
              maxLength={250}
              rows={3}
              className="form-control"
              placeholder="Enter Remark"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  checkExpression: "",
                })
              }
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          {/* {(checkIsAccessible(INSERTFUNCTION) && addressId === 0) ||
            (checkIsAccessible(DEACTIVATEFUNCTION) && addressId !== 0) ? (
            <div className="form-group mb-4">
              <div className="checkbox checkbox-solid-dark col-12 col-sm-6">
                <input
                  id="id_input_fm_4_is_active"
                  name="fm_4_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_3_is_active">Active</label>
              </div>
            </div>
          ) : null} */}
          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
              (checkIsAccessible(EDITFUNCTION) && addressId !== 0) ? (
              <div className="d-sm-flex justify-content-end">

                <button
                  className="btn btn-outline-primary col-md-1 col-3 px-1 mb-2 mb-sm-0"
                  type="reset"
                >
                  Reset
                </button>
                <button
                  className="btn btn-primary col-md-2 col-4 mx-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {addressId ? "Update" : "Submit"}
                </button>

                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleBackClick()}><ArrowLeft height={"16px"} width={"16px"} />Back</button>

                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleNextClick()}>Next<ArrowRight height={"16px"} width={"16px"} /></button>

              </div>
            ) : null
          ) : null}

          <br />

          {isSuccess && (
            <div className="col-sm-12 mb-2">
              <div className="bg-primary px-3 py-3 rounded d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div
                    style={{
                      backgroundColor: "#FFEBB2",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Check
                      height={"16px"}
                      style={{ strokeWidth: 5 }}
                      className="txt-primary"
                    />
                  </div>
                  <div>
                    <div className="mb-1 d-flex align-items-center">
                      <h6
                        style={{ color: "#FFEBB2" }}
                        className="uppercase px-1 m-0"
                      >

                      </h6>
                      <div
                        style={{ backgroundColor: "#FFEBB2", height: "5px" }}
                        className="w-100 rounded ml-2"
                      ></div>
                    </div>
                    <div style={{ color: "#FFEBB2", marginLeft: 15 }} className="fw-bold">
                      Do you want to add a another address detail.
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className=" d-flex justify-content-end"                >
                    <div>
                      <button
                        type="reset"
                        className="btn 
                            rounded btn-sm "
                        style={{ backgroundColor: "#FFEBB2" }}
                      >

                        <span className="fw-bold">Add</span>
                      </button>
                    </div>
                  </div>
                  <div className="mx-2 d-flex"
                  >
                    <div style={{ marginTop: -15 }} >
                      <span
                        onClick={closeItem}
                        style={{
                          cursor: "pointer",
                        }}
                      > <X style={{ color: "#FFEBB2" }} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )}


        </form>
      </div>
      <div className="mb-4">
        <Datatable
          titleData="Address List"
          columnData={tableColumnHeaderList}
          rowData={addressRowList}
          csvFilename="Address.csv"
        />
      </div>
    </>
  );
};

export default Address;
