import {
  Users,
  Home,
  Database,
  User,
  Edit,
  Clock,
  UserCheck,
  Settings,
  FileText,
  Film,
  Briefcase
} from "react-feather";
import { CALL_REPORTS_PAGE_RT, HOME_PAGE_RT } from "../../../constant";

export const MENUITEMS = [
  {
    title: "Dashboard",
    icon: Home,
    type: "link",
    path: HOME_PAGE_RT,
    badgeType: "primary",
    active: false,
  },
  {
    title: "Customer",
    icon: Users,
    type: "sub",
    active: false,
    children: [
      { path: "/customer/new", title: "New Customer", type: "link" },
      { path: "/customer/view", title: "View Customer", type: "link" },
    ],
  },
  // {
  //   title: "Reports",
  //   icon: FileText,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { path: CALL_REPORTS_PAGE_RT, title: "Call Reports", type: "link" },
  //   ],
  // },
  {
    title: "User Management",
    icon: UserCheck,
    type: "sub",
    active: false,
    children: [
      // { path: "/usermanagement/role", title: "Role", type: "link" },
      { path: "/usermanagement/role_line", title: "RoleLine", type: "link" },
      { path: "/usermanagement/employee", title: "Employee", type: "link" },
      { path: "/usermanagement/user", title: "User", type: "link" },
      // { path: "/usermanagement/department", title: "Department", type: "link" },
      // { path: "/usermanagement/designation", title: "Designation", type: "link" },
    ],
  },
  // {
  //   title: "Configuration",
  //   icon: Settings,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { path: "/configuration/client", title: "Client", type: "link" },
  //     { path: "/configuration/interface", title: "Interface", type: "link" },
  //     { path: "/configuration/function", title: "Function", type: "link" },
  //     { path: "/configuration/photo_type", title: "Photo Type", type: "link" },
  //     { path: "/configuration/currency_allocate_to_country", title: "Currency Allocate To Country", type: "link" },
  //   ],
  // },
  {
    title: "Master Files",
    icon: Database,
    type: "sub",
    active: false,
    children: [
      // { path: "/masterfile/bureau_training_center", title: "Bureau Training Center", type: "link", },
      // { path: "/masterfile/cc1_disposition", title: "CC1 Disposition", type: "link", },
      // { path: "/masterfile/cc2_disposition", title: "CC2 Disposition", type: "link", },
      // { path: "/masterfile/city", title: "City", type: "link" },
      { path: "/masterfile/civil_status", title: "CivilStatus", type: "link" },
      // { path: "/masterfile/complexion", title: "Complexion", type: "link" },
      // { path: "/masterfile/connected_source", title: "Connected Source", type: "link", },
      // { path: "/masterfile/connected_source_line", title: "Connected Source Line", type: "link", },
      { path: "/masterfile/country", title: "Country", type: "link" },
      // { path: "/masterfile/currency", title: "Currency", type: "link" },
      // { path: "/masterfile/customer_address_type", title: "Customer Address Type", type: "link", },
      // { path: "/masterfile/customer_type", title: "Customer Type", type: "link", },
      { path: "/masterfile/district", title: "District", type: "link" },
      {
        path: "/masterfile/document_type",
        title: "DocumentType",
        type: "link",
      },
      {
        title: "Education",
        type: "sub",
        active: false,
        children: [
          {
            path: "/masterfile/education_field",
            title: "Education Stream",
            type: "link",
          },
          {
            path: "/masterfile/education_level",
            title: "Education Level",
            type: "link",
          },
          {
            path: "/masterfile/education_achievement",
            title: "Education Achievement",
            type: "link",
          },
          // {
          //   path: "/masterfile/education_grade",
          //   title: "Education Grade",
          //   type: "link",
          // },
          // {
          //   path: "/masterfile/education_subject",
          //   title: "Education Subject",
          //   type: "link",
          // },
        ],
      },
      // { path: "/masterfile/gender", title: "Gender", type: "link" },
      // { path: "/masterfile/grama_seva_division", title: "Grama Seva Division", type: "link", },
      {
        path: "/masterfile/job_category_line",
        title: "JobCategoryLine",
        type: "link",
      },
      { path: "/masterfile/job_category", title: "JobCategory", type: "link" },
      // { path: "/masterfile/job_skill", title: "Job Skill", type: "link" },
      // { path: "/masterfile/language", title: "Language", type: "link" },
      // { path: "/masterfile/most_interested", title: "Most Interested", type: "link", },
      // { path: "/masterfile/customer_mood", title: "Mood", type: "link", },
      // { path: "/masterfile/network_type", title: "Network Type", type: "link", },
      { path: "/masterfile/nationality", title: "Nationality", type: "link" },
      // { path: "/masterfile/organization", title: "Organization", type: "link" },
      // { path: "/masterfile/profile_progress_type", title: "Profile Progress Type", type: "link", },
      // { path: "/masterfile/police_station", title: "Police Station", type: "link", },
      // { path: "/masterfile/passport_type", title: "Passport Type", type: "link", },
      // { path: "/masterfile/religion", title: "Religion", type: "link" },
      // { path: "/masterfile/relationship", title: "Relationship", type: "link" },
      // { path: "/masterfile/skill", title: "Skill", type: "link" },
      // { path: "/masterfile/title", title: "Title", type: "link" },
    ],
  },
];

export const CUSTOMERMENUITEMS = [
  {
    title: "Dashboard",
    icon: Home,
    type: "link",
    path: "/",
    badgeType: "primary",
    active: true,
  },
  {
    title: "Apply Job",
    icon: Briefcase,
    type: "link",
    path: "/",
    badgeType: "primary",
    active: true,
  },
  // {
  //   title: "User Account",
  //   icon: UserCheck,
  //   type: "link",
  //   path: "/",
  //   badgeType: "primary",
  //   active: true,
  // },
  {
    title: "Customer Details",
    icon: User,
    type: "link",
    path: "/",
    badgeType: "primary",
    active: false,
  },
  {
    title: "Edit Customer",
    icon: Edit,
    type: "link",
    path: "/",
    badgeType: "primary",
    active: false,
  },
  {
    title: "User Account",
    icon: UserCheck,
    type: "link",
    path: "/",
    badgeType: "primary",
    active: true,
  },

  {
    title: "Interview Video",
    icon: Film,
    type: "link",
    path: "/",
    badgeType: "primary",
    active: false,
  },

  // {
  //   title: "Application Generator",
  //   icon: FileText,
  //   type: "link",
  //   path: "/",
  //   badgeType: "primary",
  //   active: false,
  // },
  // {
  //   title: "Customer History",
  //   icon: Clock,
  //   type: "link",
  //   path: "/",
  //   badgeType: "primary",
  //   active: false,
  // },
];
