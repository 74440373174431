import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";

import default_avatar from "../../../assets/images/user/default_avatar.png";
import { Link, useParams } from "react-router-dom";
import { Edit } from "react-feather";
import ConfigDB from "../../../data/customizer/config";
import image from "../../../assets/images/user.png"

const UserPanel = () => {
  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);
  const customerData = useSelector((content) => content.CustomerReducer);
  const { customer_id } = useParams();

  const srcNormalUser = customer_id !== undefined && loggedUser.profilePictureDocumentPath === undefined
  ? image
  : 
  image
  // `${ConfigDB.data.fileServerUrl}${loggedUser.profilePictureDocumentPath}`;

  const srcEdgeAdmin = customer_id === undefined && loggedUser.profilePictureDocumentPath === undefined
  ? image
  : 
  image
  // `${ConfigDB.data.fileServerUrl}${loggedUser.profilePictureDocumentPath}`;


  const src = loggedUser.roleName === "NormalUser" ? srcNormalUser : srcEdgeAdmin;


  return (
    <Fragment>
      <div className="sidebar-user text-center">
        <div>
          <img
            className="img-60 rounded-circle lazyloaded blur-up"

            // Hosted Image Url
            //src={customer_id !== undefined ? `${ConfigDB.data.fileServerUrl}${customerData.profilePicture}` : `${process.env.PUBLIC_URL}/DeshaktheeERP${default_avatar}`}

            src={

              // customer_id !== undefined && loggedUser.profilePictureDocumentPath == undefined && loggedUser.roleName == 'NormalUser'
              //   ? 
              // `${ConfigDB.data.fileServerUrl}${customerData.profilePicture}`
              // image
                       src
              // :
              //  image
              //  `${ConfigDB.data.fileServerUrl}${loggedUser.profilePictureDocumentPath}`

            }
            alt="#"
          />
          <div className="profile-edit">
            {loggedUser.roleName === 'EdgeAdmin' ? (
              <Link to={`/user`}>
                <Edit />
              </Link>
            ) : null}
          </div>
        </div>
        <h6 className="mt-3 f-14">
          {customer_id === undefined
            ? loggedUser.employeeName
            :
            // customerData.name?.split(" ")[0]
            ""
          }
        </h6>
        {customer_id === undefined ? <p>{loggedUser.roleName}</p>
         : <h6>{loggedUser.employeeName?.split(" ")[0]}</h6>}
         
          {/* <p>{customer_id === undefined ? loggedUser.roleName
         :  customerData.name?.split(" ")[0]}.</p> */}

        {/* <div>
          {customer_id != undefined ? (
            <div>
            <h6>Welcome</h6>
            <h6>{customerData.name?.split(" ")[0]}</h6>
            </div>
          ) : (
            <h6>{customerData.name?.split(" ")[0]}</h6>
          )}
        </div> */}

      </div>
    </Fragment>
  );
};

export default UserPanel;
