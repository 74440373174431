import { useCallback, useState } from "react";

const useAccess = (logged_user, interface_name = "") => {
  const [interfaceList] = useState(
    logged_user.roleList.map((item) => item.interfaceSearchKey)
  );
  const [interfaceFunctions] = useState(
    logged_user.roleList.find(
      (item) => item?.interfaceSearchKey === interface_name
    )?.functionList
  );
  const [organizationList] = useState(logged_user.activeOrgRoleList);

  //   console.log("Mounting Access...");
  const checkAccess = useCallback(
    (function_name, checking_interface_name = "") => {
      if (function_name !== "") {
        // console.log(`Checking Access... for ${function_name}`);
        /** Return True if accessible */
        if (interfaceFunctions !== undefined) {
          const result = interfaceFunctions.find(
            (item) => item.functionSearchKey === function_name
          );
          // console.log(result);
          return result !== undefined;
        } else {
          // return true
          if (checking_interface_name !== "") {
            const functions = logged_user.roleList.find(
              (item) => item?.interfaceSearchKey === checking_interface_name
            )?.functionList;
            if (functions !== undefined) {
              const result = functions.find(
                (item) => item.functionSearchKey === function_name
              );
              // console.log(result);
              return result !== undefined;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      }
      return false;
    },
    [interfaceFunctions]
  );

  const checkOrgAccess = useCallback(() => {
    return organizationList.length > 1;
  }, [organizationList]);

  const checkInterfaceAccess = useCallback(
    (interface_name) => {
      if (interface_name !== "") {
        // console.log(`Checking Interface Access... for ${interface_name}`);
        /** Return True if accessible */
        const result = interfaceList
          .map((item) => item.toLocaleLowerCase())
          .includes(interface_name.toLocaleLowerCase());
        // console.log(interfaceList);
        // console.log(result);
        return result;
      }
      return false;
    },
    [interfaceList]
  );

  return [checkAccess, checkOrgAccess, checkInterfaceAccess];
};

export default useAccess;
