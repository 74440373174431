//CUSTOMIZER 
export const ADD_COSTOMIZER ="ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS ="ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";

//USER
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const STORE_ROLE_LIST = "STORE_ROLE_LIST"
export const SESSION_EXPIRED = "SESSION_EXPIRED"
export const CHANGE_ORG = "CHANGE_ORGANIZATION"

//CUSTOMER
export const STORE_CUSTOMER = "STORE_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const REMOVE_CUSTOMER = "REMOVE_CUSTOMER";
