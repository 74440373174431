import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
// images
import default_avatar from "../../assets/images/user/default_avatar.png";
import formValidation from "../../customHooks/useValidation";
import DetailRowLayout from "../common/DetailRowLayout";
import { LOADED, LOADING } from "../../constant";
import { useSelector } from "react-redux";
import useAxiosTemplates from "../../customHooks/useAxiosTemplates";
import { ConvertMillisToDate } from "../../data/util/DateUtil";
import Swal from "sweetalert2";
import ConfigDB from "../../data/customizer/config";
import { LoadStaticURL } from "../../data/util/LoadStaticURL";

const Index = () => {
  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isF2Validated, setIsF2Validated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [customerData, setCustomerData] = useState(undefined);
  // const [userData, setUserData] = useState(undefined);

  // form values
  const [profilePicture, setProfilePicture] = useState({
    base64Value: "",
    extension: "",
    changed: false,
    url: "",
  });
  const enteredPassword = useRef("");

  // breadcrumb values
  let childLinks = [
    { value: "User Profile", active: false },
    { value: "Edit", active: true },
  ];

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const sendRequest = useAxiosTemplates();

  useEffect(() => {
    const reqBody = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      userId: loggedUser.userId,
    };
    let responseData = new Promise((resolve) => {
      const result = sendRequest({
        url: "/viewEmployee",
        data: reqBody,
      });
      resolve(result);
    });
    responseData.then((response_data) => {
      if (response_data) {
        if (response_data.allEmployeeList) {
          let selectedEmployee;
          setCustomerData(
            (selectedEmployee = response_data.allEmployeeList.find(
              (item) => item.bpartnerId === loggedUser.bpartnerId
            ))
          );
          setProfilePicture({
            changed: false,
            base64Value: "",
            extension: "",
            url: selectedEmployee.profilePicturePath,
          });
          setIsLoaded(LOADED);
        }
      }
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const submitForm = (evt) => {
    setIsValidated(true);
    console.log(profilePicture);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let employee = {
        clientId: customerData.clientId,
        orgId: customerData.orgId,
        userId: parseInt(loggedUser.userId),
        bpartnerId: customerData.bpartnerId,
        isActive: customerData.isActive,
        firstName: customerData.firstName,
        lastName: customerData.lastName,
        titleId: customerData.titleId,
        genderId: customerData.genderId,
        nicNo: customerData.nicNo,
        dateOfBirth: customerData.dateOfBirth,
        contactNumber: customerData.contactNumber,
        email: customerData.email,
        departmentId: customerData.departmentId,
        designationId: customerData.designationId,
        nationalityId: customerData.nationalityId,
        countryId: customerData.countryId,
        address: customerData.address === null ? "" : customerData.address,
        cityId: customerData.cityId,
        profilePicture: profilePicture.base64Value, //Base64 file
        isProfilePictureChange: profilePicture.changed,
        profilePictureNameExtension: profilePicture.extension,
        description: formData.get("fm_1_description"),
        remark: customerData.remark,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/saveEmployee",
          data: employee,
          template: "CONTROL_DATA",
        });
        resolve(result);
      });
      responseData.then((reponse_data) => {
        if (reponse_data) {
          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text: "Your Profile has successfully updated.",
            showConfirmButton: false,
            timer: 5000,
          });
        }
      });
      // let user = {
      //   clientId: parseInt(dpClientValue),
      //   orgId: parseInt(dpOrganizationValue),
      //   loggedUserId: parseInt(loggedUser.userId),
      //   userId: userId,
      //   isPasswordChanged: true,
      //   password: formData.get("fm_2_confirm_password"),
      // };
    }
  };
  const submitNewPassword = (evt) => {
    setIsF2Validated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      const loginDetails = {
        userId: loggedUser.userId,
        password: formData.get("fm_2_current_password"),
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/checkPasswordValid",
          data: loginDetails,
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          if (response_data.isPasswordMatch) {
            let user = {
              clientId: loggedUser.clientId,
              orgId: loggedUser.orgId,
              loggedUserId: parseInt(loggedUser.userId),
              userId: loggedUser.userId,
              isPasswordChanged: true,
              password: formData.get("fm_2_confirm_password"),
            };
            let nestedRresponseData = new Promise((resolve) => {
              const result = sendRequest({
                url: "/saveUser",
                data: user,
                template: "CONTROL_DATA",
              });
              resolve(result);
            });
            nestedRresponseData.then((nested_reponse_data) => {
              if (nested_reponse_data) {
                Swal.fire({
                  icon: "success",
                  title: "Request Successful",
                  text: "User Passsword has successfully updated.",
                  showConfirmButton: false,
                  timer: 5000,
                });
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title:"Attemp Failed",
              text: "Wrong Password. Re-Enter your Current Password.",
              showConfirmButton: false,
              timer: 5000,
            });
          }
        }
      });
    }
  };
  const readUrl = (event) => {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setProfilePicture({
        changed: true,
        base64Value: reader.result,
        extension: mimeType.replace(/image\//, "."),
        url: "",
      });
    };
  };

  return (
    <>
      <Breadcrumb parent="Dashboard" title="User" children={childLinks} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Profile</h5>
                <span>Edit your profile details</span>
              </div>
              <div className="card-body border-bottom">
                <DetailRowLayout
                  display={isLoaded === LOADED}
                  labels={["First Name", "Last Name"]}
                  data={
                    isLoaded === LOADED
                      ? [customerData.firstName, customerData.lastName]
                      : null
                  }
                />
                <DetailRowLayout
                  display={isLoaded === LOADED}
                  labels={["Date of Birth", "Gender", "NIC", "Title"]}
                  data={
                    isLoaded === LOADED
                      ? [
                          customerData.dateOfBirth === null
                            ? ""
                            : ConvertMillisToDate(customerData.dateOfBirth),
                          customerData.genderName,
                          customerData.nicNo,
                          customerData.titleName,
                        ]
                      : null
                  }
                />
                <DetailRowLayout
                  display={isLoaded === LOADED}
                  labels={["Contact Number", "Email"]}
                  data={
                    isLoaded === LOADED
                      ? [customerData.contactNumber, customerData.email]
                      : null
                  }
                />
                <DetailRowLayout
                  display={isLoaded === LOADED}
                  labels={["Department", "Designation", "Nationality"]}
                  data={
                    isLoaded === LOADED
                      ? [
                          customerData.departmentName,
                          customerData.designationName,
                          customerData.nationalityName,
                        ]
                      : null
                  }
                />
                <DetailRowLayout
                  display={isLoaded === LOADED}
                  labels={["Address"]}
                  data={isLoaded === LOADED ? [customerData.address] : null}
                />
                <DetailRowLayout
                  display={isLoaded === LOADED}
                  labels={["Country", "City"]}
                  data={
                    isLoaded === LOADED
                      ? [customerData.countryName, customerData.cityName]
                      : null
                  }
                />
                <DetailRowLayout
                  display={isLoaded === LOADED}
                  labels={["Remark"]}
                  data={isLoaded === LOADED ? [customerData.remark] : null}
                />
                {isLoaded === LOADED ? (
                  <form
                    tabIndex={1}
                    onSubmit={(evt) => submitForm(evt)}
                    className={`form-1 mt-4 ${
                      isValidated ? "was-validated" : ""
                    }`}
                    noValidate
                  >
                    <div className="form-row mb-4">
                      <div className="form-group col-3">
                        <div className="user-image ">
                          <div className="avatar">
                            <img
                              className="pro"
                              alt=""
                              src={
                                profilePicture.url
                                  ? `${ConfigDB.data.fileServerUrl}${profilePicture.url}`
                                  : profilePicture.base64Value
                                  ? profilePicture.base64Value
                                  : LoadStaticURL(default_avatar)
                              }
                              data-intro="This is Profile image"
                            />
                            <div className="icon-wrapper">
                              <i
                                className="icofont icofont-pencil-alt-5"
                                data-intro="Change Profile image here"
                              >
                                <input
                                  accept="image/*"
                                  className="pencil"
                                  type="file"
                                  onChange={(e) => readUrl(e)}
                                />
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-9">
                        <label htmlFor="id_input_fm_1_description">
                          Description
                        </label>
                        <textarea
                          id="id_input_fm_1_description"
                          name="fm_1_description"
                          maxLength={250}
                          className="form-control"
                          rows="3"
                          placeholder="Enter Description"
                          onChange={(evt) =>
                            formValidation({ event: evt, checkExpression: "" })
                          }
                          defaultValue={customerData.description}
                        ></textarea>
                        <small className="txt-danger position-absolute d-none input-validation"></small>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary col-2 mx-3"
                        type="submit"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                ) : null}
              </div>
              {isLoaded === LOADED ? (
                <div className="card-body">
                  <form
                    onSubmit={(evt) => submitNewPassword(evt)}
                    className={`form-2 ${isF2Validated ? "was-validated" : ""}`}
                    noValidate
                  >
                    <div className={`form-row mb-4`}>
                      <div className="form-group col-6 position-relative">
                        <label htmlFor="id_input_fm_2_current_password">
                          Current Password
                        </label>
                        <input
                          id="id_input_fm_2_current_password"
                          name="fm_2_current_password"
                          type="password"
                          autoComplete="off"
                          maxLength={45}
                          className="form-control"
                          placeholder="Current Password"
                          onChange={(evt) =>
                            formValidation({
                              event: evt,
                              validatePassword: true,
                            })
                          }
                          required
                        />
                        <small className="invalid-feedback position-absolute">
                          Please enter current Password
                        </small>
                        <small className="txt-danger position-absolute d-none input-validation"></small>
                      </div>
                    </div>
                    <div className={`form-row mb-4`}>
                      <div className="form-group col-6 position-relative">
                        <label htmlFor="id_input_fm_2_password">Password</label>
                        <input
                          ref={enteredPassword}
                          id="id_input_fm_2_password"
                          name="fm_2_password"
                          type="password"
                          autoComplete="off"
                          maxLength={45}
                          minLength={8}
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={(evt) =>
                            formValidation({
                              event: evt,
                              validatePassword: true,
                            })
                          }
                          required
                        />
                        <small className="invalid-feedback position-absolute">
                          Please enter a Password
                        </small>
                        <small className="txt-danger position-absolute d-none input-validation"></small>
                      </div>
                      <div className="form-group col-6 position-relative">
                        <label htmlFor="id_input_fm_2_confirm_password">
                          Confirm Password
                        </label>
                        <input
                          id="id_input_fm_2_confirm_password"
                          name="fm_2_confirm_password"
                          type="password"
                          autoComplete="off"
                          maxLength={45}
                          className="form-control"
                          placeholder="Re-Enter Password"
                          onChange={(evt) =>
                            formValidation({
                              event: evt,
                              confirmPassword: true,
                              enteredPassword: enteredPassword.current.value,
                            })
                          }
                          required
                        />
                        <small className="invalid-feedback position-absolute">
                          Please re-enter Password
                        </small>
                        <small className="txt-danger position-absolute d-none input-validation"></small>
                      </div>
                    </div>
                    <div className="text-warning ">
                      Passsword Requirements
                      <ol className=" d-flex flex-column">
                        <li>Minimum character length of password is 8.</li>
                        <li>
                          Passowrd must consist of at least a single character
                          from Uppercase Letters, Lowercase Letters, Digits and
                          Special Characters (!@#$%^&*-_+).
                        </li>
                        <li>
                          New password should not be a password from last 5
                          passwords used.
                        </li>
                        <li>
                          Password will be expired in 60 days. You must changed
                          the password before expiration.
                        </li>
                      </ol>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-outline-primary col-2 mx-3"
                        type="submit"
                      >
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
