import React, { useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { LOADED, LOADING } from "../../constant";
import useAxiosTemplates from "../../customHooks/useAxiosTemplates";
import { ConvertMillisToDate } from "../../data/util/DateUtil";
import Breadcrumb from "../common/breadcrumb";
import Datatable, { ControlButtons } from "../common/datatable";
import CircleIcon from "@mui/icons-material/Circle";
import ConfigDB from "../../data/customizer/config";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const CallReports = () => {
  const CONFIRMED_REPORTS = 1;
  const ALL_CALL_REPORTS = 2;
  const REJECTED_REPORTS = 3;

  // breadcrumb values
  let childLinks = [
    { value: "Reports", active: false },
    { value: "Call Reports", active: true },
  ];
  const dispatch = useDispatch();

  const sendRequest = useAxiosTemplates();

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  const [reportType, setReportType] = useState(0);
  const [reportTypeList] = useState([
    CONFIRMED_REPORTS,
    ALL_CALL_REPORTS,
    REJECTED_REPORTS,
  ]);
  const [reportTitle, setReportTitle] = useState("Confirmed Calls");
  const [dpBranchValue, setDpBranchValue] = useState(0);
  const [branchList] = useState([
    { orgId: -1, name: "All" },
    ...loggedUser.activeOrgRoleList,
  ]);

  const [isLoading, setIsLoading] = useState(LOADED);

  const startDate = useRef();
  const endDate = useRef();

  // table data
  const [reportRowList, setReportRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "BpartnerId",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: "ID",
      options: {
        display: false,
        download: true,
        filter: false,
        viewColumns: true,
      },
    },
    {
      name: "Number",
      options: {
        display: true,
        download: true,
        filter: false,
        viewColumns: true,
      },
    },
    {
      name: "BranchId",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: "Branch Name",
      options: {
        display: false,
        download: true,
        filter: false,
        viewColumns: true,
      },
    },
    {
      name: "First Name",
      options: {
        display: true,
        download: true,
        filter: false,
        viewColumns: true,
      },
    },
    {
      name: "Last Name",
      options: {
        display: false,
        download: true,
        filter: false,
        viewColumns: true,
      },
    },
    {
      name: "Preferred Name",
      options: {
        display: false,
        download: true,
        filter: false,
        viewColumns: true,
      },
    },
    {
      name: "Birth Date",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Place of Birth",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Gender",
      options: {
        display: true,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Civil Status",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "District",
      options: {
        display: true,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "City",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Grama Seva Division",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Police Station",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Nationality",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Religion",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "NIC",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Children",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Youngest Child Age",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Years of Experience",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Expected Country",
      options: {
        display: true,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Bureau Training Center",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Visit Date",
      options: {
        display: true,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Job",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    {
      name: "Disposition",
      options: {
        display: reportType === ALL_CALL_REPORTS ? true : false,
        download: reportType === ALL_CALL_REPORTS ? true : false,
        filter: reportType === ALL_CALL_REPORTS ? true : false,
        viewColumns: reportType === ALL_CALL_REPORTS ? true : false,
      },
    },
    {
      name: "Remark",
      options: {
        display: false,
        download: true,
        filter: true,
        viewColumns: true,
      },
    },
    // {
    //   name: "Call",
    //   options: {
    //     display: true,
    //     download: false,
    //     filter: false,
    //     viewColumns: true,
    //   },
    // },
  ];
  // table functions
  const onRowClick = (rowData) => {
    dispatch({
      type: "STORE_CUSTOMER",
      payload: {
        bpartnerId: rowData[0],
        clientId: loggedUser.clientId,
        orgId: rowData[3],
        searchKey: rowData[1],
      },
    });
    window.open(
      `${ConfigDB.data.hosted_url}/customer/view/${rowData[1]}/`,
      "_blank"
    );
  };
  const onDownload = (buildHead, buildBody, columns, data) => {
    const columnData =
      columns
        .reduce(
          (so_far, column) =>
            column.download ? so_far + column.name + "," : so_far,
          ""
        )
        .slice(0, -1) + "\r\n";
    const rowData = data
      .reduce(
        (so_far, row) =>
          so_far +
          row.data
            .filter((_, index) => columns[index].download)
            .map((column_data) =>
              typeof column_data === "object"
                ? IsPhoneNumber(column_data?.props?.value)
                  ? `${column_data?.props?.value?.slice(
                      0,
                      3
                    )}----${column_data?.props?.value?.slice(
                      column_data.props.value.length - 3,
                      column_data.props.value.length
                    )}`
                  : column_data?.props?.value
                : column_data
            )
            .join(",") +
          "\r\n",
        ""
      )
      .trim();
    return `${columnData}${rowData}\r\n`;
  };

  const IsPhoneNumber = (phone_number) => {
    if (phone_number) {
      return !phone_number.match(/[^\d]/) && phone_number.length >= 9;
    }
  };

  const ProtectedNumber = ({ value }) => {
    return (
      <div className="txt-dark pointer" style={{ minWidth: "max-content" }}>
        {value.slice(0, 3)}
        <CircleIcon className="text-secondary" sx={{ fontSize: 10 }} />
        <CircleIcon className="text-secondary" sx={{ fontSize: 10 }} />
        <CircleIcon className="text-secondary" sx={{ fontSize: 10 }} />
        <CircleIcon className="text-secondary" sx={{ fontSize: 10 }} />
        {value.slice(value.length - 3, value.length)}
      </div>
    );
  };

  const SingleLineValue = ({ value }) => {
    return (
      <div style={{ textTransform: "capitalize", minWidth: "max-content" }}>
        {value}
      </div>
    );
  };

  const changeReportType = (elem) => {
    if (elem.checked) {
      reportTypeList.forEach((report_item) => {
        if (report_item !== parseInt(elem.value))
          document.querySelector(
            `#id_input_filter_${report_item}`
          ).checked = false;
      });
      setReportType(parseInt(elem.value));
    } else {
      setReportType(0);
    }
  };

  const generateReport = () => {
    let responseData;
    if (startDate.current.value === "") {
      startDate.current.classList.add("is-invalid");
    } else if (endDate.current.value === "") {
      startDate.current.classList.remove("is-invalid");
      endDate.current.classList.add("is-invalid");
    } else if (reportType === 0) {
      startDate.current.classList.remove("is-invalid");
      endDate.current.classList.remove("is-invalid");
      reportTypeList.forEach((report_item) => {
        document
          .querySelector(`#id_input_filter_${report_item}`)
          .nextElementSibling.classList.add("text-danger");
      });
    } else {
      startDate.current.classList.remove("is-invalid");
      endDate.current.classList.remove("is-invalid");
      reportTypeList.forEach((report_item) => {
        document
          .querySelector(`#id_input_filter_${report_item}`)
          .nextElementSibling.classList.remove("text-danger");
      });
      setIsLoading(LOADING);
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: parseInt(dpBranchValue), //Client wise, orgId = -1
        startDate: startDate.current.value,
        endDate: endDate.current.value,
      };
      switch (reportType) {
        case ALL_CALL_REPORTS:
          responseData = new Promise((resolve) => {
            const result = sendRequest({
              url: "/report/viewAllStatusCustomerProfileDetail",
              data: reqBody,
            });
            resolve(result);
          });
          break;
        case CONFIRMED_REPORTS:
          responseData = new Promise((resolve) => {
            const result = sendRequest({
              url: "/report/viewConfirmedCustomerProfileDetail",
              data: reqBody,
            });
            resolve(result);
          });
          break;
        case REJECTED_REPORTS:
          responseData = new Promise((resolve) => {
            const result = sendRequest({
              url: "/report/viewRejectedCustomerProfileDetail",
              data: reqBody,
            });
            resolve(result);
          });
          break;
        default:
          break;
      }
      responseData.then((response_data) => {
        if (response_data) {
          if (response_data.customerList.length > 0) {
            let tempArray = [];
            response_data.customerList.forEach((customer_record) => {
              tempArray.push([
                customer_record.bpartnerId,
                customer_record.customerId,
                customer_record.contactNumber === "" ? (
                  ""
                ) : (
                  <ProtectedNumber value={customer_record.contactNumber} />
                ),
                customer_record.orgId,
                <SingleLineValue value={customer_record.orgName} />,
                !parseInt(customer_record.firstName) ? (
                  <SingleLineValue value={customer_record.firstName} />
                ) : (
                  <ProtectedNumber value={customer_record.firstName} />
                ),
                !parseInt(customer_record.lastName) ? (
                  <SingleLineValue value={customer_record.lastName} />
                ) : (
                  <ProtectedNumber value={customer_record.lastName} />
                ),
                !parseInt(customer_record.preferredName) ? (
                  <SingleLineValue value={customer_record.preferredName} />
                ) : (
                  <ProtectedNumber value={customer_record.preferredName} />
                ),
                customer_record.dateOfBirth
                  ? ConvertMillisToDate(customer_record.dateOfBirth)
                  : "",
                customer_record.placeOfBirth,
                customer_record.genderName,
                customer_record.civilStatusName,
                customer_record.districtName,
                customer_record.cityName,
                customer_record.gramaSevaDivisionName,
                customer_record.policeStationName,
                customer_record.nationalityName,
                customer_record.religionName,
                customer_record.nicNo,
                customer_record.children,
                customer_record.youngestChildAgeYear ||
                customer_record.youngestChildAgeMonth
                  ? `${customer_record.youngestChildAgeYear}Y ${customer_record.youngestChildAgeMonth}M`
                  : "",
                customer_record.yearOfExperience ||
                customer_record.monthOfExperience
                  ? `${customer_record.yearOfExperience}Y ${customer_record.monthOfExperience}M`
                  : "",
                customer_record.expectedCountryName,
                customer_record.bureauTrainingCenterName,
                customer_record.confirmedVisitDate
                  ? ConvertMillisToDate(customer_record.confirmedVisitDate)
                  : "",
                customer_record.jobName,
                customer_record.dispositionStatus,
                customer_record.remark,
              ]);
            });
            setReportRowList(tempArray);
          } else {
            setReportRowList([]);
          }
          setReportTitle(
            reportType === CONFIRMED_REPORTS
              ? "Confirmed Calls"
              : reportType === ALL_CALL_REPORTS
              ? "All Calls"
              : "Rejected Calls"
          );
          setIsLoading(LOADED);
        }
      });
    }
  };

  return (
    <>
      <Breadcrumb parent="Dashboard" title="Reports" children={childLinks} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="form-row mb-4">
                  <div className="form-group col-12 col-md-4 position-relative">
                    <label
                      htmlFor="id_input_branch"
                      className="col-form-label pt-0"
                    >
                      Branch
                    </label>
                    <select
                      id="id_input_branch"
                      name="branch"
                      className="form-select"
                      onChange={(evt) => setDpBranchValue(evt.target.value)}
                      value={dpBranchValue}
                    >
                      {branchList.map((list_item, index) => (
                        <option value={list_item?.orgId} key={index}>
                          {list_item?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-12 col-md-4 position-relative">
                    <label htmlFor="id_input_start_date">Start Date</label>
                    <input
                      ref={startDate}
                      type="date"
                      className="form-control"
                      id="id_input_start_date"
                      name="start_date"
                      required
                    />
                    <div className="invalid-feedback position-absolute">
                      Please select Start Date
                    </div>
                  </div>
                  <div className="form-group col-12 col-md-4 position-relative">
                    <label htmlFor="id_input_end_date">End Date</label>
                    <input
                      ref={endDate}
                      type="date"
                      className="form-control"
                      id="id_input_end_date"
                      name="end_date"
                      required
                    />
                    <div className="invalid-feedback position-absolute">
                      Please select End Date
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-12 col-md-3">
                    <div className="checkbox checkbox-solid-dark ml-2">
                      <input
                        id={`id_input_filter_${ALL_CALL_REPORTS}`}
                        type="checkbox"
                        onChange={(evt) => changeReportType(evt.target)}
                        value={ALL_CALL_REPORTS}
                      />
                      <label htmlFor={`id_input_filter_${ALL_CALL_REPORTS}`}>
                        All Calls
                      </label>
                    </div>
                  </div>
                  <div className="form-group col-12 col-md-3">
                    <div className="checkbox checkbox-solid-dark ml-2">
                      <input
                        id={`id_input_filter_${CONFIRMED_REPORTS}`}
                        type="checkbox"
                        onChange={(evt) => changeReportType(evt.target)}
                        value={CONFIRMED_REPORTS}
                      />
                      <label htmlFor={`id_input_filter_${CONFIRMED_REPORTS}`}>
                        Confirmed Calls
                      </label>
                    </div>
                  </div>
                  <div className="form-group col-12 col-md-3">
                    <div className="checkbox checkbox-solid-dark ml-2">
                      <input
                        id={`id_input_filter_${REJECTED_REPORTS}`}
                        type="checkbox"
                        onChange={(evt) => changeReportType(evt.target)}
                        value={REJECTED_REPORTS}
                      />
                      <label htmlFor={`id_input_filter_${REJECTED_REPORTS}`}>
                        Rejected Calls
                      </label>
                    </div>
                  </div>
                  <div className="form-group col-12 col-md-3 position-relative">
                    <button
                      onClick={generateReport}
                      className="btn btn-primary w-100"
                    >
                      Generate Report
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-4 position-relative">
              {isLoading === LOADING ? (
                <div className="form-loader d-flex flex-column justify-content-center align-items-center">
                  <div className="loader-box">
                    <div className="loader">
                      <div className="line bg-secondary"></div>
                      <div className="line bg-secondary"></div>
                      <div className="line bg-secondary"></div>
                      <div className="line bg-secondary"></div>
                    </div>
                  </div>
                  <div>Generating Report...</div>
                </div>
              ) : null}
              <Datatable
                titleData={reportTitle}
                columnData={tableColumnHeaderList}
                rowData={reportRowList}
                onRowClick={onRowClick}
                onDownload={onDownload}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallReports;
