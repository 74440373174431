import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

// components
import Breadcrumb from "../common/breadcrumb";
import Datatable, { ControlButtons, BooleanValue } from "../common/datatable";
import formValidation from "../../customHooks/useValidation";

//network requests
import useAccess from "../../customHooks/useAccess";
import useAxiosTemplates from "../../customHooks/useAxiosTemplates";

const Organization = () => {
  // breadcrumb values
  let childLinks = [
    { value: "Master File", active: false },
    { value: "Organization", active: true },
  ];
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(
    loggedUser,
    "Organization"
  );
  const sendRequest = useAxiosTemplates();

  // helper containers
  const [resData, setResData] = useState();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form controls
  const [isValidated, setIsValidated] = useState(false);

  const [clientList, setClientList] = useState([
    { clientId: "", name: "-Select-" },
  ]);
  const [dpClientValue, setDpClientValue] = useState(0);

  const [organizationId, setOrganizationId] = useState(0);

  const [organizationRowList, setOrganizationRowList] = useState([]);

  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: "Client",
      options: {
        display: false,
        download: true,
        filter: false,
        viewColumns: false,
      },
    },

    "Search Key",
    "Name",
    "Telephone 1",
    "Telephone 2",
    "Email",
    "Address",
    "Description",
    "Remark",
    "Logo Small",
    "Logo Medium",
    "Logo Large",
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "",
      options: {
        display:
          checkIsAccessible("EditOrganization") ||
          checkIsAccessible("DeleteOrganization"),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];

  const [editOrganization] = useState(
    () => (organization_id, response_data) => {
      const editingOrganization = response_data.find(
        (item) => item.organizationId === organization_id
      );
      setOrganizationId(organization_id);
      setDpClientValue(editingOrganization.clientId);
      document.querySelector("input[name='fm_1_searck_key']").value =
        editingOrganization.searchKey;
      document.querySelector("input[name='fm_1_name']").value =
        editingOrganization.name;
      document.querySelector("input[name='fm_1_phone_no_1']").value =
        editingOrganization.phoneNo1;
      document.querySelector("input[name='fm_1_phone_no_2']").value =
        editingOrganization.phoneNo2;
      document.querySelector("input[name='fm_1_email']").value =
        editingOrganization.email;
      document.querySelector("input[name='fm_1_website']").value =
        editingOrganization.website;
      document.querySelector("input[name='fm_1_address']").value =
        editingOrganization.address;
      document.querySelector("textarea[name='fm_1_description']").value =
        editingOrganization.description;
      document.querySelector("textarea[name='fm_1_remark']").value =
        editingOrganization.remark;
      document.querySelector("input[name='fm_1_logo_small']").file =
        editingOrganization.logoSmall;
      document.querySelector("input[name='fm_1_logo_medium']").value =
        editingOrganization.logoMedium;
      document.querySelector("input[name='fm_1_logo_large']").value =
        editingOrganization.logoLarge;
      document.querySelector("input[name='fm_1_is_active']").checked =
        editingOrganization.isActive;
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  );
  const [deleteOrganization] = useState(
    () => (organization_id, logged_user) => {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "This will permenantly deletes this Organization!!!",
        showDenyButton: true,
        confirmButtonText: "Delete",
        denyButtonText: "Cancel",
        denyButtonColor: "#2a3142",
      }).then((result) => {
        if (result.isConfirmed) {
          const reqBody = {
            clientId: logged_user.clientId,
            orgId: logged_user.orgId,
            userId: logged_user.userId,
            organizationId: organization_id,
          };
          let responseData = new Promise((resolve) => {
            const result = sendRequest({
              url: "/deleteOrganization",
              data: reqBody,
              template: "CONTROL_DATA",
            });
            resolve(result);
          });
          responseData.then((response_data) => {
            if (response_data) {
              Swal.fire({
                icon: "success",
                title: "Request Successful",
                text: "Organization has successfully deleted.",
                showConfirmButton: false,
                timer: 5000,
              });
              setRefreshTable(true);
            }
          });
        }
      });
    }
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // fetch master data
  useEffect(() => {
    if (refreshTable) {
      document
        .querySelectorAll("input:required")
        .forEach((elem) =>
          elem.previousSibling.classList.add("required-input-mark")
        );
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({ url: "/viewOrganization", data: reqBody });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          if (response_data.activeClientList && !isValidated) {
            setClientList((prevClientList) => [
              ...prevClientList,
              ...response_data.activeClientList,
            ]);
            setDpClientValue(
              response_data.activeClientList.find(
                (item) => item.clientId === loggedUser.clientId
              ).clientId
            );
          }
          if (response_data.allOrganizationList) {
            setResData(response_data.allOrganizationList);
          }
        }
      });
      setRefreshTable(false);
    }
  }, [refreshTable, loggedUser, isValidated]);
  useEffect(() => {
    if (clientList.length > 1) {
      if (resData) {
        let tempList = [];
        resData.forEach((listItem) => {
          tempList.push([
            listItem.organizationId,
            clientList?.find((item) => item.clientId === listItem.clientId)
              .name,
            listItem.searchKey,
            listItem.name,
            listItem.phoneNo1,
            listItem.phoneNo2,
            listItem.email,
            listItem.website,
            listItem.address,
            listItem.description,
            listItem.remark,
            listItem.logoSmall,
            listItem.logoMedium,
            listItem.logoLarge,

            listItem.isActive ? "True" : "False",
            <ControlButtons
              editMethod={() =>
                editOrganization(listItem.organizationId, resData)
              }
              deleteMethod={() =>
                deleteOrganization(listItem.organizationId, loggedUser)
              }
            />,
          ]);
        });
        setOrganizationRowList([...tempList]);
      }
    }
  }, [resData, clientList, editOrganization, deleteOrganization, loggedUser]);

  const resetForm = (evt) => {
    setIsValidated(false);
    setDpClientValue(loggedUser.clientId);
    setOrganizationId(0);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
    setTimeout(() => {
      document.querySelector("select[name='fm_1_sa_client_id']").value =
        loggedUser.clientId;
    }, 100);
  };
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/checkOrganizationExist",
          data: {
            clientId: parseInt(dpClientValue),
            organizationId: organizationId,
          },
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          switch (response_data.existType) {
            case 0:
              break;
            case 1:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Search Key Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            case 2:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Name Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            case 3:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Search Key & Name Exist",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            default:
              break;
          }
        }
      });
    }
  };
  // const readUrl = (event) => {
  //   if (event.target.files.length === 0) return;
  //   //Image upload validation
  //   var mimeType = event.target.files[0].type;
  //   // Image upload
  //   var reader = new FileReader();
  //   reader.readAsDataURL(event.target.files[0]);
  //   reader.onload = (_event) => {
  //     console.log(mimeType.replace(/image\//, "."));
  //     setProfilePicture({
  //       changed: true,
  //       base64Value: reader.result,
  //       extension: mimeType.replace(/image\//, "."),
  //       url: "",
  //     });
  //   };
  // };

  return (
    <>
      <Breadcrumb
        parent="Dashboard"
        title="Master File"
        children={childLinks}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Organization</h5>
                <span>Master File for creating an Organization</span>
              </div>
              <div className="card-body">
                <form
                  tabIndex={1}
                  onSubmit={(evt) => submitForm(evt)}
                  onReset={(evt) => resetForm(evt)}
                  className={`form-1 ${isValidated ? "was-validated" : ""}`}
                  noValidate
                >
                  <div className="form-row mb-4">
                    <div className="form-group col-4 position-relative">
                      <label htmlFor="id_input_fm_1_search_key">
                        Search key
                      </label>
                      <input
                        id="id_input_fm_1_search_key"
                        name="fm_1_search_key"
                        type="text"
                        maxLength={250}
                        className="form-control"
                        placeholder="Enter Search Key"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            checkExpression: /[0-9!@#$%^<=>{}]+/,
                            // customMessage: customeValidationMessages[0],
                          })
                        }
                        required
                      />
                      <small className="invalid-feedback position-absolute">
                        Please enter Search Key
                      </small>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div className="form-group col-8 position-relative">
                      <label htmlFor="id_input_fm_1_name"> Name</label>
                      <input
                        id="id_input_fm_1_name"
                        name="fm_1_name"
                        type="text"
                        maxLength={250}
                        className="form-control"
                        placeholder="Enter Name"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            checkExpression: /[0-9!@#$%^<=>{}]+/,
                            // customMessage: customeValidationMessages[0],
                          })
                        }
                        required
                      />
                      <small className="invalid-feedback position-absolute">
                        Please enter a Name
                      </small>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                  </div>
                  <div className="form-row mb-4">
                    <div className="form-group col-6 position-relative">
                      <label htmlFor="id_input_fm_1_phone_no_1">
                        Telephone 1
                      </label>
                      <input
                        id="id_input_fm_1_phone_no_1"
                        name="fm_1_phone_no_1"
                        type="text"
                        maxLength={15}
                        minLength={9}
                        className="form-control"
                        placeholder="Enter Phone Number 1"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            validatePhoneNumbe: true,
                          })
                        }
                      />
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div className="form-group col-6 position-relative">
                      <label htmlFor="id_input_fm_1_phone_no_2">
                        Telephone 1
                      </label>
                      <input
                        id="id_input_fm_1_phone_no_2"
                        name="fm_1_phone_no_2"
                        type="text"
                        maxLength={15}
                        minLength={9}
                        className="form-control"
                        placeholder="Enter Phone Number 2"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            validatePhoneNumbe: true,
                          })
                        }
                      />
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                  </div>
                  <div className="form-row mb-4">
                    <div className="form-group col-6">
                      <label htmlFor="id_input_fm_1_email">Email</label>
                      <input
                        id="id_input_fm_1_email"
                        name="fm_1_email"
                        type="email"
                        maxLength={100}
                        className="form-control"
                        placeholder="Enter Email Address"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            validateEmail: true,
                          })
                        }
                      />
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div className="form-group col-6">
                      <label htmlFor="id_input_fm_1_website">Website</label>
                      <input
                        id="id_input_fm_1_website"
                        name="fm_1_website"
                        type="text"
                        maxLength={100}
                        className="form-control"
                        placeholder="Enter Website"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            validateWebsite: true,
                          })
                        }
                      />
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="id_input_fm_1_address">Address</label>
                    <textarea
                      id="id_input_fm_1_address"
                      name="fm_1_address"
                      rows="2"
                      maxLength={250}
                      className="form-control"
                      placeholder="Enter Address Line"
                      onChange={(evt) =>
                        formValidation({
                          event: evt,
                          checkExpression: /[^\w/,'".-]+/,
                          customMessage: "Invalid Address",
                        })
                      }
                    />
                    <small className="txt-danger position-absolute d-none input-validation"></small>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="id_input_fm_1_description">
                      Description
                    </label>
                    <textarea
                      id="id_input_fm_1_description"
                      name="fm_1_description"
                      maxLength={250}
                      className="form-control"
                      rows="2"
                      placeholder="Enter Description"
                      onChange={(evt) =>
                        formValidation({ event: evt, checkExpression: "" })
                      }
                    ></textarea>
                    <small className="txt-danger position-absolute d-none input-validation"></small>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="id_input_fm_1_remark">Remarks</label>
                    <textarea
                      id="id_input_fm_1_remark"
                      name="fm_1_remark"
                      rows="2"
                      maxLength={250}
                      className="form-control"
                      placeholder="Enter Remarks"
                      onChange={(evt) =>
                        formValidation({ event: evt, checkExpression: "" })
                      }
                    ></textarea>
                    <small className="txt-danger position-absolute d-none input-validation"></small>
                  </div>
                  <div className="form-row mb-4">
                    <div className="form-group col-4 position-relative">
                      <label htmlFor="id_input_fm_1_logo_small">
                        Logo Small
                      </label>
                      <input
                        id="id_input_fm_1_logo_small"
                        name="fm_1_logo_small"
                        type="file"
                        accept="image/*"
                        className="form-control"
                        placeholder=""
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                          })
                        }
                      />
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div className="form-group col-4 position-relative">
                      <label htmlFor="id_input_fm_1_logo_medium">
                        Logo Medium
                      </label>
                      <input
                        id="id_input_fm_1_logo_medium"
                        name="fm_1_logo_medium"
                        type="file"
                        accept="image/*"
                        className="form-control"
                        placeholder=""
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                          })
                        }
                      />
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div className="form-group col-4 position-relative">
                      <label htmlFor="id_input_fm_1_logo_large">
                        Logo Large
                      </label>
                      <input
                        id="id_input_fm_1_logo_large"
                        name="fm_1_logo_large"
                        type="file"
                        accept="image/*"
                        className="form-control"
                        placeholder=""
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                          })
                        }
                      />
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                  </div>
                  {checkIsAccessible("InsertOrganization") ||
                  (checkIsAccessible("DeactivateOrganization") &&
                    organizationId !== 0) ? (
                    <div className="form-group mb-4">
                      <div className="checkbox checkbox-solid-dark col-6">
                        <input
                          id="id_input_fm_1_is_active"
                          name="fm_1_is_active"
                          type="checkbox"
                          defaultChecked
                        />
                        <label htmlFor="id_input_fm_1_is_active">Active</label>
                      </div>
                    </div>
                  ) : null}
                  {checkIsAccessible("InsertOrganization") ||
                  (checkIsAccessible("EditOrganization") &&
                    organizationId !== 0) ? (
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary col-2 mx-3"
                        type="submit"
                      >
                        {organizationId ? "Update" : "Submit"}
                      </button>
                      <button className="btn btn-warning col-2" type="reset">
                        Reset
                      </button>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
            <div className="mb-4">
              <Datatable
                titleData="Organization List"
                columnData={tableColumnHeaderList}
                rowData={organizationRowList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Organization;
