// hooks
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// components
import App from "../components/app";

const PrivateRoute = () => {
  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  let location = useLocation();

  return loggedUser.orgId !== 0 && !!localStorage.getItem("token") ? (
    location.search ? (
      <Navigate to={`/customer/view/${new URLSearchParams(location.search).get("customer_id")}/`} />
    ) : (
      <App />
    )
  ) : (
    <Navigate to={`/login`} />
  );
};

export default PrivateRoute;
