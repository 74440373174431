import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";

// custom components
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../../common/datatable";

// customer hooks
import useAxiosTemplates from "../../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../../customHooks/useValidation";
import ConfigDB from "../../../../../data/customizer/config";
// import { Check, PlusCircle, RefreshCw, X } from "react-feather";
// import {
//   CUSTOMER_SOCIAL_TYPE,
//   INSERT_CUSTOMER_SOCIAL_TYPE,

// } from "../../../../../constant";
import { Col, Input, Label, Row } from "reactstrap";
import { ArrowLeft, ArrowRight } from "react-feather";

const SocialMedia = ({ b_partner_id = 0, client_id = 0, org_id = 0, set_basic_tab = null, set_back_tab = null }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshDpSocialType, setRefreshDpSocialType] = useState(false);

  // form values
  const [socialTypeList, setSocialTypeList] = useState([
    // { socialTypeId: "", name: "-Select-" },
  ]);
  const [viewSocialList, setViewSocialList] = useState([]);
  const [dpSocialTypeValue, setDpSocialTypeValue] = useState(0);
  const [socialId, setSocialId] = useState(0);
  const [responseData, setResponseData] = useState([])

  const [isSuccess, setIsSuccess] = useState(false);

  const [socialResponseData, setSocialResponseData] = useState()

  // table data
  const [socialRowList, setSocialRowList] = useState([]);
  // const tableColumnHeaderList = [
  //   {
  //     name: "Id",
  //     options: {
  //       display: false,
  //       download: false,
  //       filter: false,
  //       viewColumns: false,
  //     },
  //   },

  // "Social Media Type",
  // "Social Media Link",

  // {
  //   name: "Default",
  //   options: {
  //     customBodyRender: (value, tableMeta, updateValue) => {
  //       return <BooleanValue value={value} />;
  //     },
  //   },
  // },

  // "Remarks",

  // {
  //   name: "Active",
  //   options: {
  //     customBodyRender: (value, tableMeta, updateValue) => {
  //       return <BooleanValue value={value} />;
  //     },
  //   },
  // },

  //   {
  //     name: "",
  //     options: {
  //       display:
  //         checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
  //       download: false,
  //       filter: false,
  //       viewColumns: false,
  //     },
  //   },
  // ];


  // table functions


  // const [editSocial] = useState(() => (social_id, response_data) => {
  //   const editingSocial = response_data.find(
  //     (item) => item.socialId === social_id
  //   );
  //   setSocialId(editingSocial.socialId);

  //   setDpSocialTypeValue(editingSocial.socialTypeId);
  //   document.querySelector("input[name='fm_4_socialLink']").value =
  //     editingSocial.socialLink;
  //   document.querySelector("textarea[name='fm_4_remark']").value =
  //     editingSocial.remark;
  // document.querySelector("input[name='fm_4_is_active']").checked =
  //   editingSocial.active;
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // });

  // const [deleteSocial] = useState(() => (social_id, logged_user) => {
  //   Swal.fire({
  //     icon: "warning",
  //     title: "Are you sure?",
  //     text: "This will permenantly deletes this Social Media Record!!!",
  //     showDenyButton: true,
  //     confirmButtonText: "Delete",
  //     denyButtonText: "Cancel",
  //     denyButtonColor: "#2a3142",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const reqBody = {
  //         clientId: logged_user.clientId,
  //         orgId: logged_user.orgId,
  //         userId: logged_user.userId,
  //         socialId: social_id,
  //       };
  //       let responseData = new Promise((resolve) => {
  //         const result = sendRequest({
  //           url: "/deleteSocialTab",
  //           data: reqBody,
  //           template: "CONTROL_DATA",
  //         });
  //         resolve(result);
  //       });
  //       responseData.then((response_data) => {
  //         if (response_data) {
  //           Swal.fire({
  //             icon: "success",
  //             title: "Request Successful",
  //             text: "Social Media Record has successfully deleted.",
  //             showConfirmButton: false,
  //             timer: 5000,
  //           });
  //           setRefreshTable(true);
  //         }
  //       });
  //     }
  //   });
  // });

  // form control functions

  function handleCheckboxChange(evt, socialTypeId) {
    const linkInput = document.getElementById(`id_input_fm_4_link_id_${socialTypeId}`);

    if (linkInput) {
      linkInput.required = evt.target.checked;
    }
  }

  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    console.log(evt);

    console.log(socialTypeList);

    const form = new FormData(document.querySelector(".form-4"));
    let assignSocialTypeList = [];
    const selectedSocialTypes = form.getAll("fm_1_interface_media_type");
    console.log(selectedSocialTypes);

    const selectedLinks = form.getAll("fm_4_socialLink");
    console.log(selectedLinks);

    const selectedRemarkList = form.getAll("fm_4_remark");
    console.log(selectedRemarkList);


    if (selectedSocialTypes.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Request Failed",
        text: "Sorry, please apply a social media type before you go to the next step.",
        showConfirmButton: false,
        timer: 5000,
      });
    }

    const remarkList = [];

    selectedSocialTypes.forEach((social_item) => {
      const remarkValue = form.getAll(`fm_4__${social_item}__remark`)[0];
      remarkList.push({ socialTypeId: social_item, remark: remarkValue });
    });

    console.log(remarkList.length);

    let remarkEnteredForUnselectedSocialType = false;

    let socialidlist = []
    socialTypeList.forEach(view_social_list_item => {
      console.log(view_social_list_item);
      // socialidlist.push(view_social_list_item.map(item => item.socialTypeId))
      const socialTypeIds = view_social_list_item.socialTypeId;
      // Push socialTypeId into the new array
      socialidlist.push(socialTypeIds);

    })

    console.log(socialidlist);

    socialidlist.forEach(view_social_list_item => {
      // if(view_social_list_item.length>0){
      // view_social_list_item.forEach(view_social_list_item_social_item => {
      let filteredRemark = form.getAll(`fm_4__${view_social_list_item}__remark`)[0] || form.getAll(`fm_4__${view_social_list_item}__remark`)[1]

      console.log(filteredRemark);

      if (filteredRemark !== "" && filteredRemark != undefined) {
        if (!selectedSocialTypes.includes(view_social_list_item + '')) {
          remarkEnteredForUnselectedSocialType = true
          console.log(remarkEnteredForUnselectedSocialType);
        }
      }
      // })
      // }
    })


    const socialLinkList = [];

    selectedSocialTypes.forEach((social_item) => {
      const socialLinkValue = form.getAll(`fm_4__${social_item}__socialLink`);
      socialLinkList.push({ socialTypeId: social_item, socialLink: socialLinkValue });
    });

    console.log(socialLinkList.length);

    let socialLinkEnteredForUnselectedSocialType = false;

    console.log(socialidlist);

    socialidlist.forEach(view_social_list_item => {
      let filteredSocialLink = form.getAll(`fm_4__${view_social_list_item}__socialLink`)[0]

      console.log(filteredSocialLink);

      if (filteredSocialLink !== "" && filteredSocialLink != undefined) {
        if (!selectedSocialTypes.includes(view_social_list_item + '')) {
          socialLinkEnteredForUnselectedSocialType = true
        }
      }
    })

    // Check if selectedSocialLinkList has values not included in selectedSocialMediaType

    //  const socialLinkNotInSocialTypes = selectedMostInterestedList.some(value => !selectedVacancies.includes(value));

    let deselectedSocial = []
    let viewSocialIdList = viewSocialList.map(social_item => social_item.socialId)
    console.log(viewSocialIdList);
    selectedSocialTypes.forEach((selected_social_item) => {
      let socialItem = viewSocialList.find(view_social_item => view_social_item.socialTypeId === parseInt(selected_social_item))
      console.log(socialItem);
      if (socialItem !== undefined) {
        let itemToRemove = viewSocialIdList.indexOf(socialItem.socialId)
        delete viewSocialIdList[itemToRemove]
        console.log(socialItem);
      }
    })
    deselectedSocial = viewSocialIdList.filter(filtered_social_id_item => filtered_social_id_item > 0)
    console.log(deselectedSocial);
    deselectedSocial.forEach((deselected_social_item) => {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        socialId: deselected_social_item,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/deleteSocialTab",
          data: reqBody,
        });
        resolve(result);
      }).then((nested_reponse_data) => {
        if (nested_reponse_data.isSuccess) {
          // setTimeout(() => {
          setIsLoaded(LOADING);
          // }, 50)
          // setRefreshTable(true);
          setIsSuccess(true);

        }
      });
    })



    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);

      console.log(evt.target.value);

      selectedSocialTypes.forEach((social_item) => {


        const socialTypeIds = [...new Set(viewSocialList.map(item => item.socialTypeId))];

        console.log(socialTypeIds);
        console.log(typeof socialTypeIds[0]);

        console.log(parseInt(social_item));

        console.log(viewSocialList);

        const filteredSocials = viewSocialList
          .filter((item) => item.socialTypeId === parseInt(social_item));


        let socialIdNew = 0;

        console.log(filteredSocials.length);

        if (filteredSocials.length > 0) {
          // If there are matching jobs, set jobId to an array of jobIds
          const socialIdsArray = filteredSocials.map((item) => item.socialId); // Assuming socialTypeId is a string

          console.log(socialIdsArray[0]);

          socialIdNew = socialIdsArray[0];
          console.log(socialIdNew);

        } else {

          socialIdNew = 0;
          console.log(socialIdNew);
        }


        // Check if socialTypeId has a valid value
        if (isNaN(parseInt(social_item))) {
          console.log(isNaN(social_item));
          // Show an alert if socialTypeId is not a valid number
          Swal.fire({
            icon: "error",
            title: "Request Failed",
            text: "Please select a Social Type",
            showConfirmButton: false,
            timer: 5000,
          });
        }

        if ((socialLinkEnteredForUnselectedSocialType || remarkEnteredForUnselectedSocialType)) {
          // alert("Selected most interested or remark values should be included in selected vacancies, but no vacancies are selected.");

          Swal.fire({
            icon: "error",
            title: "Request Failed",
            text: "If you need to store data, please select the social type related to social media link and remark.",
            showConfirmButton: false,
            timer: 5000,
          });

          // Handle the alert as needed
        } else {

          let social = {
            bpartnerId: parseInt(b_partner_id),
            socialId: parseInt(socialIdNew),
            clientId: parseInt(client_id),
            orgId: parseInt(org_id),
            userId: parseInt(loggedUser.userId),
            // name: formData.get("fm_4_socialLink"),
            name: formData.get(`fm_4__${social_item}__socialLink`),
            // fm_4_socialLink
            // socialTypeId: parseInt(dpSocialTypeValue),
            socialTypeId: parseInt(social_item),
            // remark: formData.get("fm_4_remark"),
            remark: formData.getAll(`fm_4__${social_item}__remark`)[0] || formData.getAll(`fm_4__${social_item}__remark`)[1],
            isActive: true,
          };

          let nestedResponseData = new Promise((resolve) => {
            const result = sendRequest({
              url: "/saveSocialTab",
              data: social,
            });
            resolve(result);
          });
          nestedResponseData.then((nested_reponse_data) => {
            if (nested_reponse_data.isSuccess) {
              // Swal.fire({
              //   icon: "success",
              //   title: "Request Successful",
              //   text: socialId
              //     ? "Social Media has successfully updated."
              //     : "New Social Media has successfully added.",
              //   showConfirmButton: false,
              //   timer: 5000,
              // });

              // if()

              set_basic_tab();
              setIsLoaded(LOADING);
              // setRefreshTable(true);
              setIsSuccess(true);
            }

            // set_basic_tab();
            // setIsLoaded(LOADING);
            // setRefreshTable(true);
            // setIsSuccess(true);
            // }
          });
        }

        // let responseData = new Promise((resolve) => {
        //   const result = sendRequest({
        //     url: "/checkSocialExist",
        //     data: {
        //       clientId: parseInt(loggedUser.clientId),
        //       // bpartnerId: parseInt(b_partner_id),
        //       socialId: parseInt(socialId),
        //       socialTypeId: parseInt(dpSocialTypeValue),
        //       name: formData.get("fm_4_socialLink"),
        //     },
        //   });
        //   resolve(result);
        // });
        // responseData.then((response_data) => {
        //   if (response_data) {
        //     switch (response_data.existType) {
        //       case 0:
        // let social = {
        //   bpartnerId: parseInt(b_partner_id),
        //   socialId: parseInt(socialId),
        //   clientId: parseInt(client_id),
        //   orgId: parseInt(org_id),
        //   userId: parseInt(loggedUser.userId),
        //   name: formData.get("fm_4_socialLink"),
        //   socialTypeId: parseInt(dpSocialTypeValue),
        //   remark: formData.get("fm_4_remark"),
        //   isActive: true,
        // };
        // let nestedResponseData = new Promise((resolve) => {
        //   const result = sendRequest({
        //     url: "/saveSocialTab",
        //     data: social,
        //   });
        //   resolve(result);
        // });
        // nestedResponseData.then((nested_reponse_data) => {
        //   if (nested_reponse_data.isSuccess) {
        //     Swal.fire({
        //       icon: "success",
        //       title: "Request Successful",
        //       text: socialId
        //         ? "Social Media has successfully updated."
        //         : "New Social Media has successfully added.",
        //       showConfirmButton: false,
        //       timer: 5000,
        //     });
        //     setIsLoaded(LOADING);
        //     setRefreshTable(true);
        //     setIsSuccess(true);

        //   }
        // });
        //   break;
        // case 1:
        //   Swal.fire({
        //     icon: "error",
        //     title: "Request Failed",
        //     text: "Social Media Link Exists",
        //     showConfirmButton: false,
        //     timer: 5000,
        //   });
        //   break;
        // default:
        //   break;
        //     }
        //   }
        // });

      })

    }
  };

  const resetForm = (evt) => {
    evt.preventDefault();

    // let inputFields = document.querySelectorAll(".form-control");
    // let customValidationMessages =
    //   document.querySelectorAll(".input-validation");
    // inputFields.forEach((field) => {
    //   if (field.classList.contains("is-valid")) {
    //     field.classList.remove("is-valid");
    //   } else if (field.classList.contains("is-invalid")) {
    //     field.classList.remove("is-invalid");
    //   }
    // });
    // customValidationMessages.forEach((element) => {
    //   if (element.classList.contains("input-validation")) {
    //     if (!element.classList.contains("d-none")) {
    //       element.classList.add("d-none");
    //     }
    //   }
    // });

    Swal.fire({
      title: 'Are you sure you want to reset?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        setIsValidated(false);
        setSocialId(0);

        setDpSocialTypeValue(0);

        let socialTypeIdList = []
        socialTypeIdList = socialResponseData.map((listItem) => listItem.socialTypeId)
        socialTypeIdList.forEach(social_item => {
          let checkbox = document.querySelector(`#input_fm_1_active_media_type_id_${social_item}`)
          if (checkbox != null) {
            checkbox.checked = false;
          }
        })


        let socialLinkList = []
        socialLinkList = socialResponseData.map((listItem) =>
        //  listItem.jobCategoryLineId)
        ({
          socialTypeId: listItem.socialTypeId,
          socialLink: listItem.socialLink,
        }));
        socialLinkList.forEach(sociallink_item => {

          let socialLink_list = document.querySelectorAll(`#id_input_fm_4_link_id_${sociallink_item.socialTypeId}`);
          if (socialLink_list.length > 0) {
            socialLink_list[0].value = "";
          }

        })

       

        let remarkList = []
        remarkList = socialResponseData.map((listItem) =>
        ({
          socialTypeId: listItem.socialTypeId,
          remark: listItem.remark,
        }));
        remarkList.forEach(remark_item => {

          let remark_list = document.querySelectorAll(`#id_input_fm_4__${remark_item.socialTypeId}__remark`);
          if (remark_list.length > 0) {
            remark_list[0].value = "";
            remark_list[1].value = "";
          }

        })

      }
    });

    setIsSuccess(false)
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    // if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/viewSocialTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {

            if (response_data.activeSocialMediaList) {
              setSocialTypeList([
                // { socialTypeId: "", name: "-Select-" },
                ...response_data.activeSocialMediaList
              ]);
            }
            if (response_data.allSocialMediaDetailList) {
              setViewSocialList([...response_data.allSocialMediaDetailList]);
              let tempList = [];

              setSocialResponseData(response_data.allSocialMediaDetailList)

              // response_data.allSocialMediaDetailList.forEach((listItem) => {
              //   // tempList.push([
              //   //   listItem.socialId,


              //   //   listItem.socialTypeName,
              //   //   listItem.socialLink,
              //   //   listItem.remark,
              //   //   <ControlButtons
              //   //     editMethod={() =>
              //   //       editSocial(
              //   //         listItem.socialId,
              //   //         response_data.allSocialMediaDetailList
              //   //       )
              //   //     }
              //   //     disableEdit={!checkIsAccessible(EDITFUNCTION)}
              //   //     deleteMethod={() =>
              //   //       deleteSocial(listItem.socialId, loggedUser)
              //   //     }
              //   //     disableDelete={!checkIsAccessible(DELETEFUNCTION)}
              //   //   />,
              //   // ]);

              //   setSocialResponseData(response_data.allSocialMediaDetailList)


              //   let socialTypeList = []
              //   socialTypeList = response_data.allSocialMediaDetailList.map((listItem) => listItem.socialTypeId)
              //   socialTypeList.forEach(social_item => {
              //     document.querySelector(`#input_fm_1_active_media_type_id_${social_item}`).checked = true
              //   })

              //   response_data.allSocialMediaDetailList.forEach(link_item => {
              //     console.log(link_item);

              //     let linkList = document.querySelector(`#id_input_fm_4_link_id__${link_item.socialTypeId}`);
              //     console.log(linkList);
              //     linkList.value = link_item.socialLink;
              //   })

              //   // let remarkList = []
              //   // remarkList = response_data.allSocialMediaDetailList.map((listItem) =>

              //   // ({
              //   //   socialTypeList: listItem.socialTypeId,
              //   //   remark: listItem.remark,
              //   // }));

              //   // remarkList.forEach(remark_item => {
              //   //   console.log(remark_item.remark);

              //   //   let remarkList = document.querySelectorAll(`#id_input_fm_4__${remark_item.socialTypeId}__remark`);

              //   //   remarkList[0].value = remark_item.remark;
              //   //   remarkList[1].value = remark_item.remark;

              //   // })

              //   let remarkList = []
              //   remarkList = response_data.allSocialMediaDetailList.map((listItem) =>
              //   //  listItem.jobCategoryLineId)
              //   ({
              //     socialTypeId: listItem.socialTypeId,
              //     remark: listItem.remark,
              //   }));
              //   remarkList.forEach(remark_item => {
              //     console.log(remark_item);

              //     let remark_list = document.querySelectorAll(`#id_input_fm_4__${remark_item.jobCategoryLineId}__remark`);
              //     console.log(remark_item.remark);
              //     console.log(remark_list);

              //     if (remark_list.length > 0) {
              //       remark_list[0].value = remark_item.remark;

              //       remark_list[1].value = remark_item.remark;

              //       console.log(remark_list.value = remark_item.remark);
              //     }
              //   })


              // });
              // setSocialRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            // setRefreshTable(false);
          }
        });
      }
    // } else {
    //   setIsLoaded(LOADED);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    if (viewSocialList?.length > 0) {

      viewSocialList.forEach((listItem) => {
        setTimeout(() => {
        let socialTypeList = []
        socialTypeList = viewSocialList.map((listItem) => listItem.socialTypeId)
        socialTypeList.forEach(social_item => {
          document.querySelector(`#input_fm_1_active_media_type_id_${social_item}`).checked = true
        })

        viewSocialList.forEach(link_item => {
          console.log(link_item);

          let linkList = document.querySelector(`#id_input_fm_4_link_id_${link_item.socialTypeId}`);
          console.log(linkList);
          linkList.value = link_item.socialLink;
        })

        let remarkList = []
        remarkList = viewSocialList.map((listItem) =>
        //  listItem.jobCategoryLineId)
        ({
          socialTypeId: listItem.socialTypeId,
          remark: listItem.remark,
        }));
        remarkList.forEach(remark_item => {
          console.log(remark_item);

          let remark_list = document.querySelectorAll(`#id_input_fm_4__${remark_item.socialTypeId}__remark`);
          console.log(remark_item.remark);
          console.log(remark_list);

          if (remark_list.length > 0) {
            remark_list[0].value = remark_item.remark;

            remark_list[1].value = remark_item.remark;

            console.log(remark_list.value = remark_item.remark);
          }
        })
      });

    }, 100)
    }
  }, [viewSocialList])


  const closeItem = () => {
    setIsSuccess(false);
  };

  const handleBackClick = () => {
    set_back_tab()
  };

  // const handleNextClick = () => {
  //   set_basic_tab()
  // };


  return (
    <>

      <div className="card-modified-body mb-4">
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-4 ${isValidated ? "was-validated" : ""
            }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}

          {/* <div className="form-row mb-4">

            <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_4_sa_customer_social_type_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Social Type
                </label>

              </div>
              <select
                id="id_input_fm_4_sa_customer_social_type_id"
                name="fm_4_sa_customer_social_type_id"
                className="form-select"
                onChange={(evt) => setDpSocialTypeValue(evt.target.value)}
                value={dpSocialTypeValue}
                required
              >
                {socialTypeList.length
                  ? socialTypeList.map((listItem, index) => (
                    <option
                      value={listItem?.socialTypeId}
                      key={index}
                    >
                      {listItem?.name}
                    </option>
                  ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select an Social Type
              </small>
            </div>

            <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 position-relative">
              <label
                htmlFor="id_input_fm_4_social"
                className="required-input-mark"
              >
                Social Medial Link
              </label>
              <input
                id="id_input_fm_4_link"
                name="fm_4_socialLink"
                type="text"
                maxLength={250}
                className="form-control"
                placeholder="Enter Link Line"
                autoComplete="off"
                onChange={(evt) =>
                  formValidation({
                    event: evt,
                  })
                }
                required
              />
              <div className="invalid-feedback position-absolute">
                Please enter Link
              </div>
              <small className="txt-danger position-absolute d-none input-validation"></small>
            </div>

          </div> */}


          {/* <div className="form-group mb-4">
            <label htmlFor="id_input_fm_4_remark">Remark</label>
            <textarea
              id="id_input_fm_4_remark"
              name="fm_4_remark"
              maxLength={250}
              rows={3}
              className="form-control"
              placeholder="Enter Remark"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  checkExpression: "",
                })
              }
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div> */}

          <Row className="m-b-10">
            <Col lg="15">
              <Row className="m-b-20 border-bottom d-none d-md-flex">
                <Col lg="2" md="3">
                  <div className="fw-bold m-b-10 required-input-mark">
                    Social Type
                  </div>
                </Col>
                <Col lg="2" md="3">
                  <div className="fw-bold m-b-10 required-input-mark">
                    Social media link
                  </div>
                </Col>
                <Col lg="2" md="3">
                  <div className="fw-bold m-b-10">
                    Remark
                  </div>
                </Col>
              </Row>

              <Row className="m-b-20">

                {socialTypeList.length > 0
                  ? socialTypeList.map((listItem, index) => (
                    <Col lg="12"
                      key={index}
                    >
                      <Row className="m-b-10 border-bottom">
                        <Col lg="2" md="3">
                          <Label
                            className="d-block fw-bold"
                            htmlFor="input_fm_1_active"
                            key={index}
                          >
                            <Input
                              className="checkbox_animated"
                              id={`input_fm_1_active_media_type_id_${listItem.socialTypeId}`}
                              value={`${listItem.socialTypeId}`}
                              name="fm_1_interface_media_type"
                              type="checkbox"
                              onChange={(evt) => handleCheckboxChange(evt, listItem.socialTypeId)}
                            />

                            {listItem.name}
                          </Label>
                        </Col>

                        <Col lg="2" md="3" className="mt-3 mt-md-0">

                          <Row className="px-4 px-md-0 mb-2 mb-md-0">

                            <Col xs="9" md="12">
                              <Label
                                className="d-block"
                                htmlFor="input_fm_1_active"
                                key={index}
                              >
                                {/* <Input
                                  className="checkbox_animated d-none"
                                  id={`input_fm_1_most_interested_id_${vacancy_item.jobCategoryLineId}`}
                                  name="fm_1_most_interested"
                                  type="checkbox"
                                  value={`${vacancy_item.jobCategoryLineId}`}
                                />
                                <i id={`most_interested_icon_for_${vacancy_item.jobCategoryLineId}`}
                                  className="fa fa-heart-o txt-danger"
                                  onClick={() => mobileViewMostInteresetedIcon(vacancy_item.jobCategoryLineId)}>

                                </i> */}

                                <Input
                                  id={`id_input_fm_4_link_id_${listItem.socialTypeId}`}
                                  name={`fm_4__${listItem.socialTypeId}__socialLink`}
                                  type="text"
                                  maxLength={250}
                                  className="form-control"
                                  placeholder="Enter Link"
                                  autoComplete="off"
                                  onChange={(evt) => {
                                    formValidation({
                                      event: evt,
                                      checkExpression: "",
                                    });
                                    console.log(evt.target.value);
                                    document.querySelectorAll(`#id_input_fm_4_link_id__${listItem.socialTypeId}`).value = evt.target.value;
                                  }}
                                // required
                                ></Input>
                                <div className="invalid-feedback position-absolute">
                                  Please enter link
                                </div>
                                <small className="txt-danger position-absolute d-none input-validation"></small>
                              </Label>



                            </Col>

                            <Col xs="12" className="d-md-none mt-1 mt-md-0">
                              <Label
                                className="d-block"
                                htmlFor="input_fm_1_active"
                                key={index}
                              >
                                {/* <Input
                                  className="form-control px-0"
                                  id={`fm_2__${listItem.socialTypeId}__remark`}
                                  name={`fm_2__${listItem.socialTypeId}__remark`}
                                  maxLength={250}
                                  onChange={(evt) =>
                                    formValidation({ event: evt, checkExpression: "" })
                                  }
                                ></Input> */}

                                <Input
                                  // id="id_input_fm_4_remark"
                                  // name="fm_4_remark"
                                  id={`id_input_fm_4__${listItem.socialTypeId}__remark`}
                                  name={`fm_4__${listItem.socialTypeId}__remark`}
                                  maxLength={250}
                                  rows={2}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Remark"
                                  onChange={(evt) => {
                                    formValidation({
                                      event: evt,
                                      checkExpression: "",
                                    });
                                    document.querySelectorAll(`#id_input_fm_4__${listItem.socialTypeId}__remark`)[1].value = evt.target.value;
                                  }}
                                ></Input>
                                <small className="txt-danger position-absolute d-none input-validation"></small>

                              </Label>
                            </Col>

                          </Row>

                        </Col>


                        <Col lg="2" md="3" className="d-none d-md-block">

                          <Label
                            className="d-block"
                            htmlFor="input_fm_1_active"
                            key={index}
                          >
                            <Input
                              id={`id_input_fm_4__${listItem.socialTypeId}__remark`}
                              name={`fm_4__${listItem.socialTypeId}__remark`}
                              maxLength={250}
                              rows={2}
                              type="text"
                              className="form-control"
                              placeholder="Enter Remark"
                              onChange={(evt) => {
                                formValidation({
                                  event: evt,
                                  checkExpression: "",
                                });
                                document.querySelectorAll(`#id_input_fm_4__${listItem.socialTypeId}__remark`)[0].value = evt.target.value
                              }}
                            ></Input>
                            <small className="txt-danger position-absolute d-none input-validation"></small>

                          </Label>

                        </Col>


                      </Row>
                    </Col>
                  ))
                  : null}

              </Row>


            </Col>
          </Row>



          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
              (checkIsAccessible(EDITFUNCTION) && socialId !== 0) ? (
              <div className="d-sm-flex justify-content-end">

                <button
                  className="btn btn-outline-primary col-md-1 col-3 px-1 mb-2 mb-sm-0"
                  type="reset"
                >
                  Reset
                </button>
                <button
                  className="btn btn-primary col-md-2 col-4 mx-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {socialId ? "Next" : "Next"}
                </button>

                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleBackClick()}><ArrowLeft height={"16px"} width={"16px"} />Back</button>

                {/* <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleNextClick()}>Next<ArrowRight height={"16px"} width={"16px"} /></button> */}

              </div>
            ) : null
          ) : null}

          <br />

          {/* {isSuccess && (
            <div className="col-sm-12 mb-2">
              <div className="bg-primary px-3 py-3 rounded d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div
                    style={{
                      backgroundColor: "#FFEBB2",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Check
                      height={"16px"}
                      style={{ strokeWidth: 5 }}
                      className="txt-primary"
                    />
                  </div>
                  <div>
                    <div className="mb-1 d-flex align-items-center">
                      <h6
                        style={{ color: "#FFEBB2" }}
                        className="uppercase px-1 m-0"
                      >
                    
                      </h6>
                      <div
                        style={{ backgroundColor: "#FFEBB2", height: "5px" }}
                        className="w-100 rounded ml-2"
                      ></div>
                    </div>
                    <div style={{ color: "#FFEBB2", marginLeft: 15 }} className="fw-bold">
                      Do you want to add a another Social Media detail.
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className=" d-flex justify-content-end"                >
                    <div>
                      <button
                        type="reset"
                        className="btn 
                            rounded btn-sm "
                        style={{ backgroundColor: "#FFEBB2" }}
                      >

                        <span className="fw-bold">Add</span>
                      </button>
                    </div>
                  </div>
                  <div className="mx-2 d-flex"
                  >
                    <div style={{ marginTop: -15 }} >
                      <span
                        onClick={closeItem}
                        style={{
                          cursor: "pointer",
                        }}
                      > <X style={{ color: "#FFEBB2" }} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )} */}


        </form>
      </div>
      {/* <div className="mb-4">
        <Datatable
          titleData="Social Media List"
          columnData={tableColumnHeaderList}
          rowData={socialRowList}
          csvFilename="Social Media.csv"
        />
      </div> */}


    </>
  );

}
export default SocialMedia;