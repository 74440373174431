import React, { useEffect, useRef } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// //config data
import { ConfigDB } from "../data/customizer/config";

import App from "../components/app";

// Import Pages
import Logins from "../pages/login";
import LoginWithOrg from "../pages/loginWithOrg";

// // Import custom Components
import CurrentUser from "../components/user";
import Default from "../components/dashboard/defaultCompo/default";
import View from "../components/customer/view";
import SaveCustomer from "../components/customer/save";
import Client from "../components/masterFile/Client";
import Organization from "../components/masterFile/Organization";
import Country from "../components/masterFile/Country";
import Nationality from "../components/masterFile/Nationality";
import District from "../components/masterFile/District";
import ConnectedSource from "../components/masterFile/ConnectedSource";
import CC1Disposition from "../components/masterFile/CC1Disposition";
import CC2Disposition from "../components/masterFile/CC2Disposition";
import Complexion from "../components/masterFile/Complexion";
import CivilStatus from "../components/masterFile/CivilStatus";
import DocumentType from "../components/masterFile/DocumentType";
import CustomerType from "../components/masterFile/CustomerType";
import CustomerMood from "../components/masterFile/CustomerMood";
import PhoneNumberType from "../components/masterFile/PhoneNumberType";
import Religion from "../components/masterFile/Religion";
import Gender from "../components/masterFile/Gender";
import MostInterested from "../components/masterFile/MostInterested";
import CustomerAddressType from "../components/masterFile/CustomerAddressType";
import PassportType from "../components/masterFile/PassportType";
import EducationLevel from "../components/masterFile/education/EducationLevel";
import EducationGrade from "../components/masterFile/education/EducationGrade";
import EducationAchievement from "../components/masterFile/education/EducationAchievement";
import EducationSubject from "../components/masterFile/education/EducationSubject";
import EducationField from "../components/masterFile/education/EducationField";
import PhotoType from "../components/masterFile/PhotoType";
import ProfileProgressType from "../components/masterFile/ProfileProgressType";
import ConnectedSourceLine from "../components/masterFile/ConnectedSourceLine";
// import Interface from "../components/masterFile/Interface";
import PoliceStation from "../components/masterFile/PoliceStation";
import Role from "../components/masterFile/Role";
import City from "../components/masterFile/City";
import GramaSevaDivision from "../components/masterFile/GramaSevaDivision";
import Relationship from "../components/masterFile/Relationship";
import JobCategory from "../components/masterFile/JobCategory";
import JobCategoryLine from "../components/masterFile/JobCategoryLine";
import BureauTrainingCenter from "../components/masterFile/BureauTrainingCenter";
import RoleLine from "../components/masterFile/RoleLine";
import Department from "../components/masterFile/Department";
import Title from "../components/masterFile/Title";
import Designation from "../components/masterFile/Designation";
// import Function from "../components/masterFile/Function";
import PrivateRoute from "./private-route";
import Employee from "../components/masterFile/Employee";
import User from "../components/masterFile/User";
import Dashboard from "../components/customer/Dashboard";
import History from "../components/customer/History";
import DetailIndex from "../components/customer/details";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CVGenerator from "../components/customer/cvGenerator";
import CallReports from "../components/reports/CallReports";
import Language from "../components/masterFile/Language";
import Skill from "../components/masterFile/Skill";
import JobSkill from "../components/masterFile/JobSkill";
import Currency from "../components/masterFile/Currency";
import CurrencyAllocateToCountry from "../components/masterFile/CurrencyAllocateToCountry";
import InterviewVideo from "../components/customer/InterviewVideo";
import UserLoginWithEmail from "../pages/userLoginWithEmail";
import UserAccount from "../components/customer/UserAccount";
import UserLogin from "../pages/userLogin";
import ForgotPasswords from "../pages/forgotPassword";
import VerifyEmail from "../pages/verifyEmail";
import ResetPasswordConfirm from "../pages/resetPasswordConfirm";
import Job from "../components/customer/save/save/Job";

const MainRoutes = () => {
  const currentCustomer = useSelector((content) => content.CustomerReducer);
  let navigate = useNavigate();

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);
  const location = useLocation();
  const sessionExpiredMessageTriggered = useRef(false);
  const tokenRefreshTimerId = useRef(0);
  const checkUserActiveTimerId = useRef(0);
  const waitToStart = useRef(false);
  const currentTime = useRef(null);

  useEffect(() => {
    /**
     * User Session Mangement and Event Initilization
     */
    console.log("User Management Started...");
    document.addEventListener("keydown", () => {
      localStorage.setItem("lastKeyboardStrokeAt", new Date());
    });
    document.addEventListener("mousemove", () => {
      localStorage.setItem("lastMouseMoveAt", new Date());
    });
    document.addEventListener("visibilitychange", (evt) => {
      if (!!localStorage.getItem("token")) {
        if (Swal.isVisible) {
          Swal.close();
          sessionExpiredMessageTriggered.current = false;
        }
      }
    });
    window.addEventListener("storage", (event) => {
      const key = event.key;
      if (key === "store_updated") {
        localStorage.setItem(key, false);
        window.location.reload();
      }
      if (localStorage.getItem("store") === null) {
        navigate("/login");
      }
    });

    /**
     * Handling Logged User entrance
     * e.g.:- 1. if user comes after a accidential tab close
     *        2. if user comes tab close without account log out
     */
    if (
      location.pathname !== "/login" &&
      loggedUser.orgId !== 0 &&
      !!localStorage.getItem("token")
    ) {
      if (!location.pathname.match(/(customer\/view\/CUS[\d]+)/)) {
        console.log("Handling Logged User entrance");
        let comeBackAfter =
          new Date() - new Date(localStorage.getItem("lastTokenRefreshedAt"));
        if (
          comeBackAfter > ConfigDB.data.token_refresh_time_interval_in_millis
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("lastKeyboardStrokeAt");
          localStorage.removeItem("lastMouseMoveAt");
          Swal.fire({
            icon: "warning",
            title: "Session Expired",
            text: "Login again to continue your work.",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showDenyButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/login");
            }
          });
        } else {
          if (localStorage.getItem("lastTokenRefreshedAt") !== null) {
            let miliSecondsRemained =
              ConfigDB.data.token_refresh_time_interval_in_millis -
              (new Date() -
                new Date(localStorage.getItem("lastTokenRefreshedAt")));
            console.log(
              `Token Refreshes after ${Math.round(
                miliSecondsRemained / 1000
              )} seconds`
            );
            setTimeout(() => {
              // if (!document.hidden) {
              console.log("Timer 1");
              const reqInstance = axios.create({
                baseURL: ConfigDB.data.backendServerUrl,
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  // IsRefreshToken: true,
                  UserId: loggedUser.userId,
                },
              });
              reqInstance
                .post("/refreshToken")
                .then((response) => {
                  console.log(response.data);
                  if (!response.data.hasError) {
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("lastTokenRefreshedAt", new Date());
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              console.log("token refreshed at", new Date());
              console.log("new token => ", localStorage.getItem("token"));
              // }

              tokenRefreshTimerId.current = setInterval(() => {
                if (!!localStorage.getItem("token")) {
                  // if (!document.hidden) {
                  console.log("Timer 2");
                  const reqInstance = axios.create({
                    baseURL: ConfigDB.data.backendServerUrl,
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                      // IsRefreshToken: true,
                      UserId: loggedUser.userId,
                    },
                  });
                  reqInstance
                    .post("/refreshToken")
                    .then((response) => {
                      console.log(response.data);
                      if (!response.data.hasError) {
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem(
                          "lastTokenRefreshedAt",
                          new Date()
                        );
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  console.log("token refreshed at", new Date());
                  console.log("new token => ", localStorage.getItem("token"));
                  // }
                } else {
                  console.log("No Token Key [Handling Timer 3]");
                }
              }, ConfigDB.data.token_refresh_time_interval_in_millis);
            }, miliSecondsRemained);
          }
        }
      }
      // checkUserActiveTimerId.current = setInterval(() => {
      //   if (loggedUser.orgId !== 0) {
      //     currentTime.current = new Date();
      //     let keyboardDeactiveTime;
      //     let mouseDeactiveTime;

      //     if (localStorage.getItem("lastKeyboardStrokeAt") === null) {
      //       keyboardDeactiveTime = currentTime.current - new Date();
      //     } else {
      //       keyboardDeactiveTime =
      //         currentTime.current -
      //         new Date(localStorage.getItem("lastKeyboardStrokeAt"));
      //     }

      //     if (localStorage.getItem("lastMouseMoveAt") === null) {
      //       mouseDeactiveTime = currentTime.current - new Date();
      //     } else {
      //       mouseDeactiveTime =
      //         currentTime.current -
      //         new Date(localStorage.getItem("lastMouseMoveAt"));
      //     }
      //     console.log("checkUserActiveTimerId", checkUserActiveTimerId.current);

      //     if (
      //       keyboardDeactiveTime > ConfigDB.data.session_expiration_time &&
      //       mouseDeactiveTime > ConfigDB.data.session_expiration_time
      //     ) {
      //       if (!sessionExpiredMessageTriggered.current) {
      //         console.log("User inactive for 40 minutes.");
      //         localStorage.removeItem("token");
      //         Swal.fire({
      //           icon: "warning",
      //           title: "Session Expired",
      //           text: "Login again to continue your work.",
      //           allowOutsideClick: false,
      //           allowEscapeKey: false,
      //           showDenyButton: false,
      //           confirmButtonText: "Ok",
      //         }).then((result) => {
      //           if (result.isConfirmed) {
      //             navigate("/login");
      //           }
      //         });
      //         sessionExpiredMessageTriggered.current = true;
      //       }
      //     }
      //   }
      // }, ConfigDB.data.check_user_active_timer_interval);
    }

    return () => {
      clearInterval(tokenRefreshTimerId.current);
      clearInterval(checkUserActiveTimerId.current);
      document.removeEventListener("keydown", () =>
        console.log("Keydown event removed.")
      );
      document.removeEventListener("mousemove", () =>
        console.log("Keydown event removed.")
      );
      document.removeEventListener("visibilitychange", () =>
        console.log("Keydown event removed.")
      );
      window.removeEventListener("storage", () => {
        console.log("Local Storage event removed.");
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * Remove timer on fresh login(no token exists).
     * Change time state as waitToStart true
     */
    if (location.pathname === "/login" && !localStorage.getItem("token")) {
      clearInterval(tokenRefreshTimerId.current);
      clearInterval(checkUserActiveTimerId.current);
      waitToStart.current = true;
      sessionExpiredMessageTriggered.current = false;
      console.log("user check timer removed", checkUserActiveTimerId.current);
      console.log("refresh token timer removed", tokenRefreshTimerId.current);
    }

    /**
     * Starts timer after a fresh login.(after getting a new token).
     * Change timer state as waitToStart false
     */
    if (waitToStart.current) {
      if (!!localStorage.getItem("token")) {
        console.log("Starts timer after a fresh login");
        tokenRefreshTimerId.current = setInterval(() => {
          // if (localStorage.getItem("token") !== null) {
          if (!!localStorage.getItem("token")) {
            // if (!document.hidden) {
            console.log("Timer 3");
            const reqInstance = axios.create({
              baseURL: ConfigDB.data.backendServerUrl,
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                UserId: loggedUser.userId,
              },
            });
            reqInstance
              .post("/refreshToken")
              .then((response) => {
                console.log(response.data);
                if (!response.data.hasError) {
                  localStorage.setItem("token", response.data.token);
                  localStorage.setItem("lastTokenRefreshedAt", new Date());
                }
              })
              .catch((error) => {
                console.log(error);
              });
            console.log("token refreshed at", new Date());
            console.log("new token => ", localStorage.getItem("token"));
            // }
          } else {
            console.log("No Token Key [Handling Timer 3]");
          }
        }, ConfigDB.data.token_refresh_time_interval_in_millis);

        // checkUserActiveTimerId.current = setInterval(() => {
        //   currentTime.current = new Date();
        //   let keyboardDeactiveTime;
        //   let mouseDeactiveTime;

        //   if (localStorage.getItem("lastKeyboardStrokeAt") === null) {
        //     keyboardDeactiveTime = currentTime.current - new Date();
        //   } else {
        //     keyboardDeactiveTime =
        //       currentTime.current -
        //       new Date(localStorage.getItem("lastKeyboardStrokeAt"));
        //   }

        //   if (localStorage.getItem("lastMouseMoveAt") === null) {
        //     mouseDeactiveTime = currentTime.current - new Date();
        //   } else {
        //     mouseDeactiveTime =
        //       currentTime.current -
        //       new Date(localStorage.getItem("lastMouseMoveAt"));
        //   }

        //   console.log("checkUserActiveTimerId", checkUserActiveTimerId.current);

        //   if (
        //     keyboardDeactiveTime > ConfigDB.data.session_expiration_time &&
        //     mouseDeactiveTime > ConfigDB.data.session_expiration_time
        //   ) {
        //     if (!sessionExpiredMessageTriggered.current) {
        //       console.log("User inactive for 40 minutes.");
        //       localStorage.removeItem("token");
        //       Swal.fire({
        //         icon: "warning",
        //         title: "Session Expired",
        //         text: "Login again to continue your work.",
        //         allowOutsideClick: false,
        //         allowEscapeKey: false,
        //         showDenyButton: false,
        //         confirmButtonText: "Ok",
        //       }).then((result) => {
        //         if (result.isConfirmed) {
        //           navigate("/login");
        //         }
        //       });
        //       sessionExpiredMessageTriggered.current = true;
        //     }
        //   }
        // }, ConfigDB.data.check_user_active_timer_interval);

        waitToStart.current = false;
        console.log("user check timer started", checkUserActiveTimerId.current);
        console.log("refresh token timer started", tokenRefreshTimerId.current);
      } else {
        console.log("No Token Key Detected[Handling a Fresh Login]");
      }
    }

    return () => {
      clearInterval(tokenRefreshTimerId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Routes>
        <Route exact path="register" element={<UserLoginWithEmail />} />
        <Route
          exact
          path="forgotPassword/:customer_id/"
          element={<ForgotPasswords />}
        />
        <Route exact path="forgotPassword" element={<ForgotPasswords />} />
        <Route exact path="verifyEmail" element={<VerifyEmail />} />
        <Route
          exact
          path="resetPasswordConfirm"
          element={<ResetPasswordConfirm />}
        />

        <Route exact path="/customer/save/save/job" element={<Job />} />

        <Route exact path="userLogin/:customer_id/" element={<UserLogin />} />
        <Route exact path="userLogin" element={<UserLogin />} />
        <Route path={`/`} element={<PrivateRoute />}>
          <Route index element={<Default />} />
        </Route>
        <Route path="login">
          <Route index element={<Logins />} />
          <Route path="selectOrg" element={<LoginWithOrg />} />
        </Route>
        <Route exact path="/customer/view/:customer_id/" element={<App />}>
          <Route
            exact
            path="details/"
            element={
              <DetailIndex
                b_partner_id={currentCustomer.bpartnerId}
                client_id={currentCustomer.clientId}
                org_id={currentCustomer.orgId}
              />
            }
          />
          <Route
            exact
            path="apply_job/"
            element={
              <Job
                b_partner_id={currentCustomer.bpartnerId}
                client_id={currentCustomer.clientId}
                org_id={currentCustomer.orgId}
              />
            }
          />
          <Route
            exact
            path="update/:tab_id/"
            element={
              <SaveCustomer
                b_partner_id={currentCustomer.bpartnerId}
                client_id={currentCustomer.clientId}
                org_id={currentCustomer.orgId}
              />
            }
          />
          <Route exact path="history/" element={<History />} />
          <Route exact path="interview_video/" element={<InterviewVideo />} />
          {/* <Route exact path="cv/" element={<CVGenerator />} /> */}
          <Route index element={<Dashboard />} />
          <Route exact path="user_account/" element={<UserAccount />} />
          <Route exact path="apply_job/" element={<UserAccount/>} />
        </Route>
        <Route exact path="/customer/" element={<App />}>
          <Route exact path="new" element={<SaveCustomer />} />
          <Route exact path="view" element={<View />} />
        </Route>
        <Route exact path="/masterfile/" element={<App />}>
          <Route exact path="organization" element={<Organization />} />
          <Route exact path="country" element={<Country />} />
          <Route exact path="district" element={<District />} />
          <Route exact path="connected_source" element={<ConnectedSource />} />
          <Route exact path="nationality" element={<Nationality />} />
          <Route exact path="cc1_disposition" element={<CC1Disposition />} />
          <Route exact path="cc2_disposition" element={<CC2Disposition />} />
          <Route exact path="complexion" element={<Complexion />} />
          <Route exact path="civil_status" element={<CivilStatus />} />
          <Route exact path="document_type" element={<DocumentType />} />
          <Route exact path="customer_type" element={<CustomerType />} />
          <Route exact path="customer_mood" element={<CustomerMood />} />
          <Route exact path="network_type" element={<PhoneNumberType />} />
          <Route exact path="religion" element={<Religion />} />
          <Route exact path="gender" element={<Gender />} />
          <Route exact path="most_interested" element={<MostInterested />} />
          <Route
            exact
            path="customer_address_type"
            element={<CustomerAddressType />}
          />
          <Route exact path="passport_type" element={<PassportType />} />
          <Route exact path="education_level" element={<EducationLevel />} />
          <Route exact path="education_grade" element={<EducationGrade />} />
          <Route
            exact
            path="education_achievement"
            element={<EducationAchievement />}
          />
          <Route
            exact
            path="education_subject"
            element={<EducationSubject />}
          />
          <Route exact path="education_field" element={<EducationField />} />
          <Route
            exact
            path="profile_progress_type"
            element={<ProfileProgressType />}
          />
          <Route
            exact
            path="connected_source_line"
            element={<ConnectedSourceLine />}
          />
          <Route exact path="police_station" element={<PoliceStation />} />
          <Route exact path="city" element={<City />} />
          <Route
            exact
            path="grama_seva_division"
            element={<GramaSevaDivision />}
          />
          <Route exact path="relationship" element={<Relationship />} />
          <Route exact path="job_category" element={<JobCategory />} />
          <Route exact path="job_category_line" element={<JobCategoryLine />} />
          <Route
            exact
            path="bureau_training_center"
            element={<BureauTrainingCenter />}
          />
          <Route exact path="title" element={<Title />} />
          <Route exact path="skill" element={<Skill />} />
          <Route exact path="job_skill" element={<JobSkill />} />
          <Route exact path="language" element={<Language />} />
          <Route exact path="currency" element={<Currency />} />
        </Route>
        <Route path="/usermanagement" element={<App />}>
          <Route index element={<Role />} />
          <Route path="role" element={<Role />} />
          <Route path="role_line" element={<RoleLine />} />
          <Route path="department" element={<Department />} />
          <Route path="employee" element={<Employee />} />
          <Route path="designation" element={<Designation />} />
          <Route path="user" element={<User />} />
        </Route>
        <Route exact path="/configuration/" element={<App />}>
          <Route exact path="photo_type" element={<PhotoType />} />
          <Route exact path="client" element={<Client />} />
          <Route
            exact
            path="currency_allocate_to_country"
            element={<CurrencyAllocateToCountry />}
          />
          {/* <Route exact path="interface" element={<Interface />} />
          <Route exact path="function" element={<Function />} /> */}
        </Route>
        <Route exact path="/user" element={<App />}>
          <Route index element={<CurrentUser />} />
        </Route>
        <Route exact path="/reports/" element={<App />}>
          <Route exact path="call_reports" element={<CallReports />} />
        </Route>
      </Routes>
    </>
  );
};

export default MainRoutes;
