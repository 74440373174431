import React, { useState, useEffect, useRef } from "react";
import { HOME_PAGE_RT, LOGIN, UserConfirmation, UserEmail } from "../constant";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useAxiosTemplates from "../customHooks/useAxiosTemplates";
import FrontPageCarousel from "../components/carousel/democarousel";
// import bckImg from "../assets/images/backImg.png";
import Swal from "sweetalert2";
import { Alert, Button, Col, Row } from "reactstrap";
import {
  AlertTriangle,
  PlusCircle,
  RefreshCw,
  CheckCircle,
} from "react-feather";
import formValidation from "../customHooks/useValidation";
import { Modal } from "react-bootstrap";
import image from "../assets/images/530.jpg";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import parsePhoneNumber from "libphonenumber-js";
import axios from "axios";
import PhoneInputWithCountrySelect from "react-phone-number-input";

const UserLoginWithEmail = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const customeValidationMessages = [
    "Please enter letters and . only",
    "Please enter numbers Only",
    "Please enter digits and . only",
  ];

  const [showAlert, setShowAlert] = useState(false);
  const [value, setValue] = useState("");

  const [showAlert1, setShowAlert1] = useState(true);
  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const sendRequest = useAxiosTemplates();

  // form controls
  const [isValidated, setIsValidated] = useState(false);

  const [showModal, setShowModal] = useState(false);

  // const [value, setValue] = useState();

  const [codeList, setCodeList] = useState([{ codeId: "", name: "-Select-" }]);
  const [dpCodeValue, setDpCodeValue] = useState(0);
  const phoneInputRef = useRef(null);

  const [phone, setPhone] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [country, setCountry] = useState(""); // State for country code
  // const bimg = require("../assets/images/backImg.png");
  useEffect(() => {
    // Function to fetch country code based on IP
    const fetchCountryCode = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        setCountry(response.data.country.toLowerCase());
        console.log(response.data.country.toLowerCase());
      } catch (error) {
        console.error("Error fetching country code:", error);
        // Fallback to default country code if there's an error
        setCountry("us"); // Default country code
      }
    };

    fetchCountryCode();
  }, []);

  const handlePhoneNumberChange = (value) => {
    setPhone(value);
    setIsValid(true); // You can implement custom validation if necessary
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const validateContact = (contactNum) => {
    // const numericHeight = parseFloat(height);

    if (isValid) {
      // console.log("true");
      document
        .getElementById("id_input_fm_3_contact_number")
        .classList.remove("is-invalid");
      document
        .getElementById("id_input_fm_3_contact_number")
        .classList.add("is-valid");
      return true;
    } else {
      console.log("false");
      document
        .getElementById("id_input_fm_3_contact_number")
        .classList.remove("is-valid");
      document
        .getElementById("id_input_fm_3_contact_number")
        .classList.add("is-invalid");
      return false;
    }
  };

  const loginAuth = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      // handleOpenModal();

      // if (!isNaN(numericHeight) && (numericHeight === 0 || (numericHeight >= 120 && numericHeight <= 240))) {
      //   // console.log("true");
      //   document.getElementById("id_input_fm_1_height").classList.remove("is-invalid");
      //   document.getElementById("id_input_fm_1_height").classList.add("is-valid");
      //   return true;

      // } else {
      //   console.log("false");
      //   document.getElementById("id_input_fm_1_height").classList.remove("is-valid");
      //   document.getElementById("id_input_fm_1_height").classList.add("is-invalid");
      //   return false;
      // }

      if (!validateContact(phoneInputRef)) {
        return;
      }

      const formData = new FormData(evt.target);

      let responseData0 = new Promise((resolve) => {
        handleCloseModal();
        const result = sendRequest({
          url: "/checkEmailExist",
          data: {
            firstName: formData.get("fm_1_first_name"),
            searchKey: "EdgeWeb",
            useremail: formData.get("fm_1_email"),
          },
        });
        resolve(result);
      });
      responseData0.then((response_data) => {
        if (response_data) {
          switch (response_data.existType) {
            case 1:
              Swal.fire({
                icon: "error",
                text: "Sorry, This email already exists, Please login to the system by entering the email you registered earlier.",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            case 0:
              handleOpenModal();
              const emailDetails = {
                firstName: formData.get("fm_1_first_name"),
                lastName: formData.get("fm_1_last_name"),
                searchKey: "EdgeWeb",
                contactNumber: parseInt(phoneInputRef.current),
                useremail: formData.get("fm_1_email"),
              };
              let responseData = new Promise((resolve) => {
                const result = sendRequest({
                  url: "/getEmailConfirmation",
                  data: emailDetails,
                });
                resolve(result);
              });
              responseData.then((reponse_data) => {
                if (reponse_data) {
                  if (reponse_data.isSuccess) {
                    Swal.fire({
                      icon: "success",
                      title: "Request Successful",
                      showConfirmButton: false,
                      timer: 5000,
                    }).then((res) => {
                      setShowAlert(true);
                    });
                  }
                }
              });

              break;
            default:
              break;
          }
        }
      });

      // console.log(phoneInputRef);

      // // const formData = new FormData(evt.target);
      // const emailDetails = {
      //   firstName: formData.get("fm_1_first_name"),
      //   lastName: formData.get("fm_1_last_name"),
      //   searchKey:"EdgeWeb",
      //   // contactNumber: formData.get("fm_3_contact_number"),
      //   contactNumber: parseInt(phoneInputRef.current),
      //   useremail: formData.get("fm_1_email"),
      // };
      // let responseData = new Promise((resolve) => {

      //   handleOpenModal();

      //   const result = sendRequest({
      //     url: "/getEmailConfirmation",
      //     data: emailDetails,
      //   });
      //   resolve(result);
      // });
      // responseData.then((reponse_data) => {
      //   if (reponse_data) {
      //     if (reponse_data.isSuccess) {
      //       Swal.fire({
      //         icon: "success",
      //         title: "Request Successful",
      //         showConfirmButton: false,
      //         timer: 5000,
      //       }).then((res) => {
      //         setShowAlert(true);
      //       });
      //     }
      //     //  else {
      //     //   Swal.fire({
      //     //     icon: "error",
      //     //     text: "Sorry, This Email Already Exists",
      //     //     showConfirmButton: false,
      //     //     timer: 5000,
      //     //   });
      //     // }

      //   }
      // });
    }
  };

  const handleLogIn = (evt) => {
    evt.preventDefault();

    navigate("/userLogin");
  };

  const handleResendEmailClick = (evt) => {
    evt.preventDefault();

    navigate("/userEmail");
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1250);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1250);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  // const [value, setValue] = useState('');
  // const [isValid, setIsValid] = useState(true);

  // const handlePhoneNumberChange = (value) => {

  //   // const stringValue=String(value);
  //   // setValue(""+value);
  //   console.log("" + value);
  //   phoneInputRef.current = "" + value;
  //   //   // Validate the phone number using libphonenumber-js
  //   const phoneNumberObject = parsePhoneNumber("" + value);
  //   setIsValid(phoneNumberObject ? phoneNumberObject.isValid() : false);

  //   console.log(isValid);
  // };

  return (
    // <div>
    //   <div className="page-wrapper">
    //     <div className="container-fluid p-0">
    //       {/* <!-- login page start--> */}
    //       <div className="authentication-main">
    //         <div className="row">
    //           {/* <div className="col-md-12"> */}
    //             <div className="auth-innerright">
    //               <div className="d-flex pull-right">
    //                 <div className="authentication-box">
    //                   <div className="text-center">
    //                     {/* <span className="fs-5 fw-bold text-secondary">
    //                     WELCOME TO
    //                   </span>
    //                   <br /> */}
    //                     <span className="fs-1 fw-bold text-danger">
    //                       Hotelier Pulse
    //                     </span>
    //                     {/* <p class="fs-4" style={{ color: "black" }}> Maldives</p> */}
    //                     {/* <br />
    //                   <span className="fs-4 text-warning">E R P</span> */}
    //                   </div>

    //                   {showAlert1 && (
    //                     <Alert
    //                       className="alert-dismissible d-flex align-items-center"
    //                       // color="success"
    //                       style={{ backgroundColor: "#1abc9c" }}
    //                       isOpen={true}
    //                       toggle={() => setShowAlert1(false)}
    //                     // toggle={setShowAlert(false)}
    //                     // isOpen={true}
    //                     >
    //                       <AlertTriangle style={{ width: "40" }} />
    //                       <div className="ml-2 fw-bold" style={{ marginLeft: 20 }}>
    //                         "Welcome to Hotelier Pulse!
    //                         Unlock endless opportunities in the hospitality industry by registering with us today! Create your personalized profile, showcase your skills and
    //                         experience with your CV, explore a multitude of job vacancies tailored to your expertise, and seamlessly apply to your dream positions. Join our vibrant
    //                         community of hoteliers and embark on a journey towards career success. Register now and start shaping your future in hospitality!
    //                       </div>
    //                     </Alert>
    //                   )}

    //                   <div className="card mt-4">
    //                     <div className="card-body">
    //                       <div className="text-center">
    //                         <h4>{UserConfirmation}</h4>
    //                         {/* <h6>{"Enter your Email"} </h6> */}
    //                       </div>

    //                       <form
    //                         onSubmit={(evt) => loginAuth(evt)}
    //                         className={`form-1 ${isValidated ? "was-validated" : ""
    //                           }`}
    //                         noValidate
    //                       >

    //                         <div className="form-group mb-4">
    //                           <label
    //                             htmlFor="id_input_fm_1_first_name"
    //                             className="col-form-label pt-0 required-input-mark"
    //                           >
    //                             First Name
    //                           </label>
    //                           <input
    //                             id="id_input_fm_1_first_name"
    //                             name="fm_1_first_name"
    //                             type="text"
    //                             maxLength={250}
    //                             className="form-control"
    //                             placeholder="Enter First Name"
    //                             autoComplete="off"
    //                             onChange={(evt) =>
    //                               formValidation({
    //                                 event: evt,
    //                                 checkExpression: /[0-9!@#$%^<=>{}]+/,
    //                                 customMessage: customeValidationMessages[0],
    //                               })
    //                             }
    //                             required
    //                           />
    //                         </div>

    //                         <div className="form-group mb-4">
    //                           <label htmlFor="id_input_fm_1_last_name">Last Name</label>
    //                           <input
    //                             id="id_input_fm_1_last_name"
    //                             name="fm_1_last_name"
    //                             type="text"
    //                             maxLength={250}
    //                             className="form-control"
    //                             placeholder="Enter Last Name"
    //                             autoComplete="off"
    //                             onChange={(evt) =>
    //                               formValidation({
    //                                 event: evt,
    //                                 validateName: true,
    //                               })
    //                             }
    //                           />
    //                         </div>

    //                         <div className="form-group mb-4">
    //                           <label
    //                             htmlFor="id_input_fm_3_contact_number"
    //                           // className="col-form-label pt-0 required-input-mark"
    //                           >
    //                             Contact Number
    //                           </label>

    //                           <PhoneInput
    //                             id="id_input_fm_3_contact_number"
    //                             name="fm_3_contact_number"
    //                             className="form-control"
    //                             international
    //                             countryCallingCodeEditable={false}
    //                             defaultCountry="RU"
    //                             value={value}
    //                             onChange={handlePhoneNumberChange}
    //                           // onChange={setValue}
    //                           // style={{ width: '300px' }}
    //                           // required
    //                           />
    //                           {!isValid && <p style={{ color: 'red' }}>Invalid phone number</p>}

    //                           {/* <div className="form-group col-sm-2 mb-2 mb-sm-0">
    //                           <div className="d-flex">
    //                             <label
    //                               htmlFor="id_input_fm_1_sa_nationality_id"
    //                               className="col-form-label pt-0"
    //                             >
    //                               Code
    //                             </label>
    //                           </div>
    //                           <select
    //                             id="id_input_fm_1_sa_code_id"
    //                             name="fm_1_sa_code_id"
    //                             className="form-select"
    //                            onChange={(evt) => setDpCodeValue(evt.target.value)}
    //                            value={dpCodeValue}
    //                           >
    //                            {codeList.length
    //                               ? codeList.map((listItem, index) => (
    //                                 <option value={listItem?.codeId} key={index}>
    //                                   {listItem?.name}
    //                                 </option>
    //                               ))
    //                               : null}
    //                           </select>
    //                         </div>

    //                         <div className="form-group col-10">
    //                           <label
    //                             htmlFor="id_input_fm_3_contact_number"
    //                           >
    //                             Contact Number
    //                           </label>
    //                           <input
    //                             id="id_input_fm_3_contact_number"
    //                             name="fm_3_contact_number"
    //                             type="text"
    //                             maxLength={15}
    //                             minLength={9}
    //                             className="form-control"
    //                             placeholder="Enter Contact Number"
    //                             autoComplete="off"
    //                             onChange={(evt) =>
    //                               formValidation({
    //                                 event: evt,
    //                                 validateNumber: true,
    //                               })
    //                             }
    //                           />
    //                         </div> */}
    //                         </div>

    //                         <div className="form-group mb-4">
    //                           <label
    //                             htmlFor="id_input_fm_1_email"
    //                             className="col-form-label pt-0 required-input-mark"
    //                           >
    //                             {UserEmail}
    //                           </label>
    //                           <input
    //                             id="id_input_fm_1_email"
    //                             name="fm_1_email"
    //                             className="form-control"
    //                             placeholder={UserEmail}
    //                             type="email"
    //                             onChange={(evt) =>
    //                               formValidation({
    //                                 event: evt,
    //                                 validateEmail: true,
    //                               })
    //                             }
    //                             required
    //                           />
    //                         </div>

    //                         <div>
    //                           {/* Your content here */}
    //                           <Modal show={showModal} onHide={handleCloseModal}>

    //                             <Modal.Header closeButton>
    //                               <Modal.Title ><h4 className="text-center ml-129">Email Confirmation</h4></Modal.Title>
    //                             </Modal.Header>

    //                             <div className="d-flex justify-content-center align-items-center">
    //                               <img
    //                                 className="align-self-center mx-auto img-100 rounded-circle blur-up lazyloaded"

    //                                 src={

    //                                   image
    //                                 }
    //                                 alt="header-user"

    //                               />
    //                             </div>

    //                             <Modal.Body>
    //                               <h6 className="fw-semibold" style={{ letterSpacing: "0.1em" }}>
    //                                 We will send an email to confirm the validity of your email address. After receiving the email, click the link provided to complete your registration.</h6>
    //                             </Modal.Body>
    //                             {/* <Modal.Footer className="d-flex justify-content-between align-items-center">
    //                             <div className="d-flex justify-content-between align-items-center ">
    //                               <h6 className="fw-semibold">
    //                                 If you haven't received any email.
    //                                 <Link
    //                                   to="#"
    //                                   className="link-info"
    //                                   id="resendEmail" onClick={(evt) => handleResendEmailClick(evt)}>
    //                                   Resend Confirmation Email
    //                                 </Link>
    //                               </h6>
    //                             </div>
    //                           </Modal.Footer> */}
    //                           </Modal>
    //                         </div>

    //                         <div className="form-group form-row mt-3 mb-0 d-grid">
    //                           <button className="btn btn-primary" type="submit">
    //                             Create account
    //                           </button>
    //                         </div>

    //                         <div className="mt-3">
    //                           <label
    //                             htmlFor="id_input_fm_1_first_name"
    //                           >
    //                             Already have an the Hotelier Pulse account?
    //                           </label>

    //                           <Link
    //                             to="#"
    //                             className="link-info"
    //                             id="forgotPassword" onClick={(evt) => handleLogIn(evt)}>
    //                             Log in
    //                           </Link>
    //                         </div>

    //                       </form>
    //                     </div>
    //                   </div>
    //                 </div>
    //               {/* </div> */}
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       {/* <!-- login page end--> */}
    //     </div>
    //   </div>
    // </div>
    <div className="d-flex" style={{ height: "100vh" }}>
      {/* <div
        className="bg-image"
        style={{
          backgroundImage: bimg,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "50%",
        }}
      ></div> */}
      <div className="container-fluid p-0 ">
        {/* <!-- login page start--> */}
        <Row className="authentication-main">


          <Col lg={8} md={12} sm={12}>
            <div className="mt-5 pt-5 flex justify-content-center align-item-center">
              <FrontPageCarousel />
            </div>
          </Col>
          <Col lg={4} md={12} sm={12}>
            {/* </Row> */}
            <div className="">
              <div className="d-flex justify-content-center">

                {" "}
                {/* Added justify-content-end */}
                {/* <div className="col-md-12"> */}
                <div className="auth-innerright">
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    {" "}
                    {/* Modified to align-items-end */}
                    <div className="">
                      <div className="text-center">
                        <span className="fs-1 fw-bold text-danger">
                          Hotelier Pulse
                        </span>
                      </div>

                      {showAlert1 && !isMobile &&(
                        <Alert
                          className="alert-dismissible d-flex align-items-center"
                          style={{ backgroundColor: "#1abc9c" }}
                          isOpen={true}
                          toggle={() => setShowAlert1(false)}
                        >
                          <AlertTriangle style={{ width: "150" }} />
                          <div className="ml-2 fw-bold pt-3" style={{ marginLeft: 20 }}>
                            "Welcome to Hotelier Pulse! Unlock endless opportunities
                            in the hospitality industry by registering with us
                            today! Create your personalized profile, showcase your
                            skills and experience with your CV, explore a multitude
                            of job vacancies tailored to your expertise, and
                            seamlessly apply to your dream positions. Join our
                            vibrant community of hoteliers and embark on a journey
                            towards career success. Register now and start shaping
                            your future in hospitality!
                          </div>
                        </Alert>
                      )}

                      <div className="card mt-4">
                        <div className="card-body">
                          <div className="text-center">
                            <h4>{UserConfirmation}</h4>
                          </div>

                          <form
                            onSubmit={(evt) => loginAuth(evt)}
                            className={`form-1 ${isValidated ? "was-validated" : ""
                              }`}
                            noValidate
                          >
                            <div className="form-group mb-4">
                              <label
                                htmlFor="id_input_fm_1_first_name"
                                className="col-form-label pt-0 required-input-mark"
                              >
                                First Name
                              </label>
                              <input
                                id="id_input_fm_1_first_name"
                                name="fm_1_first_name"
                                type="text"
                                maxLength={250}
                                className="form-control"
                                placeholder="Enter First Name"
                                autoComplete="off"
                                onChange={(evt) =>
                                  formValidation({
                                    event: evt,
                                    checkExpression: /[0-9!@#$%^<=>{}]+/,
                                    customMessage: customeValidationMessages[0],
                                  })
                                }
                                required
                              />
                            </div>

                            <div className="form-group mb-4">
                              <label htmlFor="id_input_fm_1_last_name">
                                Last Name
                              </label>
                              <input
                                id="id_input_fm_1_last_name"
                                name="fm_1_last_name"
                                type="text"
                                maxLength={250}
                                className="form-control"
                                placeholder="Enter Last Name"
                                autoComplete="off"
                                onChange={(evt) =>
                                  formValidation({
                                    event: evt,
                                    validateName: true,
                                  })
                                }
                              />
                            </div>
                            {/* 
                        <div className="form-group mb-4">
                          <label htmlFor="id_input_fm_3_contact_number">
                            Contact Number
                          </label>

                          <PhoneInputWithCountrySelect
                            id="id_input_fm_3_contact_number"
                            country={country}
                            value={phone}
                            onChange={handlePhoneNumberChange}
                            type="text"
                            inputProps={{
                              name: "fm_3_contact_number",
                              required: true,
                              autoFocus: true,
                              className: "form-control",
                            }}
                          />
                          {!isValid && (
                            <p style={{ color: "red" }}>Invalid phone number</p>
                          )}
                        </div> */}
                            <div className="form-group mb-4">
                              <label htmlFor="id_input_fm_3_contact_number">
                                Contact Number
                              </label>
                              <PhoneInput
                                id="id_input_fm_3_contact_number"
                                name="fm_3_contact_number"
                                className="form-control"
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="LK"
                                value={value}
                                onChange={handlePhoneNumberChange}
                              />
                              {!isValid && (
                                <p style={{ color: "red" }}>Invalid phone number</p>
                              )}
                            </div>

                            <div className="form-group mb-4">
                              <label
                                htmlFor="id_input_fm_1_email"
                                className="col-form-label pt-0 required-input-mark"
                              >
                                {UserEmail}
                              </label>
                              <input
                                id="id_input_fm_1_email"
                                name="fm_1_email"
                                className="form-control"
                                placeholder={UserEmail}
                                type="email"
                                onChange={(evt) =>
                                  formValidation({
                                    event: evt,
                                    validateEmail: true,
                                  })
                                }
                                required
                              />
                            </div>

                            <div>
                              <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                  <Modal.Title>
                                    <h4 className="text-center ml-129">
                                      Email Confirmation
                                    </h4>
                                  </Modal.Title>
                                </Modal.Header>
                                <div className="d-flex justify-content-center align-items-center">
                                  <img
                                    className="align-self-center mx-auto img-100 rounded-circle blur-up lazyloaded"
                                    src={image}
                                    alt="header-user"
                                  />
                                </div>
                                <Modal.Body>
                                  <h6
                                    className="fw-semibold"
                                    style={{ letterSpacing: "0.1em" }}
                                  >
                                    We will send an email to confirm the validity of
                                    your email address. After receiving the email,
                                    click the link provided to complete your
                                    registration.
                                  </h6>
                                </Modal.Body>
                              </Modal>
                            </div>

                            <div className="form-group form-row mt-3 mb-0 d-grid">
                              <button className="btn btn-primary" type="submit">
                                Create account
                              </button>
                            </div>

                            <div className="mt-3">
                              <label htmlFor="id_input_fm_1_first_name">
                                Already have a Hotelier Pulse account?
                              </label>

                              <Link
                                to="#"
                                className="link-info"
                                id="forgotPassword"
                                onClick={(evt) => handleLogIn(evt)}
                              >
                                Log in
                              </Link>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Col>


        </Row>
        {/* <!-- login page end--> */}
      </div>
    </div >
    // </div>
  );
};

export default UserLoginWithEmail;