import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";
import { Alert } from "reactstrap";
import { AlertTriangle, ArrowLeft, ArrowRight, Check, PlusCircle, RefreshCw, X } from "react-feather";

// custom components
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../common/datatable";

// customer hooks
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../customHooks/useValidation";
// import { ConvertMillisToDate } from "../../../../data/util/DateUtil";
import ConfigDB from "../../../../data/customizer/config";
import {
  INSERT_COUNTRY, COUNTRY,
} from "../../../../constant";
const ExpectedCountry = ({ b_partner_id = 0, client_id = 0, org_id = 0, set_basic_tab = null, set_back_tab = null }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshDpCountry, setRefreshDpCountry] = useState(false);

  // form values

  const [countryList, setCountryList] = useState([
    { countryId: "", name: "-Select-" },
  ]);
  const [dpCountryValue, setDpCountryValue] = useState(0);

  const [expectedCountryId, setExpectedCountryId] = useState(0);

  const [isSuccess, setIsSuccess] = useState(false);

  // table data
  const [expectedCountryRowList, setExpectedCountryRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Expected Country",
    // "Remarks",
    // {
    //   name: "Active",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <BooleanValue value={value} />;
    //     },
    //   },
    // },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];

  // table functions
  const [editExpectedCountry] = useState(() => (expectedCountry_id, response_data) => {
    const editingExpectedCountry = response_data.find(
      (item) => item.expectedCountryId === expectedCountry_id
    );
    setExpectedCountryId(editingExpectedCountry.expectedCountryId);
    setDpCountryValue(editingExpectedCountry.countryId);
    document.querySelector("textarea[name='fm_4_remark']").value =
      editingExpectedCountry.remark;
    // document.querySelector("input[name='fm_4_is_active']").checked =
    //   editingExpectedCountry.active;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  const [deleteExpectedCountry] = useState(() => (expectedCountry_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this expected country record!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          expectedCountryId: expectedCountry_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "deleteExpectedCountryTab",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Expected country record has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);

      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/checkExpectedCountryExist",
          data: {
            clientId: parseInt(loggedUser.clientId),
            bpartnerId: parseInt(b_partner_id),
            expectedCountryId: parseInt(expectedCountryId),
            countryId: parseInt(dpCountryValue),
          },
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          switch (response_data.existType) {
            case 0:
              let expectedCountry = {
                bpartnerId: parseInt(b_partner_id),
                expectedCountryId: parseInt(expectedCountryId),
                clientId: parseInt(client_id),
                orgId: parseInt(org_id),
                userId: parseInt(loggedUser.userId),
                countryId: parseInt(dpCountryValue),
                remark: formData.get("fm_4_remark"),
                isActive: true,
                // isActive: formData.get("fm_4_is_active") ? true : false,
              };
              let nestedResponseData = new Promise((resolve) => {
                const result = sendRequest({
                  url: "/saveExpectedCountryTab",
                  data: expectedCountry,
                });
                resolve(result);
              });

              nestedResponseData.then((nested_reponse_data) => {
                if (nested_reponse_data) {
                  Swal.fire({
                    icon: "success",
                    title: "Request Successful",
                    text: expectedCountryId
                      ? "Expected country has successfully updated."
                      : "New Eexpected country has successfully added.",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  setIsLoaded(LOADING);
                  setRefreshTable(true);
                  setIsSuccess(true);
                }

              });
              break;

            case 1:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Country Exists",
                showConfirmButton: false,
                timer: 5000,
              });

              break;
            default:
              break;
          }
        }
      });

      // let expectedCountry = {
      //   bpartnerId: parseInt(b_partner_id),
      //   expectedCountryId: parseInt(expectedCountryId),
      //   clientId: parseInt(client_id),
      //   orgId: parseInt(org_id),
      //   userId: parseInt(loggedUser.userId),
      //   countryId: parseInt(dpCountryValue),
      //   remark: formData.get("fm_4_remark"),
      //   isActive: formData.get("fm_4_is_active") ? true : false,
      // };
      // let responseData = new Promise((resolve) => {
      //   const result = sendRequest({
      //     url: "/saveExpectedCountryTab",
      //     data: expectedCountry,
      //   });
      //   resolve(result);
      // });
      // responseData.then((reponse_data) => {
      //   console.log(reponse_data);
      //   if (reponse_data) {
      //     Swal.fire({
      //       icon: "success",
      //       title: "Request Successful",
      //       text: expectedCountryId
      //         ? "Expected Country has successfully updated."
      //         : "New Expected Country has successfully added.",
      //       showConfirmButton: false,
      //       timer: 5000,
      //     });
      //     setIsLoaded(LOADING);
      //     setRefreshTable(true);
      //   }
      // });
    }
  };

  const resetForm = (evt) => {
    evt.preventDefault();
    // setIsValidated(false);
    // setExpectedCountryId(0);
    // setDpCountryValue(0);

    // let inputFields = document.querySelectorAll(".form-control");
    // let customValidationMessages =
    //   document.querySelectorAll(".input-validation");
    // inputFields.forEach((field) => {
    //   if (field.classList.contains("is-valid")) {
    //     field.classList.remove("is-valid");
    //   } else if (field.classList.contains("is-invalid")) {
    //     field.classList.remove("is-invalid");
    //   }
    // });
    // customValidationMessages.forEach((element) => {
    //   if (element.classList.contains("input-validation")) {
    //     if (!element.classList.contains("d-none")) {
    //       element.classList.add("d-none");
    //     }
    //   }
    // });

    Swal.fire({
      title: 'Are you sure you want to reset?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {

        setExpectedCountryId(0);
        setDpCountryValue(0);

        document.getElementById('id_input_fm_4_remark').value = '';

        setIsValidated(false);
      }
    });

    setIsSuccess(false)
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/viewExpectedCountryTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            if (response_data.activeCountryList) {
              setCountryList([
                { countryId: "", name: "-Select-" },
                ...response_data.activeCountryList,
              ]);
            }

            if (response_data.allTableList) {
              let tempList = [];
              response_data.allTableList.forEach((listItem) => {
                tempList.push([
                  listItem.expectedCountryId,
                  listItem.country,
                  // listItem.remark,
                  // listItem.active ? "True" : "False",
                  <ControlButtons
                    editMethod={() =>
                      editExpectedCountry(
                        listItem.expectedCountryId,
                        response_data.allTableList
                      )
                    }
                    disableEdit={!checkIsAccessible(EDITFUNCTION)}
                    deleteMethod={() =>
                      deleteExpectedCountry(listItem.expectedCountryId, loggedUser)
                    }
                    disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                  />,
                ]);
              });
              setExpectedCountryRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    if (refreshDpCountry) {
      console.log("try");
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveExpectedCountry",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setExpectedCountryRowList([
            { countryId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpCountry(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpCountry]);

  const closeItem = () => {
    setIsSuccess(false);
  };

  const handleBackClick = () => {
    set_back_tab()
  };

  const handleNextClick = () => {
    set_basic_tab()
  };

  return (
    <>
      <div className="card-modified-body bg-white pt-0 mb-4">
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={b_partner_id === 0}
        >
          <AlertTriangle />
          <p className="ml-2">
            You must create a customer, before add language details.
          </p>
        </Alert>
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-4 ${isValidated ? "was-validated" : ""
            }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-row mb-4">
            <div className="form-group col-12 col-sm-3 position-relative">

              {/* <div className="form-group col-12 col-sm-8 mb-3 mb-sm-0 position-relative"> */}
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_1_sa_country_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Expected Country
                </label>
                {/* <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${refreshDpCountry ? "rotate-icon" : ""
                    }`}
                  height={"14px"}
                  onClick={() => setRefreshDpCountry(true)}
                />
                <PlusCircle
                  className={`mt-1 dp-refresh-icon`}
                  height={"14px"}
                  onClick={() => openMasterFileWindow(COUNTRY, INSERT_COUNTRY)}
                /> */}
              </div>
              <select
                id="id_input_fm_1_sa_country_id"
                name="fm_1_sa_country_id"
                className="form-select"
                onChange={(evt) => setDpCountryValue(evt.target.value)}
                value={dpCountryValue}
                required
              >
                {countryList.length
                  ? countryList.map((listItem, index) => (
                    <option value={listItem?.countryId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select a country
              </small>
            </div>
          </div>


          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_4_remark">Remark</label>
            <textarea
              id="id_input_fm_4_remark"
              name="fm_4_remark"
              maxLength={250}
              rows={3}
              className="form-control"
              placeholder="Enter Remark"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  checkExpression: "",
                })
              }
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          {/* {(checkIsAccessible(INSERTFUNCTION) && expectedCountryId === 0) ||
            (checkIsAccessible(DEACTIVATEFUNCTION) && expectedCountryId !== 0) ? (
            <div className="form-group mb-4">
              <div className="checkbox checkbox-solid-dark col-12 col-sm-6">
                <input
                  id="id_input_fm_4_is_active"
                  name="fm_4_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_3_is_active">Active</label>
              </div>
            </div>
          ) : null} */}
          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
              (checkIsAccessible(EDITFUNCTION) && expectedCountryId !== 0) ? (
              <div className="d-sm-flex justify-content-end">

                <button
                  className="btn btn-outline-primary col-md-1 col-3 px-1 mb-2 mb-sm-0"
                  type="reset"
                >
                  Reset
                </button>
                <button
                  className="btn btn-primary col-md-2 col-4 mx-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {expectedCountryId ? "Update" : "Submit"}
                </button>

                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleBackClick()}><ArrowLeft height={"16px"} width={"16px"} />Back</button>

                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleNextClick()}>Next<ArrowRight height={"16px"} width={"16px"} /></button>

              </div>
            ) : null
          ) : null}

          <br />

          {isSuccess && (
            <div className="col-sm-12 mb-2">
              <div className="bg-primary px-3 py-3 rounded d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div
                    style={{
                      backgroundColor: "#FFEBB2",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Check
                      height={"16px"}
                      style={{ strokeWidth: 5 }}
                      className="txt-primary"
                    />
                  </div>
                  <div>
                    <div className="mb-1 d-flex align-items-center">
                      <h6
                        style={{ color: "#FFEBB2" }}
                        className="uppercase px-1 m-0"
                      >
                        {/* {searchKey} */}
                      </h6>
                      <div
                        style={{ backgroundColor: "#FFEBB2", height: "5px" }}
                        className="w-100 rounded ml-2"
                      ></div>
                    </div>
                    <div style={{ color: "#FFEBB2", marginLeft: 15 }} className="fw-bold">
                      {/* <UserCheck height={"14px"} style={{ strokeWidth: 3 }} /> */}
                      {/* {` ${firstName}’s`}  */}
                      Do you want to add a another expected country detail.
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className=" d-flex justify-content-end"                >
                    <div>
                      <button
                        type="reset"
                        className="btn 
                            rounded btn-sm "
                        style={{ backgroundColor: "#FFEBB2" }}
                      >

                        <span className="fw-bold">Add</span>
                      </button>
                    </div>
                  </div>
                  <div className="mx-2 d-flex"
                  >
                    <div style={{ marginTop: -15 }} >
                      <span
                        onClick={closeItem}
                        style={{
                          cursor: "pointer",
                        }}
                      > <X style={{ color: "#FFEBB2" }} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )}

        </form>
      </div>
      <div className="mb-4">
        <Datatable
          titleData="Expected Country List"
          columnData={tableColumnHeaderList}
          rowData={expectedCountryRowList}
          csvFilename="Expected Country.csv"
        />
      </div>
    </>
  );
};
export default ExpectedCountry;