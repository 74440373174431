import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
// import "./i18n";
import { HashRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

// ** Import custom components for redux **
import { Provider } from "react-redux";
import store from "./store";

//config data
import configDB from "./data/customizer/config";

import MainRoutes from "./routes";

const Root = () => {
  useEffect(() => {
    const abortController = new AbortController();
    const color = localStorage.getItem("color");
    const layout =
      localStorage.getItem("layout_version") ||
      configDB.data.color.layout_version;
    document.body.classList.add(layout);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    document
      .getElementById("color")
      .setAttribute(
        "href",
        `${process.env.PUBLIC_URL}/assets/css/${color}.css`
      );

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <HashRouter>
          <MainRoutes />
        </HashRouter>
      </Provider>
    </div>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
