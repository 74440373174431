import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";

// custom components
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../../common/datatable";

// customer hooks
import useAxiosTemplates from "../../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../../customHooks/useValidation";
import ConfigDB from "../../../../../data/customizer/config";
import { ArrowLeft, ArrowRight, Check, PlusCircle, RefreshCw, X } from "react-feather";
import {
  INSERT_PHONE_NUMBER_TYPE,
  PHONE_NUMBER_TYPE,
} from "../../../../../constant";

const ContactNumber = ({ b_partner_id = 0, client_id = 0, org_id = 0, set_basic_tab = null, set_back_tab = null }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshDpNetworkType, setRefreshDpNetworkType] = useState(false);

  // form values
  const [phoneNumberTypeList, setPhoneNumberTypeList] = useState([
    { networkTypeId: "", name: "-Select-" },
  ]);
  const [dpNumberTypeValue, setDpNumberTypeValue] = useState(0);
  const [contactNumberId, setContactNumberId] = useState(0);

  const [isSuccess, setIsSuccess] = useState(false);

  // table data
  const [contactNumberRowList, setContactNumberRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Phone Number Type",
    "Contact Number",
    // {
    //   // name: "Default",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <BooleanValue value={value} />;
    //     },
    //   },
    // },

    // "Remarks",
    // {
    //   name: "Active",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <BooleanValue value={value} />;
    //     },
    //   },
    // },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editNumber] = useState(() => (contact_number_id, response_data) => {
    const editingNumber = response_data.find(
      (item) => item.contactNumberId === contact_number_id
    );
    setContactNumberId(editingNumber.contactNumberId);
    setDpNumberTypeValue(editingNumber.networkTypeId);
    document.querySelector("input[name='fm_3_contact_number']").value =
      editingNumber.contactNumber;

    // document.querySelector("input[name='fm_3_is_default']").checked =
    //   editingNumber.isDefault;

    document.querySelector("textarea[name='fm_3_remark']").value =
      editingNumber.remark;
    // document.querySelector("input[name='fm_3_is_active']").checked =
    //   editingNumber.isActive;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  const [deleteNumber] = useState(() => (contact_number_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this contact number!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          contactNumberId: contact_number_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "customer/deleteContactNo",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Contact number record has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);

      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/checkContactExist",
          data: {
            clientId: parseInt(loggedUser.clientId),
            bpartnerId: parseInt(b_partner_id),
            orgId: parseInt(org_id),
            contactNumberId: parseInt(contactNumberId),
            networkTypeId: parseInt(dpNumberTypeValue),
            contactNumber: formData.get("fm_3_contact_number"),
          },
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          switch (response_data.existType) {
            case 0:
              let contactNumber = {
                bpartnerId: parseInt(b_partner_id),
                contactNumberId: parseInt(contactNumberId),
                clientId: parseInt(client_id),
                orgId: parseInt(org_id),
                userId: parseInt(loggedUser.userId),
                contactNumber: formData.get("fm_3_contact_number"),
                networkTypeId: parseInt(dpNumberTypeValue),
                remark: formData.get("fm_3_remark"),
                isActive: true,
                // isActive: formData.get("fm_3_is_active") ? true : false,
                isDefault: formData.get("fm_3_is_default") ? true : false,
              };
              let nestedResponseData = new Promise((resolve) => {
                const result = sendRequest({
                  url: "/customer/saveContactNoTab",
                  data: contactNumber,
                });
                resolve(result);
              });
              nestedResponseData.then((nested_reponse_data) => {
                if (nested_reponse_data) {
                  Swal.fire({
                    icon: "success",
                    title: "Request Successful",
                    text: contactNumberId
                      ? "Contact number has successfully updated."
                      : "New contact number has successfully added.",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  setIsLoaded(LOADING);
                  setRefreshTable(true);
                  setIsSuccess(true);
                }
              });
              break;
            case 1:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Contact Number Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            default:
              break;
          }
        }
      });



      // let contactNumber = {
      //   bpartnerId: parseInt(b_partner_id),
      //   contactNumberId: parseInt(contactNumberId),
      //   clientId: parseInt(client_id),
      //   orgId: parseInt(org_id),
      //   userId: parseInt(loggedUser.userId),
      //   contactNumber: formData.get("fm_3_contact_number"),
      //   networkTypeId: parseInt(dpNumberTypeValue),
      //   remark: formData.get("fm_3_remark"),
      //   isActive: formData.get("fm_3_is_active") ? true : false,
      //   isDefault: formData.get("fm_3_is_default") ? true : false,
      // };
      // let responseData = new Promise((resolve) => {
      //   const result = sendRequest({
      //     url: "/customer/saveContactNoTab",
      //     data: contactNumber,
      //     template: "CONTROL_DATA",
      //   });
      //   resolve(result);
      // });
      // responseData.then((reponse_data) => {
      //   if (reponse_data) {
      //     Swal.fire({
      //       icon: "success",
      //       title: "Request Successful",
      //       text: contactNumberId
      //         ? "Contact Number has successfully updated."
      //         : "New Contact Number has successfully added.",
      //       showConfirmButton: false,
      //       timer: 5000,
      //     });
      //     setIsLoaded(LOADING);
      //     setRefreshTable(true);
      //   }
      // });
    }
  };

  const resetForm = (evt) => {
    evt.preventDefault();

    // setIsValidated(false);
    // setDpNumberTypeValue(0);
    // setContactNumberId(0);
    // let inputFields = document.querySelectorAll(".form-control");
    // let customValidationMessages =
    //   document.querySelectorAll(".input-validation");
    // inputFields.forEach((field) => {
    //   if (field.classList.contains("is-valid")) {
    //     field.classList.remove("is-valid");
    //   } else if (field.classList.contains("is-invalid")) {
    //     field.classList.remove("is-invalid");
    //   }
    // });
    // customValidationMessages.forEach((element) => {
    //   if (element.classList.contains("input-validation")) {
    //     if (!element.classList.contains("d-none")) {
    //       element.classList.add("d-none");
    //     }
    //   }
    // });

    Swal.fire({
      title: 'Are you sure you want to reset?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {

        setIsValidated(false);
        setDpNumberTypeValue(0);
        setContactNumberId(0);
        document.getElementById('id_input_fm_3_remark').value = '';
        document.getElementById('id_input_fm_3_contact_number').value = '';

        setIsValidated(false);
      }
    });

    setIsSuccess(false)
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewContactNoTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            if (response_data.activeNetworkTypeList) {
              setPhoneNumberTypeList([
                { networkTypeId: "", name: "-Select-" },
                ...response_data.activeNetworkTypeList,
              ]);
            }
            if (response_data.allCustomerContactNoTableList) {
              let tempList = [];
              response_data.allCustomerContactNoTableList.forEach(
                (listItem) => {
                  tempList.push([
                    listItem.contactNumberId,
                    listItem.networkTypeName,
                    listItem.contactNumber,
                    // listItem.isDefault ? "True" : "False",
                    // listItem.remark,
                    // listItem.isActive ? "True" : "False",
                    <ControlButtons
                      editMethod={() =>
                        editNumber(
                          listItem.contactNumberId,
                          response_data.allCustomerContactNoTableList
                        )
                      }
                      disableEdit={!checkIsAccessible(EDITFUNCTION)}
                      deleteMethod={() =>
                        deleteNumber(listItem.contactNumberId, loggedUser)
                      }
                      disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                    />,
                  ]);
                }
              );
              setContactNumberRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    if (refreshDpNetworkType) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveNetworkType",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setPhoneNumberTypeList([
            { networkTypeId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpNetworkType(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpNetworkType]);

  const closeItem = () => {
    setIsSuccess(false);
  };


  const handleChangeEditContactNumber = (value) => {


    console.log('Handle Change EditLanguage method called with value:', value);

    if (b_partner_id > 0) {
      const reqBody = {
        bpartnerId: parseInt(b_partner_id),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        networkTypeId: parseInt(value),
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/getContactDetails",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          if (response_data.length > 0) {

            console.log(response_data);
            setContactNumberId(response_data[0].contactNumberId);
            setDpNumberTypeValue(value);


            document.querySelector("input[name='fm_3_contact_number']").value =
            response_data[0].number;

            document.querySelector("textarea[name='fm_3_remark']").value =
            response_data[0].remark;


          } else {
            setContactNumberId(0);
            setDpNumberTypeValue(value);

            // document.querySelector("input[name='fm_3_contact_number']").value = "";
            // document.querySelector("textarea[name='fm_3_remark']").value = "";
          }
          // setIsLoaded(LOADED);
          // setRefreshTable(false);
        }
      });

    } else {
      setIsLoaded(LOADED);
    }

  };

  const handleBackClick = () => {
    set_back_tab()
  };

  const handleNextClick = () => {
    set_basic_tab()
  };

  return (
    <>
      <div className="card-modified-body mb-4">
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-3 ${isValidated ? "was-validated" : ""
            }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-row mb-4">
            <div className="form-group col-12 col-sm-4 mb-3 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_3_sa_network_type_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Phone Number Type
                </label>
                {/* <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${refreshDpNetworkType ? "rotate-icon" : ""
                    }`}
                  height={"14px"}
                  onClick={() => setRefreshDpNetworkType(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(
                      PHONE_NUMBER_TYPE,
                      INSERT_PHONE_NUMBER_TYPE
                    )
                  }
                /> */}
              </div>
              <select
                id="id_input_fm_3_sa_network_type_id"
                name="fm_3_sa_network_type_id"
                className="form-select"
                onChange={(evt) => {
                  setDpNumberTypeValue(evt.target.value)
                  handleChangeEditContactNumber(evt.target.value);
                }
                }
                value={dpNumberTypeValue}
                required
              >
                {phoneNumberTypeList.length
                  ? phoneNumberTypeList.map((listItem, index) => (
                    <option value={listItem?.networkTypeId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select a phone number type
              </small>
            </div>
            <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
              <label
                htmlFor="id_input_fm_3_contact_number"
                className="required-input-mark"
              >
                Contact Number
              </label>
              <input
                id="id_input_fm_3_contact_number"
                name="fm_3_contact_number"
                type="text"
                maxLength={15}
                minLength={9}
                className="form-control"
                placeholder="Enter Contact Number"
                autoComplete="off"
                onChange={(evt) =>
                  formValidation({
                    event: evt,
                    validateNumber: true,
                  })
                }
                required
              />
              <div className="invalid-feedback position-absolute">
                Please enter a contact number.
              </div>
              <small className="txt-danger position-absolute d-none input-validation"></small>
            </div>

            {/* <div className="form-group col-12 col-sm-4 d-flex align-items-end">
              <div className="checkbox checkbox-solid-dark">
                <input
                  id="id_input_fm_3_is_default"
                  name="fm_3_is_default"
                  type="checkbox"
                />
                <label htmlFor="id_input_fm_3_is_default" className="mt-0 mb-0">
                  Default
                </label>
              </div>
            </div> */}
          </div>
          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_3_remark">Remarks</label>
            <textarea
              id="id_input_fm_3_remark"
              name="fm_3_remark"
              maxLength={250}
              rows="3"
              className="form-control"
              placeholder="Enter Remarks"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  checkExpression: "",
                })
              }
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          {/* {(checkIsAccessible(INSERTFUNCTION) && contactNumberId === 0) ||
            (checkIsAccessible(DEACTIVATEFUNCTION) && contactNumberId !== 0) ? (
            <div className="form-group mb-4">
              <div className="checkbox checkbox-solid-dark col-12 col-sm-6">
                <input
                  id="id_input_fm_3_is_active"
                  name="fm_3_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_3_is_active">Active</label>
              </div>
            </div>
          ) : null} */}
          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
              (checkIsAccessible(EDITFUNCTION) && contactNumberId !== 0) ? (
              <div className="d-sm-flex justify-content-end">

                <button
                  className="btn btn-outline-primary col-md-1 col-3 px-1 mb-2 mb-sm-0"
                  type="reset"
                >
                  Reset
                </button>
                <button
                  className="btn btn-primary col-md-2 col-4 mx-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {contactNumberId ? "Update" : "Submit"}
                </button>


                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => set_back_tab("save_customer_tab_4")}><ArrowLeft height={"16px"} width={"16px"} />Back</button>

                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleNextClick()}>Next<ArrowRight height={"16px"} width={"16px"} /></button>

              </div>
            ) : null
          ) : null}

          <br />

          {isSuccess && (
            <div className="col-sm-12 mb-2">
              <div className="bg-primary px-3 py-3 rounded d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div
                    style={{
                      backgroundColor: "#FFEBB2",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Check
                      height={"16px"}
                      style={{ strokeWidth: 5 }}
                      className="txt-primary"
                    />
                  </div>
                  <div>
                    <div className="mb-1 d-flex align-items-center">
                      <h6
                        style={{ color: "#FFEBB2" }}
                        className="uppercase px-1 m-0"
                      >
                        {/* {searchKey} */}
                      </h6>
                      <div
                        style={{ backgroundColor: "#FFEBB2", height: "5px" }}
                        className="w-100 rounded ml-2"
                      ></div>
                    </div>
                    <div style={{ color: "#FFEBB2", marginLeft: 15 }} className="fw-bold">
                      {/* <UserCheck height={"14px"} style={{ strokeWidth: 3 }} /> */}
                      {/* {` ${firstName}’s`}  */}
                      Do you want to add a another contact number detail.
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className=" d-flex justify-content-end"                >
                    <div>
                      <button
                        type="reset"
                        className="btn 
                            rounded btn-sm "
                        style={{ backgroundColor: "#FFEBB2" }}
                      >
                        <span className="fw-bold">Add</span>
                      </button>
                    </div>
                  </div>
                  <div className="mx-2 d-flex"
                  >
                    <div style={{ marginTop: -15 }} >
                      <span
                        onClick={closeItem}
                        style={{
                          cursor: "pointer",
                        }}
                      > <X style={{ color: "#FFEBB2" }} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )}


        </form>
      </div>
      <div className="mb-4">
        <Datatable
          titleData="Contact Number List"
          columnData={tableColumnHeaderList}
          rowData={contactNumberRowList}
          csvFilename="Contact Number.csv"
        />
      </div>
    </>
  );
};

export default ContactNumber;
// min length validation
