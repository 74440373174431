import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useAccess from "../../../../customHooks/useAccess";
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import { ArrowLeft, ArrowRight, Clock } from "react-feather";
import DetailRowLayout from "../../../common/DetailRowLayout";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  CSL_TYPE_LIST,
  LOADED,
  LOADING,
} from "../../../../constant";
import {
  ConvertMillisToDate,
  ConvertMillisToDateTime,
} from "../../../../data/util/DateUtil";
import { LoadStaticURL } from "../../../../data/util/LoadStaticURL";
import NoDetailsFound from "../../../../assets/images/not-details-found.png";

const ConnectedSource = ({ b_partner_id = 0, client_id = 0, org_id = 0, set_basic_tab = null, set_back_tab = null }) => {
  const EDITFUNCTION = "EditCustomer";

  let navigate = useNavigate();
  const { customer_id } = useParams();

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // form controls
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [customerData, setCustomerData] = useState(undefined);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const sendRequest = useAxiosTemplates();

  useEffect(() => {
    const reqBody = {
      clientId: client_id,
      orgId: org_id,
      userId: loggedUser.userId,
      bpartnerId: b_partner_id,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/viewProfileConnectedSourceTab",
        data: reqBody,
        check_for_errors: false,
      });
      resolve(result);
    }).then((response_data) => {
      setCustomerData(response_data);
      setIsLoaded(LOADED);
    });
  }, []);

  const handleBackClick = () => {
    set_back_tab()
  };

  const handleNextClick = () => {
    set_basic_tab()
  };

  return (
    <div className="card-modified-body bg-white pt-0 mb-4">
      <div>{isLoaded === LOADING ? <Skeleton className="w-25" /> : null}</div>
      <div>
        {isLoaded === LOADED ? (
          customerData.created ? (
            <div
              className="d-flex align-items-center mb-2"
            // style={{ minWidth: "max-content" }}
            >
              <Clock height="12px" className="text-secondary"></Clock>
              <div className="text-secondary">
                {customerData.updated === customerData.created
                  ? "Created "
                  : "Last Modified "}
                on {ConvertMillisToDate(customerData.updated)} By{" "}
                {customerData.updatedBy}
              </div>
            </div>
          ) : null
        ) : (
          <Skeleton className="w-25" />
        )}
      </div>
      <div>
        {isLoaded === LOADED ? (
          customerData.created ? (
            <div>
              <DetailRowLayout
                display={isLoaded === LOADED}
                labels={[
                  // "Connected Source",
                  "How you got to know about us",
                  "Remark"
                  // "Connected Source Line",
                  // "Noticed Time",
                ]}
                data={
                  isLoaded === LOADED
                    ? [
                      customerData.connectedSourceName,
                      customerData.remark
                      // customerData.connectedSourceLineName,
                      // ConvertMillisToDateTime(
                      //   customerData.noticedTime
                      // ).replace("T", " "),
                    ]
                    : null
                }
              />
              {/* <DetailRowLayout
                display={isLoaded === LOADED}
                labels={[
                  isLoaded === LOADED
                    ? CSL_TYPE_LIST.find(
                        (list_item) =>
                          list_item.value ===
                          customerData.connectedSourceLineType
                      ).name
                    : "",
                ]}
                data={
                  isLoaded === LOADED
                    ? [customerData.connectedBpartnerName]
                    : null
                }
              /> */}
              {/* <DetailRowLayout
                display={isLoaded === LOADED}
                labels={["Remark"]}
                data={isLoaded === LOADED ? [customerData.remark] : null}
              /> */}
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center">
              <img src={LoadStaticURL(NoDetailsFound)} alt="" width={"200px"} />
              <p className="ml-2 fs-5 fw-bold text-warning">
                No Details Inserted.
              </p>
            </div>
          )
        ) : (
          // Loading Skeleton
          <>
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={[
                "Connected Source",
                "Remark"
                // "Connected Source Line",
                // "Noticed Time",
              ]}
              data={[]}
            />
            {/* <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["BPartner Name"]}
              data={[]}
            />
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Remark"]}
              data={[]}
            /> */}
          </>
        )}
        <div className="d-sm-flex justify-content-end mt-4">
          {checkIsAccessible(EDITFUNCTION) && b_partner_id !== 0 && isLoaded ? (
            <button
              onClick={() =>
                navigate(
                  `/customer/view/${customer_id}/update/save_customer_tab_14/`
                )
              }
              className="btn btn-primary col-12 col-md-2 mx-3 mb-2 mb-sm-0"
            >
              {isLoaded === LOADED && customerData.created
                ? "Update"
                : "Insert"}
            </button>
          ) : null}

          <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleBackClick()}><ArrowLeft height={"16px"} width={"16px"} />Back</button>

          <button type="button" class="btn btn-link col-md-1 col-2 px-2 d-flex align-items-center" onClick={() => handleNextClick()}>Next<ArrowRight height={"16px"} width={"16px"} /></button>

        </div>
      </div>
    </div>
  );
};

export default ConnectedSource;
