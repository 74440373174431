// hooks
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// custom hooks
import useAccess from "../../customHooks/useAccess";
import useAxiosTemplates from "../../customHooks/useAxiosTemplates";

// components
import Breadcrumb from "../common/breadcrumb";
import Swal from "sweetalert2";

// constants
import {
  CURRENCY_ALLOCATE_TO_COUNTRY,
  EDIT_CURRENCY_ALLOCATE_TO_COUNTRY,
  LOADED,
  LOADING,
} from "../../constant";
import Datatable, { ControlButtons } from "../common/datatable";

const CurrencyAllocateToCountry = () => {
  // breadcrumb values
  let childLinks = [
    { value: "Configurations", active: false },
    { value: "Currency Allocate To Country", active: true },
  ];

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(
    loggedUser,
    CURRENCY_ALLOCATE_TO_COUNTRY
  );
  const sendRequest = useAxiosTemplates();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);
  const [isLoaded, setIsLoaded] = useState(LOADING);

  // form controls
  const [isValidated, setIsValidated] = useState(false);

  // form values
  const [countryList, setCountryList] = useState([]);
  const [dpCountryValue, setDpCountryValue] = useState(0);
  const [currencyList, setCurrencyList] = useState([]);

  // table data
  const [currencyRowList, setCurrencyRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Country",
    "Currency",
    {
      name: "",
      options: {
        display: true,
        // checkIsAccessible(EDIT_CURRENCY) ||
        // checkIsAccessible(DELETE_CURRENCY),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editCurrency] = useState(() => (response_data) => {
    setDpCountryValue(response_data.countryId);
    document
      .querySelectorAll("input[name='fm_1_currency_id']")
      .forEach((element) => (element.checked = false));
    response_data.currencyList.forEach(
      (currency_item) =>
        (document.querySelector(
          `#id_input_fm_1_currency_id_${currency_item.currencyId}`
        ).checked = true)
    );
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  // fetch master data
  useEffect(() => {
    if (refreshTable) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/viewCurrencyAllocateToCountry",
          data: reqBody,
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          setCountryList([
            { countryId: "", name: "-Select-" },
            ...response_data.activeCountryList,
          ]);
          let tempList = [];
          response_data.allCountryCurrencyAllocatedList.forEach((list_item) => {
            console.log(list_item);
            tempList.push([
              list_item.countryId,
              list_item.countryName,
              list_item.currencyList
                .map((currency_item) => currency_item.name)
                .join(" , "),
              <ControlButtons
                editMethod={() => editCurrency(list_item)}
                disableEdit={
                  !checkIsAccessible(EDIT_CURRENCY_ALLOCATE_TO_COUNTRY)
                }
                disableDelete={true}
              />,
            ]);
            // }
          });
          setCurrencyRowList([...tempList]);
          setIsLoaded(LOADED);
        }
      });
      setRefreshTable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    const reqBody = {
      clientId: loggedUser.clientId,
    };
    let responseData = new Promise((resolve) => {
      const result = sendRequest({
        url: "/getAllActiveCurrency",
        data: reqBody,
      });
      resolve(result);
    });
    responseData.then((response_data) => {
      if (response_data) {
        setCurrencyList([...response_data]);
        setIsLoaded(LOADED);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // form control functions
  const resetForm = () => {
    setIsValidated(false);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };

  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    const formData = new FormData(evt.target);
    const selectedCurrencies = formData
      .getAll("fm_1_currency_id")
      .map((item) => item.split("__"));
    if (selectedCurrencies.length) {
      let element = document.querySelector(".permission-validation");
      if (!element.classList.contains("d-none")) {
        element.classList.add("d-none");
      }
      if (evt.target.checkValidity()) {
        const currencies = [];
        selectedCurrencies.forEach((item) => currencies.push(item[1]));
        let skillSet = {
          clientId: parseInt(loggedUser.clientId),
          orgId: parseInt(loggedUser.orgId),
          userId: parseInt(loggedUser.userId),
          countryId: parseInt(dpCountryValue),
          allocatedCurrencyList: currencies,
        };
        setIsLoaded(LOADING);
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "/saveCurrencyAllocateToCountry",
            data: skillSet,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Currencies have successfully updated",
              showConfirmButton: false,
              timer: 5000,
            });
            setIsLoaded(LOADED);
            setRefreshTable(true);
            setDpCountryValue(0);
          }
        });
      }
    } else {
      validatePermission();
    }
  };

  const validatePermission = (evt) => {
    const formData = new FormData(document.querySelector(".form-1"));
    const selectedFunctions = formData
      .getAll("fm_1_currency_id")
      .map((item) => item.split("__"));
    if (selectedFunctions.length) {
      let element = document.querySelector(".permission-validation");
      if (!element.classList.contains("d-none")) {
        element.classList.add("d-none");
      }
    } else {
      let element = document.querySelector(".permission-validation");
      if (element.classList.contains("d-none")) {
        element.classList.remove("d-none");
      }
    }
  };

  return (
    <>
      <Breadcrumb
        parent="Dashboard"
        title="Configurations"
        children={childLinks}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card position-relative">
              {isLoaded === LOADING ? (
                <div className="form-loader d-flex flex-column justify-content-center align-items-center">
                  <div className="loader-box">
                    <div className="loader">
                      <div className="line bg-primary"></div>
                      <div className="line bg-primary"></div>
                      <div className="line bg-primary"></div>
                      <div className="line bg-primary"></div>
                    </div>
                  </div>
                  <div>Loading...</div>
                </div>
              ) : null}
              <div className="card-header">
                <h5>Currency Allocate To Country</h5>
                <span>Master File to map Currency Codes with Countries</span>
              </div>
              <div className="card-body">
                <form
                  tabIndex={1}
                  onSubmit={(evt) => submitForm(evt)}
                  onReset={(evt) => resetForm(evt)}
                  className={`form-1 ${isValidated ? "was-validated" : ""}`}
                  noValidate
                >
                  <div className="form-row mb-4">
                    <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_country_id"
                        className="col-form-label pt-0"
                      >
                        Country
                      </label>
                      <select
                        id="id_input_fm_1_sa_country_id"
                        name="fm_1_sa_country_id"
                        className="form-select"
                        onChange={(evt) => setDpCountryValue(evt.target.value)}
                        value={dpCountryValue}
                        required
                      >
                        {countryList.length
                          ? countryList.map((listItem, index) => (
                              <option value={listItem?.countryId} key={index}>
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a Country
                      </small>
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="id_input_fm_1_currencies">
                      Currency / Currencies
                    </label>
                    <div className="carousel-container p-2 form-row mx-0 mt-1 border rounded">
                      {currencyList?.length
                        ? [
                            ...Array(Math.ceil(currencyList.length / 3)).keys(),
                          ].map((listItem, index) => (
                            <>
                              <div
                                className={`d-sm-flex align-items-center col-12 col-md-4 border-end`}
                              >
                                <div className="mx-4">
                                  <div className="form-group">
                                    <div className="checkbox checkbox-solid-dark">
                                      <input
                                        id={`id_input_fm_1_currency_id_${
                                          currencyList[index * 3]?.currencyId
                                        }`}
                                        name="fm_1_currency_id"
                                        type="checkbox"
                                        value={`crr__${
                                          currencyList[index * 3]?.currencyId
                                        }`}
                                      />
                                      <label
                                        htmlFor={`id_input_fm_1_currency_id_${
                                          currencyList[index * 3]?.currencyId
                                        }`}
                                      >
                                        {currencyList[index * 3]?.name}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {index * 3 + 1 !== currencyList.length ? (
                                <>
                                  <div
                                    className={`d-sm-flex align-items-center col-12 col-md-4 border-end`}
                                  >
                                    <div className="mx-4">
                                      <div className="form-group">
                                        <div className="checkbox checkbox-solid-dark">
                                          <input
                                            id={`id_input_fm_1_currency_id_${
                                              currencyList[index * 3 + 1]
                                                ?.currencyId
                                            }`}
                                            name="fm_1_currency_id"
                                            type="checkbox"
                                            value={`crr__${
                                              currencyList[index * 3 + 1]
                                                ?.currencyId
                                            }`}
                                          />
                                          <label
                                            htmlFor={`id_input_fm_1_currency_id_${
                                              currencyList[index * 3 + 1]
                                                ?.currencyId
                                            }`}
                                          >
                                            {currencyList[index * 3 + 1]?.name}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {index * 3 + 2 !== currencyList.length ? (
                                    <div
                                      className={`d-sm-flex align-items-center col-12 col-md-4`}
                                    >
                                      <div className="mx-4">
                                        <div className="form-group">
                                          <div className="checkbox checkbox-solid-dark">
                                            <input
                                              id={`id_input_fm_1_currency_id_${
                                                currencyList[index * 3 + 2]
                                                  ?.currencyId
                                              }`}
                                              name="fm_1_currency_id"
                                              type="checkbox"
                                              value={`crr__${
                                                currencyList[index * 3 + 2]
                                                  ?.currencyId
                                              }`}
                                            />
                                            <label
                                              htmlFor={`id_input_fm_1_currency_id_${
                                                currencyList[index * 3 + 2]
                                                  ?.currencyId
                                              }`}
                                            >
                                              {
                                                currencyList[index * 3 + 2]
                                                  ?.name
                                              }
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </>
                              ) : null}
                            </>
                          ))
                        : null}
                    </div>
                    <small className="permission-validation txt-danger position-absolute d-none input-validation">
                      Select at least 1 Currency
                    </small>
                  </div>
                  <div className="d-sm-flex justify-content-end">
                    <button
                      className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                      type="submit"
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-warning col-12 col-sm-2"
                      type="reset"
                    >
                      Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <Datatable
            titleData="Currency List"
            columnData={tableColumnHeaderList}
            rowData={currencyRowList}
          />
        </div>
      </div>
    </>
  );
};

export default CurrencyAllocateToCountry;
