export class ConfigDB {
  static data = {
    developmentMode: true,
    // isPassport: true,
    backendServerUrl:
    "https://pulse.onesapro.com:9000/HotelierPulse/",
      //"https://hotelier.saprosolutions.com:9000/HotelierPulse",
      // "https://cd99-37-60-229-178.ngrok-free.app/HotelierPulse/",
      // "http://192.168.1.201:9000/",
    // "http://localhost:8080/EdgeBackend-0.0.1-SNAPSHOT/",
    // "https://293b-2402-d000-8104-839-942f-d48f-b0ff-da5d.ngrok-free.app",
    // "https://theedgebe.saprosolutions.com/EdgeErpBackEnd/",
    //"https://theedgebe.saprosolutions.com/EdgeErpBackEnd/",
    // "https://theedgebe.saprosolutions.com/",
    fileServerUrl: "https://pulse.onesapro.com/EdgeErpBackEnd/Image",
    //"https://hotelier.saprosolutions.com/EdgeErpBackEnd/Image",
    // fileServerUrl: "https://erp.deshakthee.com/image",
    // fileServerUrl: "http://192.168.1.151/Image",
    documentServerUrl: "https://pulse.onesapro.com/EdgeErpBackEnd/Document",
    //"https://hotelier.saprosolutions.com/EdgeErpBackEnd/Document",
    // "https://theedgebe.saprosolutions.com/EdgeErpBackEnd/Document",
    // documentServerUrl: "http://192.168.1.151/Document",
    // documentServerUrl: "http://theedge.saprosolutions.com/Document", 
    videoServerUrl: "https://pulse.onesapro.com/EdgeErpBackEnd/Video",
    //"https://theedgebe.saprosolutions.com/EdgeErpBackEnd/Video",
    // videoServerUrl: "https://erp.deshakthee.com/video",
    // videoServerUrl: "http://theedge.saprosolutions.com/Video",
    // videoServerUrl: "http://192.168.1.151/Video",
    hosted_url:
      // "http://localhost/DeshaktheeERP/#",
      // "http://localhost:3000/#",
      //"https://hotelier.saprosolutions.com/#",
      "https://pulse.onesapro.com/#",
    //"https://hotelier.saprosolutions.com/#",
    token_refresh_time_interval_in_millis: 1000 * 60 * 30, //1800000, //30m
    session_expiration_time: 2400000, //40m
    check_user_active_timer_interval: 5000, //5sec
    visiting_customer_check_timer_interval: 1000 * 60 * 10, // 10 min
    max_file_input_size_in_bytes: 5242880, //5MB
    max_video_input_size_in_bytes: 104857600, //50MB
    settings: {
      layout_type: "ltr",
      sidebar: { wrapper: "default", bodyWrapper: "default" },
      sidebar_setting: "default-sidebar",
      sidebar_backround: "dark-sidebar",
      hash_router: true,
      apache_folder_name: "TheEdgeERP",
    },
    color: {
      layout_version: "light",
      color: "color-1",
      primary_color: "#1B998B",
      secondary_color: "#2e9de4",
      mix_layout: "default",
    },
    router_animation: "fadeIn",
  };
}

export default ConfigDB;
