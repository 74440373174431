import React, { Fragment, useState } from "react";

// components
import { AlertTriangle, Phone, Send } from "react-feather";
import { Home } from "react-feather";
import { Alert, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// tab panes
import Address from "./Address";
import ContactNumber from "./ContactNumber";
import SocialMedia from "./SocialMedia";
import EmergencyContact from "./EmergencyContact";

const Contact = ({ b_partner_id = 0, client_id = 0, org_id = 0, set_back_tab = null, set_basic_tab = null }) => {
  const [nestedTab, setNestedTab] = useState("contact_tab_1");

  return (
    <Fragment>
      <div className="px-4 bg-white">
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={b_partner_id === 0}
        >
          <AlertTriangle />
          <p className="ml-2">
            You must create a customer, before add contact details.
          </p>
        </Alert>
        <div>
          <div className="d-none d-sm-block">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`fw-bold d-flex align-items-center ${nestedTab === "contact_tab_1" ? "active" : "txt-primary"
                    }`}
                  onClick={() => setNestedTab("contact_tab_1")}
                >
                  <Phone
                    height={"16px"}
                    width={"16px"}
                    style={{ marginRight: "8px" }}
                  />
                  Contact Number
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`fw-bold d-flex align-items-center ${nestedTab === "contact_tab_2" ? "active" : "txt-primary"
                    }`}
                  onClick={() => setNestedTab("contact_tab_2")}
                >
                  <Home
                    height={"16px"}
                    width={"16px"}
                    style={{ marginRight: "8px" }}
                  />
                  Address
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`fw-bold d-flex align-items-center ${nestedTab === "contact_tab_3" ? "active" : "txt-primary"
                    }`}
                  onClick={() => setNestedTab("contact_tab_3")}
                >
                  <Send
                    height={"16px"}
                    width={"16px"}
                    style={{ marginRight: "8px" }}
                  />
                  Social Media
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`fw-bold d-flex align-items-center ${nestedTab === "contact_tab_4" ? "active" : "txt-primary"
                    }`}
                  onClick={() => setNestedTab("contact_tab_4")}
                >
                  <Phone
                    height={"16px"}
                    width={"16px"}
                    style={{ marginRight: "8px" }}
                  />
                  Emergency Contact
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
        <div className="px-0 pb-0">
          <TabContent activeTab={nestedTab}>
            <TabPane className="fade show" tabId="contact_tab_1">
              {nestedTab === "contact_tab_1" ?
                <ContactNumber
                  b_partner_id={b_partner_id}
                  client_id={client_id}
                  org_id={org_id}
                  set_basic_tab={() => setNestedTab("contact_tab_2")}
                  set_back_tab={set_back_tab}
                />
                : null}
            </TabPane>
            <TabPane tabId="contact_tab_2">
              {nestedTab === "contact_tab_2" ?
                <Address
                  b_partner_id={b_partner_id}
                  client_id={client_id}
                  org_id={org_id}
                  set_basic_tab={() => setNestedTab("contact_tab_3")}
                  set_back_tab={() => setNestedTab("contact_tab_1")}
                />
                : null}
            </TabPane>
            <TabPane tabId="contact_tab_3">
              {nestedTab === "contact_tab_3" ?
                <SocialMedia
                  b_partner_id={b_partner_id}
                  client_id={client_id}
                  org_id={org_id}
                  set_basic_tab={() => setNestedTab("contact_tab_4")}
                  set_back_tab={() => setNestedTab("contact_tab_2")}
                />
                : null}
            </TabPane>
            <TabPane tabId="contact_tab_4">
              {nestedTab === "contact_tab_4" ?
                <EmergencyContact
                  b_partner_id={b_partner_id}
                  client_id={client_id}
                  org_id={org_id}
                  set_back_tab={() => setNestedTab("contact_tab_3")}
                  set_basic_tab={set_basic_tab}
                />
                : null}
            </TabPane>
          </TabContent>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
