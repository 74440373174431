import { REMOVE_CUSTOMER, STORE_CUSTOMER, UPDATE_CUSTOMER } from "../../redux/actionTypes";

const initial_state = {
  clientId: 0,
  orgId: 0,
  bpartnerId: 0,
  searchKey: "",
  name: "",
  profilePicture: "",
};

const customerReducer = (state = initial_state, action) => {
  switch (action.type) {
    case STORE_CUSTOMER:
      return {
        ...state,
        clientId: action.payload.clientId,
        orgId: action.payload.orgId,
        bpartnerId: action.payload.bpartnerId,
        searchKey: action.payload.searchKey,
        name: action.payload.name,
        profilePicture: action.payload.profilePicture,
      };

    case UPDATE_CUSTOMER:
      return {
        ...state,
        name: action.payload.name,
        profilePicture: action.payload.profilePicture,
      };

    case REMOVE_CUSTOMER:
      return {
        clientId: 0,
        orgId: 0,
        bpartnerId: 0,
        searchKey: "",
        name: "",
        profilePicture: "",
      };

    default:
      return { ...state };
  }
};

export default customerReducer;
