import React from "react";
import { Range, getTrackBackground } from "react-range";
import ConfigDB from "../../data/customizer/config";

const RangeSlider = ({ rangeValues, step, min, max, onChange }) => {
  return (
    <Range
      values={rangeValues}
      step={step}
      min={min}
      max={max}
      onChange={onChange}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            height: "36px",
            display: "flex",
            width: "100%",
          }}
        >
          <output style={{ marginTop: "30px" }}>{rangeValues[0]}</output>
          <div
            ref={props.ref}
            style={{
              height: "5px",
              width: "100%",
              marginLeft: "30px",
              marginRight: "30px",
              borderRadius: "4px",
              background: getTrackBackground({
                values: rangeValues,
                colors: ["#ccc", ConfigDB.data.color.primary_color, "#ccc"],
                min: min,
                max: max,
              }),
              alignSelf: "center",
            }}
          >
            {children}
          </div>
          <output style={{ marginTop: "30px" }}>{rangeValues[1]}</output>
        </div>
      )}
      renderThumb={({ props, isDragged }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: "42px",
            width: "42px",
            borderRadius: "4px",
            backgroundColor: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 2px 6px #AAA",
          }}
        >
          <div
            style={{
              height: "16px",
              width: "5px",
              backgroundColor: isDragged ? "#7366ff" : "#CCC",
            }}
          />
        </div>
      )}
    />
  );
};

export default RangeSlider;
