import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";
import { Alert } from "reactstrap";
import { AlertTriangle, ArrowLeft, ArrowRight, Check, PlusCircle, RefreshCw, X } from "react-feather";

// custom components
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../common/datatable";
import FileUploader from "../../../common/fileUploader";

// customer hooks
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../customHooks/useValidation";
import { ConvertMillisToDate } from "../../../../data/util/DateUtil";
import ConfigDB from "../../../../data/customizer/config";

//import 'flatpickr/dist/flatpickr.min.css'; // Import the CSS file


import {
  // INSERT_PASSPORT_TYPE, PASSPORT_TYPE
  INSERT_COUNTRY, COUNTRY,
} from "../../../../constant";

const Passport = ({ b_partner_id = 0, client_id = 0, org_id = 0, passport_number = null, set_basic_tab = null, set_back_tab = null }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";
  const MAXFILELIMIT = 5;

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  // helper containers
  const [attachmentList, setAttachmentList] = useState([]);


  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);
  const [previewFileList, setPreviewFileList] = useState([]);

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  // const [refreshDpPassportType, setRefreshDpPassportType] = useState(false);
  const [refreshDpCountry, setRefreshDpCountry] = useState(false);

  // form values
  // const [passportTypeList, setPassportList] = useState([
  //   { passportTypeId: "", name: "-Select-" },
  // ]);
  // const [dpPassportTypeValue, setDpPassportTypeValue] = useState(0);

  const [CountryList, setCountryList] = useState([
    { countryId: "", name: "-Select-" },
  ]);
  const [dpCountryValue, setDpCountryValue] = useState(0);

  const [passportId, setPassportId] = useState(0);

  const [isSuccess, setIsSuccess] = useState(false);

  // const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  // const [selectedImage, setSelectedImage] = useState(null);

  // table data
  const [passportRowList, setPassportRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Number",
    // "Passport Type",
    // "Country",
    "Issue Date",
    "Expire Date",
    // {
    //   name: "Collected",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <BooleanValue value={value} />;
    //     },
    //   },
    // },
    // "Remarks",
    // {
    //   name: "Active",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <BooleanValue value={value} />;
    //     },
    //   },
    // },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editPassport] = useState(() => (passport_id, response_data) => {
    const editingPassport = response_data.find(
      (item) => item.passportId === passport_id
    );
    setPassportId(editingPassport.passportId);
    // setDpPassportTypeValue(editingPassport.passportTypeId);
    setDpCountryValue(editingPassport.countryId);
    document.querySelector("input[name='fm_5_passport_no']").value =
      editingPassport.passportNo;
    document.querySelector("input[name='fm_5_issue_date']").value =
      ConvertMillisToDate(editingPassport.issueDate);
    document.querySelector("input[name='fm_5_expire_date']").value =
      ConvertMillisToDate(editingPassport.expireDate);
    // document.querySelector("input[name='fm_5_is_collected']").checked =
    //   editingPassport.isCollected;
    document.querySelector("textarea[name='fm_5_remark']").value =
      editingPassport.remark;
    // document.querySelector("input[name='fm_5_is_active']").checked =
    //   editingPassport.isActive;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const reqBody = {
      passportId: editingPassport.passportId,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/getPassportAttachmentListByPassportId",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {



      if (response_data) {
        let tempList = [];
        let tempList2 = [];
        response_data.forEach((listItem) => {
          tempList.push({
            documentId: listItem.id,
            isModified: false,
            attachmentExtension: "",
            attachment: "",
          });
          tempList2.push({
            id: listItem.id,
            type: listItem.filePath.split(".")[1],
            source: listItem.filePath,
          });
        });
        console.log(tempList2);
        setPreviewFileList(tempList2);
        setAttachmentList([...tempList]);
        setIsLoaded(LOADED);
        setRefreshTable(false);
      }
    });
  });
  const [deletePassport] = useState(() => (passport_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this passport record!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          passportId: passport_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "customer/deletePassport",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Passport record has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });


  // form control functions


  const validateDates = (startDateInput, endDateInput) => {
    const startDate = new Date(startDateInput);
    const endDate = new Date(endDateInput);


    if (startDate >= endDate) {

      Swal.fire({
        icon: "error",
        title: "Request Failed",
        text: "Invalid date range. Please ensure the issue date is before the expire date.",
        showConfirmButton: false,
        timer: 5000,
      });
      document.getElementById("id_input_fm_5_expire_date").classList.add("is-invalid");
      return false;
    }

    // // Additional check to ensure the start date is not equal to the end date
    if (startDate.getTime() === endDate.getTime()) {
      Swal.fire({
        icon: "error",
        title: "Request Failed",
        text: "Issue date and expire date should not be equal.",
        showConfirmButton: false,
        timer: 5000,
      });
      document.getElementById("id_input_fm_5_expire_date").classList.add("is-invalid");
      return false;
    }
    document.getElementById("id_input_fm_5_expire_date").classList.remove("is-invalid");
    return true; // Dates are valid
  };



  function formatDate(inputDate) {
    const date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Convert the date to "mm/dd/yy" format
      const month = date.getMonth() + 1; // JavaScript months are 0-indexed
      const day = date.getDate();
      const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year

      return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    }
    return inputDate; // Return the original date if it couldn't be parsed
  }


  const submitForm = (evt) => {

    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {


      const startDateInput = document.getElementById("id_input_fm_5_issue_date").value;
      const endDateInput = document.getElementById("id_input_fm_5_expire_date").value;

      // Standardize dates to "mm/dd/yy" format
      const standardizedStartDate = formatDate(startDateInput);
      const standardizedEndDate = formatDate(endDateInput);


      // Validate dates
      if (!validateDates(standardizedStartDate, standardizedEndDate)) {
        // Validation failed, prevent form submission
        return;
      }


      const formData = new FormData(evt.target);


      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/checkPassportExist",
          data: {
            clientId: parseInt(loggedUser.clientId),
            bpartnerId: parseInt(b_partner_id),
            passportId: parseInt(passportId),
            countryId: parseInt(dpCountryValue),
            passportNo: formData.get("fm_5_passport_no"),
          },
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          switch (response_data.existType) {
            case 0:
              let passport = {
                bpartnerId: parseInt(b_partner_id),
                passportId: parseInt(passportId),
                clientId: parseInt(client_id),
                orgId: parseInt(org_id),
                userId: parseInt(loggedUser.userId),
                passportNo: formData.get("fm_5_passport_no"),
                // passportTypeId: parseInt(dpPassportTypeValue),
                countryId: parseInt(dpCountryValue),
                issueDate: formData.get("fm_5_issue_date"),
                expireDate: formData.get("fm_5_expire_date"),
                remark: formData.get("fm_5_remark"),
                isActive: true,
                // isActive: formData.get("fm_5_is_active") ? true : false,
                isCollected: formData.get("fm_5_is_collected") ? true : false,
                attachmentList: attachmentList,
              };
              let nestedResponseData = new Promise((resolve) => {
                const result = sendRequest({
                  url: "/customer/savePassportTab",
                  data: passport,

                });
                resolve(result);
              });

              nestedResponseData.then((nested_reponse_data) => {
                if (nested_reponse_data) {
                  Swal.fire({
                    icon: "success",
                    title: "Request Successful",
                    text: passportId
                      ? "Passport has successfully updated."
                      : "New passport has successfully added.",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  setIsLoaded(LOADING);
                  setRefreshTable(true);
                  setIsSuccess(true);
                }

              });
              break;

            case 1:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Country Exists",
                showConfirmButton: false,
                timer: 5000,
              });

              break;
            default:
              break;
          }
        }
      });





      // let passport = {
      //   bpartnerId: parseInt(b_partner_id),
      //   passportId: parseInt(passportId),
      //   clientId: parseInt(client_id),
      //   orgId: parseInt(org_id),
      //   userId: parseInt(loggedUser.userId),
      //   passportNo: formData.get("fm_5_passport_no"),
      //   // passportTypeId: parseInt(dpPassportTypeValue),
      //   countryId:parseInt(dpCountryValue),
      //   issueDate: formData.get("fm_5_issue_date"),
      //   expireDate: formData.get("fm_5_expire_date"),
      //   remark: formData.get("fm_5_remark"),
      //   isActive: formData.get("fm_5_is_active") ? true : false,
      //   isCollected: formData.get("fm_5_is_collected") ? true : false,
      //   attachmentList: attachmentList,
      // };
      // console.log(passport);
      // let responseData = new Promise((resolve) => {
      //   const result = sendRequest({
      //     url: "/customer/savePassportTab",
      //     data: passport,
      //     template: "CONTROL_DATA",
      //   });
      //   resolve(result);
      // });
      // responseData.then((reponse_data) => {
      //   if (reponse_data) {
      //     Swal.fire({
      //       icon: "success",
      //       title: "Request Successful",
      //       text: passportId
      //         ? "Passport has successfully updated."
      //         : "New Passport has successfully added.",
      //       showConfirmButton: false,
      //       timer: 5000,
      //     });
      //     setIsLoaded(LOADING);
      //     setRefreshTable(true);
      //   }
      // });
    }
  };

  const resetForm = (evt) => {

    evt.preventDefault();

    // let inputFields = document.querySelectorAll(".form-control");
    // let customValidationMessages =
    //   document.querySelectorAll(".input-validation");
    // inputFields.forEach((field) => {
    //   if (field.classList.contains("is-valid")) {
    //     field.classList.remove("is-valid");
    //   } else if (field.classList.contains("is-invalid")) {
    //     field.classList.remove("is-invalid");
    //   }
    // });
    // customValidationMessages.forEach((element) => {
    //   if (element.classList.contains("input-validation")) {
    //     if (!element.classList.contains("d-none")) {
    //       element.classList.add("d-none");
    //     }
    //   }
    // });

    Swal.fire({
      title: 'Are you sure you want to reset?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {


        setIsValidated(false);
        setPassportId(0);
        setDpCountryValue(0);
        setAttachmentList([]);
        setPreviewFileList([]);


        document.getElementById('id_input_fm_5_passport_no').value = '';
        document.getElementById('id_input_fm_5_remark').value = '';
        document.getElementById('id_input_fm_5_issue_date').value = '';
        document.getElementById('id_input_fm_5_expire_date').value = '';

        setIsValidated(false);
      }
    });

    setIsSuccess(false)
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewPassportTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            // if (response_data.activePassportTypeList) {
            //   setPassportList([
            //     { passportTypeId: "", name: "-Select-" },
            //     ...response_data.activePassportTypeList,
            //   ]);
            // }

            // if (passport_number !== "") {
            //   document.querySelector("input[ name='fm_5_passport_no']").value =
            // passport_number;
            // }


            if (response_data.activeCountryList) {
              setCountryList([
                { countryId: "", name: "-Select-" },
                ...response_data.activeCountryList,
              ]);
            }
            if (response_data.allCustomerPassportTableList) {
              let tempList = [];
              response_data.allCustomerPassportTableList.forEach((listItem) => {
                tempList.push([
                  listItem.passportId,
                  listItem.passportNo,
                  // listItem.passportTypeName,

                  // listItem.countryName,
                  ConvertMillisToDate(listItem.issueDate),
                  ConvertMillisToDate(listItem.expireDate),
                  // listItem.isCollected ? "True" : "False",
                  // listItem.remark,
                  // listItem.isActive ? "True" : "False",
                  <ControlButtons
                    editMethod={() =>
                      editPassport(
                        listItem.passportId,
                        response_data.allCustomerPassportTableList
                      )
                    }
                    disableEdit={!checkIsAccessible(EDITFUNCTION)}
                    deleteMethod={() =>
                      deletePassport(listItem.passportId, loggedUser)
                    }
                    disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                  />,
                ]);



              });
              setPassportRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  const closeItem = () => {
    setIsSuccess(false);
  };

  // useEffect(() => {
  //   if (refreshDpPassportType) {
  //     const reqBody = {
  //       clientId: loggedUser.clientId,
  //     };
  //     new Promise((resolve) => {
  //       const result = sendRequest({
  //         url: "/getAllActivePassportType",
  //         data: reqBody,
  //       });
  //       resolve(result);
  //     }).then((response_data) => {
  //       if (response_data) {
  //         setPassportList([
  //           { passportTypeId: "", name: "-Select-" },
  //           ...response_data,
  //         ]);
  //         setRefreshDpPassportType(false);
  //       }
  //     });
  //   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [refreshDpPassportType]);

  // useEffect(() => {
  //   if (refreshDpCountry) {
  //     const reqBody = {
  //       clientId: loggedUser.clientId,
  //     };
  //     new Promise((resolve) => {
  //       const result = sendRequest({
  //         url: "/getAllActiveCountry",
  //         data: reqBody,
  //       });
  //       resolve(result);
  //     }).then((response_data) => {
  //       if (response_data) {
  //         setCountryList([
  //           { countryId: "", name: "-Select-" },
  //           ...response_data,
  //         ]);
  //         setRefreshDpCountry(false);
  //       }
  //     });
  //   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [refreshDpCountry]);

  const handleBackClick = () => {
    set_back_tab()
  };

  const handleNextClick = () => {
    set_basic_tab()
  };

  return (
    <>
      <div className="card-modified-body bg-white pt-0 mb-4">
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={b_partner_id === 0}
        >
          <AlertTriangle />
          <p className="ml-2">
            You must create a customer, before add passport details.
          </p>
        </Alert>
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-5 ${isValidated ? "was-validated" : ""
            }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-row mb-4">
            <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
              <label
                htmlFor="id_input_fm_5_passport_no"
                className="required-input-mark"
              >
                Number
              </label>
              <input
                id="id_input_fm_5_passport_no"
                name="fm_5_passport_no"
                type="text"
                maxLength={30}
                className="form-control"
                placeholder="Enter Passport Number"
                autoComplete="off"
                onChange={(evt) =>
                  formValidation({
                    event: evt,
                    validateSearchKey: true,
                  })
                }
                required
              />
              <div className="invalid-feedback position-absolute">
                Please enter passport number.
              </div>
              <small className="txt-danger position-absolute d-none input-validation"></small>
            </div>
            {/* <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_5_sa_passport_type_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Passport Type
                </label>
                <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpPassportType ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpPassportType(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(PASSPORT_TYPE, INSERT_PASSPORT_TYPE)
                  }
                />
              </div>
              <select
                id="id_input_fm_5_sa_passport_type_id"
                name="fm_5_sa_passport_type_id"
                className="form-select"
                onChange={(evt) => setDpPassportTypeValue(evt.target.value)}
                value={dpPassportTypeValue}
                required
              >
                {passportTypeList.length
                  ? passportTypeList.map((listItem, index) => (
                      <option value={listItem?.passportTypeId} key={index}>
                        {listItem?.name}
                      </option>
                    ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select a Passport Type
              </small>
            </div> */}


            {/* <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_5_sa_country_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Country
                </label>
              </div>
              <select
                id="id_input_fm_5_sa_country_id"
                name="fm_5_sa_country_id"
                className="form-select"
                onChange={(evt) => setDpCountryValue(evt.target.value)}
                value={dpCountryValue}
                required
              >
                {CountryList.length
                  ? CountryList.map((listItem, index) => (
                    <option value={listItem?.countryId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select a Country
              </small>
            </div> */}


            <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
              <label
                htmlFor="id_input_fm_5_issue_date"
                className="required-input-mark"
              >
                Issue Date
              </label>
              <input
                id="id_input_fm_5_issue_date"
                name="fm_5_issue_date"
                type="date"

                // type="text"
                // class="form-control flatpickr-input"

                className="form-control"
                required
              />
              <div className="invalid-feedback position-absolute">
                Please select issue date
              </div>
            </div>
            <div className="form-group col-12 col-sm-3 position-relative">
              <label
                htmlFor="id_input_fm_5_expire_date"
                className="required-input-mark"
              >
                Expire Date
              </label>
              <input
                id="id_input_fm_5_expire_date"
                name="fm_5_expire_date"
                type="date"
                className="form-control"
                required
              />
              <div className="invalid-feedback position-absolute">
                Please select expire date
              </div>
            </div>
          </div>
          <FileUploader
            label={"Upload observation page and bio data page of Passport"}
            attachment_list={attachmentList}
            preview_list={previewFileList}
            set_attachment_list={setAttachmentList}
            set_preview_list={setPreviewFileList}
            max_file_limit={MAXFILELIMIT}
          // is_lightbox_open={isLightboxOpen}
          // set_is_lightbox_open={setIsLightboxOpen}
          // selected_image={selectedImage}
          // set_selected_image={setSelectedImage}
          />
          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_5_remark">Remark</label>
            <textarea
              id="id_input_fm_5_remark"
              name="fm_5_remark"
              rows="3"
              maxLength={250}
              className="form-control"
              placeholder="Enter Remark"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  checkExpression: "",
                })
              }
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          <div className="form-row mb-4">
            {/* <div className="checkbox checkbox-solid-dark mx-1 mx-sm-1 col-12 col-sm-3">
              <input
                id="id_input_fm_5_is_collected"
                name="fm_5_is_collected"
                type="checkbox"
              />
              <label htmlFor="id_input_fm_5_is_collected">Collected</label>
            </div> */}
            {/* {(checkIsAccessible(INSERTFUNCTION) && passportId === 0) ||
              (checkIsAccessible(DEACTIVATEFUNCTION) && passportId !== 0) ? (
              <div className="checkbox checkbox-solid-dark mx-1 mx-sm-1 col-12 col-sm-3">
                <input
                  id="id_input_fm_5_is_active"
                  name="fm_5_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_5_is_active">Active</label>
              </div>
            ) : null} */}
          </div>
          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
              (checkIsAccessible(EDITFUNCTION) && passportId !== 0) ? (
              <div className="d-sm-flex justify-content-end">

                <button
                  className="btn btn-outline-primary col-md-1 col-3 px-1 mb-2 mb-sm-0"
                  type="reset"
                >
                  Reset
                </button>
                <button
                  className="btn btn-primary col-md-2 col-4 mx-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {passportId ? "Update" : "Submit"}
                </button>

                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleBackClick()}><ArrowLeft height={"16px"} width={"16px"} />Back</button>

                <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleNextClick()}>Next<ArrowRight height={"16px"} width={"16px"} /></button>

              </div>
            ) : null
          ) : null}

          <br />

          {isSuccess && (
            <div className="col-sm-12 mb-2">
              <div className="bg-primary px-3 py-3 rounded d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div
                    style={{
                      backgroundColor: "#FFEBB2",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Check
                      height={"16px"}
                      style={{ strokeWidth: 5 }}
                      className="txt-primary"
                    />
                  </div>
                  <div>
                    <div className="mb-1 d-flex align-items-center">
                      <h6
                        style={{ color: "#FFEBB2" }}
                        className="uppercase px-1 m-0"
                      >

                      </h6>
                      <div
                        style={{ backgroundColor: "#FFEBB2", height: "5px" }}
                        className="w-100 rounded ml-2"
                      ></div>
                    </div>
                    <div style={{ color: "#FFEBB2", marginLeft: 15 }} className="fw-bold">
                      Do you want to add a another Passport detail.
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className=" d-flex justify-content-end"                >
                    <div>
                      <button
                        type="reset"
                        className="btn 
                            rounded btn-sm "
                        style={{ backgroundColor: "#FFEBB2" }}
                      >

                        <span className="fw-bold">Add</span>
                      </button>
                    </div>
                  </div>
                  <div className="mx-2 d-flex"
                  >
                    <div style={{ marginTop: -15 }} >
                      <span
                        onClick={closeItem}
                        style={{
                          cursor: "pointer",
                        }}
                      > <X style={{ color: "#FFEBB2" }} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )}

        </form>
      </div>

      <div className="mb-4">
        <Datatable
          titleData="Passport List"
          columnData={tableColumnHeaderList}
          rowData={passportRowList}
          csvFilename="Passport.csv"
        />
      </div>
    </>
  );
};

export default Passport;
