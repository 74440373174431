import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import imageOnError from "../../assets/images/default-image.jpg";
import pdfIcon from "../../assets/images/default-png.png";
import videoIcon from "../../assets/images/videoIcon.png"
import wordIcon from "../../assets/images/wordIcon.jpg"
import excelIcon from "../../assets/images/excelicon.jpg"
import { DownloadCloud } from "react-feather";
import ConfigDB from "../../data/customizer/config";

const getFileTypeName = (file_type) => {
  switch (file_type) {
    case "jpg":
      return "Images";
    case "jpeg":
      return "Images";
    case "png":
      return "Images";
    case "pdf":
      return "Documents";
    case "mp4":
      return "Videos";
    case "docx":
      return "Documents";
    case "xlsx":
      return "Documents";
    default:
      break;
  }
};

const DetailRowLayout = ({
  display,
  labels,
  data,
  attachment_row = false,
  active = null,
  lastRow = false,
}) => {
  const [attachmentList, setAttachmentList] = useState([]);

  useEffect(() => {
    /** Managing a row for List of Attachments => Images, PDF,...etc. */
    if (attachment_row) {
      let attachmentTypeList = [];
      let tempAttachmentList = [];
      // getting available file types
      data.forEach((attachment_item) => {
        let fileType = attachment_item.filePath.split(".")[1];
        attachmentTypeList.push(getFileTypeName(fileType));
        // console.log("attachment file type detected as => ", fileType);
      });
      // structuring tempAttachmentList Holder
      new Set(attachmentTypeList).forEach((attachment_item_type) => {
        tempAttachmentList.push({
          type: attachment_item_type,
          attachmentList: [],
        });
      });

      // grouping attachments according to the file type
      data.forEach((attachment_item) => {
        let splited_path = attachment_item.filePath.split("\\");
        let attachment = {
          name: splited_path[splited_path.length - 1],
          source: attachment_item.filePath,
        };
        let typeIndex = tempAttachmentList.findIndex(
          (item) =>
            item.type ===
            getFileTypeName(attachment_item.filePath.split(".")[1])
        );
        tempAttachmentList[typeIndex].attachmentList.push(attachment);
      });
      // console.log(tempAttachmentList);
      setAttachmentList(tempAttachmentList);
    }
  }, []);

  const toPdfDownload = (listSorce) => {

    const documentURL = `${ConfigDB.data.documentServerUrl}${listSorce}`;
    const link = document.createElement("a");
    link.setAttribute("href", documentURL);
    link.setAttribute("download", "sdsdfsdf.pdf");
    link.setAttribute("target", "_blank");
    link.click();
  }

  const toWordDownload = (listSorce) => {

    const documentURL = `${ConfigDB.data.documentServerUrl}${listSorce}`;
    const link = document.createElement("a");
    link.setAttribute("href", documentURL);
    link.setAttribute("download", "sdsdfsdf.docx");
    link.setAttribute("target", "_blank");
    link.click();
  }

  const toExcelDownload = (listSorce) => {

    const documentURL = `${ConfigDB.data.documentServerUrl}${listSorce}`;
    const link = document.createElement("a");
    link.setAttribute("href", documentURL);
    link.setAttribute("download", "sdsdfsdf.xlsx");
    link.setAttribute("target", "_blank");
    link.click();
  }
  return (
    <>
      <div
        className="d-none d-md-flex row mx-1 py-2"
        style={{
          borderBottom: `${lastRow === false ? 1 : 0}px solid${active === true
            ? "#1B998B"
            : active === false
              ? "#ff9f40"
              : "#dee2e6"
            }`,
        }}
      >
        {/* text row */}
        {labels !== undefined
          ? labels.map((label_item, index) => (
            <div
              key={index}
              className={`col-${Math.round(12 / labels.length)}`}
              style={{ paddingLeft: `${index === 0 ? "0" : "12px"}` }}
            >
              <div
                className={`fw-bold ${active === true
                  ? "txt-primary"
                  : active === false
                    ? "txt-warning"
                    : null
                  }`}
              >
                {display ? label_item : <Skeleton className="w-50" />}
              </div>
              <div
                className={`${active === true
                  ? "txt-primary"
                  : active === false
                    ? "txt-warning"
                    : "text-secondary"
                  }`}
              >
                {display ? (
                  data[index] === "" || data[index] === null ? (
                    "Not Specified"
                  ) : (
                    data[index]
                  )
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
          ))
          : null}


        {/* attachment row */}
        {attachmentList.length > 0
          ? attachmentList.map((attachment_item, index) => (
            <div key={index} style={{ paddingLeft: "0" }}>
              <div
                className={`fw-bold  mb-2 ${active === true
                  ? "txt-primary"
                  : active === false
                    ? "txt-warning"
                    : null
                  }`}
              >
                {attachment_item.type}
              </div>
              <div className="d-flex">
                {attachment_item.attachmentList.map(
                  (attachment, sub_index) => (
                    <div
                      className="d-flex flex-column align-items-center mx-2"
                      key={sub_index}
                    >
                      {attachment.name.split(".")[1] === "pdf" ? (
                        <>
                          <img
                            width="200px"
                            alt=""
                            className={`mb-1 ${sub_index === 0 ? "" : "ml-2"}`}
                            src={pdfIcon}
                          />

                          <div className="btn btn-link text-info">
                            <DownloadCloud
                              height={"25px"}
                              onClick={() => toPdfDownload(attachment.source)}
                            />
                          </div>

                        </>
                      ) : attachment.name.split(".")[1] === "mp4" ? (

                        // <img
                        //   width="200px"
                        //   alt=""
                        //   className={`mb-1 ${sub_index === 0 ? "" : "ml-2"}`}
                        //   src={videoIcon}

                        // />
                        <video width="200px" alt="" className={`mb-1 ${sub_index === 0 ? "" : "ml-2"}`}>
                          <source
                            src={`${ConfigDB.data.videoServerUrl}${attachment.source}`}
                            onError={(event) => {
                              event.target.src = `${process.env.PUBLIC_URL}${videoIcon}`;
                              event.onerror = null;
                            }}

                          />
                        </video>
                      ) : attachment.name.split(".")[1] === "docx" ? (
                        <>
                          <img
                            width="200px"
                            alt=""
                            className={`mb-1 ${sub_index === 0 ? "" : "ml-2"}`}
                            src={wordIcon}

                          />
                          <div className="btn btn-link text-info">
                            <DownloadCloud
                              height={"25px"}
                              onClick={() => toWordDownload(attachment.source)}
                            />
                          </div>
                        </>
                      ) : attachment.name.split(".")[1] === "xlsx" ? (
                        <>
                          <img
                            width="200px"
                            alt=""
                            className={`mb-1 ${sub_index === 0 ? "" : "ml-2 mt-3"}`}
                            src={excelIcon}

                          />

                          <div className="btn btn-link text-info">
                            <DownloadCloud
                              height={"25px"}
                              onClick={() => toExcelDownload(attachment.source)}
                            />
                          </div>

                        </>
                      ) : (
                        <img
                          width="200px"
                          alt=""
                          className={`mb-1 ${sub_index === 0 ? "" : "ml-2"}`}
                          src={`${ConfigDB.data.fileServerUrl}${attachment.source}`}
                          onError={(event) => {
                            /** Hosted Image Url */
                            // event.target.src = `${process.env.PUBLIC_URL}/DeshaktheeERP${imageOnError}`;
                            event.target.src = `${process.env.PUBLIC_URL}${imageOnError}`;
                            event.onerror = null;
                          }}
                        />
                      )}
                      <div
                        className={`${active === true
                          ? "txt-primary"
                          : active === false
                            ? "txt-warning"
                            : "text-secondary"
                          }`}
                      >
                        {attachment.name}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ))
          : null}
          
        {display && lastRow ? (
          <div
            className={`mt-2 px-2 rounded d-flex ${active ? "bg-primary" : "bg-warning"
              }`}
          >

            
            {/* {active ? "Active" : "Not Active"} */}
          </div>
        ) : null}
      </div>
      <div className="d-md-none mx-1">
        {/* text row */}
        {labels !== undefined
          ? labels.map((label_item, index) => (
            <div
              key={index}
              className={`py-2 col-${Math.round(12 / labels.length)} w-100`}
              style={{
                borderBottom: `${lastRow === false ? 1 : 0}px solid${active === true
                  ? "#1B998B"
                  : active === false
                    ? "#ff9f40"
                    : "#dee2e6"
                  }`,
              }}
            >
              <div
                className={`fw-bold ${active === true
                  ? "txt-primary"
                  : active === false
                    ? "txt-warning"
                    : null
                  }`}
              >
                {display ? label_item : <Skeleton className="w-50" />}
              </div>
              <div
                className={`${active === true
                  ? "txt-primary"
                  : active === false
                    ? "txt-warning"
                    : "text-secondary"
                  }`}
              >
                {display ? (
                  data[index] === "" || data[index] === null ? (
                    "Not Specified"
                  ) : (
                    data[index]
                  )
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
          ))
          : null}

        {/* attachment row */}
        {attachmentList.length > 0
          ? attachmentList.map((attachment_item, index) => (
            <div
              key={index}
              style={{
                paddingLeft: "0",
                borderBottom: `${lastRow === false ? 1 : 0}px solid${active === true
                  ? "#1B998B"
                  : active === false
                    ? "#ff9f40"
                    : "#dee2e6"
                  }`,
              }}
            >
              <div
                className={`fw-bold  mb-2 ${active === true
                  ? "txt-primary"
                  : active === false
                    ? "txt-warning"
                    : null
                  }`}
              >
                {attachment_item.type}
              </div>
              <div className="d-flex flex-column align-items-start">
                {attachment_item.attachmentList.map(
                  (attachment, sub_index) => (
                    <div
                      className="d-flex flex-column align-items-center mb-1"
                      key={sub_index}
                    >
                      {attachment.name.split(".")[1] === "pdf" ? (
                        <img
                          width="200px"
                          alt=""
                          className={`mb-1 ${sub_index === 0 ? "" : "ml-2"}`}
                          src={pdfIcon}
                        />
                      ) : attachment.name.split(".")[1] === "mp4" ? (
                        <video
                          width="200px"
                          alt=""
                          className={`mb-1 ${sub_index === 0 ? "" : "ml-2"}`}
                        // src={videoI}
                        />
                      ) : (
                        <img
                          width="200px"
                          alt=""
                          className={`mb-1 ${sub_index === 0 ? "" : "ml-2"}`}
                          src={`${ConfigDB.data.fileServerUrl}${attachment.source}`}
                          onError={(event) => {
                            /** Hosted Image Url */
                            // event.target.src = `${process.env.PUBLIC_URL}/DeshaktheeERP${imageOnError}`;
                            event.target.src = `${process.env.PUBLIC_URL}${imageOnError}`;
                            event.onerror = null;
                          }}
                        />
                      )}
                      <div
                        className={`${active === true
                          ? "txt-primary"
                          : active === false
                            ? "txt-warning"
                            : "text-secondary"
                          }`}
                      >
                        {attachment.name}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ))
          : null}
        {display && lastRow ? (
          <div
            className={`mt-2 px-2 rounded d-flex ${active ? "bg-primary" : "bg-warning"
              }`}
          >
            {active ? "Active" : "Not Active"}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DetailRowLayout;
