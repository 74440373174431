import React, { useEffect, useState } from "react";
import { AlertTriangle, ArrowLeft, ArrowRight, Check, PlusCircle, RefreshCw, X } from "react-feather";
import { Alert } from "reactstrap";
// import { useParams } from "react-router-dom";
import {
  // INSERT_JOB_CATEGORY,
  // INSERT_SKILL,
  // JOB_CATEGORY,
  LOADED,
  LOADING,
  // SKILL,
  // SUBMITTING,
} from "../../../../constant";
import { useSelector } from "react-redux";
import useAccess from "../../../../customHooks/useAccess";
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import ConfigDB from "../../../../data/customizer/config";
import Swal from "sweetalert2";

// components
// import Breadcrumb from "../common/breadcrumb";
import Datatable, { BooleanValue, ControlButtons } from "../../../common/datatable";
import VideoUploader from "../../../common/videoUploader";

// utilities
// import formValidation from "../../customHooks/useValidation";
import axios from "axios";
import DocumentUploader from "../../../common/documentUploader";
import SkillVideoUploader from "../../../common/skillVideoUploader";


const Skill = ({
  b_partner_id = 0, client_id = 0, org_id = 0, set_basic_tab = null, set_back_tab = null
}) => {

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  const MAXFILELIMIT = 5;

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  // const [refreshSkillList, setRefreshSkillList] = useState(false);
  // const [refreshDpJobCategory, setRefreshDpJobCategory] = useState(false);


  // const [skillList, setSkillList] = useState([]);
  // const [jobList, setJobList] = useState([
  //   { jobCategoryId: 0, name: "- Select -" },
  // ]);
  // const [dpJobValue, setDpJobValue] = useState(0);

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);
  // const customerData = useSelector((content) => content.CustomerReducer);
  // const { customer_id } = useParams();

  // breadcrumb values
  //  let childLinks = [
  //   { value: customer_id, active: false },
  //   { value: "Interview Video", active: true },
  // ];

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  // helper containers
  const [attachmentDocumentList, setAttachmentDocumentList] = useState([]);
  const [attachmentVideoList, setAttachmentVideoList] = useState([]);

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);
  const [previewFileList, setPreviewFileList] = useState([]);
  const [previewFileList2, setPreviewFileList2] = useState([]);


  // form values
  const [skillId, setSkillId] = useState(0);

  // table data
  const [skillRowList, setSkillRowList] = useState([]);

  const [isSuccess, setIsSuccess] = useState(false);

  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Achievements",
    // "Remarks",

    // {
    //   name: "Active",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <BooleanValue value={value} />;
    //     },
    //   },
    // },
    {
      // name: "Action",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];

  // table functions
  const [editSkill] = useState(() => (skill_id, response_data) => {
    // resetForm();
    // setTimeout(() => {
    const editingSkill = response_data
    // .find(
    //   (item) => item.skillId === skill_id
    // );
    setSkillId(editingSkill.skillId);

    let tempList = [];
    let tempList2 = [];
    response_data.allModifyDocumentList.forEach((listItem) => {
      tempList.push({
        documentId: listItem.documentId,
        isModified: false,
        attachmentExtension: "",
        attachment: "",
      });
      tempList2.push({
        id: listItem.documentId,
        type: listItem.documentPath.split(".")[1],
        source: listItem.documentPath,
      });
    });
    setPreviewFileList(tempList2);
    console.log(tempList2);

    setAttachmentDocumentList([...tempList]);
    setIsLoaded(LOADED);
    setRefreshTable(false);

    tempList = [];
    tempList2 = [];
    response_data.allModifyVideoList.forEach((listItem) => {
      tempList.push({
        videoId: listItem.videoId,
        isModified: false,
        attachmentExtension: "",
        attachment: "",
      });
      tempList2.push({
        id: listItem.videoId,
        type: listItem.videoPath.split(".")[1],
        source: listItem.videoPath,
      });
    });
    setPreviewFileList2(tempList2);
    setAttachmentVideoList([...tempList]);
    setIsLoaded(LOADED);
    setRefreshTable(false);

    // setPreviewFileList([
    //   {
    //     id: editingSkill.skillId,
    //     type: editingSkill.allModifyDocumentList.filePath.split(".")[1],
    //     source: editingSkill.allModifyDocumentList.filePath,
    //   },
    // ]);
    // setAttachmentDocumentList([
    //   {
    //     documentId: editingSkill.skillId,
    //     isModified: false,
    //     attachmentExtension: "",
    //     attachment: "",
    //   },
    // ]);

    // setPreviewFileList2([
    //   {
    //     id: editingSkill.skillId,
    //     type: editingSkill.allModifyVideoList.documentPath.split(".")[1],
    //     source: editingSkill.allModifyVideoList.documentPath,
    //   },
    // ]);
    // setAttachmentVideoList([
    //   {
    //     documentId: editingSkill.skillId,
    //     isModified: false,
    //     attachmentExtension: "",
    //     attachment: "",
    //   },
    // ]);
    document.querySelector("textarea[name='fm_1_achievements']").value =
      editingSkill.achievements;
    document.querySelector("textarea[name='fm_1_remark']").value =
      editingSkill.remark;
    // document.querySelector("input[name='fm_1_is_active']").checked =
    //   editingSkill.active;

    // if (document.querySelector("input[name='fm_1_is_active']")) {
    //   document.querySelector("input[name='fm_1_is_active']").checked =
    //     editingSkill.isActive;
    // }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // }, 1000);
    setIsLoaded(LOADED);
    setRefreshTable(false);
  });

  const [deleteSkill] = useState(() => (skill_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this skill!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          skillId: skill_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "deleteSkillTab",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Skill has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    // setIsLoaded(LOADING);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      const documents = new FormData();
      const videos = new FormData();
      let skill = {
        bpartnerId: parseInt(b_partner_id),
        skillId: parseInt(skillId),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        achievements: formData.get("fm_1_achievements"),
        remark: formData.get("fm_1_remark"),
        isActive: true,
        // isActive: formData.get("fm_1_is_active") ? true : false,
      };


      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/saveModifySkillTab",
          data: skill,
          template: "REQUEST_DATA",
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          console.log(response_data);
          const reqInstance = axios.create({
            baseURL: ConfigDB.data.backendServerUrl,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              UserId: loggedUser.userId,
            },
          });

          videos.append("clientId", loggedUser.clientId);
          videos.append("orgId", loggedUser.orgId);
          videos.append("userId", loggedUser.userId);
          videos.append("skillId", skillId ? skillId : response_data.skillId);
          videos.append("remark", "");
          videos.append("isActive", true);
          videos.append("isAttachmentVideoModified", false);
          videos.append("attachmentVideoExtension", "");
          videos.append("attachmentVideo", "");
          // console.log(parseInt(skillId));

          for (let i = 0; i < attachmentVideoList.length; i++) {
            if (attachmentVideoList[i].isModified) {
              const item = attachmentVideoList[i];
              videos.set(
                "isAttachmentVideoModified",
                attachmentVideoList.length > i ? item.isModified : false
              );
              if (attachmentVideoList.length) {
                if (attachmentVideoList[i].isModified) {
                  videos.set(
                    "attachmentVideoExtension",
                    attachmentVideoList[i].attachmentExtension
                  );
                }
              }
              if (attachmentVideoList.length) {
                if (attachmentVideoList[i].isModified) {
                  videos.set(
                    "attachmentVideo",
                    attachmentVideoList.length > i ? item.attachment : ""
                  );
                }
              }
              reqInstance
                .post("/saveModifyVideo", videos, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: (progressEvent) => {
                    const progress = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    // Update progress bar UI
                    setUploadPercentage(progress);
                    setRefreshTable(true);
                  },
                })
            }
          }

          documents.append("clientId", loggedUser.clientId);
          documents.append("orgId", loggedUser.orgId);
          documents.append("userId", loggedUser.userId);
          documents.append("skillId", skillId ? skillId : response_data.skillId);
          documents.append("remark", "");
          documents.append("isActive", true);
          documents.append("isAttachmentDocumentModified", false);
          documents.append("attachmentDocumentExtension", "");
          documents.append("attachmentDocument", "");

          for (let i = 0; i < attachmentDocumentList.length; i++) {
            if (attachmentDocumentList[i].isModified) {
              const item = attachmentDocumentList[i];
              documents.set(
                "isAttachmentDocumentModified",
                attachmentDocumentList.length > i ? item.isModified : false
              );

              if (attachmentDocumentList.length) {
                if (attachmentDocumentList[i].isModified) {
                  documents.set(
                    "attachmentDocumentExtension",
                    attachmentDocumentList[i].attachmentExtension
                  );
                }
              }
              if (attachmentDocumentList.length) {
                if (attachmentDocumentList[i].isModified) {
                  documents.set(
                    "attachmentDocument",
                    attachmentDocumentList.length > i ? item.attachment : ""
                  );
                }
              }

              reqInstance
                .post("/saveModifyDocument", documents, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: (progressEvent) => {
                    const progress = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    // Update progress bar UI
                    setUploadPercentage(progress);
                  },
                })
            }
          }

          // reqInstance
          // .post("/saveModifyDocument", documents, {
          //   headers: {
          //     "Content-Type": "multipart/form-data",
          //   },
          //   onUploadProgress: (progressEvent) => {
          //     const progress = Math.round(
          //       (progressEvent.loaded * 100) / progressEvent.total
          //     );
          //     // Update progress bar UI
          //     setUploadPercentage(progress);
          //   },
          // })

          // reqInstance
          //   .post("/saveModifyVideo", videos, {
          //     headers: {
          //       "Content-Type": "multipart/form-data",
          //     },
          //     onUploadProgress: (progressEvent) => {
          //       const progress = Math.round(
          //         (progressEvent.loaded * 100) / progressEvent.total
          //       );
          //       // Update progress bar UI
          //       setUploadPercentage(progress);
          //     },
          //   })
          // reqInstance
          //   .post("/saveModifyVideo", videos, {
          //     headers: {
          //       "Content-Type": "multipart/form-data",
          //     },
          //     onUploadProgress: (progressEvent) => {
          //       const progress = Math.round(
          //         (progressEvent.loaded * 100) / progressEvent.total
          //       );
          //       // Update progress bar UI
          //       setUploadPercentage(progress);
          //     },
          //   })

          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text: skillId
              ? "Skill has successfully updated."
              : "New skill has successfully uploaded.",
            showConfirmButton: false,
            timer: 5000,
          });
          setIsLoaded(LOADING);
          setRefreshTable(true);
          setIsSuccess(true);
          // window.location.reload(true);
        }
      })
      // .catch((error) => {
      //   console.log(error);
      // });
    }
  };

  const resetForm = (evt) => {
    // setIsValidated(false);
    // setAttachmentDocumentList([]);
    // setAttachmentVideoList([]);
    // setPreviewFileList([]);
    // setPreviewFileList2([]);
    // setSkillId(0);
    // let inputFields = document.querySelectorAll(".form-control");
    // let customValidationMessages =
    //   document.querySelectorAll(".input-validation");
    // inputFields.forEach((field) => {
    //   if (field.classList.contains("is-valid")) {
    //     field.classList.remove("is-valid");
    //   } else if (field.classList.contains("is-invalid")) {
    //     field.classList.remove("is-invalid");
    //   }
    // });
    // customValidationMessages.forEach((element) => {
    //   if (element.classList.contains("input-validation")) {
    //     if (!element.classList.contains("d-none")) {
    //       element.classList.add("d-none");
    //     }
    //   }
    // });
    
    evt.preventDefault();

    Swal.fire({
      title: 'Are you sure you want to reset?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked 'OK', reset the form or perform other actions

        setIsValidated(false);
        setAttachmentDocumentList([]);
        setAttachmentVideoList([]);
        setPreviewFileList([]);
        setPreviewFileList2([]);
        setSkillId(0);

        document.getElementById('id_input_fm_1_achievements').value = '';
        document.getElementById('id_input_fm_1_remark').value = '';
        // id_input_fm_1_remark

        // setDpLanguageValue('');
        // setWriteValue('');
        // setReadValue('');
        // setSpeakValue('');
        // setUnderstandValue('');
        // setHover('');
        // setHoverRead('');
        // setHoverSpeak('');
        // setHoverUnderstand('');
        // document.getElementById('id_input_fm_5_remark').value = '';

        setIsValidated(false);
      }
    });
    
    setIsSuccess(false)
  };

  // const openMasterFileWindow = (interface_name, function_name) => {
  //   if (isInsertAllowed(function_name, interface_name)) {
  //     window.open(
  //       `${ConfigDB.data.hosted_url}/masterfile/${interface_name
  //         .replace(/(\B[A-Z])/g, "_$1")
  //         .toLocaleLowerCase()}`,
  //       "_blank",
  //       "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
  //     );
  //   } else {
  //     Swal.fire({
  //       icon: "warning",
  //       title: "Not Allowed",
  //       text: "You don't have permission for this task.",
  //       showConfirmButton: false,
  //       timer: 5000,
  //     });
  // TODO: get all the message strings to a one page
  //   }
  // };


  // useEffect(() => {
  //   // console.log(previewFileList);
  //   // console.log(attachmentList);
  // }, [previewFileList, previewFileList2]);


  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/viewModifySkillTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            if (response_data.skillList) {
              let tempList = [];
              response_data.skillList.forEach((list_item) => {
                // console.log(list_item);
                tempList.push([
                  list_item.skillId,
                  list_item.achievements,
                  // list_item.remark,
                  // list_item.active ? "True" : "False",
                  <ControlButtons
                    editMethod={() =>
                      editSkill(list_item.skillId, list_item)

                      // editSkill(list_item.skillId,
                      //    response_data.allModifyDocumentList,
                      //    response_data.allModifyVideoList
                      //    )
                    }
                    disableEdit={!checkIsAccessible(EDITFUNCTION)}
                    deleteMethod={() => deleteSkill(list_item.skillId, loggedUser)}
                    disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                  />,
                ]);

              });
              setSkillRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
  }, [refreshTable]);

  const closeItem = () => {
    setIsSuccess(false);
  };

  const handleBackClick = () => {
    set_back_tab()
  };

  const handleNextClick = () => {
    set_basic_tab()
  };

  // useEffect(() => {
  //   const reqBody = {
  //     clientId: loggedUser.clientId,
  //     orgId: loggedUser.orgId,
  //     userId: loggedUser.userId,
  //     bpartnerId: b_partner_id,
  //   };
  //   new Promise((resolve) => {
  //     const result = sendRequest({
  //       url: "/customer/viewSkillTab",
  //       data: reqBody,
  //     });
  //     resolve(result);
  //   }).then((response_data) => {
  //     if (response_data) {
  // setJobList((prev_item) => [
  //   prev_item[0],
  //   ...response_data.customerJobCategoryList,
  // ]);
  // if (response_data.customerSkillTableList.length > 0) {
  // setDpJobValue(response_data.customerSkillTableList[0].jobCategoryId);
  // setSkillList(response_data.customerSkillTableList[0].skillList);
  //     }
  //   }
  //   setIsLoaded(LOADED);
  // });
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   if (dpJobValue > 0) {
  //     setIsLoaded(LOADING);
  //   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dpJobValue]);

  // useEffect(() => {
  //   if (isLoaded === LOADING) {
  //     const reqBody = {
  //       bpartnerId: b_partner_id,
  // jobCategoryId: dpJobValue,
  // };
  // new Promise((resolve) => {
  //   const result = sendRequest({
  //     url: "/customer/getSkill",
  //     data: reqBody,
  //   });
  //   resolve(result);
  // }).then((response_data) => {
  //   if (response_data) {
  // setSkillList(response_data.skillList);
  //       }
  //       setIsLoaded(LOADED);
  //     });
  //   }
  // }, [isLoaded]);

  // form control functions
  // const submitForm = (evt) => {
  //   setIsValidated(true);
  //   evt.preventDefault();
  //   if (evt.target.checkValidity()) {
  //     const formData = new FormData(evt.target);
  //     let selectedSkills = [];
  //     skillList.forEach((skill_item) => {
  //       selectedSkills.push({
  // skillId: skill_item.skillId,
  // isCan: !!formData.get(`fm_14_sk_${skill_item.skillId}_skilled`),
  // isShowApplication: !!formData.get(
  //   `fm_14_sk_${skill_item.skillId}_isshowinapplication`
  // ),
  //     remark: formData.get(`fm_14_sk_${skill_item.skillId}_remark`),
  //   });
  // });
  // let skill = {
  //   bpartnerId: parseInt(b_partner_id),
  //   clientId: parseInt(client_id),
  //   orgId: parseInt(org_id),
  //   userId: parseInt(loggedUser.userId),
  // jobCategoryId: dpJobValue,
  // skillList: selectedSkills,
  // };
  // setIsLoaded(SUBMITTING);
  // new Promise((resolve) => {
  //   const result = sendRequest({
  //     url: "/customer/saveSkillTab",
  //     data: skill,
  //     template: "CONTROL_DATA",
  //       });
  //       resolve(result);
  //     }).then((reponse_data) => {
  //       if (reponse_data) {
  //         Swal.fire({
  //           icon: "success",
  //           title: "Request Successful",
  //           text: "Customer Skills has successfully updated.",
  //           showConfirmButton: false,
  //           timer: 5000,
  //         });
  //       }
  //       setIsLoaded(LOADING);
  //     });
  //   }
  // };

  return (
    // <div className="card-modified-body bg-white pt-0 mb-4">
    //   <Alert
    //     className="alert-dismissible d-flex align-items-center"
    //     color="warning"
    //     isOpen={b_partner_id === 0}
    //   >
    //     <AlertTriangle />
    //     <p className="ml-2">
    //       You must create a Customer, before add Skill Details.
    //     </p>
    //   </Alert>
    //   <Alert
    //     className="alert-dismissible d-flex align-items-center"
    //     color="warning"
    //     isOpen={jobList.length === 0}
    //   >
    //     <AlertTriangle />
    //     <p className="ml-2">First, add data in Job Tab.</p>
    //   </Alert>
    //   <form
    //     onSubmit={(evt) => submitForm(evt)}
    //     className={`position-relative form-14 ${
    //       isValidated ? "was-validated" : ""
    //     }`}
    //     noValidate
    //   >
    //     {isLoaded === LOADING ? (
    //       <div className="form-loader d-flex justify-content-center align">
    //         <div className="loader-box">
    //           <div className="loader">
    //             <div className="line bg-secondary"></div>
    //             <div className="line bg-secondary"></div>
    //             <div className="line bg-secondary"></div>
    //             <div className="line bg-secondary"></div>
    //           </div>
    //         </div>
    //       </div>
    //     ) : null}
    //     <div className="form-group col-12 col-sm-4 position-relative mb-4">
    //       <div clasasName="d-flex">
    //         <label
    //           htmlFor="id_input_fm_14_sa_job_category_id"
    //           className="col-form-label pt-0 required-input-mark"
    //         >
    //           Job Category
    //         </label>
    //         <RefreshCw
    //           className={`mx-3 mt-1 dp-refresh-icon ${
    //             refreshDpJobCategory ? "rotate-icon" : ""
    //           }`}
    //           height={"14px"}
    //           onClick={() => setRefreshDpJobCategory(true)}
    //         />
    //         <PlusCircle
    //           className="mt-1 dp-refresh-icon"
    //           height={"14px"}
    //           onClick={() =>
    //             openMasterFileWindow(JOB_CATEGORY, INSERT_JOB_CATEGORY)
    //           }
    //         />
    //       </div>
    //       <select
    //         id="id_input_fm_14_sa_job_category_id"
    //         name="fm_14_sa_job_category_id"
    //         className="form-select"
    //         onChange={(evt) => setDpJobValue(evt.target.value)}
    //         value={dpJobValue}
    //       >
    //         {jobList.length
    //           ? jobList.map((list_item, index) => (
    //               <option value={list_item?.jobCategoryId} key={index}>
    //                 {list_item?.name}
    //               </option>
    //             ))
    //           : null}
    //       </select>
    //     </div>
    //     <div className="form-row mb-4">
    //       <div className="form-group col-3 d-flex">
    //         <div className="d-flex">
    //           <label className="col-form-label pt-0 fw-bold">Skill</label>
    //           <RefreshCw
    //             className={`mx-3 mt-1 dp-refresh-icon ${
    //               refreshSkillList ? "rotate-icon" : ""
    //             }`}
    //             height={"14px"}
    //             onClick={() => setRefreshSkillList(true)}
    //           />
    //           <PlusCircle
    //             className="mt-1 dp-refresh-icon"
    //             height={"14px"}
    //             onClick={() => openMasterFileWindow(SKILL, INSERT_SKILL)}
    //           />
    //         </div>
    //       </div>
    //       <div className="form-group col-3 d-flex justify-content-center">
    //         <label
    //           htmlFor="id_input_fm_2_is_most_interested_job"
    //           className="mt-0 mb-0 fw-bold"
    //         >
    //           Skilled In
    //         </label>
    //       </div>
    //       <div className="form-group col-3 d-flex justify-content-center">
    //         <label
    //           htmlFor="id_input_fm_2_is_most_interested_job"
    //           className="mt-0 mb-0 fw-bold"
    //         >
    //           Show In Application
    //         </label>
    //       </div>
    //       <div className="form-group col-3 d-flex">
    //         <label
    //           htmlFor="id_input_fm_2_is_most_interested_job"
    //           className="mt-0 mb-0 fw-bold"
    //         >
    //           Remark
    //         </label>
    //       </div>
    //     </div>
    //     {isLoaded === LOADED && skillList.length > 0 ? (
    //       skillList.map((skill_item, index) => (
    //         <div key={index} className="form-row mb-4 align-items-center">
    //           <div className="form-group col-3 d-flex">
    //             <label className="col-form-label mt-1">
    //               {skill_item.skillName}
    //             </label>
    //           </div>
    //           <div className="form-group col-3 d-flex justify-content-center">
    //             <div className="checkbox checkbox-solid-dark mb-2">
    //               <input
    //                 id={`id_fm_14_sk_${skill_item.skillId}_skilled`}
    //                 name={`fm_14_sk_${skill_item.skillId}_skilled`}
    //                 type="checkbox"
    //                 defaultChecked={skill_item.isCan}
    //               />
    //               <label
    //                 htmlFor={`id_fm_14_sk_${skill_item.skillId}_skilled`}
    //               ></label>
    //             </div>
    //           </div>
    //           <div className="form-group col-3 d-flex justify-content-center">
    //             <div className="checkbox checkbox-solid-dark mb-2">
    //               <input
    //                 id={`id_fm_14_sk_${skill_item.skillId}_isshowinapplication`}
    //                 name={`fm_14_sk_${skill_item.skillId}_isshowinapplication`}
    //                 type="checkbox"
    //                 defaultChecked={skill_item.isShowApplication}
    //               />
    //               <label
    //                 htmlFor={`id_fm_14_sk_${skill_item.skillId}_isshowinapplication`}
    //                 className=""
    //               ></label>
    //             </div>
    //           </div>
    //           <div className="form-group col-3 d-flex ">
    //             <textarea
    //               className="form-control"
    //               id={`id_fm_14_sk_${skill_item.skillId}_remark`}
    //               name={`fm_14_sk_${skill_item.skillId}_remark`}
    //               maxLength={250}
    //               onChange={(evt) =>
    //                 formValidation({ event: evt, checkExpression: "" })
    //               }
    //               value={skill_item.remark}
    //             ></textarea>
    //           </div>
    //         </div>
    //       ))
    //     ) : (
    //       <Alert
    //         className="alert-dismissible d-flex align-items-center bg-white rounded"
    //         color="warning"
    //         isOpen={true}
    //       >
    //         <AlertTriangle className="txt-warning" />
    //         <p className="ml-2 txt-warning fw-bold">
    //           Selected Job Category has no mapped skill set.
    //         </p>
    //       </Alert>
    //     )}
    //     {b_partner_id && jobList.length > 0 ? (
    //       <div className="d-sm-flex justify-content-end">
    //         <button
    //           className="btn btn-primary col-12 col-sm-2 mb-2 mb-sm-0"
    //           type="submit"
    //         >
    //           Update
    //         </button>
    //       </div>
    //     ) : null}
    //   </form>
    // </div>
    <>
      <div className="card-modified-body bg-white pt-0 mb-4">
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={b_partner_id === 0}
        >
          <AlertTriangle />
          <p className="ml-2">
            You must create a customer, before add skill details.
          </p>
        </Alert>
        {/* <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={jobList.length === 0}
        >
          <AlertTriangle />
          <p className="ml-2">First, add data in Job Tab.</p>
        </Alert> */}
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-14 ${isValidated ? "was-validated" : ""
            }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
              {/* <div>Saving... {uploadPercentage}%</div> */}
            </div>
          ) : null}

          {/* <div className="form-group col-12 col-sm-4 position-relative mb-4">
          <div clasasName="d-flex">
            <label
              htmlFor="id_input_fm_14_sa_job_category_id"
              className="col-form-label pt-0 required-input-mark"
            >
              Job Category
            </label>
            <RefreshCw
              className={`mx-3 mt-1 dp-refresh-icon ${
                refreshDpJobCategory ? "rotate-icon" : ""
              }`}
              height={"14px"}
              onClick={() => setRefreshDpJobCategory(true)}
            />
            <PlusCircle
              className="mt-1 dp-refresh-icon"
              height={"14px"}
              onClick={() =>
                openMasterFileWindow(JOB_CATEGORY, INSERT_JOB_CATEGORY)
              }
            />
          </div>
           <select
            id="id_input_fm_14_sa_job_category_id"
            name="fm_14_sa_job_category_id"
            className="form-select"
            onChange={(evt) => setDpJobValue(evt.target.value)}
            value={dpJobValue}
          >
            {jobList.length
              ? jobList.map((list_item, index) => (
                  <option value={list_item?.jobCategoryId} key={index}>
                    {list_item?.name}
                  </option>
                ))
              : null}
          </select>
        </div> */}

          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_1_achievements" className="required-input-mark">Achievements</label>
            <textarea
              id="id_input_fm_1_achievements"
              name="fm_1_achievements"
              rows="3"
              maxLength={250}
              className="form-control"
              placeholder="Enter Achievements"
              required
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>

          <DocumentUploader
            // label={"Images/Documents"}
            attachment_list={attachmentDocumentList}
            preview_list={previewFileList}

            set_attachment_list={setAttachmentDocumentList}
            set_preview_list={setPreviewFileList}
            max_file_limit={MAXFILELIMIT}
            set_refresh_table={setRefreshTable}
          />

          <SkillVideoUploader
            label={"Videos"}
            attachment_list={attachmentVideoList}
            preview_list={previewFileList2}
            set_attachment_list={setAttachmentVideoList}
            set_preview_list={setPreviewFileList2}
            max_file_limit={MAXFILELIMIT}
            set_refresh_table={setRefreshTable}
          />

          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_1_remark">Remark</label>
            <textarea
              id="id_input_fm_1_remark"
              name="fm_1_remark"
              rows="3"
              maxLength={250}
              className="form-control"
              placeholder="Enter Remarks"
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>

          {/* {(checkIsAccessible(INSERTFUNCTION) && skillId === 0) ||
            (checkIsAccessible(DEACTIVATEFUNCTION) && skillId !== 0) ? (
            <div className="form-group mb-4">
              <div className="checkbox checkbox-solid-dark col-12 col-sm-6 mx-2">
                <input
                  id="id_input_fm_1_is_active"
                  name="fm_1_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_1_is_active">Active</label>
              </div>
            </div>
          ) : null} */}

          {checkIsAccessible(INSERTFUNCTION) ||
            (checkIsAccessible(EDITFUNCTION) && skillId !== 0) ? (
            <div className="d-sm-flex justify-content-end">

              <button className="btn btn-outline-primary col-md-1 col-3 px-1 mb-2 mb-sm-0" type="reset">
                Reset
              </button>
              <button
                className="btn btn-primary col-md-2 col-4 mx-3 mb-2 mb-sm-0"
                type="submit"

              >
                {skillId ? "Update" : "Submit"}
              </button>

              <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleBackClick()}><ArrowLeft height={"16px"} width={"16px"} />Back</button>

              <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleNextClick()}>Next<ArrowRight height={"16px"} width={"16px"} /></button>

            </div>
          ) : null}


          {/* <div className="form-row mb-4">
            <div className="form-group col-3 d-flex">
              <div className="d-flex">
                <label className="col-form-label pt-0 fw-bold">Skill</label>
                <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${refreshSkillList ? "rotate-icon" : ""
                    }`}
                  height={"14px"}
                  onClick={() => setRefreshSkillList(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() => openMasterFileWindow(SKILL, INSERT_SKILL)}
                />
              </div>
            </div>
            <div className="form-group col-3 d-flex justify-content-center">
              <label
                htmlFor="id_input_fm_2_is_most_interested_job"
                className="mt-0 mb-0 fw-bold"
              >
                Skilled In
              </label>
            </div>
            <div className="form-group col-3 d-flex justify-content-center">
              <label
                htmlFor="id_input_fm_2_is_most_interested_job"
                className="mt-0 mb-0 fw-bold"
              >
                Show In Application
              </label>
            </div>
            <div className="form-group col-3 d-flex">
              <label
                htmlFor="id_input_fm_2_is_most_interested_job"
                className="mt-0 mb-0 fw-bold"
              >
                Remark
              </label>
            </div>
          </div> */}
          {/* {isLoaded === LOADED && skillList.length > 0 ? (
            skillList.map((skill_item, index) => (
              <div key={index} className="form-row mb-4 align-items-center">
                <div className="form-group col-3 d-flex">
                  <label className="col-form-label mt-1">
                    {skill_item.skillName}
                  </label>
                </div>
                <div className="form-group col-3 d-flex justify-content-center">
                  <div className="checkbox checkbox-solid-dark mb-2">
                    <input
                      id={`id_fm_14_sk_${skill_item.skillId}_skilled`}
                      name={`fm_14_sk_${skill_item.skillId}_skilled`}
                      type="checkbox"
                      defaultChecked={skill_item.isCan}
                    />
                    <label
                      htmlFor={`id_fm_14_sk_${skill_item.skillId}_skilled`}
                    ></label>
                  </div>
                </div>
                <div className="form-group col-3 d-flex justify-content-center">
                  <div className="checkbox checkbox-solid-dark mb-2">
                    <input
                      id={`id_fm_14_sk_${skill_item.skillId}_isshowinapplication`}
                      name={`fm_14_sk_${skill_item.skillId}_isshowinapplication`}
                      type="checkbox"
                      defaultChecked={skill_item.isShowApplication}
                    />
                    <label
                      htmlFor={`id_fm_14_sk_${skill_item.skillId}_isshowinapplication`}
                      className=""
                    ></label>
                  </div>
                </div>
                <div className="form-group col-3 d-flex ">
                  <textarea
                    className="form-control"
                    id={`id_fm_14_sk_${skill_item.skillId}_remark`}
                    name={`fm_14_sk_${skill_item.skillId}_remark`}
                    maxLength={250}
                    onChange={(evt) =>
                      formValidation({ event: evt, checkExpression: "" })
                    }
                    value={skill_item.remark}
                  ></textarea>
                </div>
              </div>
            ))
          ) : (
            <Alert
              className="alert-dismissible d-flex align-items-center bg-white rounded"
              color="warning"
              isOpen={true}
            >
              <AlertTriangle className="txt-warning" />
              <p className="ml-2 txt-warning fw-bold">
                Selected Job Category has no mapped skill set.
              </p>
            </Alert>
          )}
          {b_partner_id && jobList.length > 0 ? (
            <div className="d-sm-flex justify-content-end">
              <button
                className="btn btn-primary col-12 col-sm-2 mb-2 mb-sm-0"
                type="submit"
              >
                Update
              </button>
            </div>
          ) : null} */}

          <br />

          {isSuccess && (
            <div className="col-sm-12 mb-2">
              <div className="bg-primary px-3 py-3 rounded d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div
                    style={{
                      backgroundColor: "#FFEBB2",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Check
                      height={"16px"}
                      style={{ strokeWidth: 5 }}
                      className="txt-primary"
                    />
                  </div>
                  <div>
                    <div className="mb-1 d-flex align-items-center">
                      <h6
                        style={{ color: "#FFEBB2" }}
                        className="uppercase px-1 m-0"
                      >
                        {/* {searchKey} */}
                      </h6>
                      <div
                        style={{ backgroundColor: "#FFEBB2", height: "5px" }}
                        className="w-100 rounded ml-2"
                      ></div>
                    </div>
                    <div style={{ color: "#FFEBB2", marginLeft: 15 }} className="fw-bold">
                      {/* <UserCheck height={"14px"} style={{ strokeWidth: 3 }} /> */}
                      {/* {` ${firstName}’s`}  */}
                      Do you want to add a another skill detail.
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className=" d-flex justify-content-end"                >
                    <div>
                      <button
                        type="reset"
                        className="btn 
                            rounded btn-sm "
                        style={{ backgroundColor: "#FFEBB2" }}
                      >

                        <span className="fw-bold">Add</span>
                      </button>
                    </div>
                  </div>
                  <div className="mx-2 d-flex"
                  >
                    <div style={{ marginTop: -15 }} >
                      <span
                        onClick={closeItem}
                        style={{
                          cursor: "pointer",
                        }}
                      > <X style={{ color: "#FFEBB2" }} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )}

        </form>
      </div>
      <div className="mb-4">
        <Datatable
          titleData="Skill List"
          columnData={tableColumnHeaderList}
          rowData={skillRowList}
          csvFilename="Skill.csv"
        />
      </div>

    </>
  );
};

export default Skill;
