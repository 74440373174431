import React, { Component, useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
//import carouselData from '../../assets/carouselImages/carouselImges.json';
import image_01 from '../../assets/carouselImages/plus.png';
import image_02 from '../../assets/carouselImages/pms advertisement.jpg';
import image_03 from '../../assets/carouselImages/pms.png';
import image_04 from '../../assets/carouselImages/vision.png';

const FrontPageCarousel = () => {
    // const [carouselData, setCarouselData] = useState([]);

    // useEffect(() => {
    //     fetch('../../assets/carouselImages/carouselImges.json')
    //         .then(response => response.json())
    //         .then(data => setCarouselData(data));
    // }, []);

    //const [data] = useState(carouselData);
    // const [imgs] = {image_01,image_02,image_03,image_04};

    return (
        <div className="d-flex justify-content-center">
            <Carousel
                autoPlay
                interval={3000}
                infiniteLoop
                showThumbs={false}
                showStatus={false}
            >
                <div>
                    <img src={image_01} alt="Legend 1" />
                </div>
                <div>
                    <img src={image_02} alt="Legend 2" />
                </div>
                <div>
                    <img src={image_03} alt="Legend 3" />
                </div>
                <div>
                    <img src={image_04} alt="Legend 3" />
                </div>
                {/* {data.map((item, index) => ( */}
                    {/* <div key={index}> */}
                        {/* {console.log(imgs)}; */}
                        {/* <img src={item.src} alt={item.alt} /> */}
                        {/* <p className="legend">{item.legend}</p> */}
                    {/* </div> */}
                {/* ))} */}
            </Carousel>
        </div>
    );
};

export default FrontPageCarousel;