import React, { useRef, useState } from "react";
import { DownloadCloud, Trash } from "react-feather";
import Swal from "sweetalert2";
import ConfigDB from "../../data/customizer/config";
import imageOnError from "../../assets/images/default-image.jpg";
import pdfIcon from "../../assets/images/pdficon.jpg";
import wordIcon from "../../assets/images/wordIcon.jpg";
import excelIcon from "../../assets/images/excelicon.jpg";
import { LoadStaticURL } from "../../data/util/LoadStaticURL";
import Lightbox from "react-image-lightbox";

const FileUploader = ({
  label,
  attachment_list,
  preview_list,
  set_attachment_list,
  set_preview_list,
  max_file_limit,
}) => {


  // input field data
  const fileValidationMessage = useRef("");
  const fileComponent = useRef("");

  const handleFileUpload = (event) => {
    let reader = new FileReader();
    reader.onload = function () {
      // console.log(reader.result);
      // console.log(event.target.files[0].type);
      // console.log("Megabytes => ", event.target.files[0].size / 1024 ** 2);
      if (
        event.target.files[0].size <
        ConfigDB.data.max_file_input_size_in_bytes &&
        preview_list.length < max_file_limit
      ) {
        if (fileComponent.current.classList.contains("is-invalid"))
          fileComponent.current.classList.remove("is-invalid");
        if (
          preview_list.find((item) => item.source === reader.result) ===
          undefined
        ) {
          set_preview_list((prevList) => [
            ...prevList,
            { id: 0, type: event.target.files[0].type, source: reader.result },
          ]);
          let attachment = new FormData();
          attachment.append("documentId", 0)
          attachment.append("isModified", true)
          attachment.append("attachmentExtension", `.${event.target.files[0].type.split("/")[1]}`)
          attachment.append("attachment", event.target.files[0])
          set_attachment_list((prev_list_items) => [
            ...prev_list_items,
            attachment
          ]);
        }
      } else {
        if (!fileComponent.current.classList.contains("invalid-feedback"))
          fileComponent.current.classList.add("is-invalid");
        fileValidationMessage.current.innerHTML =
          preview_list.length >= max_file_limit
            ? `Cannot insert more than ${max_file_limit} files.`
            : `Please insert files less than ${ConfigDB.data.max_file_input_size_in_bytes / 1024 ** 2
            }MB`;
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleRemove = (preview_item, index) => {
    let removingItem = undefined;
    if (fileComponent.current.classList.contains("is-invalid"))
      fileComponent.current.classList.remove("is-invalid");
    if (preview_item.id === 0) {
      removingItem = attachment_list.find(
        (item) => item.attachment === preview_item.source
      );
      if (removingItem !== undefined) {
        let tempAttachments = attachment_list;
        tempAttachments.splice(attachment_list.indexOf(removingItem), 1);
        let tempPreviews = preview_list;
        tempPreviews.splice(index, 1);
        set_attachment_list([...tempAttachments]);
        set_preview_list([...tempPreviews]);
      }

    } else {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "This will permanently deletes this file from the server.",
        showDenyButton: true,
        confirmButtonText: "Delete",
        denyButtonText: "Cancel",
        denyButtonColor: "#2a3142",
      }).then((result) => {
        if (result.isConfirmed) {
          removingItem = attachment_list.find(
            (item) => item.documentId === preview_item.id
          );
          if (removingItem !== undefined) {
            let tempAttachments = attachment_list;
            tempAttachments.splice(attachment_list.indexOf(removingItem), 1);
            let tempPreviews = preview_list;
            tempPreviews.splice(index, 1);
            set_attachment_list([...tempAttachments]);
            set_preview_list([...tempPreviews]);
          }
        }
      });
    }
  };


  const toPdfDownload = (listSorce) => {

    const documentURL = `${ConfigDB.data.documentServerUrl}${listSorce}`;
    const link = document.createElement("a");
    link.setAttribute("href", documentURL);
    link.setAttribute("download", "sdsdfsdf.pdf");
    link.setAttribute("target", "_blank");
    link.click();
  }


  return (
    <div className="form-group mb-4">
      <label>{label}</label>
      <div ref={fileComponent} className="border rounded p-4 form-control">
        {/* previewer */}
        <div className="form-row">
          {preview_list.map((listItem, index) => (

            <div
              className={`col-12 col-sm-5 m-2 py-2 d-flex align-items-center justify-content-between bg-light rounded`}
              key={index}
            >
              {listItem.type === "application/pdf" ||
                listItem.type === "pdf" ? (
                <>
                  <img width="100px" alt=""
                    //  src={LoadStaticURL(pdfIcon)} 
                    src={
                      listItem.id
                        ? `${ConfigDB.data.documentServerUrl}${listItem.source}`
                        : listItem.source
                    }
                    onError={(event) => {
                      event.target.src = LoadStaticURL(pdfIcon);
                      event.onerror = null;
                    }}
                  />

                  <div className="btn btn-link text-info">
                    <DownloadCloud
                      height={"25px"}
                      onClick={() => toPdfDownload(listItem.source)}
                    />
                  </div>
                </>
              )
                // : (listItem.type === "application/msword" ||
                //   listItem.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ? (
                //   // Display Word icon for Word documents
                //   <img width="100px" alt="" src={LoadStaticURL(wordIcon)} />
                // ) : listItem.type === "application/vnd.ms-excel" ||
                //   listItem.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                //   // Display Excel icon for Excel files
                //   <img width="100px" alt="" src={LoadStaticURL(excelIcon)} />
                // )
                :
                (
                  <img
                    width="200px"
                    alt=""
                    src={
                      listItem.id
                        ? `${ConfigDB.data.fileServerUrl}${listItem.source}`
                        : listItem.source
                    }
                    onError={(event) => {
                      event.target.src = LoadStaticURL(imageOnError);
                      event.onerror = null;
                    }}
                  />

                )}



              <div className="btn btn-link text-danger">
                <Trash
                  height={"20px"}
                  onClick={() => handleRemove(listItem, index)}
                />
              </div>

            </div>
          ))}


        </div>
        {/* input handler */}
        <div className="btn btn-outline-primary position-relative">
          Add Files
          <input
            type="file"
            accept=".pdf,image/*"
            className="form-control"
            onChange={(e) => handleFileUpload(e)}
          />
        </div>
      </div>
      <div ref={fileValidationMessage} className="invalid-feedback"></div>
      <small className="" style={{ color: "#EA2027" }}>
        <ul className="d-sm-flex">
          <li>
            1. Maximum file size is{" "}
            {ConfigDB.data.max_file_input_size_in_bytes / 1024 ** 2}MB.
          </li>
          <li className="mx-sm-3">
            2. You can upload upto {max_file_limit} files.
          </li>
          <li className="">3. Only images and PDF files are allowed.</li>
        </ul>
      </small>
    </div>
  );
};

export default FileUploader;
