import React, { useEffect, useState } from "react";
import { ArrowLeft, ArrowRight, Clock } from "react-feather";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate, useParams } from "react-router-dom";
import useAccess from "../../../../customHooks/useAccess";
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import DetailRowLayout from "../../../common/DetailRowLayout";
import { useSelector } from "react-redux";
import NoDetailsFound from "../../../../assets/images/not-details-found.png";
import { LoadStaticURL } from "../../../../data/util/LoadStaticURL";
import { ConvertMillisToDate } from "../../../../data/util/DateUtil";
import { Col, Row } from "reactstrap";

const Job = ({ b_partner_id = 0, client_id = 0, org_id = 0, set_basic_tab = null, set_back_tab = null }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";

  let navigate = useNavigate();
  const { customer_id } = useParams();

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // form controls
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [customerData, setCustomerData] = useState(undefined);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const sendRequest = useAxiosTemplates();

  useEffect(() => {
    const reqBody = {
      clientId: client_id,
      orgId: org_id,
      userId: loggedUser.userId,
      bpartnerId: b_partner_id,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/viewProfileJobTab",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      setCustomerData(response_data);
      setIsLoaded(LOADED);
    });
  }, []);

  const handleBackClick = () => {
    set_back_tab()
  };

  const handleNextClick = () => {
    set_basic_tab()
  };

  return (
    <div className="card-modified-body bg-white pt-0 mb-4">
      <div>{isLoaded === LOADING ? <Skeleton className="w-25" /> : null}</div>
      <div>

        {/* <Row className="m-b-20 border-bottom d-none d-md-flex">
          <Col lg="2" md="6">
            <div className="fw-bold m-b-10 required-input-mark">
              Job
            </div>
          </Col>
          <Col lg="2" md="6">
            <div className="fw-bold m-b-10 required-input-mark">
            Vacancy
            </div>
          </Col>
          <Col lg="2" md="6">
            <div className="fw-bold m-b-10 required-input-mark">
            Most Interested
            </div>
          </Col>
          <Col lg="2" md="6">
            <div className="fw-bold m-b-10 required-input-mark">
            Remark
            </div>
          </Col>
        </Row> */}

        {isLoaded === LOADED ? (


          customerData.jobList.length > 0 ? (

            customerData.jobList.map((job_item, index) => (
              <div className="mb-4" key={index}>

                {/* <div className="col-sm-12 col-md-8 col-lg-6"> */}
                <div
                  className="d-flex align-items-center mb-2"
                // style={{ minWidth: "max-content" }}
                >
                  <Clock height="12px" className="text-secondary"></Clock>
                  <div className="text-secondary">
                    {job_item.updated === job_item.created
                      ? "Created "
                      : "Last Modified "}
                    on {ConvertMillisToDate(job_item.updated)} By{" "}
                    {job_item.updatedBy}{" "}
                  </div>
                </div>
                {/* </div> */}

                <div key={index} className="rounded mb-2">
                  <DetailRowLayout
                    display={isLoaded === LOADED}
                    labels={["Job", "Vacancy", "Most Interested", "Remark"]}
                    data={
                      isLoaded === LOADED
                        ? [
                          job_item.jobCategoryName,
                          job_item.jobCategoryLineName,
                          `${job_item.isMostInterestedJob ? "Yes" : "No"}`,
                          job_item.remark
                        ]
                        : null
                    }
                    active={job_item.isActive}
                  />
                  {/* <DetailRowLayout
                    display={isLoaded === LOADED}
                    labels={["Remark"]}
                    data={isLoaded === LOADED ? [job_item.remark] : null}
                    active={job_item.isActive}
                    lastRow={true}
                  /> */}
                </div>
              </div>
            ))
          ) : (
            <div className="d-flex flex-column align-items-center">
              <img src={LoadStaticURL(NoDetailsFound)} alt="" width={"200px"} />
              <p className="ml-2 fs-5 fw-bold text-warning">
                No Details Inserted.
              </p>
            </div>
          )
        ) : (
          <>
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Job", "Vacancy", "Most Interested", "Any Comment"]}
              data={[]}
            />
            {/* <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Any Comment"]}
              data={[]}
            /> */}
          </>
        )}
        <div className="d-sm-flex justify-content-end mt-4">
          {checkIsAccessible(EDITFUNCTION) && b_partner_id !== 0 && isLoaded ? (
            <button
              onClick={() =>
                navigate(
                  `/customer/view/${customer_id}/update/save_customer_tab_2/`
                )
              }
              className="btn btn-primary col-md-2 col-12 mx-3 mb-2 mb-sm-0"
            >
              {customerData.jobList.length > 0 ? "Update" : "Insert"}
            </button>



          ) : null}
          {/* {checkIsAccessible(DEACTIVATEFUNCTION) &&
          b_partner_id !== 0 &&
          isLoaded ? (
            <button
              className="ml-2 btn btn-outline-warning col-2"
              type="submit"
            >
              Deactivate
            </button>
          ) : null} */}

          <button type="button" class="btn btn-link col-md-1 col-2 px-1 d-flex align-items-center" onClick={() => handleBackClick()}><ArrowLeft height={"16px"} width={"16px"} />Back</button>

          <button type="button" class="btn btn-link col-md-1 col-2 px-2 d-flex align-items-center" onClick={() => handleNextClick()}>Next<ArrowRight height={"16px"} width={"16px"} /></button>

        </div>
      </div>
    </div>
  );
};

export default Job;
