import React from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import ComingSoonImage from "../../assets/images/coming_soon.png";

const History = () => {
  const { customer_id } = useParams();

  // breadcrumb values
  let childLinks = [
    { value: customer_id, active: false },
    { value: "History", active: true },
  ];

  return (
    <>
      <Breadcrumb parent="Dashboard" title="History" children={childLinks} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body d-flex flex-column align-items-center">
                <img width="500px" src={ComingSoonImage} alt="" />
                <div className="fs-2">Coming Soon...</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
